'use strict';
export const FuncionesAuxiliares = {
  coords,
  dGajo,
  nuevoGajo,
  leyenda,
  nuevaEtiqueta,
  redondearDosDecimales,
  soloValorUnico,
  nullOrValueAsNumber
};

/* Calcular las coordenadas x,y a partir del ángulo y radio. */
const coords = (angle, CX = 130, CY = 120, RADIUS = 100) => {
  let arad = (Math.PI / 180) * angle
  
  return {
    "x": CX + RADIUS * Math.cos(arad),
    "y": CY + RADIUS * Math.sin(arad)
  }
}

/* Crea el valor 'd' del SVG de cada gajo de la circunferencia. */
function dGajo(ap, af, CX = 130, CY = 120, RADIUS = 100) {
  //ap: ángulo inicial
  //af: ángulo final

  let modificadorArco = '1 0,1'

  const { x: Xap, y: Yap } = coords(ap, CX, CY, RADIUS)
  const { x: Xaf, y: Yaf } = coords(af, CX, CY, RADIUS)

  if ((af - ap) > 180) {
    modificadorArco = '0 1,1';
  }

  let parametro_d = `M${ CX },${ CY } L${ Xap },${ Yap } A${ RADIUS },${ RADIUS } ${ modificadorArco } ${ Xaf },${ Yaf } z`

  return parametro_d;
}

/* Crea cada gajo de la circunferencia. */
function nuevoGajo(ap, af, color, i) {

  return `<path id="gajo${ i }" fill="${ color }" stroke="#fff" stroke-width="2" d="${ dGajo(ap, af) }"></path>`;

}

function leyenda(y, color, texto) {

  return `<rect x="0" y="${ y }" width="20" height="20" fill="${ color }"></rect>
           <text x="30" y="${ parseInt(y + 18) }"><tspan >${ texto }</tspan></text>`

}

/* Crea la etiqueta con el porcentaje dentro de la circunferencia. */
function nuevaEtiqueta(ap, af, text, idEtiqueta) {

  let ac = ap + (af - ap) / 2
  const { x, y } = coords(ac)

  return `<text id="etiqueta` + idEtiqueta + `" x="` + x + `" y="` + y + `" fill="#fff" text-anchor="middle" dominant-baseline="middle"><tspan >` + text + `</tspan></text>`

}

function redondearDosDecimales(num) {
  return Number.parseFloat(num).toFixed(2);
}

function soloValorUnico(value, index, self) {
  return self.indexOf(value) === index;
}

function nullOrValueAsNumber (value) {
  if(value === null){
    return null
  }

  return Number((value).toFixed(2))
}