import { MAINTENANCE_APP_KEY_CHECK } from '../utility/constants'

export async function checkMaintenanceStatus() {
  const endpoint = `${process.env.MAINTENANCE_API_BASE_URL}/check-maintenance`
  const maintenanceDefined = !!process.env.MAINTENANCE_API_BASE_URL

  if (!maintenanceDefined) {
    return { status: false, message: '' }
  }

  try {
    const response = await fetch(endpoint)

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    const cookbookMaintenance = data.find(item => item.app === MAINTENANCE_APP_KEY_CHECK && item.active === true)
    return {
      status: cookbookMaintenance.active,
      message: cookbookMaintenance.message || '',
    }
  } catch (error) {
    console.error('Error fetching maintenance status:', error)
    throw error
  }
}
