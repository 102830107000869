"use strict";
import React, { Component } from "react";
import { PlantillasRecetas } from "../utility/plantillasRecetas";
import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  Divider,
  Modal,
  Typography,
  Select,
  Table,
  ConfigProvider,
  BackTop,
  Checkbox,
  Progress,
  Space,
  Transfer,
} from "antd";
import { database } from "../services/database.service";
import { userService } from "../services";
import "./register-files";
const { Title } = Typography;
import { IMPRIMIR_RECETAS_MENU_COLUMNS } from "../utility/constants";
import CustomizeRenderEmpty from "../components/CustomizeRenderEmpty";

class ImprimirRecetasBloque extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      users: [],
      redirecting: false,
      loading: false,
      iconLoading: false,
      employee: {},
      employees: [],
      options: [],
      listaOptionsEstadosMenus: [],
      optionsEstadosMenu: [],
      statusListaEstadosMenus: null,
      optionsCentrosUsuarios: [],
      usuario: null,
      resultadoBusquedaPorPlato: [],
      statusResultadoBusquedaPorPlato: null,
      letallowClearDieta: true,
      statusValueEstadoMenu: null,
      statusValueNomMenu: null,
      statusValueCodMenu: null,
      filteredInfo: null,
      sortedInfo: null,
      exportarPlatosExcel: [],
      familiaSeleccionada: null,
      dietaSeleccionada: null,
      nombrePlato: null,
      selectedRows: [],
      selectedRowKeys: [],
      selectedRowsMenu: [],
      selectedRowKeysMenu: [],
      listaFotosRecetas: [],
      loadingSpingBuscarPlato: false,
      textoLoadingSpingBuscarPlato: "",
      visibleModalLogoRecetasMe100: false,
      visibleModalLogoRecetasMa100: false,
      valorSelectLogo: "",
      valorSelectPlantillaReceta: "",
      checkboxImprimirFamiliaPlato: false,
      checkboxImprimirPesoPlato: false,
      checkBoxGroupRecetasImprimir: [],
      checkAll: false,
      listaOptionsCatalogosUsuario: [],
      optionsCatalogosUsuario: [],
      checkboxImprimirImagenPlato: false,
      dataRecetasM100: [],
      visibleModalProgreso: false,
      porcientoModalProgreso: 25,
      listaIdiomas: [],
      mockData: [],
      targetKeys: [],
      visibleModalSeletImpresionRecetas: false,
      catalogoActualIdioma: "",
      idiomaActual: "525",
    };
  }

  /**
   * Sacame todo lo que tengas que sacar de la API antes de imprimir Pantalla Buscar Por Plato */
  componentWillMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem("user")),
      users: { loading: true },
      employee: JSON.parse(localStorage.getItem("employee")),
      employees: { loading: true },
      isLoaded: true,
      loadingSpingBuscarPlato: true,
      textoLoadingSpingBuscarPlato:
        "Cargando pantalla para imprimir recetas por menú...",
      catalogoActualIdioma: "",
      idiomaActual: "525",
    });

    this.getlistaOptionsEstadosMenus();
    this.getlistaOptionsCatalogosUsuario();
    this.getListaIdiomas();
  }

  onChange = (nextTargetKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  /**
   Obtengo el listado de idiomas y actualizo el estado de los elementos
 */
  getListaIdiomas = () => {
    database.getIdiomas().then((res) => {
      this.setState({
        listaIdiomas: res.response,
        statusListaIdiomas: "success",
      });
    });
  };
  /**
      Obtengo el listado de catálogos del usuario y actualizo estado.
    */
  getlistaOptionsCatalogosUsuario = () => {
    database
      .getCatalogosUsuario(
        JSON.parse(localStorage.getItem("user")).sAMAccountName
      )
      .then((res) => {
        this.setState({
          listaOptionsCatalogosUsuario: res.response,
        });

        let resultadoCatalogoUsuario = [];
        for (
          let i = 0;
          i < this.state.listaOptionsCatalogosUsuario.length;
          i++
        ) {
          resultadoCatalogoUsuario.push({
            value: `${this.state.listaOptionsCatalogosUsuario[i].CATALOGO}`,
            label: `${this.state.listaOptionsCatalogosUsuario[i].NOMBRE}`,
          });
        }

        this.setState({
          optionsCatalogosUsuario: resultadoCatalogoUsuario,
          loadingSpingBuscarPlato: false,
          textoLoadingSpingBuscarPlato: "",
        });
      });
  };

  /**
    Obtengo el listado de estados de los menús y actualizo estado.
  */
  getlistaOptionsEstadosMenus = () => {
    database.getEstadosMenus().then((res) => {
      this.setState({
        listaOptionsEstadosMenus: res.response,
        statusListaEstadosMenus: "success",
      });

      let resultadoEstadosMenu = [];
      for (let i = 0; i < this.state.listaOptionsEstadosMenus.length; i++) {
        resultadoEstadosMenu.push({
          value: `${this.state.listaOptionsEstadosMenus[i].ID_ESTADO_MENU}`,
          label: `${this.state.listaOptionsEstadosMenus[i].NOMBRE}`,
        });
      }

      this.setState({ optionsEstadosMenu: resultadoEstadosMenu });
    });
  };

  /**
   * Recetear los campos del buscador al pinchar botón cancelar.
   * */
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      statusValueEstadoMenu: null,
      statusValueNomMenu: null,
      statusValueCodMenu: null,
      loading: false,
      selectedRows: [],
      selectedRowKeys: [],
      selectedRowsMenu: [],
      selectedRowKeysMenu: [],
    });
  };

  /**
   * Manejar la paginación al mostrar el resultado de la busqueda de las recetas.
   * */
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  /**
   * Verificar que el usurio tenga seleccionado el menú y mostrar el Modal dialogs
   * para que el usuario selecciones el logo que se debe imrimir en la plantilla de la etiqueta.
   */
  confirmacionImprimirRecetasPDF = () => {
    try {
      this.setState({
        selectedRowKeys: [],
        porcientoModalProgreso: 25,
      });

      if (this.state.selectedRowKeysMenu.length === 0) {
        Modal.warning({
          title: "Advertencia",
          content: "Debes seleccionar un menú para imprimir las recetas.",
        });
      } else {
        let listadoRecetasMenu = "null";
        let fechaServicio = "null";
        let tipoMenu = "null";
        let codMenu = this.state.selectedRowKeysMenu[0];
        /**
         * Obtengo el listado de recetas con sus datos dado el menú...
         */
          database
            .getRecetasMenu(
              codMenu.toString(),
              listadoRecetasMenu,
              fechaServicio,
              tipoMenu
            )
            .then(
              (valor) => {
                let listaRecetasMenuValores = valor.response;

                if (
                  listaRecetasMenuValores.status === "success" &&
                  listaRecetasMenuValores.message.length > 0
                ) {
                  let listaCodigoRecetasTabla = [];

                  let listaRecetasMenu = listaRecetasMenuValores.message;

                  for (let i = 0; i < listaRecetasMenu.length; i++) {
                    listaCodigoRecetasTabla.push({
                      key: listaRecetasMenu[i].CODIGO_RECETA,
                      cod_receta: listaRecetasMenu[i].CODIGO_RECETA,
                      nombre_receta: listaRecetasMenu[i].NCOMERCIAL_RECETA,
                      dec_alergenos:
                        listaRecetasMenu[i].DECLARACION_ALERGENOS ===
                        "INCOMPLETA" ? (
                          <span style={{ color: "#f3000c" }}>
                            {listaRecetasMenu[i].DECLARACION_ALERGENOS}
                          </span>
                        ) : (
                          listaRecetasMenu[i].DECLARACION_ALERGENOS
                        ),
                    });
                  }

                  this.setState({
                    visibleModalLogoRecetasMa100: true,
                    //listaRecetasMenuOrdenadas: listaCodigoRecetas,
                    dataRecetasM100: listaCodigoRecetasTabla,
                  });
                } else if (
                  listaRecetasMenuValores.status === "success" &&
                  listaRecetasMenuValores.message.length === 0
                ) {
                  Modal.error({
                    title: "Error",
                    content: "El menú seleccionado no tiene recetas asociadas.",
                  });
                } else {
                  Modal.error({
                    title: "Error",
                    content:
                      "Ha ocurrido el siguiente error: " +
                      listaRecetasMenuValores.message,
                    onOk() {},
                  });
                }
              },
              (error) => this.setState({ error, loading: true })
            );
        
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content:
          "Ha ocurrido el siguiente error al Recuperar las recetas del Menú seleccionado: " +
          e +
          ". Contactar con el administrador.",
      });
    }
  };

  /**
   * Imprime las recetas seleccionadas por el usuario almacenadas en this.state.selectedRowKeys
   */
  imprimirRecetasPDF = () => {
    this.setState({ listaIngredientesRecetas: [] });

    let listaCodigoRecetas = this.state.targetKeys;

    try {
      if (listaCodigoRecetas.length != 0 && listaCodigoRecetas.length <= 60) {
        const catalogoActualIdioma = this.state.catalogoActualIdioma;
        const idiomaActual = this.state.idiomaActual;
        const valorSelectLogo = this.state.valorSelectLogo;
        const checkboxImprimirImagenPlato =
          this.state.checkboxImprimirImagenPlato;
        const checkboxImprimirFamiliaPlato =
          this.state.checkboxImprimirFamiliaPlato;
        const checkboxImprimirPesoPlato = this.state.checkboxImprimirPesoPlato;
        const tiempo = checkboxImprimirImagenPlato === true ? 2000 : 1000;
        const plantilla = this.state.valorSelectPlantillaReceta;

        if (plantilla === "plantilla-recetas-1") {
          //Plantilla Comercial
          PlantillasRecetas.imprimirRecetasPlantilla1(
            catalogoActualIdioma,
            listaCodigoRecetas.toString(),
            idiomaActual,
            valorSelectLogo,
            checkboxImprimirImagenPlato,
            checkboxImprimirFamiliaPlato,
            checkboxImprimirPesoPlato
          );
        } else if (plantilla === "plantilla-recetas-2") {
          //Plantilla Centros In Situ
          PlantillasRecetas.imprimirRecetasPlantilla2(
            catalogoActualIdioma,
            listaCodigoRecetas.toString(),
            idiomaActual,
            valorSelectLogo,
            checkboxImprimirImagenPlato
          );
        } else if (plantilla === "plantilla-recetas-3") {
          //Plantilla Centros Transportado
          if (listaCodigoRecetas.length <= 15) {
            PlantillasRecetas.imprimirRecetasPlantilla3(
              catalogoActualIdioma,
              listaCodigoRecetas.toString(),
              idiomaActual,
              valorSelectLogo,
              checkboxImprimirImagenPlato
            );
          } else {
            Modal.error({
              title: "Error",
              content: `Para la plantilla seleccionada solo puede imprimir bloques de 20 recetas. Actualmente tiene seleccionadas ${listaCodigoRecetas.length} recetas.`,
            });
          }
        } else if (plantilla === "plantilla-recetas-4") {
          //Plantilla Centros Transportado
          if (listaCodigoRecetas.length <= 15) {
            PlantillasRecetas.imprimirRecetasPlantilla4(
              catalogoActualIdioma,
              listaCodigoRecetas.toString(),
              idiomaActual,
              valorSelectLogo,
              checkboxImprimirImagenPlato
            );
          } else {
            Modal.error({
              title: "Error",
              content: `Para la plantilla seleccionada solo puede imprimir bloques de 20 recetas. Actualmente tiene seleccionadas ${listaCodigoRecetas.length} recetas.`,
            });
          }
        }

        setTimeout(() => {
          this.setState({
            loadingSpingBuscarPlato: false,
            textoLoadingSpingBuscarPlato: "",
            checkboxImprimirFamiliaPlato: false,
            checkboxImprimirPesoPlato: false,
            checkboxImprimirImagenPlato: false,
            checkBoxListaCodigoRecetasOrdenadas: [],
            visibleModalProgreso: false,
          });
        }, tiempo * listaCodigoRecetas.length);
      } else {
        Modal.error({
          title: "Error",
          content:
            "Debe seleccionar como mínimo una receta para imprimir y como mucho 60 recetas a la vez.",
        });
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content:
          "Ha ocurrido el siguiente error al Imprimir las Fichas Técnicas: " +
          e +
          ". Contactar con el administrador.",
      });
      this.setState({
        loadingSpingBuscarPlato: false,
        textoLoadingSpingBuscarPlato: "",
        checkboxImprimirFamiliaPlato: false,
        checkboxImprimirPesoPlato: false,
        checkboxImprimirImagenPlato: false,
        checkBoxListaCodigoRecetasOrdenadas: [],
        visibleModalProgreso: false,
        visibleModalSeletImpresionRecetas: false,
        porcientoModalProgreso: 25,
      });
    }
  };

  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
      });
    }, 1000);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  filterOptionTransfer = (inputValue, option) =>
    option.description.indexOf(inputValue) > -1;

  handleChangeTransfer = (targetKeys) => {
    this.setState({ targetKeys });
  };

  onFinishBuscarRecetasCatalogo = (idioma, catalogo) => {
    /**
     * Actualizo estados para mostrar Cargando datos hasta que termine de recuperar
     * y mostrar los datos
     */
    this.setState({
      loadingSpingBuscarPlato: true,
      textoLoadingSpingBuscarPlato:
        "Cargando recetas del catálogo " + catalogo + " ...",
    });

    /**
     * Ejecuto la consulta para recuperar los menús
     */
    try {
        database.getListadoRecetasNCIdiomas(catalogo, idioma).then(
          (valor) => {
            const listaRecetasIdioma = valor.response;

            if (listaRecetasIdioma.length > 0) {
              const targetKeysNew = [];
              const mockDataNew = [];
              for (let i = 0; i < listaRecetasIdioma.length; i++) {
                const data = {
                  key: listaRecetasIdioma[i].COD_RECETA.toString(),
                  title:
                    "(" +
                    listaRecetasIdioma[i].COD_RECETA +
                    ") " +
                    listaRecetasIdioma[i].NOM_RECETA,
                  description:
                    "(" +
                    listaRecetasIdioma[i].COD_RECETA +
                    ") " +
                    listaRecetasIdioma[i].NOM_RECETA,
                };
                mockDataNew.push(data);
              }
              this.setState({
                mockData: mockDataNew,
                targetKeys: targetKeysNew,
                visibleModalSeletImpresionRecetas: true,
              });
              this.setState({
                loadingSpingBuscarPlato: false,
                textoLoadingSpingBuscarPlato: "",
              });
            } else {
              Modal.error({
                title: "Error",
                content:
                  "No se han recuperado las recetas del catálogo " +
                  catalogo +
                  " en el idioma seleccionado.",
              });
            }
          },
          (error) => this.setState({ error, loading: true })
        );
      
    } catch (err) {
      Modal.error({
        title: "Error",
        content:
          "Ha ocurrido el siguiente error al recuperar las recetas del catálogo " +
          catalogo +
          ": " +
          err,
      });
    }
  };

  loadingSpingImprimirRecetas = () => {
    this.setState({
      loadingSpingBuscarPlato: true,
      textoLoadingSpingBuscarPlato: "Cargando datos...",
    });
  };

  onChangeStateMenu = () => {
    this.setState({
      statusValueEstadoMenu: "success",
    });
  };

  onChangeCodMenu = (e) => {
    const sd = e;
    if (sd === "") {
      this.setState({
        statusValueCodMenu: "null",
      });
    } else {
      this.setState({
        statusValueCodMenu: "success",
      });
    }
  };

  onChangeNameMenu = (e) => {
    const sd = e.target.value;
    if (sd === "") {
      this.setState({
        statusValueNomMenu: "null",
      });
    } else {
      this.setState({
        statusValueNomMenu: "success",
      });
    }
  };

  /**
   * Comprueba que se haya seleccionado un logo y lanzo la impresión de las recetas llamando a
   * la fucnión imprimirRecetasPDF y actualizo el estado de mantener vislible el Modal dialogs de los logos.
   */
  handleOkModalLogo = () => {
    if (
      (this.state.valorSelectLogo.length === 0 ||
        this.state.valorSelectPlantillaReceta.length === 0) &&
      this.state.targetKeys.length > 0
    ) {
      Modal.error({
        title: "Error",
        content:
          "Te has dejado por seleccionar el logo o la plantilla para imprimir la(s) recetas.",
      });
    } else if (
      this.state.valorSelectLogo.length > 0 &&
      this.state.targetKeys.length === 0
    ) {
      Modal.error({
        title: "Error",
        content:
          "Debes seleccionar al menos una receta antes de lanzar la impresión.",
      });
    } else if (
      this.state.valorSelectLogo.length === 0 &&
      this.state.targetKeys.length === 0 &&
      this.state.valorSelectPlantillaReceta.length === 0
    ) {
      Modal.error({
        title: "Error",
        content:
          "Debes seleccionar al menos una receta así como el logo y la plantilla antes de lanzar la impresión.",
      });
    } else {
      this.imprimirRecetasPDF();
    }
  };

  /**
   * Actualizo el estado de mantener vislible el Modal dialogs de los logos al usuario cancelar la acción.
   */
  handleCancelModalLogo = () => {
    this.setState({
      visibleModalLogoRecetasMe100: false,
      visibleModalLogoRecetasMa100: false,
      // listaRecetasMenuOrdenadas: [],
      selectedRowKeys: [],
      checkBoxListaCodigoRecetasOrdenadas: [],
    });
  };

  /**
   * Actualizo el estado con el valor del logo seleccionado por el usuario.
   */

  onChangeSelectLogo = (value) => {
    this.setState({
      statusValueFamilia: "success",
      valorSelectLogo: value,
    });
  };

  /**
   * Actualizo el estado con el valor de la plantilla seleccionada por el usuario.
   */

  onChangePlantillaReceta = (value) => {
    this.setState({
      valorSelectPlantillaReceta: value,
    });
  };

  /**
   * Mostar checkbox para imprimir o no la familia en la receta.
   */
  onChangeCheckboxImprimirFamiliaPlato = (e) => {
    let checkedGU = e.target.checked;

    this.setState({
      checkboxImprimirFamiliaPlato: checkedGU,
    });
  };

  /**
   * Mostar checkbox para imprimir o no el peso de la receta.
   */
  onChangeCheckboxImprimirPesoPlato = (e) => {
    let checkedGU = e.target.checked;

    this.setState({
      checkboxImprimirPesoPlato: checkedGU,
    });
  };

  /**
   * Mostar checbox para imprimir o no la imagen en la receta.
   */
  onChangeCheckboxImprimirImagenPlato = (e) => {
    let checkedGU = e.target.checked;

    this.setState({
      checkboxImprimirImagenPlato: checkedGU,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  /**
   * Cancelar la modal Seleccionar si vas a imprimir por Menú o por bloques las Recetas.
   */
  handleCancelModalSeletImpresionRecetas = () => {
    this.setState({
      visibleModalSeletImpresionRecetas: false,
      checkboxImprimirFamiliaPlato: false,
      checkboxImprimirPesoPlato: false,
      checkboxImprimirImagenPlato: false,
    });
  };

  /**
   * Imprimo Idioma seleccionado
   */
  onChangeIdiomas = (value) => {
    this.setState({
      idiomaActual: value,
      catalogoActualIdioma: this.state.catalogoActualIdioma,
    });
  };

  render() {
    const { selectedRowKeys, dataRecetasM100 } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelectedM100 = selectedRowKeys.length > 0;

    const { Option } = Select;

    let resultadoEstadosMenu = [];
    for (let i = 0; i < this.state.listaOptionsEstadosMenus.length; i++) {
      resultadoEstadosMenu.push(
        <Select.Option
          key={this.state.listaOptionsEstadosMenus[i].ID_ESTADO_MENU}
          label={this.state.listaOptionsEstadosMenus[i].NOMBRE}
          title={this.state.listaOptionsEstadosMenus[i].NOMBRE}
        >
          {this.state.listaOptionsEstadosMenus[i].NOMBRE}
        </Select.Option>
      );
    }

    /**
     * Construyo el select con los catálogos del usuario que se van a mostrar
     */
    let resultadoCentros = [];
    for (let i = 0; i < this.state.listaOptionsCatalogosUsuario.length; i++) {
      resultadoCentros.push(
        <Select.Option
          key={this.state.listaOptionsCatalogosUsuario[i].CATALOGO}
          label={this.state.listaOptionsCatalogosUsuario[i].NOMBRE}
          title={this.state.listaOptionsCatalogosUsuario[i].NOMBRE}
        >
          {this.state.listaOptionsCatalogosUsuario[i].CATALOGO +
            "." +
            this.state.listaOptionsCatalogosUsuario[i].NOMBRE}
        </Select.Option>
      );
    }

    /**
     * Construyo el select con los idiomas que se van a mostrar en el select
     */
    let resultadoIdiomas = [];

    for (let i = 0; i < this.state.listaIdiomas.length; i++) {
      resultadoIdiomas.push(
        <Select.Option
          key={this.state.listaIdiomas[i].COD_IDIOMA}
          label={
            this.state.listaIdiomas[i].COD_IDIOMA +
            "." +
            this.state.listaIdiomas[i].DESC_IDIOMA
          }
          title={
            this.state.listaIdiomas[i].COD_IDIOMA +
            "." +
            this.state.listaIdiomas[i].DESC_IDIOMA
          }
        >
          {this.state.listaIdiomas[i].DESC_IDIOMA}
        </Select.Option>
      );
    }

    const {
      loadingSpingBuscarPlato,
      textoLoadingSpingBuscarPlato,
      visibleModalLogoRecetasMa100,
    } = this.state;

    /**
     * Formulario para buscar recetas del catálogo seleccionado.
     */
    const AdvancedSearchForm = () => {
      const [form] = Form.useForm();

      const onFinish = (values) => {
        this.setState({
          catalogoActualIdioma: values.selectCatalogo,
          idiomaActual:
            values.selectIdioma !== undefined ? values.selectIdioma : "525",
        });
        let selectCatalogo = this.state.catalogoActualIdioma;
        let selectIdiomas = this.state.idiomaActual;

        this.onFinishBuscarRecetasCatalogo(selectIdiomas, selectCatalogo);
      };

      return (
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <Row gutter={8}>
            <Col
              xs={{ span: 12, offset: 1 }}
              lg={{ span: 8, offset: 1 }}
              style={{ float: "left" }}
            >
              <Form.Item
                name="selectCatalogo"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Campo obligatorio.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seleccionar Catálogo"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {resultadoCentros}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 12, offset: 1 }}
              lg={{ span: 6, offset: 1 }}
              style={{ float: "left" }}
            >
              <Form.Item
                hasFeedback
                name="selectIdioma"
                rules={[
                  {
                    required: false,
                    message: "Campo obligatorio.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seleccionar Idioma"
                  optionFilterProp="children"
                  defaultValue={this.state.idiomaActual}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {resultadoIdiomas}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }}>
              <Button
                key="back"
                onClick={() => {
                  form.resetFields();
                }}
                style={{ float: "left" }}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "left", marginLeft: 20 }}
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </Form>
      );
    };

    return (
      <div>
        <ConfigProvider renderEmpty={CustomizeRenderEmpty}>
          <Spin
            spinning={loadingSpingBuscarPlato}
            delay={500}
            tip={textoLoadingSpingBuscarPlato}
          >
            <div>
              <AdvancedSearchForm />
              <div id="infoBusquedaPorPlato"></div>
              <Modal
                visible={visibleModalLogoRecetasMa100}
                title="Imprimir Recetas del Menú"
                onOk={this.handleOkModalLogo}
                width={770}
                style={{ top: 20 }}
                onCancel={this.handleCancelModalLogo}
                footer={[
                  <Button key="back" onClick={this.handleCancelModalLogo}>
                    Cancelar
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={this.handleOkModalLogo}
                  >
                    Aceptar
                  </Button>,
                ]}
              >
                <p className="select-modal">
                  Debes seleccionar el logo y si quieres mostrar o no la familia
                  y la imagen de la receta. Esta acción puede tardar en
                  dependencia de la cantidad de recetas que tenga el menú.
                </p>
                <Row>
                  <Col span={8}>
                    <Checkbox
                      checked={this.state.checkboxImprimirFamiliaPlato}
                      onChange={this.onChangeCheckboxImprimirFamiliaPlato}
                    >
                      Imprimir familia.
                    </Checkbox>
                  </Col>

                  <Col span={8}>
                    <Checkbox
                      checked={this.state.checkboxImprimirImagenPlato}
                      onChange={this.onChangeCheckboxImprimirImagenPlato}
                    >
                      Imprimir imagen.
                    </Checkbox>
                  </Col>

                  <Col span={8}>
                    <Select
                      showSearch
                      style={{ width: "90%" }}
                      id="selectBuscador"
                      placeholder="Seleccionar Logo"
                      optionFilterProp="children"
                      onChange={this.onChangeSelectLogo}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="images/logoCompassGroup.png">
                        Compass Group
                      </Option>
                      <Option value="images/logoEurestColectividades.png">
                        Eurest Colectividades
                      </Option>
                      <Option value="images/logoEurestCatalunya.png">
                        Eurest Catalunya
                      </Option>
                      <Option value="images/logoEurestEuskadi.png">
                        Eurest Euskadi
                      </Option>
                      <Option value="images/logoEurestServices.png">
                        Eurest Services
                      </Option>
                      <Option value="images/logoMedirest.png">Medirest</Option>
                      <Option value="images/logoScolarest.png">
                        Scolarest
                      </Option>
                      <Option value="images/logoVilaplana.png">
                        Vilaplana
                      </Option>
                      <Option value="images/logoVitarest.png">Vitarest</Option>
                    </Select>
                  </Col>
                </Row>
                <Title level={4}>Listado de platos asociados al menú.</Title>
                <div>
                  <Row>
                    <Col span={18}>
                      <span style={{ marginLeft: 8, marginBottom: 8 }}>
                        {hasSelectedM100
                          ? `Seleccionadas ${selectedRowKeys.length} recetas`
                          : ""}
                      </span>
                    </Col>
                    <Col span={6}>
                      <Button
                        style={{ marginRight: 10, marginBottom: 8 }}
                        key="back"
                        onClick={this.handleCancelModalLogo}
                      >
                        Cancelar
                      </Button>
                      <Button
                        key="submit"
                        type="primary"
                        onClick={this.handleOkModalLogo}
                      >
                        Aceptar
                      </Button>
                    </Col>
                  </Row>

                  <Divider style={{ margin: 0 }}></Divider>
                  <Table
                    size="small"
                    rowSelection={rowSelection}
                    columns={IMPRIMIR_RECETAS_MENU_COLUMNS}
                    dataSource={dataRecetasM100}
                    pagination={{
                      position: ["topRight", "bottomRight"],
                      pageSizeOptions: ["40", "50", "60"],
                      defaultPageSize: 40,
                    }}
                  />
                </div>
              </Modal>
            </div>
          </Spin>
          <Modal
            title="Progreso Impresión Recetas"
            visible={this.state.visibleModalProgreso}
            footer={null}
            closable={false}
          >
            <Space direction="vertical">
              <p>
                El proceso de impresión puede tardar unos minutos. Depende de la
                cantidad de recetas y el peso de la imagen de la misma.
              </p>
              <Row gutter={8}>
                <Col span={24}>
                  <Progress
                    style={{ marginLeft: 170 }}
                    type="circle"
                    percent={this.state.porcientoModalProgreso}
                  />
                </Col>
              </Row>
            </Space>
          </Modal>
          <Modal
            visible={this.state.visibleModalSeletImpresionRecetas}
            title="Seleccionar Recetas"
            maskClosable={false}
            width={890}
            closable={true}
            style={{ top: 20 }}
            footer={null}
            onCancel={this.handleCancelModalSeletImpresionRecetas}
          >
            <Space direction="vertical">
              <p>
                El número de recetas a imprimir por bloque dependerá de la
                plantilla. Lo estándar es que sean como mucho 60 recetas para
                imprimir en cada bloque.
              </p>

              <Row>
                <Col span={5}>
                  <Checkbox
                    checked={this.state.checkboxImprimirFamiliaPlato}
                    onChange={this.onChangeCheckboxImprimirFamiliaPlato}
                  >
                    Imprimir familia.
                  </Checkbox>
                </Col>

                <Col span={5}>
                  <Checkbox
                    checked={this.state.checkboxImprimirImagenPlato}
                    onChange={this.onChangeCheckboxImprimirImagenPlato}
                  >
                    Imprimir imagen.
                  </Checkbox>
                </Col>

                <Col span={7}>
                  <Select
                    showSearch
                    style={{ width: "90%" }}
                    id="selectBuscador"
                    placeholder="Seleccionar Logo"
                    optionFilterProp="children"
                    onChange={this.onChangeSelectLogo}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="images/logoCompassGroup.png">
                      Compass Group
                    </Option>
                    <Option value="images/logoEurestColectividades.png">
                      Eurest Colectividades
                    </Option>
                    <Option value="images/logoEurestCatalunya.png">
                      Eurest Catalunya
                    </Option>
                    <Option value="images/logoEurestEuskadi.png">
                      Eurest Euskadi
                    </Option>
                    <Option value="images/logoEurestServices.png">
                      Eurest Services
                    </Option>
                    <Option value="images/logoMedirest.png">Medirest</Option>
                    <Option value="images/logoScolarest.png">Scolarest</Option>
                    <Option value="images/logoVilaplana.png">Vilaplana</Option>
                    <Option value="images/logoVitarest.png">Vitarest</Option>
                  </Select>
                </Col>

                <Col span={7}>
                  <Select
                    showSearch
                    style={{ width: "90%" }}
                    id="selectBuscador"
                    placeholder="Seleccionar plantilla"
                    optionFilterProp="children"
                    onChange={this.onChangePlantillaReceta}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option
                      key="1"
                      label="plantilla-recetas1"
                      value="plantilla-recetas-1"
                    >
                      Plantilla Comercial
                    </Option>
                    <Option
                      key="2"
                      label="plantilla-recetas2"
                      value="plantilla-recetas-2"
                    >
                      Plantilla Centros In Situ GV
                    </Option>
                    <Option
                      key="3"
                      label="plantilla-recetas3"
                      value="plantilla-recetas-3"
                    >
                      Plantilla Centros Transportados GV
                    </Option>
                    <Option
                      key="4"
                      label="plantilla-recetas4"
                      value="plantilla-recetas-4"
                    >
                      Plantilla Cocina Central GV
                    </Option>
                  </Select>
                </Col>

                <Row>
                  <Col>
                    <Checkbox
                      checked={this.state.checkboxImprimirPesoPlato}
                      onChange={this.onChangeCheckboxImprimirPesoPlato}
                    >
                      Imprimir Peso Neto/Bruto.
                    </Checkbox>
                  </Col>
                </Row>
              </Row>
              <Row gutter={24}>
                <Col key={4} span={24}>
                  <Transfer
                    dataSource={this.state.mockData}
                    showSearch
                    filterOption={this.filterOptionTransfer}
                    targetKeys={this.state.targetKeys}
                    onChange={this.handleChangeTransfer}
                    render={(item) => item.title}
                    listStyle={{
                      width: 400,
                      height: 320,
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Button
                    style={{ float: "right" }}
                    key="submit"
                    type="primary"
                    onClick={this.handleOkModalLogo}
                  >
                    Aceptar
                  </Button>

                  <Button
                    style={{ float: "right", marginRight: 10, marginBottom: 8 }}
                    key="back"
                    onClick={this.handleCancelModalSeletImpresionRecetas}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Space>
          </Modal>
        </ConfigProvider>
        <BackTop />
      </div>
    );
  }
}

export default ImprimirRecetasBloque;
