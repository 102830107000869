"use strict";

import React from "react";
import { Modal, Progress } from "antd";
import { database } from "../services/database.service";
import PDFDocument from "pdfkit";
import blobStream from "blob-stream";
import { FuncionesAuxiliares } from "./funcionesAuxiliares";

var f = new Date();
const fechaActual =
  f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear();
var SVGtoPDF = require("svg-to-pdfkit");
const informacionAlergenos =
  "* La información sobre alérgenos ha sido obtenida de los datos facilitados por los proveedores y puede estar sujeta a cambios por lo que siempre debe cotejarse con la lectura de etiqueta u otro soporte facilitado por el proveedor.";

var infoAlergenosNoComercial =
  "* La información sobre alérgenos ha sido obtenida de los datos facilitados por los proveedores y puede estar sujeta a cambios por lo que siempre debe cotejarse con la lectura de etiqueta u otro soporte facilitado por el proveedor. Esta Ficha técnica es propiedad de la compañía, cualquier copia, difusión o cesión de la misma queda totalmente prohibida sin su previa autorización.";

export const PlantillasRecetas = {
  imprimirRecetasPlantilla1,
  imprimirRecetasPlantilla2,
  imprimirRecetasPlantilla3,
  imprimirRecetasPlantilla4,
};

function llamarModal(tiempoSetTimeOut, modal) {
  modal.update({
    title: "Información",
    content: (
      <div>
        <p>
          Se están imprimiendo las recetas. Esta acción puede tardar unos
          segundos...
        </p>
        <Progress percent={25} status="active" />
      </div>
    ),
  });

  setTimeout(() => {
    modal.update({
      content: (
        <div>
          <p>
            Se están imprimiendo las recetas. Esta acción puede tardar unos
            segundos...
          </p>
          <Progress percent={50} status="active" />
        </div>
      ),
    });
  }, tiempoSetTimeOut / 2);

  setTimeout(
    () => {
      modal.update({
        content: (
          <div>
            <p>
              Se están imprimiendo las recetas. Esta acción puede tardar unos
              segundos...
            </p>
            <Progress percent={75} status="active" />
          </div>
        ),
      });
    },
    tiempoSetTimeOut / 1,
    5
  );
}

async function imprimirRecetasPlantilla1(
  catalogoActualIdioma,
  listaCodigoRecetas,
  idiomaActual,
  valorSelectLogo,
  checkboxImprimirImagenPlato,
  checkboxImprimirFamiliaPlato,
  checkboxImprimirPesoPlato
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    const modal = Modal.info();
    const tiempo = checkboxImprimirImagenPlato === true ? 110 : 50;

    llamarModal(tiempo * listaCodigoRecetas.length, modal);

    /**
     * Obtengo el listado de recetas con sus datos dado el menú...
     */

    database
      .getRecetasBloques(catalogoActualIdioma, listaCodigoRecetas, idiomaActual)
      .then((valor) => {
        const listaRecetasMenuValores = valor.response;
        console.log("depués");

        /**
         * Recorro el JSON listaRecetasMenu para obtener solamente los códigos de las recetas para buscar sus ingredientes y fotos
         */

        if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length > 0
        ) {
          var listaRecetasMenu = listaRecetasMenuValores.message;
          /**
           * Recupero todos los ingredientes de las recetas seleccionadas por el usuario.
           */
          database.getIngredientesRecetas(listaCodigoRecetas).then((valor) => {
            const listaIngredientesRecetas = valor.response;

            database
              .getTotalIngredientesRecetas(listaCodigoRecetas)
              .then((valor) => {
                const listaTotalIngredientesRecetas = valor.response;

                console.log("listaRecetasMenu");
                console.log(listaRecetasMenu);
                console.log("listaIngredientesRecetas");
                console.log(listaIngredientesRecetas);
                console.log("listaTotalIngredientesRecetas");
                console.log(listaTotalIngredientesRecetas);

                //this.setState({ listaFotosRecetas: [] });

                //Crear el documento
                var doc = new PDFDocument({
                  layout: "landscape",
                  //size: [210, 210],
                  margin: 5,
                  info: {
                    Title: "Recetas",
                    Author: "Alejandro Vázquez Chávez",
                  },
                });

                //Canalizar el documento a un blob
                var stream = doc.pipe(blobStream());

                //Obtener lista de recetas con fotos a imprimir

                let count = 0;
                let error = 0;

                listaRecetasMenu.forEach(async function (listItem, i) {
                  let listaFotosRecetas = []
                  if (checkboxImprimirImagenPlato === true) {
                    const valor = await database.getFotosRecetas(listItem.CODIGO_RECETA)
                    listaFotosRecetas = valor?.response
                  }
                  console.log("listaFotosRecetas");
                  console.log(listaFotosRecetas);

                  console.log("Imprimir pdf");

                  var partesPorCiento = listaRecetasMenu.length / 4;

                  if (
                    listaFotosRecetas.length === 0 ||
                    listaFotosRecetas.length > 0
                  ) {
                    /**
                     * Creo el gráfico de pastel de cada receta.
                     */
                    if (count != 0) {
                      //Agrego la segunda página
                      doc.addPage();
                    }

                    //Gráfico pastel
                    var arcoGrafico = "";
                    var leyendaGrafico = "";
                    var etiquetaGrafico = "";

                    var oGrafico = [
                      {
                        nombre: "Proteinas",
                        porcentaje: "0",
                        color: "#0140CA",
                      },
                      {
                        nombre: "Lípidos",
                        porcentaje: "0",
                        color: "#DD1812",
                      },
                      {
                        nombre: "Fibra",
                        porcentaje: "0",
                        color: "#16A6FE",
                      },
                      {
                        nombre: "Hidratos",
                        porcentaje: "0",
                        color: "#6ab150",
                      },
                    ];

                    const total =
                      listaRecetasMenu[i].PROTEINAS +
                      listaRecetasMenu[i].LIPIDOS +
                      listaRecetasMenu[i].FIBRA +
                      listaRecetasMenu[i].HIDRATOS_C;

                    const porcientoProteinas =
                      (listaRecetasMenu[i].PROTEINAS / total) * 100;
                    const porcientoLipidos =
                      (listaRecetasMenu[i].LIPIDOS / total) * 100;
                    const porcientoFibra =
                      (listaRecetasMenu[i].FIBRA / total) * 100;
                    const porcientoHidratos =
                      (listaRecetasMenu[i].HIDRATOS_C / total) * 100;

                    console.log(porcientoProteinas);
                    console.log(porcientoLipidos);
                    console.log(porcientoFibra);
                    console.log(porcientoHidratos);

                    oGrafico[0].porcentaje = porcientoProteinas;
                    oGrafico[1].porcentaje = porcientoLipidos;
                    oGrafico[2].porcentaje = porcientoFibra;
                    oGrafico[3].porcentaje = porcientoHidratos;

                    console.log(oGrafico);

                    var ap = Array(); // angulos de partida
                    var af = Array(); // angulos finales

                    for (var t = 0; t < oGrafico.length; t++) {
                      var porcentaje = oGrafico[t].porcentaje;
                      var color = oGrafico[t].color;
                      var nombre = oGrafico[t].nombre;
                      // calcula el valor del ángulo en radianes
                      af[t] = (porcentaje * 360) / 100;

                      if (t > 0) {
                        af[t] += af[t - 1];
                        ap[t] = af[t - 1];
                      } else {
                        ap[t] = 0;
                      }

                      arcoGrafico += FuncionesAuxiliares.nuevoGajo(
                        ap[t],
                        af[t],
                        color,
                        t
                      );

                      leyendaGrafico += FuncionesAuxiliares.leyenda(
                        t * 30,
                        color,
                        nombre
                      );

                      if (porcentaje > 4) {
                        etiquetaGrafico +=
                          FuncionesAuxiliares.nuevaEtiqueta(
                            ap[t],
                            af[t],
                            Math.round(porcentaje * 100) / 100 + "%",
                            t
                          );
                      }
                    }
                    console.log(arcoGrafico);
                    console.log(leyendaGrafico);

                    /**
                     * Creo el SVG del gráfico: svg, svgEtiqueta, svgLeyenda.
                     */
                    const svg =
                      ` <svg id = "lienzoSVG" width="260" height="250">` +
                      arcoGrafico +
                      `</svg>`;

                    const svgEtiqueta =
                      ` <svg id = "lienzoSVGEtiqueta" width="260" height="250">` +
                      etiquetaGrafico +
                      `<style><![CDATA[ text { font: bold 20px; } ]]></style></svg>`;

                    const svgLeyenda =
                      ` <svg id = "lienzoSVGLeyenda" width="260" height="250">` +
                      leyendaGrafico +
                      `</svg>`;

                    console.log(svg);
                    console.log(svgLeyenda);

                    /**
                     * Imprimo el SVG del gráfico: svg, svgEtiqueta, svgLeyenda en el documento.
                     */
                    SVGtoPDF(doc, svg, 70, 442, {
                      width: 130,
                      height: 130,
                      preserveAspectRatio: "xMinYMin",
                    });
                    SVGtoPDF(doc, svgEtiqueta, 100, 470, {
                      width: 70,
                      height: 70,
                      preserveAspectRatio: "xMinYMin",
                    });
                    SVGtoPDF(doc, svgLeyenda, 250, 470, {
                      width: 130,
                      height: 130,
                      preserveAspectRatio: "xMinYMin",
                    });

                    //Logo compañía
                    doc.image(`${valorSelectLogo}`, 250, 50, {
                      scale: 0.5,
                    });
                    //.image('images/logoMedirest.png', 250, 40, { scale: 0.50 });

                    //Fecha de impresión
                    doc
                      .fontSize(10)
                      .font("Helvetica")
                      .text(`Fecha impresión: ${fechaActual}`, 25, 595, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Codificación receta-catálogo
                    doc
                      .fontSize(10)
                      .text(
                        `${listaRecetasMenu[i].CODIGO_RECETA}` +
                          "-" +
                          `${listaRecetasMenu[i].CATALOGO}`,
                        680,
                        595,
                        { align: "center", width: 80, height: 900 }
                      )
                      .moveDown();

                    //Información sobre los alérgenos
                    doc
                      .fontSize(10)
                      .font("Helvetica")
                      .fillColor("blue")
                      .text(`${informacionAlergenos}`, 25, 570, {
                        width: 740,
                        height: 300,
                      })
                      .moveDown();

                    //Nombre receta
                    doc
                      .fontSize(18)
                      .font("Helvetica-Bold")
                      .fillColor("black")
                      .text(
                        `${listaRecetasMenu[i].NCOMERCIAL_RECETA}`,
                        25,
                        40,
                        { width: 510, height: 60 }
                      )
                      .moveDown();

                    //Descriptivo Elaboración receta
                    if (listaRecetasMenu[i].ELABORACION != null) {
                      doc
                        .fontSize(10)
                        .font("Helvetica")
                        .fillColor("black")
                        .text(
                          `${listaRecetasMenu[i].ELABORACION}`,
                          425,
                          210,
                          { width: 340, height: 175 }
                        )
                        .moveDown();
                    }

                    console.log("listaIngredientesRecetas");
                    console.log(listaIngredientesRecetas);

                    /**
                     * Recorro la lista de ingredientes para imprimir los de cada receta en concreto.
                     */

                    var ingredientesParrafo = "";

                    for (
                      let n = 0;
                      n < listaIngredientesRecetas.length;
                      n++
                    ) {
                      for (
                        let l = 0;
                        l < listaTotalIngredientesRecetas.length;
                        l++
                      ) {
                        console.log("listaTotalIngredientesRecetas[l]");
                        console.log(listaTotalIngredientesRecetas[l]);
                        console.log(
                          "listaTotalIngredientesRecetas[l].COD_RECETA"
                        );
                        console.log(
                          listaTotalIngredientesRecetas[l].COD_RECETA
                        );
                        if (
                          listaTotalIngredientesRecetas[l].COD_RECETA ===
                            listaIngredientesRecetas[n].COD_RECETA &&
                          listaTotalIngredientesRecetas[l].COD_RECETA ===
                            listaRecetasMenu[i].CODIGO_RECETA
                        ) {
                          if (ingredientesParrafo === "") {
                            ingredientesParrafo +=
                              listaIngredientesRecetas[n].INGREDIENTE +
                              " (" +
                              listaIngredientesRecetas[n].CANTIDAD +
                              ") ";
                          } else {
                            ingredientesParrafo +=
                              ", " +
                              listaIngredientesRecetas[n].INGREDIENTE +
                              " (" +
                              listaIngredientesRecetas[n].CANTIDAD +
                              ") ";
                          }
                        }
                      }
                    }

                    if (ingredientesParrafo.length === 0) {
                      for (
                        let b = 0;
                        b < listaIngredientesRecetas.length;
                        b++
                      ) {
                        if (
                          listaIngredientesRecetas[b].ROWNUMBER === 1 &&
                          listaIngredientesRecetas[b].COD_RECETA ===
                            listaRecetasMenu[i].CODIGO_RECETA
                        ) {
                          console.log(
                            listaIngredientesRecetas[b].COD_RECETA
                          );
                          console.log(listaRecetasMenu[i].CODIGO_RECETA);

                          //Marcar título de INGREDIENTE b
                          doc
                            .lineCap("butt")
                            .moveTo(27, 218)
                            .lineTo(290, 218)
                            .lineWidth(10)
                            .fillAndStroke("blue", "#dbe8ff")
                            .stroke();

                          //Marcar PESO INGREDIENTE b
                          doc
                            .lineCap("butt")
                            .moveTo(373, 218)
                            .lineTo(292, 218)
                            .lineWidth(10)
                            .stroke();

                          //Borde INGREDIENTE b
                          doc
                            .lineWidth(0.5)
                            .fillAndStroke("black")
                            .roundedRect(27, 213, 262, 10)
                            .stroke();

                          //Borde PESO INGREDIENTE b
                          doc
                            .lineWidth(0.5)
                            .fillAndStroke("black")
                            .roundedRect(292, 213, 80, 10)
                            .stroke();

                          //INGREDIENTE b
                          doc
                            .fontSize(10)
                            .font("Helvetica")
                            .fillColor("black")
                            .text(
                              listaIngredientesRecetas[b].INGREDIENTE,
                              31,
                              215,
                              { width: 260, height: 10 }
                            )
                            .moveDown();

                          //PESO INGREDIENTE b
                          doc
                            .fontSize(10)
                            .text(
                              listaIngredientesRecetas[b].CANTIDAD,
                              296,
                              215,
                              { width: 73, height: 10, align: "right" }
                            )
                            .moveDown();
                        } else if (
                          listaIngredientesRecetas[b].ROWNUMBER != 1 &&
                          listaIngredientesRecetas[b].COD_RECETA ===
                            listaRecetasMenu[i].CODIGO_RECETA
                        ) {
                          //Marcar título de INGREDIENTE b SUMAR 16
                          doc
                            .lineCap("butt")
                            .moveTo(
                              27,
                              218 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12
                            )
                            .lineTo(
                              290,
                              218 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12
                            )
                            .lineWidth(10)
                            .fillAndStroke("blue", "#dbe8ff")
                            .stroke();

                          //Marcar PESO INGREDIENTE b SUMAR 16
                          doc
                            .lineCap("butt")
                            .moveTo(
                              373,
                              218 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12
                            )
                            .lineTo(
                              292,
                              218 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12
                            )
                            .lineWidth(10)
                            .stroke();

                          //Borde INGREDIENTE b SUMAR 16
                          doc
                            .lineWidth(0.5)
                            .fillAndStroke("black")
                            .roundedRect(
                              27,
                              213 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12,
                              262,
                              10
                            )
                            .stroke();

                          //Borde PESO INGREDIENTE b SUMAR 16
                          doc
                            .lineWidth(0.5)
                            .fillAndStroke("black")
                            .roundedRect(
                              292,
                              213 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12,
                              80,
                              10
                            )
                            .stroke();

                          //INGREDIENTE b SUMAR 16
                          doc
                            .fontSize(10)
                            .font("Helvetica")
                            .fillColor("black")
                            .text(
                              listaIngredientesRecetas[b].INGREDIENTE,
                              31,
                              215 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12,
                              { width: 260, height: 10 }
                            )
                            .moveDown();

                          //PESO INGREDIENTE b SUMAR 16
                          doc
                            .fontSize(10)
                            .text(
                              listaIngredientesRecetas[b].CANTIDAD,
                              296,
                              215 +
                                12 * listaIngredientesRecetas[b].ROWNUMBER -
                                12,
                              { width: 73, height: 10, align: "right" }
                            )
                            .moveDown();
                        }
                      }
                    }

                    console.log("ingredientesParrafo");
                    console.log(ingredientesParrafo);

                    //INGREDIENTE en párrafo
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(ingredientesParrafo, 31, 215, {
                        width: 345,
                        height: 450,
                      })
                      .moveDown();

                    //Descriptivo PRODUCTO - ACTUALIZADO
                    doc
                      .fontSize(10)
                      .font("Helvetica")
                      .fillColor("black")
                      .text("PRODUCTO", 131, 204, {
                        width: 345,
                        height: 195,
                      })
                      .moveDown();

                    //Descriptivo PESO BRUTO (g) - ACTUALIZADO
                    doc
                      .fontSize(10)
                      .font("Helvetica")
                      .fillColor("black")
                      .text("PESO BRUTO (g)", 293, 195, {
                        width: 345,
                        height: 195,
                      })
                      .moveDown();

                    //Descriptivo Para 100 raciones - ACTUALIZADO
                    doc
                      .fontSize(8)
                      .font("Helvetica")
                      .fillColor("black")
                      .text("(Para 100 raciones)", 298, 205, {
                        width: 345,
                        height: 195,
                      })
                      .moveDown();

                    let fotoRec = "null";
                    let ascii = "";
                    var arr;

                    if (checkboxImprimirImagenPlato === true) {
                      //Imagen receta
                      if (listaFotosRecetas.length != 0) {
                        //Si las recetas no tienen fotos no recorro la lista.

                        for (let a = 0; a < listaFotosRecetas.length; a++) {
                          /**
                           * Recorro el listado de recetas y si hay fotos viene en varchar de 4000 caracteres cada línea,
                           * la concateno y la guardo en el buffer para convertir la cadena completa a base64.
                           */
                          if (
                            listaRecetasMenu[i].CODIGO_RECETA ===
                            listaFotosRecetas[a].RECETA_ID
                          ) {
                            var buf = Buffer.from(
                              listaFotosRecetas[a].FOTO
                            );

                            if (fotoRec === "null") {
                              ascii = buf;
                              fotoRec = ascii.length;
                            } else {
                              arr = [ascii, buf];
                              ascii = Buffer.concat(arr);
                            }
                          } else if (
                            ascii === "" &&
                            a === listaFotosRecetas.length
                          ) {
                            ascii += "";
                          }
                        }
                      } else {
                        ascii += "";
                      }

                      console.log(ascii);

                      /**
                       * Si la receta no tiene imagen, se imprime una por defecto.
                       */
                      if (ascii === "") {
                        doc.image(
                          "images/imagenPorDefectoP1-2.png",
                          545,
                          20,
                          {
                            width: 228,
                            height: 150,
                          }
                        );
                      } else {
                        var b64 = Buffer.from(ascii).toString("base64");
                        console.log(ascii.length);
                        console.log(ascii);
                        console.log(b64);

                        var imagenBase64 = new Buffer(b64, "base64");
                        var formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);
                        //console.log('ejemplo: ' + ejemplo);
                        //console.log('subCadena: ' + subCadena);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen cabecera.
                            doc.image(imagenBase64, 545, 20, {
                              width: 228,
                              height: 150,
                            });
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 1: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen cabecera.
                          doc.image(
                            "images/imagenPorDefecto.png",
                            545,
                            20,
                            {
                              width: 228,
                              height: 150,
                            }
                          );
                        }
                      }
                    }

                    //Imagen alérgenos
                    doc.image("images/alergenosIconos.png", 423, 415, {
                      scale: 1,
                      width: 340,
                    });

                    //Código receta
                    doc
                      .fontSize(18)
                      .text(
                        `${listaRecetasMenu[i].CODIGO_RECETA}`,
                        25,
                        100,
                        { align: "left", width: 90 }
                      )
                      .moveDown();

                    if (
                      listaRecetasMenu[i].DECLARACION_ALERGENOS !=
                      "INCOMPLETA"
                    ) {
                      if (listaRecetasMenu[i].GLUTEN === "SI") {
                        //Marcar alérgenos GLUTEN
                        doc
                          .lineCap("round")
                          .moveTo(438, 480)
                          .lineTo(453, 480)
                          .lineWidth(10)
                          .fillAndStroke("black", "#000000")
                          .stroke();
                      } else if (listaRecetasMenu[i].GLUTEN === "TRAZAS") {
                        //Texto TRAZAS alérgenos GLUTEN
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 426, 478, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (listaRecetasMenu[i].CRUSTACEOS === "SI") {
                        //Marcar alérgenos CRUSTÁCEOS
                        doc
                          .lineCap("round")
                          .moveTo(487, 480)
                          .lineTo(502, 480)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].CRUSTACEOS === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos CRUSTÁCEOS
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 475, 478, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (listaRecetasMenu[i].HUEVO === "SI") {
                        //Marcar alérgenos HUEVOS
                        doc
                          .lineCap("round")
                          .moveTo(536, 480)
                          .lineTo(550, 480)
                          .lineWidth(10)
                          .stroke();
                      } else if (listaRecetasMenu[i].HUEVO === "TRAZAS") {
                        //Texto TRAZAS alérgenos HUEVOS
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 524, 478, {
                            width: 510,
                            height: 60,
                          });
                      }
                      if (listaRecetasMenu[i].PESCADO === "SI") {
                        //Marcar alérgenos PESCADO
                        doc
                          .lineCap("round")
                          .moveTo(585, 480)
                          .lineTo(599, 480)
                          .lineWidth(10)
                          .stroke();
                      } else if (listaRecetasMenu[i].PESCADO === "TRAZAS") {
                        //Texto TRAZAS alérgenos PESCADO
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 573, 478, {
                            width: 510,
                            height: 60,
                          });
                      }
                      if (listaRecetasMenu[i].CACAHUTES === "SI") {
                        //Marcar alérgenos CACAUHETES
                        doc
                          .lineCap("round")
                          .moveTo(632, 480)
                          .lineTo(645, 480)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].CACAHUTES === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos CACAUHETES
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 620, 478, {
                            width: 510,
                            height: 60,
                          });
                      }
                      if (listaRecetasMenu[i].SOJA === "SI") {
                        //Marcar alérgenos SOJA
                        doc
                          .lineCap("round")
                          .moveTo(680, 480)
                          .lineTo(693, 480)
                          .lineWidth(10)
                          .stroke();
                      } else if (listaRecetasMenu[i].SOJA === "TRAZAS") {
                        //Texto TRAZAS alérgenos SOJA
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 668, 478, {
                            width: 510,
                            height: 60,
                          });
                      }
                      if (listaRecetasMenu[i].LECHE_Y_DERIVADOS === "SI") {
                        //Marcar alérgenos LACTEOS
                        doc
                          .lineCap("round")
                          .moveTo(730, 480)
                          .lineTo(743, 480)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].LECHE_Y_DERIVADOS === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos LACTEOS
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 717, 478, {
                            width: 510,
                            height: 60,
                          });
                      }
                      if (listaRecetasMenu[i].FRUTOS_CASCARAS === "SI") {
                        //Marcar alérgenos FRUTOS  DE CÁSCARA
                        doc
                          .lineCap("round")
                          .moveTo(440, 553)
                          .lineTo(455, 553)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].FRUTOS_CASCARAS === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos FRUTOS  DE CÁSCARA
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 430, 549, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (listaRecetasMenu[i].APIO_Y_DERIVADOS === "SI") {
                        //Marcar alérgenos APIO
                        doc
                          .lineCap("round")
                          .moveTo(490, 553)
                          .lineTo(505, 553)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].APIO_Y_DERIVADOS === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos APIO
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 479, 549, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (
                        listaRecetasMenu[i].MOSTAZA_Y_DERIVADOS === "SI"
                      ) {
                        //Marcar alérgenos MOSTAZA
                        doc
                          .lineCap("round")
                          .moveTo(538, 553)
                          .lineTo(552, 553)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].MOSTAZA_Y_DERIVADOS === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos MOSTAZA
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 526, 549, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (listaRecetasMenu[i].SESAMO === "SI") {
                        //Marcar alérgenos GRANOS DE SÉSAMO
                        doc
                          .lineCap("round")
                          .moveTo(587, 553)
                          .lineTo(601, 553)
                          .lineWidth(10)
                          .stroke();
                      } else if (listaRecetasMenu[i].SESAMO === "TRAZAS") {
                        //Texto TRAZAS alérgenos GRANOS DE SÉSAMO
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 575, 549, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (listaRecetasMenu[i].MOLUSCOS === "SI") {
                        //Marcar alérgenos MOLUSCO
                        doc
                          .lineCap("round")
                          .moveTo(637, 553)
                          .lineTo(651, 553)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].MOLUSCOS === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos MOLUSCO
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 627, 549, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (listaRecetasMenu[i].ALTRAMUCES === "SI") {
                        //Marcar alérgenos ALTRAMUCES
                        doc
                          .lineCap("round")
                          .moveTo(685, 553)
                          .lineTo(698, 553)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].ALTRAMUCES === "TRAZAS"
                      ) {
                        //Texto TRAZAS alérgenos ALTRAMUCES
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 675, 549, {
                            width: 510,
                            height: 60,
                          })
                          .moveDown();
                      }
                      if (
                        listaRecetasMenu[i].DIXIDO_AZUFRE_Y_SULFITOS ===
                        "SI"
                      ) {
                        //Marcar alérgenos SULFITOS
                        doc
                          .lineCap("round")
                          .moveTo(737, 553)
                          .lineTo(751, 553)
                          .lineWidth(10)
                          .stroke();
                      } else if (
                        listaRecetasMenu[i].DIXIDO_AZUFRE_Y_SULFITOS ===
                        "TRAZAS"
                      ) {
                        //Texto TRAZAS SULFITOS
                        doc
                          .fontSize(9)
                          .font("Helvetica-Bold")
                          .text("TRAZAS", 725, 549, {
                            width: 510,
                            height: 60,
                          });
                      }
                    } else {
                      //Marcar declaración INCOMPLETA
                      doc
                        .fontSize(45)
                        .font("Helvetica-Bold")
                        .fillColor("red")
                        .text("INCOMPLETA", 445, 470, {
                          width: 510,
                          height: 60,
                        })
                        .moveDown();
                    }

                    //Color azul del borde
                    doc.lineWidth(2).fillAndStroke("blue", "#003cac");

                    //Borde ingredientes
                    doc.roundedRect(20, 190, 360, 220).stroke();

                    //Borde Elaboración plato
                    doc.roundedRect(413, 190, 360, 190).stroke();

                    //Borde alérgenos
                    doc.roundedRect(413, 400, 360, 160).stroke();

                    //Borde porcentaje de nutrientes
                    doc.roundedRect(20, 435, 360, 125).stroke();

                    //Marcar título tipo de opción
                    doc
                      .lineCap("round")
                      .moveTo(35, 150)
                      .lineTo(160, 150)
                      .lineWidth(30)
                      .stroke();

                    //Marcar título ingredientes
                    doc
                      .lineCap("round")
                      .moveTo(40, 190)
                      .lineTo(150, 190)
                      .lineWidth(25)
                      .stroke();

                    //Marcar título porcentaje de nutrientes
                    doc
                      .lineCap("round")
                      .moveTo(40, 435)
                      .lineTo(240, 435)
                      .lineWidth(25)
                      .stroke();

                    //Marcar título Elaboración del Plato
                    doc
                      .lineCap("round")
                      .moveTo(435, 190)
                      .lineTo(620, 190)
                      .lineWidth(25)
                      .stroke();

                    //Marcar título Alérgenos
                    doc
                      .lineCap("round")
                      .moveTo(435, 400)
                      .lineTo(540, 400)
                      .lineWidth(25)
                      .stroke();

                    //Descriptivo opción plato
                    if (listaRecetasMenu[i].DESAYUNO === 1) {
                      doc
                        .fontSize(18)
                        .font("Helvetica-Bold")
                        .fillColor("white")
                        .text("DESAYUNO", 20, 143, {
                          align: "center",
                          width: 150,
                        })
                        .moveDown();
                    } else if (listaRecetasMenu[i].MEDIAMA === 1) {
                      doc
                        .fontSize(16)
                        .font("Helvetica-Bold")
                        .fillColor("white")
                        .text("MEDIA MAÑANA", 20, 143, {
                          align: "center",
                          width: 150,
                        })
                        .moveDown();
                    } else if (listaRecetasMenu[i].PRIMERO) {
                      doc
                        .fontSize(18)
                        .font("Helvetica-Bold")
                        .fillColor("white")
                        .text("PRIMERO", 20, 143, {
                          align: "center",
                          width: 150,
                        })
                        .moveDown();
                    } else if (listaRecetasMenu[i].SEGUNDO) {
                      doc
                        .fontSize(18)
                        .font("Helvetica-Bold")
                        .fillColor("white")
                        .text("SEGUNDO", 20, 143, {
                          align: "center",
                          width: 150,
                        })
                        .moveDown();
                    } else if (listaRecetasMenu[i].MERIENDA) {
                      doc
                        .fontSize(18)
                        .font("Helvetica-Bold")
                        .fillColor("white")
                        .text("MERIENDA", 20, 143, {
                          align: "center",
                          width: 150,
                        })
                        .moveDown();
                    } else if (listaRecetasMenu[i].POSTRE) {
                      doc
                        .fontSize(18)
                        .font("Helvetica-Bold")
                        .fillColor("white")
                        .text("POSTRE", 20, 143, {
                          align: "center",
                          width: 150,
                        })
                        .moveDown();
                    }

                    //Descriptivo Ingredientes
                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("INGREDIENTES", 40, 185, { width: 150 })
                      .moveDown();

                    //Descriptivo Elaboración del plato
                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("ELABORACIÓN DEL PLATO", 435, 185, {
                        width: 180,
                      })
                      .moveDown();

                    //Descriptivo Alérgenos
                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("PORCENTAJE DE NUTRIENTES", 40, 430, {
                        width: 190,
                      })
                      .moveDown();

                    //Descriptivo porcentaje de nutrientes
                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("ALÉRGENOS", 435, 395, { width: 190 })
                      .moveDown();

                    //Agrego la segunda página
                    doc.addPage();

                    //Logo compañía
                    doc.image(`${valorSelectLogo}`, 150, 20, {
                      scale: 0.5,
                    });
                    //.image('images/logoMedirest.png', 150, 0, { scale: 0.50 });

                    //Imprimir o no la imagen de la receta
                    if (checkboxImprimirImagenPlato === true) {
                      if (ascii === "") {
                        //Imagen receta segunda hoja
                        doc.image(
                          "images/imagenPorDefectoP1-2.png",
                          475,
                          20,
                          { width: 295, height: 205 }
                        );
                      } else {
                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen cabecera. //Imagen receta segunda hoja
                            doc.image(new Buffer(b64, "base64"), 475, 20, {
                              width: 295,
                              height: 205,
                            });
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 1: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen cabecera.
                          doc.image(
                            "images/imagenPorDefecto.png",
                            475,
                            20,
                            {
                              width: 295,
                              height: 205,
                            }
                          );
                        }
                      }
                    }

                    //Fecha de impresión
                    doc
                      .fontSize(10)
                      .font("Helvetica")
                      .text(`Fecha impresión: ${fechaActual}`, 25, 595, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Codificación receta-catálogo
                    doc
                      .fontSize(10)
                      .text(
                        `${listaRecetasMenu[i].CODIGO_RECETA}` +
                          "-" +
                          `${listaRecetasMenu[i].CATALOGO}`,
                        680,
                        595,
                        { align: "center", width: 80, height: 900 }
                      )
                      .moveDown();

                    //Texto conservación y consumo
                    doc
                      .fontSize(12)
                      .text(
                        "Conservar por debajo de 8º C hasta el consumo, elaborar con la menor antelación.",
                        135,
                        280,
                        { align: "left", width: 330, height: 55 }
                      )
                      .moveDown();

                    doc
                      .fontSize(12)
                      .text(
                        "Platos calientes, realizar un tratamiento térmico por encima de 75º C y mantener por encima de 65º C hasta el consumo.",
                        160,
                        310,
                        { align: "left", width: 310, height: 55 }
                      )
                      .moveDown();

                    doc
                      .fontSize(12)
                      .text("Consumo inmediato.", 35, 358, {
                        align: "left",
                        width: 310,
                        height: 55,
                      })
                      .moveDown();

                    //Nombre receta
                    doc
                      .fontSize(18)
                      .font("Helvetica-Bold")
                      .fillColor("black")
                      .text(
                        `${listaRecetasMenu[i].NCOMERCIAL_RECETA}`,
                        25,
                        145,
                        { align: "left", width: 435, height: 55 }
                      )
                      .moveDown();

                    if (checkboxImprimirPesoPlato === true) {
                      //Impresión peso bruto
                      doc
                        .fontSize(12)
                        .text(
                          `${new Intl.NumberFormat('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(listaRecetasMenu[i].PESOT)} g`,
                          585,
                          280
                        )
                        .moveDown();
                    }

                    //Impresión familia del plato
                    if (checkboxImprimirFamiliaPlato === true) {
                      doc
                        .fontSize(12)
                        .font("Helvetica")
                        .text(
                          `${listaRecetasMenu[i].DESC_FAMILIA}`,
                          615,
                          340
                        )
                        .moveDown();
                    }

                    //Código receta
                    doc
                      .fontSize(18)
                      .text(
                        `${listaRecetasMenu[i].CODIGO_RECETA}`,
                        20,
                        196,
                        { align: "center", width: 90 }
                      )
                      .moveDown();

                    //Letra Bold
                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .text("CONSERVACIÓN:", 30, 265)
                      .moveDown();

                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .text("CONSUMO:", 30, 345)
                      .moveDown();

                    doc
                      .fontSize(12)
                      .text("Para platos fríos:", 35, 280)
                      .moveDown();

                    doc
                      .fontSize(12)
                      .text("Para platos calientes:", 35, 310)
                      .moveDown();

                    if (checkboxImprimirPesoPlato === true) {
                      doc
                        .fontSize(12)
                        .text("Peso bruto:", 515, 280)
                        .moveDown();
                    }

                    if (checkboxImprimirFamiliaPlato === true) {
                      doc
                        .fontSize(12)
                        .text("Familia del plato:", 515, 340)
                        .moveDown();
                    }

                    doc.lineWidth(2).fillAndStroke("blue", "#003cac");

                    //Marcar descripción del borde de concervación y consumo
                    doc
                      .roundedRect(20, 245, 440, 130)
                      .stroke()
                      .fillAndStroke("blue", "#003cac");

                    if (
                      checkboxImprimirPesoPlato === true ||
                      checkboxImprimirFamiliaPlato === true
                    ) {
                      //Marcar descripción del borde de peso y familia
                      doc.roundedRect(500, 245, 270, 130).stroke();
                    }

                    //Marcar descripción del borde de valoración nutricional
                    doc.roundedRect(20, 400, 750, 180).stroke();

                    //Marcar título de concervación y consumo
                    doc
                      .lineCap("round")
                      .moveTo(40, 245)
                      .lineTo(240, 245)
                      .lineWidth(25)
                      .stroke();

                    if (
                      checkboxImprimirPesoPlato === true ||
                      checkboxImprimirFamiliaPlato === true
                    ) {
                      //Marcar título de peso y familia
                      doc
                        .lineCap("round")
                        .moveTo(690, 245)
                        .lineTo(520, 245)
                        .lineWidth(25)
                        .stroke();
                    }

                    //Marcar título de valoración nutricional
                    doc
                      .lineCap("round")
                      .moveTo(40, 400)
                      .lineTo(240, 400)
                      .lineWidth(25)
                      .stroke();

                    //Descriptivo CONSERVACIÓN Y CONSUMO
                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("CONSERVACIÓN Y CONSUMO", 35, 240)
                      .moveDown();

                    //Descriptivo CONX3D?d%sMNNRMSig8SERVACIÓN Y CONSUMO
                    doc
                      .fontSize(12)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("VALORACIÓN NUTRICIONAL", 35, 395)
                      .lineWidth(25)
                      .moveDown();

                    if (
                      checkboxImprimirPesoPlato === true ||
                      checkboxImprimirFamiliaPlato === true
                    ) {
                      //Descriptivo CONSERVACIÓN Y CONSUMO
                      doc
                        .fontSize(12)
                        .font("Helvetica-Bold")
                        .fillColor("white")
                        .text("PESO Y FAMILIA DEL PLATO", 520, 240)
                        .moveDown();
                    }

                    //Inicia el primer bloque de Valoración Nutricional

                    //Marcar título de ENERGÍA KCal(kj/kcal)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 424)
                      .lineTo(140, 424)
                      .lineWidth(18)
                      .fillAndStroke("blue", "#dbe8ff")
                      .stroke();

                    //Descriptivo ENERGÍA KCal(kj/kcal)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .fillColor("black")
                      .text("ENERGÍA KCal(kj/kcal)", 28, 421)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor ENERGÍA KCal(kj/kcal)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 424)
                      .lineTo(208, 424)
                      .lineWidth(18)
                      .stroke();

                    //Valor ENERGÍA KCal(kj/kcal)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(
                          listaRecetasMenu[i].KCALORIAS.toFixed(2)
                        )}` +
                          "/" +
                          `${Number(
                            listaRecetasMenu[i].CALORIAS.toFixed(2)
                          )}`,
                        140,
                        421,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de LÍPIDOS (g)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 445)
                      .lineTo(140, 445)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo LÍPIDOS (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("LÍPIDOS (g)", 28, 442)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor LÍPIDOS (g)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 445)
                      .lineTo(208, 445)
                      .lineWidth(18)
                      .stroke();

                    //Valor LÍPIDOS (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].LIPIDOS.toFixed(2))}`,
                        140,
                        442,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de GRASAS SATURADAS (g)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 466)
                      .lineTo(140, 466)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo GRASAS SATURADAS (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("GRASAS SATURADAS (g)", 28, 463)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor GRASAS SATURADAS (g)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 466)
                      .lineTo(208, 466)
                      .lineWidth(18)
                      .stroke();

                    //Valor GRASAS SATURADAS (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].AGS.toFixed(2))}`,
                        140,
                        463,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de HID. DE CARBONO (g)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 486)
                      .lineTo(140, 486)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo HID. DE CARBONO (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("HID. DE CARBONO (g)", 28, 483)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor HID. DE CARBONO (g)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 486)
                      .lineTo(208, 486)
                      .lineWidth(18)
                      .stroke();

                    //Valor HID. DE CARBONO (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(
                          listaRecetasMenu[i].HIDRATOS_C.toFixed(2)
                        )}`,
                        140,
                        483,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de AZUCARES(g)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 507)
                      .lineTo(140, 507)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo AZUCARES(g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("AZUCARES(g)", 28, 504)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor HID. DE CARBONO (g)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 507)
                      .lineTo(208, 507)
                      .lineWidth(18)
                      .stroke();

                    //Valor HID. DE CARBONO (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(
                          listaRecetasMenu[i].AZUCARES.toFixed(2)
                        )}`,
                        140,
                        504,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de PROTEINAS (g)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 528)
                      .lineTo(140, 528)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo PROTEINAS (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("PROTEINAS (g)", 28, 525)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor PROTEINAS (g)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 528)
                      .lineTo(208, 528)
                      .lineWidth(18)
                      .stroke();

                    //Valor HID. DE PROTEINAS (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(
                          listaRecetasMenu[i].PROTEINAS.toFixed(2)
                        )}`,
                        140,
                        525,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de SAL (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 548)
                      .lineTo(140, 548)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo SAL (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("SAL (mg)", 28, 545, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Marcar valor SAL (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 548)
                      .lineTo(208, 548)
                      .lineWidth(18)
                      .stroke();

                    //Valor HID. DE SAL (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].SAL.toFixed(2))}`,
                        140,
                        545,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de FIBRA (g)
                    doc
                      .lineCap("butt")
                      .moveTo(25, 568)
                      .lineTo(140, 568)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo FIBRA (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("FIBRA (g)", 28, 564, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Marcar valor FIBRA (g)
                    doc
                      .lineCap("butt")
                      .moveTo(142, 568)
                      .lineTo(208, 568)
                      .lineWidth(18)
                      .stroke();

                    //Valor HID. DE FIBRA (g)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].FIBRA.toFixed(2))}`,
                        140,
                        565,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Termina el primer bloque de Valoración Nutricional

                    //Inicia el segundo bloque de Valoración Nutricional

                    //Marcar título de COLESTEROL (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 424)
                      .lineTo(213, 424)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo COLESTEROL (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("COLESTEROL (mg)", 215, 421)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de COLESTEROL (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 424)
                      .lineTo(329, 424)
                      .lineWidth(18)
                      .stroke();

                    //Valor COLESTEROL (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(
                          listaRecetasMenu[i].COLESTEROL.toFixed(2)
                        )}`,
                        326,
                        421,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA A (mcg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 444)
                      .lineTo(213, 444)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA A (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA A (mcg)", 215, 441)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de VITAMINA A (mcg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 444)
                      .lineTo(329, 444)
                      .lineWidth(18)
                      .stroke();

                    //Valor VITAMINA A (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].VITA_A.toFixed(2))}`,
                        326,
                        441,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA B1 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 465)
                      .lineTo(213, 465)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA B1 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA B1 (mg)", 215, 462)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de VITAMINA B1 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 465)
                      .lineTo(329, 465)
                      .lineWidth(18)
                      .stroke();

                    //Valor VITAMINA B1 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].VITA_B1.toFixed(2))}`,
                        326,
                        462,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA B2 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 485)
                      .lineTo(213, 485)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA B2 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA B2 (mg)", 215, 483)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de VITAMINA B2 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 485)
                      .lineTo(329, 485)
                      .lineWidth(18)
                      .stroke();

                    //Valor VITAMINA B2 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].VITA_B2.toFixed(2))}`,
                        326,
                        482,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA B6 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 505)
                      .lineTo(213, 505)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA B6 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA B6 (mg)", 215, 502)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de VITAMINA B6 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 505)
                      .lineTo(329, 505)
                      .lineWidth(18)
                      .stroke();

                    //Valor VITAMINA B6 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].VITA_B6.toFixed(2))}`,
                        326,
                        502,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de ÁCIDO FÓLICO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 526)
                      .lineTo(213, 526)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo ÁCIDO FÓLICO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("ÁCIDO FÓLICO (mg)", 215, 523)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de ÁCIDO FÓLICO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 526)
                      .lineTo(329, 526)
                      .lineWidth(18)
                      .stroke();

                    //Valor ÁCIDO FÓLICO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(
                          listaRecetasMenu[i].A_FOLICO.toFixed(2)
                        )}`,
                        326,
                        523,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA B12 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 546)
                      .lineTo(213, 546)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA B12 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA B12 (mg)", 215, 543, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Marcar valor de VITAMINA B12 (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 546)
                      .lineTo(329, 546)
                      .lineWidth(18)
                      .stroke();

                    //Valor ÁCIDO VITAMINA B12 (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      //.fillColor('black')
                      .text(
                        `${Number(
                          listaRecetasMenu[i].VITA_B12.toFixed(2)
                        )}`,
                        326,
                        543,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA C (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(327, 567)
                      .lineTo(213, 567)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA C (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA C (mg)", 215, 564, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Marcar valor de VITAMINA C (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(393, 567)
                      .lineTo(329, 567)
                      .lineWidth(18)
                      .stroke();

                    //Valor ÁCIDO VITAMINA C (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].VITA_C.toFixed(2))}`,
                        326,
                        564,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Termina el segundo bloque de Valoración Nutricional

                    //Inicia el tercer bloque de Valoración Nutricional

                    //Marcar título de NIACINA (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 424)
                      .lineTo(397, 424)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo NIACINA (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("NIACINA (mg)", 399, 421)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de NIACINA (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 424)
                      .lineTo(512, 424)
                      .lineWidth(18)
                      .stroke();

                    //Valor NIACINA (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].NIACINA.toFixed(2))}`,
                        509,
                        421,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA D (mcg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 444)
                      .lineTo(397, 444)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA D (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA D (mcg)", 399, 441)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de VITAMINA D (mcg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 444)
                      .lineTo(512, 444)
                      .lineWidth(18)
                      .stroke();

                    //Valor VITAMINA D (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].VITA_D.toFixed(2))}`,
                        509,
                        441,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de VITAMINA E (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 465)
                      .lineTo(397, 465)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo VITAMINA E (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("VITAMINA E (mg)", 399, 462)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de VITAMINA E (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 465)
                      .lineTo(512, 465)
                      .lineWidth(18)
                      .stroke();

                    //Valor VITAMINA E (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].VITA_E.toFixed(2))}`,
                        509,
                        462,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de RETINOL (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 485)
                      .lineTo(397, 485)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo RETINOL (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("RETINOL (mg)", 399, 482)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de RETINOL (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 485)
                      .lineTo(512, 485)
                      .lineWidth(18)
                      .stroke();

                    //Valor RETINOL (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].RETINOL.toFixed(2))}`,
                        509,
                        482,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de CAROTENOS (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 505)
                      .lineTo(397, 505)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo CAROTENOS (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("CAROTENOS (mg)", 399, 502)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de CAROTENOS (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 505)
                      .lineTo(512, 505)
                      .lineWidth(18)
                      .stroke();

                    //Valor CAROTENOS (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(
                          listaRecetasMenu[i].CAROTENO.toFixed(2)
                        )}`,
                        509,
                        502,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de CALCIO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 526)
                      .lineTo(397, 526)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo CALCIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("CALCIO (mg)", 399, 523)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de CALCIO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 526)
                      .lineTo(512, 526)
                      .lineWidth(18)
                      .stroke();

                    //Valor CALCIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].CALCIO.toFixed(2))}`,
                        509,
                        522,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de FOSFORO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 546)
                      .lineTo(397, 546)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo FOSFORO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("FOSFORO (mg)", 400, 543, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Marcar valor de FOSFORO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 546)
                      .lineTo(512, 546)
                      .lineWidth(18)
                      .stroke();

                    //Valor FOSFORO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].FOSFORO.toFixed(2))}`,
                        509,
                        542,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de HIERRO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(510, 567)
                      .lineTo(397, 567)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo HIERRO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("HIERRO (mg)", 400, 564, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Marcar valor de FOSFORO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(576, 567)
                      .lineTo(512, 567)
                      .lineWidth(18)
                      .stroke();

                    //Valor FOSFORO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].HIERRO.toFixed(2))}`,
                        509,
                        562,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Termina el tercer bloque de Valoración Nutricional

                    //Inicia el cuarto bloque de Valoración Nutricional

                    //Marcar título de MAGNESIO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(695, 424)
                      .lineTo(580, 424)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo MAGNESIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("MAGNESIO (mg)", 583, 421)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de MAGNESIO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(697, 424)
                      .lineTo(765, 424)
                      .lineWidth(18)
                      .stroke();

                    //Valor MAGNESIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      //.fillColor('black')
                      .text(
                        `${Number(
                          listaRecetasMenu[i].MAGNESIO.toFixed(2)
                        )}`,
                        696,
                        421,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de POTASIO (mcg)
                    doc
                      .lineCap("butt")
                      .moveTo(695, 444)
                      .lineTo(580, 444)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo POTASIO (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("POTASIO (mcg)", 583, 441)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de POTASIO (mcg)
                    doc
                      .lineCap("butt")
                      .moveTo(697, 444)
                      .lineTo(765, 444)
                      .lineWidth(18)
                      .stroke();

                    //Valor POTASIO (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      //.fillColor('black')
                      .text(
                        `${Number(listaRecetasMenu[i].POTASIO.toFixed(2))}`,
                        696,
                        441,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de SODIO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(695, 465)
                      .lineTo(580, 465)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo SODIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("SODIO (mg)", 583, 462)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de SODIO (mg)
                    doc
                      .lineCap("butt")
                      .moveTo(697, 465)
                      .lineTo(765, 465)
                      .lineWidth(18)
                      .stroke();

                    //Valor SODIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(
                        `${Number(listaRecetasMenu[i].SODIO.toFixed(2))}`,
                        696,
                        462,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de YODO (mcg)
                    doc
                      .lineCap("butt")
                      //.fillAndStroke("red", "#900")
                      .moveTo(695, 485)
                      .lineTo(580, 485)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo YODO (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("YODO (mcg)", 583, 482)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de YODO (mcg)
                    doc
                      .lineCap("butt")
                      //.fillAndStroke("red", "#900")
                      .moveTo(697, 485)
                      .lineTo(765, 485)
                      .lineWidth(18)
                      .stroke();

                    //Valor YODO (mcg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      //.fillColor('black')
                      .text(
                        `${Number(listaRecetasMenu[i].IODO.toFixed(2))}`,
                        696,
                        482,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de SELENIO (mg)
                    doc
                      .lineCap("butt")
                      //.fillAndStroke("red", "#900")
                      .moveTo(695, 505)
                      .lineTo(580, 505)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo SELENIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("SELENIO (mg)", 583, 502)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de SELENIO (mg)
                    doc
                      .lineCap("butt")
                      //.fillAndStroke("red", "#900")
                      .moveTo(697, 505)
                      .lineTo(765, 505)
                      .lineWidth(18)
                      .stroke();

                    //Valor SELENIO (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      //.fillColor('black')
                      .text(
                        `${Number(listaRecetasMenu[i].SELENIO.toFixed(2))}`,
                        696,
                        502,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Marcar título de ZINC (mg)
                    doc
                      .lineCap("butt")
                      //.fillAndStroke("red", "#900")
                      .moveTo(695, 526)
                      .lineTo(580, 526)
                      .lineWidth(18)
                      .stroke();

                    //Descriptivo ZINC (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text("ZINC (mg)", 583, 523)
                      .lineWidth(18)
                      .moveDown();

                    //Marcar valor de ZINC (mg)
                    doc
                      .lineCap("butt")
                      //.fillAndStroke("red", "#900")
                      .moveTo(697, 526)
                      .lineTo(765, 526)
                      .lineWidth(18)
                      .stroke();

                    //Valor ZINC (mg)
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      //.fillColor('black')
                      .text(
                        `${Number(listaRecetasMenu[i].ZINC.toFixed(2))}`,
                        696,
                        523,
                        { width: 65, height: 20, align: "right" }
                      )
                      .moveDown();

                    //Termina el cuarto bloque de Valoración Nutricional

                    console.log("Todo bien hasta aquí.");
                    count++;
                    console.log("count: ", count);
                    console.log(
                      "listaRecetasMenu.length: ",
                      listaRecetasMenu.length
                    );

                    if (count === listaRecetasMenu.length && error === 0) {
                      setTimeout(() => {
                        // Obtengo el blob cuando está hecho todo.
                        doc.end();
                        stream.on("finish", function () {
                          //   // // get a blob you can do whatever you like with
                          //   // const blob = stream.toBlob('application/pdf');

                          //   // or get a blob URL for display in the browser
                          const url = stream.toBlobURL("application/pdf");
                          // abrir un PDF en una pestaña nueva
                          window.open(url, "_blank");
                          modal.destroy();
                          // redirigir la pestaña actual a otra URL
                          //window.location.href = 'http://ejemplo.com';
                        });
                      }, 1000);
                    }
                  }
                  //Hasta aquí los ingredientes.
                });
              });
          });
        } else if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length === 0
        ) {
          Modal.error({
            title: "Error",
            content:
              "El menú " +
              this.state.selectedRowsMenu[0].ID_MENU +
              "." +
              this.state.selectedRowsMenu[0].NOM_MENU +
              " no tiene recetas asignadas.",
          });
          modal.destroy();
        } else {
          Modal.error({
            title: "Error",
            content:
              "Ha ocurrido el siguiente error: " +
              listaRecetasMenuValores.message,
          });
          modal.destroy();
        }
      });
  } catch (e) {
    Modal.error({
      title: "Error",
      content:
        "Ha ocurrido el siguiente error al Imprimir las Etiquetas: " +
        e +
        ". Contactar con el administrador.",
    });
    console.log(e);
  }
}

async function imprimirRecetasPlantilla2(
  catalogoActualIdioma,
  listaCodigoRecetas,
  idiomaActual,
  valorSelectLogo,
  checkboxImprimirImagenPlato
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    const modal = Modal.info();
    const tiempo = checkboxImprimirImagenPlato === true ? 110 : 50;

    llamarModal(tiempo * listaCodigoRecetas.length, modal);
    /**
     * Obtengo el listado de recetas con sus datos dado el menú...
     */
    database
      .getRecetasBloques(catalogoActualIdioma, listaCodigoRecetas, idiomaActual)
      .then((valor) => {
        const listaRecetasMenuValores = valor.response;

        /**
         * Recorro el JSON listaRecetasMenu para obtener solamente los códigos de las recetas para buscar sus ingredientes y fotos
         */

        if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length > 0
        ) {
          var listaRecetasMenu = listaRecetasMenuValores.message;
          /**
           * Recupero todos los ingredientes de las recetas seleccionadas por el usuario.
           */
          database.getIngredientesRecetas(listaCodigoRecetas).then((valor) => {
            const listaIngredientesRecetas = valor.response;

            database
              .getTotalIngredientesRecetas(listaCodigoRecetas)
              .then(async (valor) => {
                const listaTotalIngredientesRecetas = valor.response;

                console.log("listaRecetasMenu");
                console.log(listaRecetasMenu);
                console.log("listaIngredientesRecetas");
                console.log(listaIngredientesRecetas);
                console.log("listaTotalIngredientesRecetas");
                console.log(listaTotalIngredientesRecetas);

                //this.setState({ listaFotosRecetas: [] });

                let listaFotosRecetas = []
                if (checkboxImprimirImagenPlato === true) {
                  const valor = await database.getFotosRecetas(listaCodigoRecetas)
                  listaFotosRecetas = valor?.response
                }

                //Crear el documento
                var doc = new PDFDocument({
                  size: "LETTER",
                  layout: "portrait",
                  margin: 5,
                  info: {
                    Title: "Recetas",
                    Author: "Alejandro Vázquez Chávez",
                  },
                });

                //Canalizar el documento a un blob
                var stream = doc.pipe(blobStream());

                /**
                 * Creo el gráfico de pastel de cada receta.
                 */
                for (let i = 0; i < listaRecetasMenu.length; i++) {
                  //Logo compañía
                  doc.image(valorSelectLogo, 110, 60, {
                    scale: 0.4,
                  });

                  //Nombre de la receta
                  doc
                    .fontSize(14)
                    .font("Helvetica-Bold")
                    .fillColor("black")
                    .text(
                      `${listaRecetasMenu[i].NCOMERCIAL_RECETA} (${listaRecetasMenu[i].NOMBRE_RECETA})`,
                      15,
                      22,
                      {
                        width: 380,
                        height: 60,
                      }
                    )
                    .moveDown();

                  //Fecha de impresión
                  doc
                    .fontSize(9)
                    .font("Helvetica")
                    .text(`Fecha impresión: ${fechaActual}`, 15, 765, {
                      width: 230,
                      height: 300,
                    })
                    .moveDown();

                  //Codificación receta-catálogo
                  doc
                    .fontSize(9)
                    .text(
                      `${listaRecetasMenu[i].CODIGO_RECETA}-${listaRecetasMenu[i].CATALOGO}`,
                      515,
                      765,
                      {
                        align: "right",
                        width: 80,
                        height: 900,
                      }
                    )
                    .moveDown();

                  //Descriptivo sobre los alérgenos
                  doc
                    .fontSize(6)
                    .font("Helvetica")
                    .fillColor("blue")
                    .text(`${infoAlergenosNoComercial}`, 15, 748, {
                      width: 585,
                      height: 300,
                    })
                    .moveDown();

                  /**
                   * SOMBREADO DESCRIPTIVOS INFO. NUTRICIONAL
                   */
                  doc
                    .lineCap("butt")
                    .lineWidth(14)
                    .fillAndStroke("blue", "#dbe8ff")
                    .moveTo(20, 208) // Sombreado ENERGÍA
                    .lineTo(163, 208) // Sombreado ENERGÍA
                    .moveTo(20, 222) //Sombreado Proteinas
                    .lineTo(163, 222) //Sombreado Proteinas
                    .moveTo(20, 236) //Sombreado H. Carbonos
                    .lineTo(163, 236) //Sombreado H. Carbonos
                    .moveTo(20, 250) //Sombreado Azucares
                    .lineTo(163, 250) //Sombreado Azucares
                    .moveTo(20, 264) //Sombreado sal
                    .lineTo(163, 264) //Sombreado sal
                    .moveTo(20, 278) //Sombreado FIBRA
                    .lineTo(163, 278) //Sombreado FIBRA
                    .moveTo(20, 292) //Sombreado lipidos
                    .lineTo(163, 292) //Sombreado lipidos
                    .moveTo(20, 306) //Sombreado colesterol
                    .lineTo(163, 306) //Sombreado colesterol
                    .moveTo(20, 320) //Sombreado ags
                    .lineTo(163, 320) //Sombreado ags
                    .moveTo(20, 334) //Sombreado agm
                    .lineTo(163, 334) //Sombreado agm
                    .moveTo(20, 348) //Sombreado agp
                    .lineTo(163, 348) //Sombreado agp
                    .moveTo(20, 362) //Sombreado calcio
                    .lineTo(163, 362) //Sombreado calcio
                    .moveTo(20, 376) //Sombreado hierro
                    .lineTo(163, 376) //Sombreado hierro
                    .moveTo(20, 390) //Sombreado sodio
                    .lineTo(163, 390) //Sombreado sodio
                    .moveTo(20, 404) //Sombreado iodo
                    .lineTo(163, 404) //Sombreado iodo
                    .moveTo(20, 418) //Sombreado magnesio
                    .lineTo(163, 418) //Sombreado magnesio
                    .moveTo(20, 432) //Sombreado zinc
                    .lineTo(163, 432) //Sombreado zinc
                    .moveTo(20, 446) //Sombreado potasio
                    .lineTo(163, 446) //Sombreado potasio
                    .moveTo(20, 460) //Sombreado foforo
                    .lineTo(163, 460) //Sombreado foforo
                    .moveTo(20, 474) //Sombreado selenio
                    .lineTo(163, 474) //Sombreado selenio
                    .moveTo(20, 488) //Sombreado vitamina a
                    .lineTo(163, 488) //Sombreado vitamina a
                    .moveTo(20, 502) //Sombreado vitamina b1
                    .lineTo(163, 502) //Sombreado vitamina b1
                    .moveTo(20, 516) //Sombreado vitamina b2
                    .lineTo(163, 516) //Sombreado vitamina b2
                    .moveTo(20, 530) //Sombreado vitamina b2
                    .lineTo(163, 530) //Sombreado vitamina b2
                    .moveTo(20, 544) //Sombreado acido folico
                    .lineTo(163, 544) //Sombreado acido folico
                    .moveTo(20, 558) //Sombreado niacina
                    .lineTo(163, 558) //Sombreado niacina
                    .moveTo(20, 572) //Sombreado vitamina b6
                    .lineTo(163, 572) //Sombreado vitamina b6
                    .moveTo(20, 586) //Sombreado vitamina b12
                    .lineTo(163, 586) //Sombreado vitamina b12
                    .moveTo(20, 600) //Sombreado retinol
                    .lineTo(163, 600) //Sombreado retinol
                    .moveTo(20, 614) //Sombreado caroteno
                    .lineTo(163, 614) //Sombreado caroteno
                    .moveTo(20, 628) //Sombreado vitamina d
                    .lineTo(163, 628) //Sombreado vitamina d
                    .moveTo(20, 642) //Sombreado vitamina e
                    .lineTo(163, 642) //Sombreado vitamina e
                    .stroke();

                  /**
                   * BORDES INFO. NUTRICIONAL
                   */
                  doc
                    .lineWidth(0.5)
                    .fillAndStroke("black")
                    .roundedRect(20, 201, 93, 14) //Borde ENERGÍA
                    .roundedRect(20, 215, 93, 14) //Borde Proteinas
                    .roundedRect(20, 229, 93, 14) //Borde H. Carbonos
                    .roundedRect(20, 243, 93, 14) //Borde Azucares
                    .roundedRect(20, 257, 93, 14) //Borde Sal
                    .roundedRect(20, 271, 93, 14) //Borde fibra
                    .roundedRect(20, 285, 93, 14) //Borde lipidos
                    .roundedRect(20, 299, 93, 14) //Borde colesterol
                    .roundedRect(20, 313, 93, 14) //Borde ags
                    .roundedRect(20, 327, 93, 14) //Borde agm
                    .roundedRect(20, 341, 93, 14) //Borde agp
                    .roundedRect(20, 355, 93, 14) //Borde calcio
                    .roundedRect(20, 369, 93, 14) //Borde hierro
                    .roundedRect(20, 383, 93, 14) //Borde sodio
                    .roundedRect(20, 397, 93, 14) //Borde iodo
                    .roundedRect(20, 411, 93, 14) //Borde magnesio
                    .roundedRect(20, 425, 93, 14) //Borde zinc
                    .roundedRect(20, 439, 93, 14) //Borde potasio
                    .roundedRect(20, 453, 93, 14) //Borde fosforo
                    .roundedRect(20, 467, 93, 14) //Borde selenio
                    .roundedRect(20, 481, 93, 14) //Borde vitamina a
                    .roundedRect(20, 495, 93, 14) //Borde vitamina b1
                    .roundedRect(20, 509, 93, 14) //Borde vitamina b2
                    .roundedRect(20, 523, 93, 14) //Borde acido folico
                    .roundedRect(20, 537, 93, 14) //Borde vitamina c
                    .roundedRect(20, 551, 93, 14) //Borde niacina
                    .roundedRect(20, 565, 93, 14) //Borde vitamina b6
                    .roundedRect(20, 579, 93, 14) //Borde vitamina b12
                    .roundedRect(20, 593, 93, 14) //Borde retinol
                    .roundedRect(20, 607, 93, 14) //Borde caroteno
                    .roundedRect(20, 621, 93, 14) //Borde vitamina d
                    .roundedRect(20, 635, 93, 14) //Borde vitamina e
                    .roundedRect(19, 668, 145, 77) //Borde Alergenos
                    .stroke();

                  /**
                   * BORDES VALOR INFO. NUTRICIONAL
                   */
                  doc
                    .lineWidth(0.5)
                    .fillAndStroke("black")
                    .roundedRect(113, 201, 50, 14) //Borde Valor Energía
                    .roundedRect(113, 215, 50, 14) //Borde Valor Proteinas
                    .roundedRect(113, 229, 50, 14) //Borde Valor  H. Carbonos
                    .roundedRect(113, 243, 50, 14) //Borde Valor  Azucares
                    .roundedRect(113, 257, 50, 14) //Borde Valor fibra
                    .roundedRect(113, 271, 50, 14) //Borde Valor lípidos
                    .roundedRect(113, 285, 50, 14) //Borde Valor colesterol
                    .roundedRect(113, 299, 50, 14) //Borde Valor AGS
                    .roundedRect(113, 313, 50, 14) //Borde Valor AGM
                    .roundedRect(113, 327, 50, 14) //Borde Valor AGP
                    .roundedRect(113, 341, 50, 14) //Borde Valor calcio
                    .roundedRect(113, 355, 50, 14) //Borde Valor hierro
                    .roundedRect(113, 369, 50, 14) //Borde Valor sodio
                    .roundedRect(113, 383, 50, 14) //Borde Valor iodo
                    .roundedRect(113, 397, 50, 14) //Borde Valor magnesio
                    .roundedRect(113, 411, 50, 14) //Borde Valor zinc
                    .roundedRect(113, 425, 50, 14) //Borde Valor potasio
                    .roundedRect(113, 439, 50, 14) //Borde Valor fosforo
                    .roundedRect(113, 453, 50, 14) //Borde Valor selenio
                    .roundedRect(113, 467, 50, 14) //Borde Valor vitamina A
                    .roundedRect(113, 481, 50, 14) //Borde Valor vitamina b1
                    .roundedRect(113, 495, 50, 14) //Borde Valor vitamina b2
                    .roundedRect(113, 509, 50, 14) //Borde Valor acido folico
                    .roundedRect(113, 523, 50, 14) //Borde Valor vitamina c
                    .roundedRect(113, 537, 50, 14) //Borde Valor niacina
                    .roundedRect(113, 551, 50, 14) //Borde Valor vitamina b6
                    .roundedRect(113, 565, 50, 14) //Borde Valor vitamina b12
                    .roundedRect(113, 579, 50, 14) //Borde Valor retinol
                    .roundedRect(113, 593, 50, 14) //Borde Valor carotenos
                    .roundedRect(113, 607, 50, 14) //Borde Valor vitamina d
                    .roundedRect(113, 621, 50, 14) //Borde Valor vitamina E
                    .roundedRect(113, 635, 50, 14) //Borde Valor vitamina E
                    .stroke();

                  /**
                   * DESCRIPTIVOS INFO. NUTRICIONAL
                   */
                  //ENERGÍA KCal(Kjul)
                  doc
                    .fontSize(7)
                    .font("Helvetica")
                    .fillColor("black")
                    .text("ENERGÍA KCal(Kjul)", 22, 205, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].KCALORIAS
                      ),
                      111,
                      205,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("PROTEINAS (g)", 22, 220, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].PROTEINAS
                      ),
                      111,
                      220,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("HID. DE CARBONO (g)", 22, 234, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].HIDRATOS_C
                      ),
                      111,
                      234,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("AZÚCARES (g)", 22, 248, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].AZUCARES
                      ),
                      111,
                      248,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("SAL (g)", 22, 262, {
                      width: 260,
                      height: 14,
                    })
                    .fontSize(7)
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].SAL
                      ),
                      111,
                      262,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("FIBRA (g)", 22, 276, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].FIBRA
                      ),
                      111,
                      276,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("LÍPIDOS (g)", 22, 290, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].LIPIDOS
                      ),
                      111,
                      290,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("COLESTEROL (mg)", 22, 304, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].COLESTEROL
                      ),
                      111,
                      304,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("AGS (g)", 22, 318, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].AGS
                      ),
                      111,
                      318,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("AGM (g)", 22, 332, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].AGM
                      ),
                      111,
                      332,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("AGP (g)", 22, 346, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].AGP
                      ),
                      111,
                      346,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("CALCIO (mg)", 22, 360, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].CALCIO
                      ),
                      111,
                      360,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("HIERRO (mg)", 22, 374, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].HIERRO
                      ),
                      111,
                      374,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("SODIO (mg)", 22, 388, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].SODIO
                      ),
                      111,
                      388,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .moveDown()
                    .text("IODO (mg)", 22, 402, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].IODO
                      ),
                      111,
                      402,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("MAGNESIO (mg)", 22, 416, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].MAGNESIO
                      ),
                      111,
                      416,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("ZINC (mg)", 22, 430, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].ZINC
                      ),
                      111,
                      430,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("POTASIO (mcg)", 22, 444, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].POTASIO
                      ),
                      111,
                      444,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("FÓSFORO (mg)", 22, 458, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].FOSFORO
                      ),
                      111,
                      458,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("SELENIO (mg)", 22, 472, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].SELENIO
                      ),
                      111,
                      472,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA A (mg)", 22, 486, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_A
                      ),
                      111,
                      486,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA B1 (mg)", 22, 500, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_B1
                      ),
                      111,
                      500,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA B2 (mg)", 22, 514, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_B2
                      ),
                      111,
                      514,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("ÁCIDO FÓLICO (mg)", 22, 528, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].A_FOLICO
                      ),
                      111,
                      528,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA C (mg)", 22, 542, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_C
                      ),
                      111,
                      542,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("NIACINA (mg)", 22, 556, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].NIACINA
                      ),
                      111,
                      556,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA B6 (mg)", 22, 570, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_B6
                      ),
                      111,
                      570,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA B12 (mg)", 22, 584, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_B12
                      ),
                      111,
                      584,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("RETINOL (mg)", 22, 598, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].RETINOL
                      ),
                      111,
                      598,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("CAROTENO (mg)", 22, 612, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].CAROTENO
                      ),
                      111,
                      612,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA D (mg)", 22, 626, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_D
                      ),
                      111,
                      626,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .text("VITAMINA E (mg)", 22, 640, {
                      width: 260,
                      height: 14,
                    })
                    .text(
                      FuncionesAuxiliares.redondearDosDecimales(
                        listaRecetasMenu[i].VITA_E
                      ),
                      111,
                      640,
                      {
                        width: 50,
                        height: 14,
                        align: "right",
                      }
                    )
                    .moveDown();

                  let alergenos = false;
                  if (
                    listaRecetasMenu[i].DECLARACION_ALERGENOS !== null &&
                    listaRecetasMenu[i].DECLARACION_ALERGENOS.length > 0
                  )
                    alergenos = true;

                  //DECLARACION ALERGENOS
                  doc
                    .fontSize(7.7)
                    .text(
                      alergenos === true
                        ? `${listaRecetasMenu[i].DECLARACION_ALERGENOS}.`
                        : "",
                      20,
                      670,
                      {
                        width: 148,
                        height: 100,
                        align: "left",
                      }
                    )
                    .moveDown();

                  //Color azul del borde
                  doc.lineWidth(2).fillAndStroke("blue", "#003cac");

                  //Marcar título INFORMACIÓN NUTRICIONAL Y ALERGENOS
                  doc
                    .lineCap("butt")
                    .moveTo(19, 192)
                    .lineTo(164, 192)
                    .lineWidth(17)
                    .lineCap("butt")
                    .moveTo(19, 660)
                    .lineTo(164, 660)
                    .lineWidth(17)
                    .stroke();

                  //Descriptivo INFORMACIÓN NUTRICIONAL  Y ALERGENOS
                  doc
                    .fontSize(8.5)
                    .font("Helvetica-Bold")
                    .fillColor("white")
                    .text("INFORMACIÓN NUTRICIONAL", 29, 187, {
                      width: 150,
                    })
                    .text("ALÉRGENOS", 63, 657, {
                      width: 150,
                    })
                    .moveDown();

                  //Marcar título INGREDIENTES
                  doc
                    .lineCap("butt")
                    .moveTo(190, 193)
                    .lineTo(597, 193)
                    .lineWidth(22)
                    .stroke();

                  //Descriptivo Ingredientes
                  doc
                    .fontSize(11)
                    .font("Helvetica-Bold")
                    .fillColor("white")
                    .text("INGREDIENTES", 190, 188, {
                      width: 370,
                      align: "center",
                    })
                    .fontSize(8)
                    .text("PESO BRUTO (g)\n100 raciones", 364, 185, {
                      width: 397,
                      align: "center",
                    })
                    .moveDown();

                  let fotoRec = "null";
                  let ascii = "";
                  var arr;

                  if (checkboxImprimirImagenPlato === true) {
                    //Imagen receta
                    if (listaFotosRecetas.length != 0) {
                      //Si las recetas no tienen fotos no recorro la lista.

                      for (let a = 0; a < listaFotosRecetas.length; a++) {
                        /**
                         * Recorro el listado de recetas y si hay fotos viene en varchar de 4000 caracteres cada línea,
                         * la concateno y la guardo en el buffer para convertir la cadena completa a base64.
                         */
                        if (
                          listaRecetasMenu[i].CODIGO_RECETA ===
                          listaFotosRecetas[a].RECETA_ID
                        ) {
                          var buf = Buffer.from(listaFotosRecetas[a].FOTO);

                          if (fotoRec === "null") {
                            ascii = buf;
                            fotoRec = ascii.length;
                          } else {
                            arr = [ascii, buf];
                            ascii = Buffer.concat(arr);
                          }
                        } else if (
                          ascii === "" &&
                          a === listaFotosRecetas.length
                        ) {
                          ascii += "";
                        }
                      }
                    } else {
                      ascii += "";
                    }

                    //Información sobre los alérgenos
                    doc
                      .fontSize(6)
                      .font("Helvetica")
                      .fillColor("blue")
                      .text(
                        "* Foto y gramaje corresponden a grupo de edad 12 AÑOS",
                        400,
                        150,
                        {
                          width: 585,
                          height: 300,
                        }
                      )
                      .moveDown();

                    console.log(ascii);

                    /**
                     * Si la receta no tiene imagen, se imprime una por defecto.
                     */
                    if (ascii === "") {
                      doc.image("images/imagenPorDefectoP1-2.png", 395, 12, {
                        width: 200,
                        height: 130,
                      });
                    } else {
                      var b64 = Buffer.from(ascii).toString("base64");
                      console.log(ascii.length);
                      console.log(ascii);
                      console.log(b64);

                      var imagenBase64 = new Buffer(b64, "base64");
                      var formatoImagen = imagenBase64
                        .toString()
                        .substring(1, 4); //=== 'PNG'
                      //var subCadena = varPNG.substring(1,4);
                      console.log("varPNG: " + formatoImagen);
                      //console.log('ejemplo: ' + ejemplo);
                      //console.log('subCadena: ' + subCadena);

                      if (formatoImagen === "PNG") {
                        doc.image(imagenBase64, 395, 12, {
                          width: 200,
                          height: 130,
                        });
                      } else {
                        //Imagen cabecera.
                        doc.image("images/imagenPorDefecto.png", 395, 12, {
                          width: 200,
                          height: 130,
                        });

                        Modal.error({
                          title: "Error",
                          content:
                            "El formato de la imagen de la receta " +
                            listaRecetasMenu[i].CODIGO_RECETA +
                            " es incorrecto. Debes eliminar o actualizar la imagen en Menuplanner.",
                        });
                      }
                    }
                  }

                  let cantidadIngredientesPorRecetas = 0;

                  for (let b = 0; b < listaIngredientesRecetas.length; b++) {
                    if (
                      listaIngredientesRecetas[b].ROWNUMBER === 1 &&
                      listaIngredientesRecetas[b].COD_RECETA ===
                        listaRecetasMenu[i].CODIGO_RECETA
                    ) {
                      console.log(listaIngredientesRecetas[b].COD_RECETA);
                      console.log(listaRecetasMenu[i].CODIGO_RECETA);

                      doc
                        .lineCap("butt") //Sombra(Fondo) NOMBRE del INGREDIENTE
                        .moveTo(190, 209)
                        .lineTo(530, 209)
                        .lineWidth(10)
                        .fillAndStroke("blue", "#dbe8ff")
                        .moveTo(530, 209) //Sombra(Fondo) PESO del INGREDIENTE
                        .lineTo(597, 209)
                        .stroke()
                        .lineWidth(0.5) //Borde NOMBRE del INGREDIENTE
                        .fillAndStroke("black")
                        .roundedRect(190, 204, 340, 10)
                        .roundedRect(530, 204, 67, 10) //Borde PESO INGREDIENTE
                        .stroke()
                        .fontSize(9) //Descriptivo INGREDIENTE
                        .font("Helvetica")
                        .text(
                          listaIngredientesRecetas[b].INGREDIENTE,
                          192,
                          206,
                          { width: 260, height: 10 }
                        )
                        .text(
                          listaIngredientesRecetas[b].CANTIDAD,
                          522,
                          206,
                          { width: 73, height: 10, align: "right" }
                        ) //PESO INGREDIENTE
                        .moveDown();
                      cantidadIngredientesPorRecetas++;
                    } else if (
                      listaIngredientesRecetas[b].ROWNUMBER != 1 &&
                      listaIngredientesRecetas[b].COD_RECETA ===
                        listaRecetasMenu[i].CODIGO_RECETA
                    ) {
                      doc
                        .lineCap("butt") //Sombra(Fondo) NOMBRE del INGREDIENTE
                        .moveTo(
                          190,
                          209 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12
                        )
                        .lineTo(
                          530,
                          209 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12
                        )
                        .lineWidth(10)
                        .fillAndStroke("blue", "#dbe8ff")
                        .moveTo(
                          530,
                          209 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12
                        ) //Sombra(Fondo) PESO del INGREDIENTE
                        .lineTo(
                          597,
                          209 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12
                        )
                        .lineWidth(10)
                        .stroke()
                        .lineWidth(0.5) //Borde NOMBRE del INGREDIENTE
                        .fillAndStroke("black")
                        .roundedRect(
                          190,
                          204 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12,
                          340,
                          10
                        )
                        .roundedRect(
                          530,
                          204 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12,
                          67,
                          10
                        ) //Borde PESO INGREDIENTE
                        .stroke()
                        .fontSize(9) //Descriptivo INGREDIENTE
                        .text(
                          listaIngredientesRecetas[b].INGREDIENTE,
                          192,
                          206 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12,
                          { width: 260, height: 10 }
                        )
                        .text(
                          listaIngredientesRecetas[b].CANTIDAD,
                          522,
                          206 +
                            12 * listaIngredientesRecetas[b].ROWNUMBER -
                            12,
                          { width: 73, height: 10, align: "right" }
                        ) //PESO INGREDIENTE
                        .moveDown();
                      cantidadIngredientesPorRecetas++;
                    }
                  }
                  //}

                  var longitudElaboracionReceta =
                    listaRecetasMenu[i].ELABORACION === null
                      ? 0
                      : listaRecetasMenu[i].ELABORACION.length;

                  let puntosADesplazar =
                    13 * cantidadIngredientesPorRecetas +
                    (longitudElaboracionReceta / 85) * 10;

                  //Marcar título FORMA DE PREPARACIÓN.
                  doc
                    .lineWidth(2)
                    .fillAndStroke("blue", "#003cac") //Borde Color AZUL
                    .lineCap("butt")
                    .moveTo(
                      190,
                      240 + 12 * cantidadIngredientesPorRecetas - 12
                    )
                    .lineTo(
                      597,
                      240 + 12 * cantidadIngredientesPorRecetas - 12
                    )
                    .lineWidth(22)
                    .stroke()
                    .fontSize(11) //Descriptivo FORMA DE PREPARACIÓN
                    .font("Helvetica-Bold")
                    .fillColor("white")
                    .text(
                      "FORMA DE PREPARACIÓN",
                      190,
                      236 + 12 * cantidadIngredientesPorRecetas - 12,
                      {
                        width: 397,
                        align: "center",
                      }
                    )
                    .fontSize(9) //Descriptivo FORMA DE PREPARACIÓN
                    .font("Helvetica")
                    .fillColor("black")
                    .text(
                      listaRecetasMenu[i].ELABORACION === null
                        ? ""
                        : listaRecetasMenu[i].ELABORACION,
                      191,
                      255 + 12 * cantidadIngredientesPorRecetas - 12,
                      {
                        width: 405,
                        align: "justify",
                      }
                    )
                    .lineWidth(2) //Marcar título MODO Y GRAMAJES DE SERVICIO.
                    .fillAndStroke("blue", "#003cac") //Borde Color AZUL
                    .lineCap("butt")
                    .moveTo(190, 255 + puntosADesplazar)
                    .lineTo(597, 255 + puntosADesplazar)
                    .lineWidth(22)
                    .stroke()
                    .fontSize(11) //Descriptivo MODO Y GRAMAJES DE SERVICIO
                    .font("Helvetica-Bold")
                    .fillColor("white")
                    .text(
                      "MODO Y GRAMAJES DE SERVICIO",
                      190,
                      252 + puntosADesplazar,
                      {
                        width: 397,
                        align: "center",
                      }
                    )
                    .fontSize(9) //Descriptivo FORMA DE PREPARACIÓN
                    .font("Helvetica")
                    .fillColor("black")
                    .text(
                      listaRecetasMenu[i].MODO_EMPLEO_USO === null
                        ? ""
                        : listaRecetasMenu[i].MODO_EMPLEO_USO,
                      191,
                      270 + puntosADesplazar,
                      {
                        width: 405,
                        align: "justify",
                      }
                    );

                  if (
                    listaRecetasMenu.length != 1 &&
                    i != listaRecetasMenu.length - 1
                  ) {
                    //Agrego la segunda página
                    doc.addPage();
                  }
                }
                console.log("Todo bien hasta aquí.");

                // Obtengo el blob cuando está hecho todo.

                //const tiempo = checkboxImprimirImagenPlato === true ? 350 : 100;
                //console.log("Estado Imprimir Final: " + this.state.loadingSpingBuscarPlato);

                console.log(
                  "Hora final: " +
                    f.getHours() +
                    ":" +
                    f.getMinutes() +
                    ":" +
                    f.getSeconds()
                );

                doc.end();
                stream.on("finish", function () {
                  //   // // get a blob you can do whatever you like with
                  //   // const blob = stream.toBlob('application/pdf');

                  //   // or get a blob URL for display in the browser
                  const url = stream.toBlobURL("application/pdf");
                  // abrir un PDF en una pestaña nueva
                  window.open(url, "_blank");
                  modal.destroy();
                  // redirigir la pestaña actual a otra URL
                  //window.location.href = 'http://ejemplo.com';
                });
                //Hasta aquí los ingredientes.
              });
          });
        } else if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length === 0
        ) {
          Modal.error({
            title: "Error",
            content:
              "El menú " +
              this.state.selectedRowsMenu[0].ID_MENU +
              "." +
              this.state.selectedRowsMenu[0].NOM_MENU +
              " no tiene recetas asignadas.",
          });
          modal.destroy();
        } else {
          Modal.error({
            title: "Error",
            content:
              "Ha ocurrido el siguiente error: " +
              listaRecetasMenuValores.message,
          });
          modal.destroy();
        }
      });
  } catch (e) {
    Modal.error({
      title: "Error",
      content:
        "Ha ocurrido el siguiente error al Imprimir las Etiquetas: " +
        e +
        ". Contactar con el administrador.",
    });
    console.log(e);
  }
}

async function imprimirRecetasPlantilla3(
  catalogoActualIdioma,
  listaCodigoRecetas,
  idiomaActual,
  valorSelectLogo,
  checkboxImprimirImagenPlato
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    const modal = Modal.info();
    const tiempo = checkboxImprimirImagenPlato === true ? 110 : 50;

    llamarModal(tiempo * listaCodigoRecetas.length, modal);

    /**
     * Obtengo el listado de recetas con sus datos dado el menú...
     */
    database
      .getRecetasBloques(catalogoActualIdioma, listaCodigoRecetas, idiomaActual)
      .then((valor) => {
        const listaRecetasMenuValores = valor.response;

        /**
         * Recorro el JSON listaRecetasMenu para obtener solamente los códigos de las recetas para buscar sus ingredientes y fotos
         */

        if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length > 0
        ) {
          var listaRecetasMenu = listaRecetasMenuValores.message;
          /**
           * Recupero todos los ingredientes de las recetas seleccionadas por el usuario.
           */
          database.getIngredientesRecetas(listaCodigoRecetas).then((valor) => {
            const listaIngredientesRecetas = valor.response;

            database
              .getTotalIngredientesRecetas(listaCodigoRecetas)
              .then((valor) => {
                const listaTotalIngredientesRecetas = valor.response;

                console.log("listaRecetasMenu");
                console.log(listaRecetasMenu);
                console.log("listaIngredientesRecetas");
                console.log(listaIngredientesRecetas);
                console.log("listaTotalIngredientesRecetas");
                console.log(listaTotalIngredientesRecetas);

                //this.setState({ listaFotosRecetas: [] });

                //Crear el documento
                var doc = new PDFDocument({
                  size: "LETTER",
                  layout: "portrait",
                  margin: 5,
                  info: {
                    Title: "Recetas",
                    Author: "Alejandro Vázquez Chávez",
                  },
                });

                //Canalizar el documento a un blob
                var stream = doc.pipe(blobStream());

                //Obtener lista de recetas con fotos a imprimir

                let count = 0;
                let error = 0;
                listaRecetasMenu.forEach(async function (listItem, i) {
                  let listaFotosRecetas = valor?.response
                  if (checkboxImprimirImagenPlato === true) {
                    const valor = await database.getFotosRecetasPlantillas(listItem.CODIGO_RECETA)
                    listaFotosRecetas = valor?.response
                  }
                  //if (listaRecetasMenu.length != 1 && i != listaRecetasMenu.length - 1) {
                  console.log("i: ", i);

                  if (
                    listaFotosRecetas.length === 0 ||
                    listaFotosRecetas.length > 0
                  ) {
                    if (count != 0) {
                      //Agrego la segunda página
                      doc.addPage();
                    }

                    // this.setState({ listaFotosRecetas: fotosRecetas });
                    console.log(
                      "listaFotosRecetas-------------------------------------------------------------------"
                    );
                    console.log(listaFotosRecetas);
                    // console.log(this.state.listaFotosRecetas);

                    /**
                     * Creo el gráfico de pastel de cada receta.
                     */

                    //Logo compañía
                    doc.image(valorSelectLogo, 110, 60, {
                      scale: 0.4,
                    });

                    //Nombre de la receta
                    doc
                      .fontSize(14)
                      .font("Helvetica-Bold")
                      .fillColor("black")
                      .text(
                        `${listItem.NCOMERCIAL_RECETA} (${listItem.NOMBRE_RECETA})`,
                        15,
                        22,
                        {
                          width: 380,
                          height: 60,
                        }
                      )
                      .moveDown();

                    //Fecha de impresión
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(`Fecha impresión: ${fechaActual}`, 15, 765, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Codificación receta-catálogo
                    doc
                      .fontSize(9)
                      .text(
                        `${listItem.CODIGO_RECETA}-${listItem.CATALOGO}`,
                        515,
                        765,
                        {
                          align: "right",
                          width: 80,
                          height: 900,
                        }
                      )
                      .moveDown();

                    //Descriptivo sobre los alérgenos
                    doc
                      .fontSize(6)
                      .font("Helvetica")
                      .fillColor("blue")
                      .text(`${infoAlergenosNoComercial}`, 15, 748, {
                        width: 585,
                        height: 300,
                      })
                      .moveDown();

                    /**
                     * SOMBREADO DESCRIPTIVOS INFO. NUTRICIONAL
                     */

                    doc
                      .lineCap("butt")
                      .lineWidth(14)
                      .fillAndStroke("blue", "#dbe8ff")
                      .moveTo(20, 208) // Sombreado ENERGÍA
                      .lineTo(163, 208) // Sombreado ENERGÍA
                      .moveTo(20, 222) //Sombreado Proteinas
                      .lineTo(163, 222) //Sombreado Proteinas
                      .moveTo(20, 236) //Sombreado H. Carbonos
                      .lineTo(163, 236) //Sombreado H. Carbonos
                      .moveTo(20, 250) //Sombreado Azucares
                      .lineTo(163, 250) //Sombreado Azucares
                      .moveTo(20, 264) //Sombreado sal
                      .lineTo(163, 264) //Sombreado sal
                      .moveTo(20, 278) //Sombreado FIBRA
                      .lineTo(163, 278) //Sombreado FIBRA
                      .moveTo(20, 292) //Sombreado lipidos
                      .lineTo(163, 292) //Sombreado lipidos
                      .moveTo(20, 306) //Sombreado colesterol
                      .lineTo(163, 306) //Sombreado colesterol
                      .moveTo(20, 320) //Sombreado ags
                      .lineTo(163, 320) //Sombreado ags
                      .moveTo(20, 334) //Sombreado agm
                      .lineTo(163, 334) //Sombreado agm
                      .moveTo(20, 348) //Sombreado agp
                      .lineTo(163, 348) //Sombreado agp
                      .moveTo(20, 362) //Sombreado calcio
                      .lineTo(163, 362) //Sombreado calcio
                      .moveTo(20, 376) //Sombreado hierro
                      .lineTo(163, 376) //Sombreado hierro
                      .moveTo(20, 390) //Sombreado sodio
                      .lineTo(163, 390) //Sombreado sodio
                      .moveTo(20, 404) //Sombreado iodo
                      .lineTo(163, 404) //Sombreado iodo
                      .moveTo(20, 418) //Sombreado magnesio
                      .lineTo(163, 418) //Sombreado magnesio
                      .moveTo(20, 432) //Sombreado zinc
                      .lineTo(163, 432) //Sombreado zinc
                      .moveTo(20, 446) //Sombreado potasio
                      .lineTo(163, 446) //Sombreado potasio
                      .moveTo(20, 460) //Sombreado foforo
                      .lineTo(163, 460) //Sombreado foforo
                      .moveTo(20, 474) //Sombreado selenio
                      .lineTo(163, 474) //Sombreado selenio
                      .moveTo(20, 488) //Sombreado vitamina a
                      .lineTo(163, 488) //Sombreado vitamina a
                      .moveTo(20, 502) //Sombreado vitamina b1
                      .lineTo(163, 502) //Sombreado vitamina b1
                      .moveTo(20, 516) //Sombreado vitamina b2
                      .lineTo(163, 516) //Sombreado vitamina b2
                      .moveTo(20, 530) //Sombreado vitamina b2
                      .lineTo(163, 530) //Sombreado vitamina b2
                      .moveTo(20, 544) //Sombreado acido folico
                      .lineTo(163, 544) //Sombreado acido folico
                      .moveTo(20, 558) //Sombreado niacina
                      .lineTo(163, 558) //Sombreado niacina
                      .moveTo(20, 572) //Sombreado vitamina b6
                      .lineTo(163, 572) //Sombreado vitamina b6
                      .moveTo(20, 586) //Sombreado vitamina b12
                      .lineTo(163, 586) //Sombreado vitamina b12
                      .moveTo(20, 600) //Sombreado retinol
                      .lineTo(163, 600) //Sombreado retinol
                      .moveTo(20, 614) //Sombreado caroteno
                      .lineTo(163, 614) //Sombreado caroteno
                      .moveTo(20, 628) //Sombreado vitamina d
                      .lineTo(163, 628) //Sombreado vitamina d
                      .moveTo(20, 642) //Sombreado vitamina e
                      .lineTo(163, 642) //Sombreado vitamina e
                      .stroke();

                    /**
                     * BORDES INFO. NUTRICIONAL
                     */
                    doc
                      .lineWidth(0.5)
                      .fillAndStroke("black")
                      .roundedRect(20, 201, 93, 14) //Borde ENERGÍA
                      .roundedRect(20, 215, 93, 14) //Borde Proteinas
                      .roundedRect(20, 229, 93, 14) //Borde H. Carbonos
                      .roundedRect(20, 243, 93, 14) //Borde Azucares
                      .roundedRect(20, 257, 93, 14) //Borde Sal
                      .roundedRect(20, 271, 93, 14) //Borde fibra
                      .roundedRect(20, 285, 93, 14) //Borde lipidos
                      .roundedRect(20, 299, 93, 14) //Borde colesterol
                      .roundedRect(20, 313, 93, 14) //Borde ags
                      .roundedRect(20, 327, 93, 14) //Borde agm
                      .roundedRect(20, 341, 93, 14) //Borde agp
                      .roundedRect(20, 355, 93, 14) //Borde calcio
                      .roundedRect(20, 369, 93, 14) //Borde hierro
                      .roundedRect(20, 383, 93, 14) //Borde sodio
                      .roundedRect(20, 397, 93, 14) //Borde iodo
                      .roundedRect(20, 411, 93, 14) //Borde magnesio
                      .roundedRect(20, 425, 93, 14) //Borde zinc
                      .roundedRect(20, 439, 93, 14) //Borde potasio
                      .roundedRect(20, 453, 93, 14) //Borde fosforo
                      .roundedRect(20, 467, 93, 14) //Borde selenio
                      .roundedRect(20, 481, 93, 14) //Borde vitamina a
                      .roundedRect(20, 495, 93, 14) //Borde vitamina b1
                      .roundedRect(20, 509, 93, 14) //Borde vitamina b2
                      .roundedRect(20, 523, 93, 14) //Borde acido folico
                      .roundedRect(20, 537, 93, 14) //Borde vitamina c
                      .roundedRect(20, 551, 93, 14) //Borde niacina
                      .roundedRect(20, 565, 93, 14) //Borde vitamina b6
                      .roundedRect(20, 579, 93, 14) //Borde vitamina b12
                      .roundedRect(20, 593, 93, 14) //Borde retinol
                      .roundedRect(20, 607, 93, 14) //Borde caroteno
                      .roundedRect(20, 621, 93, 14) //Borde vitamina d
                      .roundedRect(20, 635, 93, 14) //Borde vitamina e
                      .roundedRect(19, 668, 145, 77) //Borde Alergenos
                      .stroke();

                    /**
                     * BORDES VALOR INFO. NUTRICIONAL
                     */
                    doc
                      .lineWidth(0.5)
                      .fillAndStroke("black")
                      .roundedRect(113, 201, 50, 14) //Borde Valor Energía
                      .roundedRect(113, 215, 50, 14) //Borde Valor Proteinas
                      .roundedRect(113, 229, 50, 14) //Borde Valor  H. Carbonos
                      .roundedRect(113, 243, 50, 14) //Borde Valor  Azucares
                      .roundedRect(113, 257, 50, 14) //Borde Valor fibra
                      .roundedRect(113, 271, 50, 14) //Borde Valor lípidos
                      .roundedRect(113, 285, 50, 14) //Borde Valor colesterol
                      .roundedRect(113, 299, 50, 14) //Borde Valor AGS
                      .roundedRect(113, 313, 50, 14) //Borde Valor AGM
                      .roundedRect(113, 327, 50, 14) //Borde Valor AGP
                      .roundedRect(113, 341, 50, 14) //Borde Valor calcio
                      .roundedRect(113, 355, 50, 14) //Borde Valor hierro
                      .roundedRect(113, 369, 50, 14) //Borde Valor sodio
                      .roundedRect(113, 383, 50, 14) //Borde Valor iodo
                      .roundedRect(113, 397, 50, 14) //Borde Valor magnesio
                      .roundedRect(113, 411, 50, 14) //Borde Valor zinc
                      .roundedRect(113, 425, 50, 14) //Borde Valor potasio
                      .roundedRect(113, 439, 50, 14) //Borde Valor fosforo
                      .roundedRect(113, 453, 50, 14) //Borde Valor selenio
                      .roundedRect(113, 467, 50, 14) //Borde Valor vitamina A
                      .roundedRect(113, 481, 50, 14) //Borde Valor vitamina b1
                      .roundedRect(113, 495, 50, 14) //Borde Valor vitamina b2
                      .roundedRect(113, 509, 50, 14) //Borde Valor acido folico
                      .roundedRect(113, 523, 50, 14) //Borde Valor vitamina c
                      .roundedRect(113, 537, 50, 14) //Borde Valor niacina
                      .roundedRect(113, 551, 50, 14) //Borde Valor vitamina b6
                      .roundedRect(113, 565, 50, 14) //Borde Valor vitamina b12
                      .roundedRect(113, 579, 50, 14) //Borde Valor retinol
                      .roundedRect(113, 593, 50, 14) //Borde Valor carotenos
                      .roundedRect(113, 607, 50, 14) //Borde Valor vitamina d
                      .roundedRect(113, 621, 50, 14) //Borde Valor vitamina E
                      .roundedRect(113, 635, 50, 14) //Borde Valor vitamina E
                      .stroke();

                    /**
                     * DESCRIPTIVOS INFO. NUTRICIONAL
                     */
                    //ENERGÍA KCal(Kjul)
                    doc
                      .fontSize(7)
                      .font("Helvetica")
                      .fillColor("black")
                      .text("ENERGÍA KCal(Kjul)", 22, 205, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.KCALORIAS
                        ),
                        111,
                        205,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("PROTEINAS (g)", 22, 220, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.PROTEINAS
                        ),
                        111,
                        220,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("HID. DE CARBONO (g)", 22, 234, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.HIDRATOS_C
                        ),
                        111,
                        234,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AZÚCARES (g)", 22, 248, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AZUCARES
                        ),
                        111,
                        248,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("SAL (g)", 22, 262, {
                        width: 260,
                        height: 14,
                      })
                      .fontSize(7)
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.SAL
                        ),
                        111,
                        262,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("FIBRA (g)", 22, 276, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.FIBRA
                        ),
                        111,
                        276,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("LÍPIDOS (g)", 22, 290, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.LIPIDOS
                        ),
                        111,
                        290,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("COLESTEROL (mg)", 22, 304, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.COLESTEROL
                        ),
                        111,
                        304,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AGS (g)", 22, 318, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AGS
                        ),
                        111,
                        318,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AGM (g)", 22, 332, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AGM
                        ),
                        111,
                        332,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AGP (g)", 22, 346, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AGP
                        ),
                        111,
                        346,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("CALCIO (mg)", 22, 360, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.CALCIO
                        ),
                        111,
                        360,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("HIERRO (mg)", 22, 374, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.HIERRO
                        ),
                        111,
                        374,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("SODIO (mg)", 22, 388, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.SODIO
                        ),
                        111,
                        388,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .moveDown()
                      .text("IODO (mg)", 22, 402, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.IODO
                        ),
                        111,
                        402,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("MAGNESIO (mg)", 22, 416, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.MAGNESIO
                        ),
                        111,
                        416,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("ZINC (mg)", 22, 430, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.ZINC
                        ),
                        111,
                        430,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("POTASIO (mcg)", 22, 444, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.POTASIO
                        ),
                        111,
                        444,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("FÓSFORO (mg)", 22, 458, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.FOSFORO
                        ),
                        111,
                        458,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("SELENIO (mg)", 22, 472, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.SELENIO
                        ),
                        111,
                        472,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA A (mg)", 22, 486, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_A
                        ),
                        111,
                        486,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B1 (mg)", 22, 500, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B1
                        ),
                        111,
                        500,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B2 (mg)", 22, 514, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B2
                        ),
                        111,
                        514,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("ÁCIDO FÓLICO (mg)", 22, 528, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.A_FOLICO
                        ),
                        111,
                        528,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA C (mg)", 22, 542, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_C
                        ),
                        111,
                        542,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("NIACINA (mg)", 22, 556, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.NIACINA
                        ),
                        111,
                        556,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B6 (mg)", 22, 570, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B6
                        ),
                        111,
                        570,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B12 (mg)", 22, 584, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B12
                        ),
                        111,
                        584,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("RETINOL (mg)", 22, 598, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.RETINOL
                        ),
                        111,
                        598,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("CAROTENO (mg)", 22, 612, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.CAROTENO
                        ),
                        111,
                        612,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA D (mg)", 22, 626, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_D
                        ),
                        111,
                        626,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA E (mg)", 22, 640, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_E
                        ),
                        111,
                        640,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .moveDown();

                    let alergenos = false;
                    if (
                      listItem.DECLARACION_ALERGENOS !== null &&
                      listItem.DECLARACION_ALERGENOS.length > 0
                    )
                      alergenos = true;

                    //DECLARACION ALERGENOS
                    doc
                      .fontSize(7.7)
                      .text(
                        alergenos === true
                          ? `${listItem.DECLARACION_ALERGENOS}.`
                          : "",
                        20,
                        670,
                        {
                          width: 148,
                          height: 100,
                          align: "left",
                        }
                      )
                      .moveDown();

                    //Color azul del borde
                    doc.lineWidth(2).fillAndStroke("blue", "#003cac");

                    //Marcar título INFORMACIÓN NUTRICIONAL Y ALERGENOS
                    doc
                      .lineCap("butt")
                      .moveTo(19, 192)
                      .lineTo(164, 192)
                      .lineWidth(17)
                      .lineCap("butt")
                      .moveTo(19, 660)
                      .lineTo(164, 660)
                      .lineWidth(17)
                      .stroke();

                    //Descriptivo INFORMACIÓN NUTRICIONAL  Y ALERGENOS
                    doc
                      .fontSize(8.5)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("INFORMACIÓN NUTRICIONAL", 29, 187, {
                        width: 150,
                      })
                      .text("ALÉRGENOS", 63, 657, {
                        width: 150,
                      })
                      .moveDown();

                    //Marcar título INGREDIENTES
                    doc
                      .lineCap("butt")
                      .moveTo(190, 193)
                      .lineTo(597, 193)
                      .lineWidth(22)
                      .stroke();

                    //Descriptivo Ingredientes
                    doc
                      .fontSize(11)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("INGREDIENTES", 190, 188, {
                        width: 370,
                        align: "center",
                      })
                      .fontSize(8)
                      .text("PESO BRUTO (g)\n100 raciones", 364, 185, {
                        width: 397,
                        align: "center",
                      })
                      .moveDown();

                    let cantidadIngredientesPorRecetas = 0;

                    for (
                      let b = 0;
                      b < listaIngredientesRecetas.length;
                      b++
                    ) {
                      if (
                        listaIngredientesRecetas[b].ROWNUMBER === 1 &&
                        listaIngredientesRecetas[b].COD_RECETA ===
                          listItem.CODIGO_RECETA
                      ) {
                        console.log(listaIngredientesRecetas[b].COD_RECETA);
                        console.log(listItem.CODIGO_RECETA);

                        doc
                          .lineCap("butt") //Sombra(Fondo) NOMBRE del INGREDIENTE
                          .moveTo(190, 209)
                          .lineTo(530, 209)
                          .lineWidth(10)
                          .fillAndStroke("blue", "#dbe8ff")
                          .moveTo(530, 209) //Sombra(Fondo) PESO del INGREDIENTE
                          .lineTo(597, 209)
                          .stroke()
                          .lineWidth(0.5) //Borde NOMBRE del INGREDIENTE
                          .fillAndStroke("black")
                          .roundedRect(190, 204, 340, 10)
                          .roundedRect(530, 204, 67, 10) //Borde PESO INGREDIENTE
                          .stroke()
                          .fontSize(9) //Descriptivo INGREDIENTE
                          .font("Helvetica")
                          .text(
                            listaIngredientesRecetas[b].INGREDIENTE,
                            192,
                            206,
                            { width: 260, height: 10 }
                          )
                          .text(
                            listaIngredientesRecetas[b].CANTIDAD,
                            522,
                            206,
                            {
                              width: 73,
                              height: 10,
                              align: "right",
                            }
                          ) //PESO INGREDIENTE
                          .moveDown();
                        cantidadIngredientesPorRecetas++;
                      } else if (
                        listaIngredientesRecetas[b].ROWNUMBER != 1 &&
                        listaIngredientesRecetas[b].COD_RECETA ===
                          listItem.CODIGO_RECETA
                      ) {
                        doc
                          .lineCap("butt") //Sombra(Fondo) NOMBRE del INGREDIENTE
                          .moveTo(
                            190,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          )
                          .lineTo(
                            530,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          )
                          .lineWidth(10)
                          .fillAndStroke("blue", "#dbe8ff")
                          .moveTo(
                            530,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          ) //Sombra(Fondo) PESO del INGREDIENTE
                          .lineTo(
                            597,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          )
                          .lineWidth(10)
                          .stroke()
                          .lineWidth(0.5) //Borde NOMBRE del INGREDIENTE
                          .fillAndStroke("black")
                          .roundedRect(
                            190,
                            204 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            340,
                            10
                          )
                          .roundedRect(
                            530,
                            204 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            67,
                            10
                          ) //Borde PESO INGREDIENTE
                          .stroke()
                          .fontSize(9) //Descriptivo INGREDIENTE
                          .text(
                            listaIngredientesRecetas[b].INGREDIENTE,
                            192,
                            206 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            { width: 260, height: 10 }
                          )
                          .text(
                            listaIngredientesRecetas[b].CANTIDAD,
                            522,
                            206 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            {
                              width: 73,
                              height: 10,
                              align: "right",
                            }
                          ) //PESO INGREDIENTE
                          .moveDown();
                        cantidadIngredientesPorRecetas++;
                      }
                    }
                    //}

                    var longitudElaboracionReceta =
                      listItem.MODO_EMPLEO_USO === null
                        ? 0
                        : listItem.MODO_EMPLEO_USO.length;

                    let puntosADesplazar =
                      13 * cantidadIngredientesPorRecetas +
                      (longitudElaboracionReceta / 85) * 10;

                    //Marcar título FORMA DE PREPARACIÓN.
                    doc
                      .lineWidth(2)
                      .fillAndStroke("blue", "#003cac") //Borde Color AZUL
                      .lineCap("butt")
                      .moveTo(
                        190,
                        240 + 12 * cantidadIngredientesPorRecetas - 12
                      )
                      .lineTo(
                        597,
                        240 + 12 * cantidadIngredientesPorRecetas - 12
                      )
                      .lineWidth(22)
                      .stroke()
                      .fontSize(11) //Descriptivo FORMA DE PREPARACIÓN
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text(
                        "MODO Y GRAMAJES DE SERVICIO",
                        190,
                        236 + 12 * cantidadIngredientesPorRecetas - 12,
                        {
                          width: 397,
                          align: "center",
                        }
                      )
                      .fontSize(9) //Descriptivo FORMA DE PREPARACIÓN
                      .font("Helvetica")
                      .fillColor("black")
                      .text(
                        listItem.MODO_EMPLEO_USO === null
                          ? ""
                          : listItem.MODO_EMPLEO_USO,
                        191,
                        255 + 12 * cantidadIngredientesPorRecetas - 12,
                        {
                          width: 405,
                          align: "justify",
                        }
                      )
                      .lineWidth(2) //Marcar título MODO Y GRAMAJES DE SERVICIO.
                      .fillAndStroke("blue", "#003cac") //Borde Color AZUL
                      .lineCap("butt")
                      .moveTo(190, 260 + puntosADesplazar)
                      .lineTo(597, 260 + puntosADesplazar)
                      .lineWidth(22)
                      .stroke()
                      .fontSize(11) //Descriptivo MODO Y GRAMAJES DE SERVICIO
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("ENVASADO", 190, 257 + puntosADesplazar, {
                        width: 397,
                        align: "center",
                      })
                      .fontSize(9) //Descriptivo FORMA DE PREPARACIÓN
                      .font("Helvetica")
                      .fillColor("black")
                      .text(
                        "Primera foto corresponde a servicio de línea fría. Segunda foto corresponde a servicio de línea caliente. Tercera foto (si aplica) corresponde a guarnición.",
                        191,
                        276 + puntosADesplazar,
                        {
                          width: 405,
                          align: "justify",
                        }
                      );

                    /**
                      * IMPRIMIR FOTOS
                      */
                    let fotoRec1 = "null";
                    let fotoRec2 = "null";
                    let fotoRec3 = "null";
                    let fotoRec4 = "null";
                    let asciiFoto1 = "";
                    let asciiFoto2 = "";
                    let asciiFoto3 = "";
                    let asciiFoto4 = "";
                    let arr1,
                      arr2,
                      arr3,
                      arr4 = [];

                    /**
                      * Obtengo solo los nombres de las fotos de las recetas para saber cuantas fotos tengo.
                      */
                    let arrayNombreFotosRecetas = listaFotosRecetas.map(
                      (currentValue, index, array) => {
                        // Elemento devuelto de nuevo_array
                        return (
                          currentValue.NOMBRE_FICHERO +
                          currentValue.RECETA_ID
                        );
                      }
                    );

                    /**
                      * Quedarme solo con los nombres de las fotos de las recetas
                      */
                    let nombresFotosRecetasUnicos =
                      arrayNombreFotosRecetas.filter(
                        FuncionesAuxiliares.soloValorUnico
                      );
                    console.log(nombresFotosRecetasUnicos);

                    if (checkboxImprimirImagenPlato === true) {
                      //Imagen receta
                      if (listaFotosRecetas.length != 0) {
                        //Si las recetas no tienen fotos no recorro la lista.

                        for (
                          let q = 0;
                          q < nombresFotosRecetasUnicos.length;
                          q++
                        ) {
                          for (
                            let a = 0;
                            a < listaFotosRecetas.length;
                            a++
                          ) {
                            /**
                              * Recorro el listado de recetas y si hay fotos viene en varchar de 4000 caracteres cada línea,
                              * la concateno y la guardo en el buffer para convertir la cadena completa a base64.
                              */

                            let nombreFotoID =
                              listaFotosRecetas[a].NOMBRE_FICHERO +
                              listaFotosRecetas[a].RECETA_ID;
                            if (q === 0) {
                              console.log("nombreFotoID 1");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[0] ===
                                  nombreFotoID
                              ) {
                                let buf1 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec1 === "null") {
                                  asciiFoto1 = buf1;
                                  fotoRec1 = asciiFoto1.length;
                                } else {
                                  arr1 = [asciiFoto1, buf1];
                                  asciiFoto1 = Buffer.concat(arr1);
                                }
                              } else if (
                                asciiFoto1 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto1 += "";
                              }
                            } else if (q === 1) {
                              console.log("nombreFotoID 2");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[1] ===
                                  nombreFotoID
                              ) {
                                let buf2 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec2 === "null") {
                                  asciiFoto2 = buf2;
                                  fotoRec2 = asciiFoto2.length;
                                } else {
                                  arr2 = [asciiFoto2, buf2];
                                  asciiFoto2 = Buffer.concat(arr2);
                                }
                              } else if (
                                asciiFoto2 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto2 += "";
                              }
                            } else if (q === 2) {
                              console.log("nombreFotoID 3");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[2] ===
                                  nombreFotoID
                              ) {
                                let buf3 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec3 === "null") {
                                  asciiFoto3 = buf3;
                                  fotoRec3 = asciiFoto3.length;
                                } else {
                                  arr3 = [asciiFoto3, buf3];
                                  asciiFoto3 = Buffer.concat(arr3);
                                }
                              } else if (
                                asciiFoto3 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto3 += "";
                              }
                            } else if (q === 3) {
                              console.log("nombreFotoID 4");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[3] ===
                                  nombreFotoID
                              ) {
                                let buf4 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec4 === "null") {
                                  asciiFoto4 = buf4;
                                  fotoRec4 = asciiFoto4.length;
                                } else {
                                  arr4 = [asciiFoto4, buf4];
                                  asciiFoto4 = Buffer.concat(arr4);
                                }
                              } else if (
                                asciiFoto4 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto4 += "";
                              }
                            }
                          }
                        }
                      } else {
                        asciiFoto1 += "";
                        asciiFoto2 += "";
                        asciiFoto3 += "";
                        asciiFoto4 += "";
                      }

                      //Información sobre los alérgenos
                      doc
                        .fontSize(6)
                        .font("Helvetica")
                        .fillColor("blue")
                        .text(
                          "* Foto y gramaje corresponden a grupo de edad 12 AÑOS",
                          400,
                          150,
                          {
                            width: 585,
                            height: 300,
                          }
                        )
                        .moveDown();

                      console.log(asciiFoto1);

                      /**
                        * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 1 - cabecera.
                        */
                      if (asciiFoto1 === "") {
                        doc.image(
                          "images/imagenPorDefectoP1-2.png",
                          395,
                          12,
                          {
                            width: 200,
                            height: 130,
                          }
                        );
                      } else {
                        var b64 =
                          Buffer.from(asciiFoto1).toString("base64");
                        console.log(asciiFoto1.length);
                        console.log(asciiFoto1);
                        console.log(b64);

                        var imagenBase64 = new Buffer(b64, "base64");
                        var formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);
                        //console.log('ejemplo: ' + ejemplo);
                        //console.log('subCadena: ' + subCadena);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen cabecera.
                            doc.image(imagenBase64, 395, 12, {
                              width: 200,
                              height: 130,
                            });
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 1: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen cabecera.
                          doc.image(
                            "images/imagenPorDefecto.png",
                            395,
                            12,
                            {
                              width: 200,
                              height: 130,
                            }
                          );

                          // Modal.error({
                          //     title: 'Error',
                          //     content: 'El formato de la imagen 1 de la receta ' + listItem.CODIGO_RECETA + ' es incorrecto. Debes eliminar o actualizar la imagen en Menuplanner.',
                          // });
                          // error = 1;
                          // modal.destroy();
                        }
                      }
                      /**
                        * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 2 - línea fría.
                        */
                      if (asciiFoto2 === "") {
                        //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar.
                        doc
                          .image(
                            "images/imagenPorDefectoP1-2.png",
                            190,
                            300 + puntosADesplazar,
                            {
                              width: 185,
                              height: 120,
                            }
                          )
                          .fontSize(7)
                          .fillColor("black")
                          .text(
                            "Imagen 1. Servicio de línea fría.",
                            190,
                            424 + puntosADesplazar,
                            {
                              width: 185,
                              height: 10,
                              align: "center",
                            }
                          );
                      } else {
                        let b64 =
                          Buffer.from(asciiFoto2).toString("base64");
                        console.log(asciiFoto1.length);
                        console.log(asciiFoto1);
                        console.log(b64);

                        let imagenBase64 = new Buffer(b64, "base64");
                        let formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);
                        //console.log('ejemplo: ' + ejemplo);
                        //console.log('subCadena: ' + subCadena);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen 2 - Línea fría.
                            doc
                              .image(
                                imagenBase64,
                                190,
                                300 + puntosADesplazar,
                                {
                                  width: 185,
                                  height: 120,
                                }
                              )
                              .fontSize(7)
                              .fillColor("black")
                              .text(
                                "Imagen 1. Servicio de línea fría.",
                                190,
                                424 + puntosADesplazar,
                                {
                                  width: 185,
                                  height: 10,
                                  align: "center",
                                }
                              );
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 2: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen 2 - Línea fría.
                          doc
                            .image(
                              "images/imagenPorDefecto.png",
                              190,
                              300 + puntosADesplazar,
                              {
                                width: 185,
                                height: 120,
                              }
                            )
                            .fontSize(7)
                            .fillColor("black")
                            .text(
                              "Imagen 1. Servicio de línea fría.",
                              190,
                              424 + puntosADesplazar,
                              {
                                width: 185,
                                height: 10,
                                align: "center",
                              }
                            );
                          // Modal.error({
                          //     title: 'Error',
                          //     content: 'El formato de la imagen 2 de la receta ' + listItem.CODIGO_RECETA + ' es incorrecto. Debes eliminar o actualizar la imagen en Menuplanner.',
                          // });
                          // error = 1;
                          // modal.destroy();
                        }
                      }

                      /**
                        * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 3 - línea caliente.
                        */
                      if (asciiFoto3 === "") {
                        //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar
                        doc
                          .image(
                            "images/imagenPorDefectoP1-2.png",
                            190 + 220,
                            300 + puntosADesplazar,
                            {
                              width: 185,
                              height: 120,
                            }
                          )
                          .fontSize(7)
                          .fillColor("black")
                          .text(
                            "Imagen 2. Servicio de línea caliente.",
                            190 + 220,
                            424 + puntosADesplazar,
                            {
                              width: 185,
                              height: 10,
                              align: "center",
                            }
                          );
                      } else {
                        let b64 =
                          Buffer.from(asciiFoto3).toString("base64");
                        console.log(asciiFoto3.length);
                        console.log(asciiFoto3);
                        console.log(b64);

                        let imagenBase64 = new Buffer(b64, "base64");
                        let formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);
                        //console.log('ejemplo: ' + ejemplo);
                        //console.log('subCadena: ' + subCadena);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen 3 - Línea Caliente
                            doc
                              .image(
                                imagenBase64,
                                190 + 220,
                                300 + puntosADesplazar,
                                {
                                  width: 185,
                                  height: 120,
                                }
                              )
                              .fontSize(7)
                              .fillColor("black")
                              .text(
                                "Imagen 2. Servicio de línea caliente.",
                                190 + 220,
                                424 + puntosADesplazar,
                                {
                                  width: 185,
                                  height: 10,
                                  align: "center",
                                }
                              );
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 3: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          // Modal.error({
                          //     title: 'Error',
                          //     content: 'El formato de la imagen 3 de la receta ' + listItem.CODIGO_RECETA + ' es incorrecto. Debes eliminar o actualizar la imagen en Menuplanner.',
                          // });
                          // error = 1;
                          // modal.destroy();
                          //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar
                          doc
                            .image(
                              "images/imagenPorDefecto.png",
                              190 + 220,
                              300 + puntosADesplazar,
                              {
                                width: 185,
                                height: 120,
                              }
                            )
                            .fontSize(7)
                            .fillColor("black")
                            .text(
                              "Imagen 2. Servicio de línea caliente.",
                              190 + 220,
                              424 + puntosADesplazar,
                              {
                                width: 185,
                                height: 10,
                                align: "center",
                              }
                            );
                        }
                      }

                      /**
                        * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 4 Guarnición)().
                        */
                      if (asciiFoto4 === "") {
                        //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar.
                        doc
                          .image(
                            "images/imagenPorDefectoP1-2.png",
                            190,
                            436 + puntosADesplazar,
                            {
                              width: 185,
                              height: 120,
                            }
                          )
                          .fontSize(7)
                          .fillColor("black")
                          .text(
                            "Imagen 3. guarnición.",
                            190,
                            430 + 129 + puntosADesplazar,
                            {
                              width: 185,
                              height: 10,
                              align: "center",
                            }
                          );
                      } else {
                        let b64 =
                          Buffer.from(asciiFoto4).toString("base64");
                        console.log(asciiFoto4.length);
                        console.log(asciiFoto4);
                        console.log(b64);

                        let imagenBase64 = new Buffer(b64, "base64");
                        let formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);
                        //console.log('ejemplo: ' + ejemplo);
                        //console.log('subCadena: ' + subCadena);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen 4 - Guarnición
                            doc
                              .image(
                                imagenBase64,
                                190,
                                430 + puntosADesplazar,
                                {
                                  width: 185,
                                  height: 120,
                                }
                              )
                              .fontSize(7)
                              .fillColor("black")
                              .text(
                                "Imagen 3. guarnición.",
                                190,
                                436 + 129 + puntosADesplazar,
                                {
                                  width: 185,
                                  height: 10,
                                  align: "center",
                                }
                              );
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 4: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          // Modal.error({
                          //     title: 'Error',
                          //     content: 'El formato de la imagen 4 de la receta ' + listItem.CODIGO_RECETA + ' es incorrecto. Debes eliminar o actualizar la imagen en Menuplanner.',
                          // });
                          // error = 1;
                          // modal.destroy();
                          //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar.
                          doc
                            .image(
                              "images/imagenPorDefecto.png",
                              190,
                              436 + puntosADesplazar,
                              {
                                width: 185,
                                height: 120,
                              }
                            )
                            .fontSize(7)
                            .fillColor("black")
                            .text(
                              "Imagen 3. guarnición.",
                              190,
                              430 + 129 + puntosADesplazar,
                              {
                                width: 185,
                                height: 10,
                                align: "center",
                              }
                            );
                        }
                      }
                    }

                    console.log("Todo bien hasta aquí.");

                    console.log(
                      "Hora final: " +
                        f.getHours() +
                        ":" +
                        f.getMinutes() +
                        ":" +
                        f.getSeconds()
                    );

                    count++;
                    console.log("count: ", count);
                    console.log(
                      "listaRecetasMenu.length: ",
                      listaRecetasMenu.length
                    );

                    if (count === listaRecetasMenu.length && error === 0) {
                      setTimeout(() => {
                        doc.end();
                        stream.on("finish", function () {
                          //   // // get a blob you can do whatever you like with
                          //   // const blob = stream.toBlob('application/pdf');

                          //   // or get a blob URL for display in the browser
                          const url = stream.toBlobURL("application/pdf");
                          // abrir un PDF en una pestaña nueva
                          window.open(url, "_blank");
                          modal.destroy();
                          // redirigir la pestaña actual a otra URL
                          //window.location.href = 'http://ejemplo.com';
                        });
                      }, 1000);
                    }
                  }
                    //Hasta aquí los ingredientes.
                });
              });
          });
        } else if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length === 0
        ) {
          Modal.error({
            title: "Error",
            content:
              "El menú " +
              this.state.selectedRowsMenu[0].ID_MENU +
              "." +
              this.state.selectedRowsMenu[0].NOM_MENU +
              " no tiene recetas asignadas.",
          });
          modal.destroy();
        } else {
          Modal.error({
            title: "Error",
            content:
              "Ha ocurrido el siguiente error: " +
              listaRecetasMenuValores.message,
          });
          modal.destroy();
        }
      });
  } catch (e) {
    Modal.error({
      title: "Error",
      content:
        "Ha ocurrido el siguiente error al Imprimir las Etiquetas: " +
        e +
        ". Contactar con el administrador.",
    });
    console.log(e);
  }
}

async function imprimirRecetasPlantilla4(
  catalogoActualIdioma,
  listaCodigoRecetas,
  idiomaActual,
  valorSelectLogo,
  checkboxImprimirImagenPlato
) {
  console.log('imprimirRecetasPlantilla4', checkboxImprimirImagenPlato)
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    const modal = Modal.info();
    const tiempo = checkboxImprimirImagenPlato === true ? 110 : 50;

    llamarModal(tiempo * listaCodigoRecetas.length, modal);

    /**
     * Obtengo el listado de recetas con sus datos dado el menú...
     */
    database
      .getRecetasBloques(catalogoActualIdioma, listaCodigoRecetas, idiomaActual)
      .then((valor) => {
        const listaRecetasMenuValores = valor.response;

        /**
         * Recorro el JSON listaRecetasMenu para obtener solamente los códigos de las recetas para buscar sus ingredientes y fotos
         */

        if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length > 0
        ) {
          var listaRecetasMenu = listaRecetasMenuValores.message;
          /**
           * Recupero todos los ingredientes de las recetas seleccionadas por el usuario.
           */
          database.getIngredientesRecetas(listaCodigoRecetas).then((valor) => {
            const listaIngredientesRecetas = valor.response;
            database
              .getTotalIngredientesRecetas(listaCodigoRecetas)
              .then((valor) => {
                const listaTotalIngredientesRecetas = valor.response;

                console.log("listaRecetasMenu");
                console.log(listaRecetasMenu);
                console.log("listaIngredientesRecetas");
                console.log(listaIngredientesRecetas);
                console.log("listaTotalIngredientesRecetas");
                console.log(listaTotalIngredientesRecetas);

                //Crear el documento
                var doc = new PDFDocument({
                  size: "LETTER",
                  layout: "portrait",
                  margin: 5,
                  info: {
                    Title: "Recetas-Plantilla Cocina Central",
                    Author: "Alejandro Vázquez Chávez",
                  },
                });

                //Canalizar el documento a un blob
                var stream = doc.pipe(blobStream());

                let count = 0;
                let error = 0;
                listaRecetasMenu.forEach(async function (listItem, i) {
                  let listaFotosRecetas = []
                  if (checkboxImprimirImagenPlato === true) {
                    const valor = await database.getFotosRecetasPlantillas(listItem.CODIGO_RECETA)
                    listaFotosRecetas = valor?.response
                  }

                  console.log("i: ", i);

                  if (
                    listaFotosRecetas.length === 0 ||
                    listaFotosRecetas.length > 0
                  ) {
                    if (count != 0) {
                      //Agrego la segunda página
                      doc.addPage();
                    }

                    // this.setState({ listaFotosRecetas: fotosRecetas });
                    console.log(
                      "listaFotosRecetas-------------------------------------------------------------------"
                    );
                    console.log(listaFotosRecetas);
                    // console.log(this.state.listaFotosRecetas);

                    //Logo compañía
                    doc.image(valorSelectLogo, 110, 60, {
                      scale: 0.4,
                    });

                    //Nombre de la receta
                    doc
                      .fontSize(14)
                      .font("Helvetica-Bold")
                      .fillColor("black")
                      .text(
                        `${listItem.NCOMERCIAL_RECETA} (${listItem.NOMBRE_RECETA})`,
                        15,
                        22,
                        {
                          width: 380,
                          height: 60,
                        }
                      )
                      .fontSize(8)
                      .font("Helvetica")
                      .text(
                        "Foto de entermado y guarnición (si aplica)\nver Reverso",
                        230,
                        80,
                        {
                          width: 150,
                          height: 25,
                          align: "center",
                        }
                      )
                      .moveDown();

                    //Fecha de impresión
                    doc
                      .fontSize(9)
                      .font("Helvetica")
                      .text(`Fecha impresión: ${fechaActual}`, 15, 765, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Codificación receta-catálogo
                    doc
                      .fontSize(9)
                      .text(
                        `${listItem.CODIGO_RECETA}-${listItem.CATALOGO}`,
                        515,
                        765,
                        {
                          align: "right",
                          width: 80,
                          height: 900,
                        }
                      )
                      .moveDown();

                    //Descriptivo sobre los alérgenos
                    doc
                      .fontSize(6)
                      .font("Helvetica")
                      .fillColor("blue")
                      .text(`${infoAlergenosNoComercial}`, 15, 748, {
                        width: 585,
                        height: 300,
                      })
                      .moveDown();

                    /**
                     * SOMBREADO DESCRIPTIVOS INFO. NUTRICIONAL
                     */
                    doc
                      .lineCap("butt")
                      .lineWidth(14)
                      .fillAndStroke("blue", "#dbe8ff")
                      .moveTo(20, 208) // Sombreado ENERGÍA
                      .lineTo(163, 208) // Sombreado ENERGÍA
                      .moveTo(20, 222) //Sombreado Proteinas
                      .lineTo(163, 222) //Sombreado Proteinas
                      .moveTo(20, 236) //Sombreado H. Carbonos
                      .lineTo(163, 236) //Sombreado H. Carbonos
                      .moveTo(20, 250) //Sombreado Azucares
                      .lineTo(163, 250) //Sombreado Azucares
                      .moveTo(20, 264) //Sombreado sal
                      .lineTo(163, 264) //Sombreado sal
                      .moveTo(20, 278) //Sombreado FIBRA
                      .lineTo(163, 278) //Sombreado FIBRA
                      .moveTo(20, 292) //Sombreado lipidos
                      .lineTo(163, 292) //Sombreado lipidos
                      .moveTo(20, 306) //Sombreado colesterol
                      .lineTo(163, 306) //Sombreado colesterol
                      .moveTo(20, 320) //Sombreado ags
                      .lineTo(163, 320) //Sombreado ags
                      .moveTo(20, 334) //Sombreado agm
                      .lineTo(163, 334) //Sombreado agm
                      .moveTo(20, 348) //Sombreado agp
                      .lineTo(163, 348) //Sombreado agp
                      .moveTo(20, 362) //Sombreado calcio
                      .lineTo(163, 362) //Sombreado calcio
                      .moveTo(20, 376) //Sombreado hierro
                      .lineTo(163, 376) //Sombreado hierro
                      .moveTo(20, 390) //Sombreado sodio
                      .lineTo(163, 390) //Sombreado sodio
                      .moveTo(20, 404) //Sombreado iodo
                      .lineTo(163, 404) //Sombreado iodo
                      .moveTo(20, 418) //Sombreado magnesio
                      .lineTo(163, 418) //Sombreado magnesio
                      .moveTo(20, 432) //Sombreado zinc
                      .lineTo(163, 432) //Sombreado zinc
                      .moveTo(20, 446) //Sombreado potasio
                      .lineTo(163, 446) //Sombreado potasio
                      .moveTo(20, 460) //Sombreado foforo
                      .lineTo(163, 460) //Sombreado foforo
                      .moveTo(20, 474) //Sombreado selenio
                      .lineTo(163, 474) //Sombreado selenio
                      .moveTo(20, 488) //Sombreado vitamina a
                      .lineTo(163, 488) //Sombreado vitamina a
                      .moveTo(20, 502) //Sombreado vitamina b1
                      .lineTo(163, 502) //Sombreado vitamina b1
                      .moveTo(20, 516) //Sombreado vitamina b2
                      .lineTo(163, 516) //Sombreado vitamina b2
                      .moveTo(20, 530) //Sombreado vitamina b2
                      .lineTo(163, 530) //Sombreado vitamina b2
                      .moveTo(20, 544) //Sombreado acido folico
                      .lineTo(163, 544) //Sombreado acido folico
                      .moveTo(20, 558) //Sombreado niacina
                      .lineTo(163, 558) //Sombreado niacina
                      .moveTo(20, 572) //Sombreado vitamina b6
                      .lineTo(163, 572) //Sombreado vitamina b6
                      .moveTo(20, 586) //Sombreado vitamina b12
                      .lineTo(163, 586) //Sombreado vitamina b12
                      .moveTo(20, 600) //Sombreado retinol
                      .lineTo(163, 600) //Sombreado retinol
                      .moveTo(20, 614) //Sombreado caroteno
                      .lineTo(163, 614) //Sombreado caroteno
                      .moveTo(20, 628) //Sombreado vitamina d
                      .lineTo(163, 628) //Sombreado vitamina d
                      .moveTo(20, 642) //Sombreado vitamina e
                      .lineTo(163, 642) //Sombreado vitamina e
                      .stroke();

                    /**
                     * BORDES INFO. NUTRICIONAL
                     */
                    doc
                      .lineWidth(0.5)
                      .fillAndStroke("black")
                      .roundedRect(20, 201, 93, 14) //Borde ENERGÍA
                      .roundedRect(20, 215, 93, 14) //Borde Proteinas
                      .roundedRect(20, 229, 93, 14) //Borde H. Carbonos
                      .roundedRect(20, 243, 93, 14) //Borde Azucares
                      .roundedRect(20, 257, 93, 14) //Borde Sal
                      .roundedRect(20, 271, 93, 14) //Borde fibra
                      .roundedRect(20, 285, 93, 14) //Borde lipidos
                      .roundedRect(20, 299, 93, 14) //Borde colesterol
                      .roundedRect(20, 313, 93, 14) //Borde ags
                      .roundedRect(20, 327, 93, 14) //Borde agm
                      .roundedRect(20, 341, 93, 14) //Borde agp
                      .roundedRect(20, 355, 93, 14) //Borde calcio
                      .roundedRect(20, 369, 93, 14) //Borde hierro
                      .roundedRect(20, 383, 93, 14) //Borde sodio
                      .roundedRect(20, 397, 93, 14) //Borde iodo
                      .roundedRect(20, 411, 93, 14) //Borde magnesio
                      .roundedRect(20, 425, 93, 14) //Borde zinc
                      .roundedRect(20, 439, 93, 14) //Borde potasio
                      .roundedRect(20, 453, 93, 14) //Borde fosforo
                      .roundedRect(20, 467, 93, 14) //Borde selenio
                      .roundedRect(20, 481, 93, 14) //Borde vitamina a
                      .roundedRect(20, 495, 93, 14) //Borde vitamina b1
                      .roundedRect(20, 509, 93, 14) //Borde vitamina b2
                      .roundedRect(20, 523, 93, 14) //Borde acido folico
                      .roundedRect(20, 537, 93, 14) //Borde vitamina c
                      .roundedRect(20, 551, 93, 14) //Borde niacina
                      .roundedRect(20, 565, 93, 14) //Borde vitamina b6
                      .roundedRect(20, 579, 93, 14) //Borde vitamina b12
                      .roundedRect(20, 593, 93, 14) //Borde retinol
                      .roundedRect(20, 607, 93, 14) //Borde caroteno
                      .roundedRect(20, 621, 93, 14) //Borde vitamina d
                      .roundedRect(20, 635, 93, 14) //Borde vitamina e
                      .roundedRect(19, 668, 145, 77) //Borde Alergenos
                      .stroke();

                    /**
                     * BORDES VALOR INFO. NUTRICIONAL
                     */
                    doc
                      .lineWidth(0.5)
                      .fillAndStroke("black")
                      .roundedRect(113, 201, 50, 14) //Borde Valor Energía
                      .roundedRect(113, 215, 50, 14) //Borde Valor Proteinas
                      .roundedRect(113, 229, 50, 14) //Borde Valor  H. Carbonos
                      .roundedRect(113, 243, 50, 14) //Borde Valor  Azucares
                      .roundedRect(113, 257, 50, 14) //Borde Valor fibra
                      .roundedRect(113, 271, 50, 14) //Borde Valor lípidos
                      .roundedRect(113, 285, 50, 14) //Borde Valor colesterol
                      .roundedRect(113, 299, 50, 14) //Borde Valor AGS
                      .roundedRect(113, 313, 50, 14) //Borde Valor AGM
                      .roundedRect(113, 327, 50, 14) //Borde Valor AGP
                      .roundedRect(113, 341, 50, 14) //Borde Valor calcio
                      .roundedRect(113, 355, 50, 14) //Borde Valor hierro
                      .roundedRect(113, 369, 50, 14) //Borde Valor sodio
                      .roundedRect(113, 383, 50, 14) //Borde Valor iodo
                      .roundedRect(113, 397, 50, 14) //Borde Valor magnesio
                      .roundedRect(113, 411, 50, 14) //Borde Valor zinc
                      .roundedRect(113, 425, 50, 14) //Borde Valor potasio
                      .roundedRect(113, 439, 50, 14) //Borde Valor fosforo
                      .roundedRect(113, 453, 50, 14) //Borde Valor selenio
                      .roundedRect(113, 467, 50, 14) //Borde Valor vitamina A
                      .roundedRect(113, 481, 50, 14) //Borde Valor vitamina b1
                      .roundedRect(113, 495, 50, 14) //Borde Valor vitamina b2
                      .roundedRect(113, 509, 50, 14) //Borde Valor acido folico
                      .roundedRect(113, 523, 50, 14) //Borde Valor vitamina c
                      .roundedRect(113, 537, 50, 14) //Borde Valor niacina
                      .roundedRect(113, 551, 50, 14) //Borde Valor vitamina b6
                      .roundedRect(113, 565, 50, 14) //Borde Valor vitamina b12
                      .roundedRect(113, 579, 50, 14) //Borde Valor retinol
                      .roundedRect(113, 593, 50, 14) //Borde Valor carotenos
                      .roundedRect(113, 607, 50, 14) //Borde Valor vitamina d
                      .roundedRect(113, 621, 50, 14) //Borde Valor vitamina E
                      .roundedRect(113, 635, 50, 14) //Borde Valor vitamina E
                      .stroke();

                    /**
                     * DESCRIPTIVOS INFO. NUTRICIONAL
                     */
                    //ENERGÍA KCal(Kjul)
                    doc
                      .fontSize(7)
                      .font("Helvetica")
                      .fillColor("black")
                      .text("ENERGÍA KCal(Kjul)", 22, 205, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.KCALORIAS
                        ),
                        111,
                        205,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("PROTEINAS (g)", 22, 220, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.PROTEINAS
                        ),
                        111,
                        220,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("HID. DE CARBONO (g)", 22, 234, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.HIDRATOS_C
                        ),
                        111,
                        234,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AZÚCARES (g)", 22, 248, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AZUCARES
                        ),
                        111,
                        248,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("SAL (g)", 22, 262, {
                        width: 260,
                        height: 14,
                      })
                      .fontSize(7)
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.SAL
                        ),
                        111,
                        262,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("FIBRA (g)", 22, 276, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.FIBRA
                        ),
                        111,
                        276,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("LÍPIDOS (g)", 22, 290, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.LIPIDOS
                        ),
                        111,
                        290,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("COLESTEROL (mg)", 22, 304, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.COLESTEROL
                        ),
                        111,
                        304,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AGS (g)", 22, 318, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AGS
                        ),
                        111,
                        318,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AGM (g)", 22, 332, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AGM
                        ),
                        111,
                        332,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("AGP (g)", 22, 346, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.AGP
                        ),
                        111,
                        346,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("CALCIO (mg)", 22, 360, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.CALCIO
                        ),
                        111,
                        360,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("HIERRO (mg)", 22, 374, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.HIERRO
                        ),
                        111,
                        374,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("SODIO (mg)", 22, 388, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.SODIO
                        ),
                        111,
                        388,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .moveDown()
                      .text("IODO (mg)", 22, 402, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.IODO
                        ),
                        111,
                        402,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("MAGNESIO (mg)", 22, 416, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.MAGNESIO
                        ),
                        111,
                        416,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("ZINC (mg)", 22, 430, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.ZINC
                        ),
                        111,
                        430,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("POTASIO (mcg)", 22, 444, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.POTASIO
                        ),
                        111,
                        444,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("FÓSFORO (mg)", 22, 458, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.FOSFORO
                        ),
                        111,
                        458,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("SELENIO (mg)", 22, 472, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.SELENIO
                        ),
                        111,
                        472,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA A (mg)", 22, 486, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_A
                        ),
                        111,
                        486,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B1 (mg)", 22, 500, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B1
                        ),
                        111,
                        500,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B2 (mg)", 22, 514, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B2
                        ),
                        111,
                        514,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("ÁCIDO FÓLICO (mg)", 22, 528, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.A_FOLICO
                        ),
                        111,
                        528,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA C (mg)", 22, 542, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_C
                        ),
                        111,
                        542,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("NIACINA (mg)", 22, 556, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.NIACINA
                        ),
                        111,
                        556,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B6 (mg)", 22, 570, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B6
                        ),
                        111,
                        570,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA B12 (mg)", 22, 584, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_B12
                        ),
                        111,
                        584,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("RETINOL (mg)", 22, 598, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.RETINOL
                        ),
                        111,
                        598,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("CAROTENO (mg)", 22, 612, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.CAROTENO
                        ),
                        111,
                        612,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA D (mg)", 22, 626, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_D
                        ),
                        111,
                        626,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .text("VITAMINA E (mg)", 22, 640, {
                        width: 260,
                        height: 14,
                      })
                      .text(
                        FuncionesAuxiliares.redondearDosDecimales(
                          listItem.VITA_E
                        ),
                        111,
                        640,
                        {
                          width: 50,
                          height: 14,
                          align: "right",
                        }
                      )
                      .moveDown();

                    let alergenos = false;
                    if (
                      listItem.DECLARACION_ALERGENOS !== null &&
                      listItem.DECLARACION_ALERGENOS.length > 0
                    )
                      alergenos = true;

                    //DECLARACION ALERGENOS
                    doc
                      .fontSize(7.7)
                      .text(
                        alergenos === true
                          ? `${listItem.DECLARACION_ALERGENOS}.`
                          : "",
                        20,
                        670,
                        {
                          width: 148,
                          height: 100,
                          align: "left",
                        }
                      )
                      .moveDown();

                    //Color azul del borde
                    doc.lineWidth(2).fillAndStroke("blue", "#003cac");

                    //Marcar título INFORMACIÓN NUTRICIONAL Y ALERGENOS
                    doc
                      .lineCap("butt")
                      .moveTo(19, 192)
                      .lineTo(164, 192)
                      .lineWidth(17)
                      .lineCap("butt")
                      .moveTo(19, 660)
                      .lineTo(164, 660)
                      .lineWidth(17)
                      .stroke();

                    //Descriptivo INFORMACIÓN NUTRICIONAL  Y ALERGENOS
                    doc
                      .fontSize(8.5)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("INFORMACIÓN NUTRICIONAL", 29, 187, {
                        width: 150,
                      })
                      .text("ALÉRGENOS", 63, 657, {
                        width: 150,
                      })
                      .moveDown();

                    //Marcar título INGREDIENTES
                    doc
                      .lineCap("butt")
                      .moveTo(190, 193)
                      .lineTo(597, 193)
                      .lineWidth(22)
                      .stroke();

                    //Descriptivo Ingredientes
                    doc
                      .fontSize(11)
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("INGREDIENTES", 190, 188, {
                        width: 370,
                        align: "center",
                      })
                      .fontSize(8)
                      .text("PESO BRUTO (g)\n100 raciones", 364, 185, {
                        width: 397,
                        align: "center",
                      })
                      .moveDown();
                    
                      let ascii = "";
  
                    /**
                     * IMPRIMIR FOTOS
                     */
                    let fotoRec1 = "null";
                    let fotoRec2 = "null";
                    let fotoRec3 = "null";
                    let fotoRec4 = "null";
                    let asciiFoto1 = "";
                    let asciiFoto2 = "";
                    let asciiFoto3 = "";
                    let asciiFoto4 = "";
                    let asciiFoto5 = "";
                    let arr1,
                      arr2,
                      arr3,
                      arr4,
                      arr5 = [];

                    /**
                     * Obtengo solo los nombres de las fotos de las recetas para saber cuantas fotos tengo.
                     */
                    let arrayNombreFotosRecetas = listaFotosRecetas.map(
                      (currentValue, index, array) => {
                        // Elemento devuelto de nuevo_array
                        return (
                          currentValue.NOMBRE_FICHERO +
                          currentValue.RECETA_ID
                        );
                      }
                    );

                    /**
                     * Quedarme solo con los nombres de las fotos de las recetas
                     */
                    let nombresFotosRecetasUnicos =
                      arrayNombreFotosRecetas.filter(
                        FuncionesAuxiliares.soloValorUnico
                      );
                    console.log(nombresFotosRecetasUnicos);

                    if (checkboxImprimirImagenPlato === true) {
                      //Imagen receta
                      if (listaFotosRecetas.length != 0) {
                        //Si las recetas no tienen fotos no recorro la lista.

                        for (
                          let q = 0;
                          q < nombresFotosRecetasUnicos.length;
                          q++
                        ) {
                          for (
                            let a = 0;
                            a < listaFotosRecetas.length;
                            a++
                          ) {
                            /**
                             * Recorro el listado de recetas y si hay fotos viene en varchar de 4000 caracteres cada línea,
                             * la concateno y la guardo en el buffer para convertir la cadena completa a base64.
                             */

                            let nombreFotoID =
                              listaFotosRecetas[a].NOMBRE_FICHERO +
                              listaFotosRecetas[a].RECETA_ID;
                            if (q === 0) {
                              console.log("nombreFotoID 1");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[0] ===
                                  nombreFotoID
                              ) {
                                let buf1 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec1 === "null") {
                                  asciiFoto1 = buf1;
                                  fotoRec1 = asciiFoto1.length;
                                } else {
                                  arr1 = [asciiFoto1, buf1];
                                  asciiFoto1 = Buffer.concat(arr1);
                                }
                              } else if (
                                asciiFoto1 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto1 += "";
                              }
                            } else if (q === 1) {
                              console.log("nombreFotoID 2");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[1] ===
                                  nombreFotoID
                              ) {
                                let buf2 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec2 === "null") {
                                  asciiFoto2 = buf2;
                                  fotoRec2 = asciiFoto2.length;
                                } else {
                                  arr2 = [asciiFoto2, buf2];
                                  asciiFoto2 = Buffer.concat(arr2);
                                }
                              } else if (
                                asciiFoto2 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto2 += "";
                              }
                            } else if (q === 2) {
                              console.log("nombreFotoID 3");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[2] ===
                                  nombreFotoID
                              ) {
                                let buf3 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec3 === "null") {
                                  asciiFoto3 = buf3;
                                  fotoRec3 = asciiFoto3.length;
                                } else {
                                  arr3 = [asciiFoto3, buf3];
                                  asciiFoto3 = Buffer.concat(arr3);
                                }
                              } else if (
                                asciiFoto3 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto3 += "";
                              }
                            } else if (q === 3) {
                              console.log("nombreFotoID 4");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[3] ===
                                  nombreFotoID
                              ) {
                                let buf4 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec4 === "null") {
                                  asciiFoto4 = buf4;
                                  fotoRec4 = asciiFoto4.length;
                                } else {
                                  arr4 = [asciiFoto4, buf4];
                                  asciiFoto4 = Buffer.concat(arr4);
                                }
                              } else if (
                                asciiFoto4 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto4 += "";
                              }
                            } else if (q === 4) {
                              console.log("nombreFotoID 5");
                              console.log(nombreFotoID);
                              if (
                                listItem.CODIGO_RECETA ===
                                  listaFotosRecetas[a].RECETA_ID &&
                                nombresFotosRecetasUnicos[3] ===
                                  nombreFotoID
                              ) {
                                let buf5 = Buffer.from(
                                  listaFotosRecetas[a].FOTO
                                );

                                if (fotoRec5 === "null") {
                                  asciiFoto5 = buf5;
                                  fotoRec5 = asciiFoto5.length;
                                } else {
                                  arr4 = [asciiFoto5, buf5];
                                  asciiFoto5 = Buffer.concat(arr5);
                                }
                              } else if (
                                asciiFoto5 === "" &&
                                a === listaFotosRecetas.length
                              ) {
                                asciiFoto5 += "";
                              }
                            }
                          }
                        }
                      } else {
                        asciiFoto1 += "";
                        asciiFoto2 += "";
                        asciiFoto3 += "";
                        asciiFoto4 += "";
                        asciiFoto5 += "";
                      }

                      //Información sobre los alérgenos
                      doc
                        .fontSize(6)
                        .font("Helvetica")
                        .fillColor("blue")
                        .text(
                          "* Foto y gramaje corresponden a grupo de edad 12 AÑOS",
                          400,
                          150,
                          {
                            width: 585,
                            height: 300,
                          }
                        )
                        .moveDown();

                      console.log(ascii);

                      /**
                       * Si la receta no tiene imagen, se imprime una por defecto.
                       */
                      if (asciiFoto1 === "") {
                        doc.image(
                          "images/imagenPorDefectoP1-2.png",
                          395,
                          12,
                          {
                            width: 200,
                            height: 130,
                          }
                        );
                      } else {
                        var b64 =
                          Buffer.from(asciiFoto1).toString("base64");
                        console.log(asciiFoto1.length);
                        console.log(asciiFoto1);
                        console.log(b64);

                        var imagenBase64 = new Buffer(b64, "base64");
                        var formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);
                        //console.log('ejemplo: ' + ejemplo);
                        //console.log('subCadena: ' + subCadena);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen cabecera.
                            doc.image(imagenBase64, 395, 12, {
                              width: 200,
                              height: 130,
                            });
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 1: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen cabecera.
                          doc.image(
                            "images/imagenPorDefecto.png",
                            395,
                            12,
                            {
                              width: 200,
                              height: 130,
                            }
                          );
                        }
                      }
                    }

                    let cantidadIngredientesPorRecetas = 0;

                    for (
                      let b = 0;
                      b < listaIngredientesRecetas.length;
                      b++
                    ) {
                      if (
                        listaIngredientesRecetas[b].ROWNUMBER === 1 &&
                        listaIngredientesRecetas[b].COD_RECETA ===
                          listaRecetasMenu[i].CODIGO_RECETA
                      ) {
                        console.log(listaIngredientesRecetas[b].COD_RECETA);
                        console.log(listaRecetasMenu[i].CODIGO_RECETA);

                        doc
                          .lineCap("butt") //Sombra(Fondo) NOMBRE del INGREDIENTE
                          .moveTo(190, 209)
                          .lineTo(530, 209)
                          .lineWidth(10)
                          .fillAndStroke("blue", "#dbe8ff")
                          .moveTo(530, 209) //Sombra(Fondo) PESO del INGREDIENTE
                          .lineTo(597, 209)
                          .stroke()
                          .lineWidth(0.5) //Borde NOMBRE del INGREDIENTE
                          .fillAndStroke("black")
                          .roundedRect(190, 204, 340, 10)
                          .roundedRect(530, 204, 67, 10) //Borde PESO INGREDIENTE
                          .stroke()
                          .fontSize(9) //Descriptivo INGREDIENTE
                          .font("Helvetica")
                          .text(
                            listaIngredientesRecetas[b].INGREDIENTE,
                            192,
                            206,
                            { width: 260, height: 10 }
                          )
                          .text(
                            listaIngredientesRecetas[b].CANTIDAD,
                            522,
                            206,
                            {
                              width: 73,
                              height: 10,
                              align: "right",
                            }
                          ) //PESO INGREDIENTE
                          .moveDown();
                        cantidadIngredientesPorRecetas++;
                      } else if (
                        listaIngredientesRecetas[b].ROWNUMBER != 1 &&
                        listaIngredientesRecetas[b].COD_RECETA ===
                          listItem.CODIGO_RECETA
                      ) {
                        doc
                          .lineCap("butt") //Sombra(Fondo) NOMBRE del INGREDIENTE
                          .moveTo(
                            190,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          )
                          .lineTo(
                            530,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          )
                          .lineWidth(10)
                          .fillAndStroke("blue", "#dbe8ff")
                          .moveTo(
                            530,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          ) //Sombra(Fondo) PESO del INGREDIENTE
                          .lineTo(
                            597,
                            209 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12
                          )
                          .lineWidth(10)
                          .stroke()
                          .lineWidth(0.5) //Borde NOMBRE del INGREDIENTE
                          .fillAndStroke("black")
                          .roundedRect(
                            190,
                            204 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            340,
                            10
                          )
                          .roundedRect(
                            530,
                            204 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            67,
                            10
                          ) //Borde PESO INGREDIENTE
                          .stroke()
                          .fontSize(9) //Descriptivo INGREDIENTE
                          .text(
                            listaIngredientesRecetas[b].INGREDIENTE,
                            192,
                            206 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            { width: 260, height: 10 }
                          )
                          .text(
                            listaIngredientesRecetas[b].CANTIDAD,
                            522,
                            206 +
                              12 * listaIngredientesRecetas[b].ROWNUMBER -
                              12,
                            {
                              width: 73,
                              height: 10,
                              align: "right",
                            }
                          ) //PESO INGREDIENTE
                          .moveDown();
                        cantidadIngredientesPorRecetas++;
                      }
                    }
                    //}

                    var longitudElaboracionReceta =
                      listItem.ELABORACION === null
                        ? 0
                        : listItem.ELABORACION.length;

                    let puntosADesplazar =
                      13 * cantidadIngredientesPorRecetas +
                      (longitudElaboracionReceta / 85) * 10;

                    //Marcar título FORMA DE PREPARACIÓN.
                    doc
                      .lineWidth(2)
                      .fillAndStroke("blue", "#003cac") //Borde Color AZUL
                      .lineCap("butt")
                      .moveTo(
                        190,
                        240 + 12 * cantidadIngredientesPorRecetas - 12
                      )
                      .lineTo(
                        597,
                        240 + 12 * cantidadIngredientesPorRecetas - 12
                      )
                      .lineWidth(22)
                      .stroke()
                      .fontSize(11) //Descriptivo FORMA DE PREPARACIÓN
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text(
                        "FORMA DE PREPARACIÓN",
                        190,
                        236 + 12 * cantidadIngredientesPorRecetas - 12,
                        {
                          width: 397,
                          align: "center",
                        }
                      )
                      .fontSize(9) //Descriptivo FORMA DE PREPARACIÓN
                      .font("Helvetica")
                      .fillColor("black")
                      .text(
                        listItem.ELABORACION === null
                          ? ""
                          : listItem.ELABORACION,
                        191,
                        255 + 12 * cantidadIngredientesPorRecetas - 12,
                        {
                          width: 405,
                          align: "justify",
                        }
                      )
                      .lineWidth(2) //Marcar título MODO Y GRAMAJES DE SERVICIO.
                      .fillAndStroke("blue", "#003cac") //Borde Color AZUL
                      .lineCap("butt")
                      .moveTo(190, 255 + puntosADesplazar)
                      .lineTo(597, 255 + puntosADesplazar)
                      .lineWidth(22)
                      .stroke()
                      .fontSize(11) //Descriptivo MODO Y GRAMAJES DE SERVICIO
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text(
                        "CAPACIDAD PRODUCTIVA DE INGREDIENTES/UNIDAD PRODUCTIVA",
                        190,
                        252 + puntosADesplazar,
                        {
                          width: 397,
                          align: "center",
                        }
                      )
                      .fontSize(9) //Descriptivo FORMA DE PREPARACIÓN
                      .font("Helvetica")
                      .fillColor("black")
                      .text(
                        listItem.COMENTARIO === null
                          ? ""
                          : listItem.COMENTARIO,
                        191,
                        270 + puntosADesplazar,
                        {
                          width: 405,
                          align: "justify",
                        }
                      );

                    //Agrego la segunda página
                    doc.addPage();

                    //Color azul del borde
                    doc
                      .lineWidth(2)
                      .fillAndStroke("blue", "#003cac")
                      .lineCap("butt")
                      .moveTo(19, 50)
                      .lineTo(597, 50)
                      .lineWidth(22)
                      .stroke()
                      .fontSize(11) //Descriptivo Envasado
                      .font("Helvetica-Bold")
                      .fillColor("white")
                      .text("ENVASADO", 19, 47, {
                        width: 576,
                        align: "center",
                      })
                      .fontSize(9) //Descriptivo FORMA DE PREPARACIÓN
                      .font("Helvetica")
                      .fillColor("black")
                      .text(
                        "Primera foto corresponde a servicio de línea fría. Segunda foto correspoende a servicio de línea caliente. Tercera foto(si aplica) corresponde a guarnición.",
                        19,
                        70,
                        {
                          width: 576,
                          align: "justify",
                        }
                      );

                    if (checkboxImprimirImagenPlato === true) {
                      /*
                        * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 2 - línea fría.
                        */
                      if (asciiFoto2 === "") {
                        //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar.
                        doc
                          .image(
                            "images/imagenPorDefectoP1-2.png",
                            30,
                            120,
                            {
                              width: 260,
                              height: 170,
                            }
                          )
                          .fontSize(7)
                          .fillColor("black")
                          .text(
                            "Imagen 1. Servicio de línea fría.",
                            30,
                            295,
                            {
                              width: 250,
                              height: 10,
                              align: "center",
                            }
                          );
                      } else {
                        let b64 =
                          Buffer.from(asciiFoto2).toString("base64");
                        console.log(asciiFoto1.length);
                        console.log(asciiFoto1);
                        console.log(b64);

                        let imagenBase64 = new Buffer(b64, "base64");
                        let formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        console.log("varPNG: " + formatoImagen);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen 2 - Línea fría.
                            doc
                              .image(imagenBase64, 30, 120, {
                                width: 260,
                                height: 170,
                              })
                              .fontSize(7)
                              .fillColor("black")
                              .text(
                                "Imagen 1. Servicio de línea fría.",
                                30,
                                295,
                                {
                                  width: 250,
                                  height: 10,
                                  align: "center",
                                }
                              );
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 2: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen 2 - Línea fría.
                          doc
                            .image("images/imagenPorDefecto.png", 30, 120, {
                              width: 260,
                              height: 170,
                            })
                            .fontSize(7)
                            .fillColor("black")
                            .text(
                              "Imagen 1. Servicio de línea fría.",
                              30,
                              295,
                              {
                                width: 250,
                                height: 10,
                                align: "center",
                              }
                            );
                        }
                      }

                      /**
                       * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 3 - línea caliente.
                       */
                      if (asciiFoto3 === "") {
                        //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar
                        doc
                          .image(
                            "images/imagenPorDefectoP1-2.png",
                            325,
                            120,
                            {
                              width: 260,
                              height: 170,
                            }
                          )
                          .fontSize(7)
                          .fillColor("black")
                          .text(
                            "Imagen 2. Servicio de línea caliente.",
                            285,
                            295,
                            {
                              width: 325,
                              align: "center",
                            }
                          );
                      } else {
                        let b64 =
                          Buffer.from(asciiFoto3).toString("base64");
                        console.log(asciiFoto3.length);
                        console.log(asciiFoto3);
                        console.log(b64);

                        let imagenBase64 = new Buffer(b64, "base64");
                        let formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        console.log("varPNG: " + formatoImagen);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen 3 - Línea Caliente
                            doc
                              .image(imagenBase64, 325, 120, {
                                width: 260,
                                height: 170,
                              })
                              .fontSize(7)
                              .fillColor("black")
                              .text(
                                "Imagen 2. Servicio de línea caliente.",
                                285,
                                295,
                                {
                                  width: 325,
                                  align: "center",
                                }
                              );
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 3: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen 3 - Envasado Cuando no tiene foto pinto la estándar
                          doc
                            .image(
                              "images/imagenPorDefecto.png",
                              325,
                              120,
                              {
                                width: 260,
                                height: 170,
                              }
                            )
                            .fontSize(7)
                            .fillColor("black")
                            .text(
                              "Imagen 2. Servicio de línea caliente.",
                              285,
                              295,
                              {
                                width: 325,
                                align: "center",
                              }
                            );
                        }
                      }

                      /*
                        * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 4 Guarnición)().
                        */
                      if (asciiFoto4 === "") {
                        //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar.
                        doc
                          .image(
                            "images/imagenPorDefectoP1-2.png",
                            180,
                            320,
                            {
                              width: 260,
                              height: 170,
                            }
                          )
                          .fontSize(7)
                          .fillColor("black")
                          .text("Imagen 3. guarnición.", 180, 496, {
                            width: 260,
                            align: "center",
                          });
                      } else {
                        let b64 =
                          Buffer.from(asciiFoto4).toString("base64");
                        console.log(asciiFoto4.length);
                        console.log(asciiFoto4);
                        console.log(b64);

                        let imagenBase64 = new Buffer(b64, "base64");
                        let formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen 4 - Guarnición
                            doc
                              .image(imagenBase64, 180, 320, {
                                width: 260,
                                height: 170,
                              })
                              .fontSize(7)
                              .fillColor("black")
                              .text("Imagen 3. guarnición.", 180, 496, {
                                width: 260,
                                align: "center",
                              });
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 4: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen 4 - Envasado Cuando no tiene foto pinto la estándar.
                          doc
                            .image(
                              "images/imagenPorDefecto.png",
                              180,
                              320,
                              {
                                width: 260,
                                height: 170,
                              }
                            )
                            .fontSize(7)
                            .fillColor("black")
                            .text("Imagen 3. guarnición.", 180, 496, {
                              width: 260,
                              align: "center",
                            });
                        }
                      }

                      //             .image('images/imagenPorDefecto.png', 180, 515, {
                      //                 width: 260,
                      //                 height: 170
                      //             })
                      //             .text('Imagen 4. Sin Descriptivo.', 180, 690, {
                      //                 width: 260,
                      //                 align: 'center'
                      //             });
                      /*
                        * Si la receta no tiene imagen, se imprime una por defecto sino imprimo foto 4 Guarnición)().
                        */
                      if (asciiFoto5 === "") {
                        //Imagen 2 - Envasado Cuando no tiene foto pinto la estándar.
                        doc
                          .image(
                            "images/imagenPorDefectoP1-2.png",
                            180,
                            515,
                            {
                              width: 260,
                              height: 170,
                            }
                          )
                          .fontSize(7)
                          .fillColor("black")
                          .text("Imagen 4. Sin descriptivo.", 180, 690, {
                            width: 260,
                            align: "center",
                          });
                      } else {
                        let b64 =
                          Buffer.from(asciiFoto5).toString("base64");
                        console.log(asciiFoto5.length);
                        console.log(asciiFoto5);
                        console.log(b64);

                        let imagenBase64 = new Buffer(b64, "base64");
                        let formatoImagen = imagenBase64
                          .toString()
                          .substring(1, 4); //=== 'PNG'
                        //var subCadena = varPNG.substring(1,4);
                        console.log("varPNG: " + formatoImagen);

                        if (formatoImagen === "PNG") {
                          try {
                            //Imagen 4 - Guarnición
                            doc
                              .image(imagenBase64, 180, 515, {
                                width: 260,
                                height: 170,
                              })
                              .fontSize(7)
                              .fillColor("black")
                              .text(
                                "Imagen 4. Sin descriptivo.",
                                180,
                                690,
                                {
                                  width: 260,
                                  align: "center",
                                }
                              );
                          } catch (error) {
                            Modal.warning({
                              title: "Advertencia",
                              content:
                                "Ha ocurrido el siguiente error al tintentar imprimir la imagen 4: " +
                                error +
                                ". Contactar con el administrador.",
                            });
                          }
                        } else {
                          //Imagen 4 - Envasado Cuando no tiene foto pinto la estándar.
                          doc
                            .image(
                              "images/imagenPorDefecto.png",
                              180,
                              515,
                              {
                                width: 260,
                                height: 170,
                              }
                            )
                            .fontSize(7)
                            .fillColor("black")
                            .text("Imagen 4. Sin descriptivo.", 180, 690, {
                              width: 260,
                              align: "center",
                            });
                        }
                      }
                    }

                    //Descriptivo sobre los alérgenos
                    doc
                      .fontSize(6)
                      .font("Helvetica")
                      .fillColor("blue")
                      .text(`${informacionAlergenos}`, 15, 748, {
                        width: 585,
                        height: 300,
                      })
                      .moveDown();

                    //Fecha de impresión
                    doc
                      .fontSize(9)
                      .fillColor("black")
                      .font("Helvetica")
                      .text(`Fecha impresión: ${fechaActual}`, 15, 765, {
                        width: 230,
                        height: 300,
                      })
                      .moveDown();

                    //Codificación receta-catálogo
                    doc
                      .fontSize(9)
                      .text(
                        `${listItem.CODIGO_RECETA}-${listItem.CATALOGO}`,
                        515,
                        765,
                        {
                          align: "right",
                          width: 80,
                          height: 900,
                        }
                      )
                      .moveDown();

                    // if (listaRecetasMenu.length != 1 && i != listaRecetasMenu.length - 1) {
                    //     //Agrego la próxima página para la próxima receta
                    //     doc.addPage();
                    // }
                    console.log(
                      "Hora final: " +
                        f.getHours() +
                        ":" +
                        f.getMinutes() +
                        ":" +
                        f.getSeconds()
                    );

                    count++;
                    console.log("count: ", count);
                    console.log(
                      "listaRecetasMenu.length: ",
                      listaRecetasMenu.length
                    );

                    if (count === listaRecetasMenu.length && error === 0) {
                      setTimeout(() => {
                        doc.end();
                        stream.on("finish", function () {
                          //   // // get a blob you can do whatever you like with
                          //   // const blob = stream.toBlob('application/pdf');

                          //   // or get a blob URL for display in the browser
                          const url = stream.toBlobURL("application/pdf");
                          // abrir un PDF en una pestaña nueva
                          window.open(url, "_blank");
                          modal.destroy();
                          // redirigir la pestaña actual a otra URL
                          //window.location.href = 'http://ejemplo.com';
                        });
                      }, 1000);
                    }
                  }
                  //Hasta aquí los ingredientes.
            });
            console.log("Todo bien hasta aquí.");
            //Hasta aquí las fotos de las recetas.
              });
          });
        } else if (
          listaRecetasMenuValores.status === "success" &&
          listaRecetasMenuValores.message.length === 0
        ) {
          Modal.error({
            title: "Error",
            content:
              "El menú " +
              this.state.selectedRowsMenu[0].ID_MENU +
              "." +
              this.state.selectedRowsMenu[0].NOM_MENU +
              " no tiene recetas asignadas.",
          });
          modal.destroy();
        } else {
          Modal.error({
            title: "Error",
            content:
              "Ha ocurrido el siguiente error: " +
              listaRecetasMenuValores.message,
          });
          modal.destroy();
        }
      });
  } catch (e) {
    Modal.error({
      title: "Error",
      content:
        "Ha ocurrido el siguiente error al Imprimir las Etiquetas: " +
        e +
        ". Contactar con el administrador.",
    });
    console.log(e);
  }
}
