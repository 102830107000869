"use strict";
import React from "react"
import { Button, Divider, Table, Tooltip, Drawer } from "antd"
import { EditOutlined, FormOutlined } from "@ant-design/icons"
import { EliminarDatosEmpresasTooltip } from "./EliminarDatosEmpresas";
import { DrawerInsertarDatosEmpresasForm } from "./DrawerInsertarDatosEmpresasForm";
import { DrawerModificarDatosEmpresasForm } from "./DrawerModificarDatosEmpresasForm";

export const DatosEmpresasBotonera = (props) => {

    const {
        enviarDatosEmpresas,
        getColumnSearchProps,
        handleChangeDatos,
        onChildrenDrawerCloseDatosEmpresa,
        onChildrenDrawerCloseModificarDatosEmpresa,
        onCloseDrawerDatosEmpresa,
        selectCatalogosUsuario,
        showChildrenDrawerDatosEmpresa,
        showChildrenDrawerModificarDatosEmpresa,
        showDrawerDatosEmpresa,
        state,
        updateStates
    } = props

    const textoAnadirDatosEmpresas = <span>Añadir Datos de Empresas</span>
    const textoEditarDatosEmpresas = <span>Editar Datos de Empresas</span>

    const columnsDatosEmpresas = [
        {
            title: "Código",
            dataIndex: "ID_UCP",
            key: "ID_UCP",
            ellipsis: true,
            width: 100,
        },
        {
            title: "Nombre",
            dataIndex: "NOMBRE_UCP",
            key: "NOMBRE_UCP",
            ellipsis: true,
            width: 400,
            ...getColumnSearchProps("NOMBRE_UCP", "Buscar Nombre Datos Empresas"),
            sorter: (a, b) => a.NOMBRE_UCP.length - b.NOMBRE_UCP.length,
            sortDirections: ["descend", "ascend"],
            showSorterTooltip: false,
        },
        {
            title: "Nombre Social",
            dataIndex: "NOMBRE_SOCIAL_UCP",
            key: "NOMBRE_SOCIAL_UCP",
            ellipsis: true,
            width: 150,
        },
        {
            title: "Dirección",
            dataIndex: "DIRECCION_UCP",
            key: "DIRECCION_UCP",
            ellipsis: true,
            width: 250,
        },
        {
            title: "CIF",
            dataIndex: "CIF_UCP",
            key: "CIF_UCP",
            ellipsis: true,
            width: 130,
        },
        {
            title: "Registro Sanitario",
            dataIndex: "REGISTRO_SANITARIO_UCP",
            key: "REGISTRO_SANITARIO_UCP",
            ellipsis: true,
            width: 150,
        },
    ];

    return (
        <Drawer
            title="Datos de Empresas"
            width={"50%"}
            closable={false}
            onClose={onCloseDrawerDatosEmpresa}
            visible={state.visibleDrawerDatosEmpresa}
        >
            <Tooltip placement="topLeft" title={textoAnadirDatosEmpresas}>
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerDatosEmpresa}
                >
                    <FormOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />

            <Tooltip placement="topLeft" title={textoEditarDatosEmpresas}>
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerModificarDatosEmpresa}
                >
                    <EditOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />

            <EliminarDatosEmpresasTooltip
                state={state}
                showDrawerDatosEmpresa={showDrawerDatosEmpresa}
            />

            <Drawer
                title="Añadir Datos de Empresas"
                width={450} //420
                closable={false}
                onClose={onChildrenDrawerCloseDatosEmpresa}
                visible={state.childrenDrawerDatosEmpresa}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DrawerInsertarDatosEmpresasForm
                    onChildrenDrawerCloseDatosEmpresa={onChildrenDrawerCloseDatosEmpresa}
                    enviarDatosEmpresas={enviarDatosEmpresas}
                    state={state}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                />
            </Drawer>

            <Drawer
                title="Modificar Datos de Empresas"
                width={450} //420
                closable={false}
                onClose={onChildrenDrawerCloseModificarDatosEmpresa}
                visible={state.childrenDrawerModificarDatosEmpresa}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DrawerModificarDatosEmpresasForm
                    onChildrenDrawerCloseModificarDatosEmpresa={onChildrenDrawerCloseModificarDatosEmpresa}
                    state={state}
                    updateStates={updateStates}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                />
            </Drawer>

            <Divider orientation="center">Listado de Empresas</Divider>

            <Table
                rowSelection={{
                    type: "radio",
                    ...state.rowSelectionDatosEmpresas,
                }}
                rowKey={(record) => record.CODIGO_UCP}
                size="small"
                columns={columnsDatosEmpresas}
                dataSource={state.datosEmpresas}
                onChange={handleChangeDatos}
                bordered
                pagination={{ defaultPageSize: 20 }}
            />
        </Drawer>
    )
}