import React from "react";
import { DatabaseOutlined } from "@ant-design/icons";

const CustomizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <DatabaseOutlined style={{ fontSize: 20 }} />
      <p>Datos No Encontrados</p>
    </div>
  );

  export default CustomizeRenderEmpty