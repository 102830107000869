import { database } from "../../services/database.service"
import { Modal } from "antd"

export const handleResponse = (res, updateState) => {
  const response = Object.values(res.response);

  updateState({ loading: false });

  if (response[0] === "success") {
    Modal.success({
      title: "Éxito",
      content: response[1],
    })

    return
  }

  Modal.error({
    title: "Error",
    content: response[1],
  })

}

export const getListaIdiomas = (updateStates) => {
    database.getIdiomas().then((res) => {
        updateStates({
        listaIdiomas: res.response,
        statusListaIdiomas: "success",
      })
    })
}

export const getlistaOptionsCatalogosUsuario = (updateStates) => {

  const { sAMAccountName } = JSON.parse(localStorage.getItem("user"))
  database
    .getCatalogosUsuario(sAMAccountName)
    .then((res) => {
      updateStates({
        listaOptionsCatalogosUsuario: res.response,
        statusListaCatalogosUsuario: "success",
      })

      let catalogosId = []
      let resultadoCatalogoUsuario = []

      res.response.forEach(({ CATALOGO, NOMBRE }) => {
        resultadoCatalogoUsuario.push({ value: CATALOGO, label: NOMBRE })
        catalogosId.push(CATALOGO)
      })
      
      updateStates({
        optionsCatalogosUsuario: resultadoCatalogoUsuario,
        loadingSpingImprimirEtiquetas: false,
        textoLoadingSpingImprimirEtiquetas: "",
        listaCatalogoId: catalogosId,
      })

    })
}

export const enviarModificarDatosEmpresas = (
  codigo,
  nameEmpresa,
  nameSocial,
  direccion,
  cif,
  registroSanitario,
  idEmpresa,
  catalogo,
  updateStates,
  onChildrenDrawerCloseModificarDatosEmpresa
) => {
  updateStates({ loading: true });

  database
    .actualizarDatosEmpresas(
      codigo,
      nameEmpresa,
      nameSocial,
      direccion,
      cif,
      registroSanitario,
      JSON.parse(localStorage.getItem("user")).sAMAccountName,
      idEmpresa,
      catalogo
    )
    .then(
      (res) => handleResponse(res, updateStates),
      (error) => updateStates({ error: error, loading: false })
    );


  onChildrenDrawerCloseModificarDatosEmpresa();
}

export const enviarModificarDatosClientes = (codigo, nameCliente, catalogo, updateStates, onChildrenDrawerCloseModificarDatosClientes) => {
  updateStates({ loading: true });

  database
    .actualizarDatosClientes(
      codigo,
      nameCliente,
      JSON.parse(localStorage.getItem("user")).sAMAccountName,
      catalogo
    )
    .then(
      (res) => handleResponse(res, updateStates),
      (error) => updateStates({ error: error, loading: false })
    );


  onChildrenDrawerCloseModificarDatosClientes();
}

export const enviarModificarObsEspeciales = (codigoOE, nameOE, catalogo, updateStates, onChildrenDrawerCloseModificarObsEspeciales) => {
  updateStates({ loading: true });

  database
    .actualizarObsEspecial(
      codigoOE,
      nameOE,
      JSON.parse(localStorage.getItem("user")).sAMAccountName,
      catalogo
    )
    .then(
      (res) => handleResponse(res, updateStates),
      (error) => updateStates({ error: error, loading: false })
    );


  onChildrenDrawerCloseModificarObsEspeciales();
}

export const enviarModificarLineasProduccion = (
  codigoLineasProduccion,
  nameLineaProduccion,
  tipo,
  descripcionTipo,
  diasCaducidad,
  textoFCaducidad,
  condicionesConservacion,
  modoEmpleo,
  catalogo, 
  updateStates,
  onChildrenDrawerCloseModificarLineasProduccion
) => {
  updateStates({ loading: true });

  database
    .actualizarLineasProduccion(
      codigoLineasProduccion,
      nameLineaProduccion,
      tipo,
      descripcionTipo,
      diasCaducidad,
      textoFCaducidad,
      condicionesConservacion,
      modoEmpleo,
      JSON.parse(localStorage.getItem("user")).sAMAccountName,
      catalogo
    )
    .then(
      (res) => handleResponse(res, updateStates),
      (error) => updateStates({ error: error, loading: false })
    );


  onChildrenDrawerCloseModificarLineasProduccion();
}
