import { Modal } from "antd"
import { plantillas } from "../../../utility/plantillas"
import moment from "moment"

moment.locale("es")

export const editarIngredientesTotal = (updateStates, formRef) => {
    const valoresFormulario = formRef.current.getFieldsValue();

    updateStates({
      txtAreaIngredientesTotal:
        valoresFormulario.ingredientesPrincipal.length === 0 ? true : false,
      txtAreaAlergenosTotal:
        valoresFormulario.ingredientesPrincipal.length === 0 ? true : false,
      txtAreaIngredientesGuarnicion:
        valoresFormulario.ingredientesGuarnicion.length === 0 ? true : false,
      tabPaneActiva: "4",
      botonEditarIngredientesTotal: true,
      cargarFomrularioPrimeraVez: 1,
      txtAreaAlergenosGuarnicion:
        valoresFormulario.ingredientesGuarnicion.length === 0 ? true : false,
    });

}

export const llamarImprimirEtiquetasGeneral = (
    codLineaProduccion,
    codCliente,
    fechaElaboracion,
    totalDiasCaducidad,
    fechaCaducidad,
    codLote,
    codCocina,
    codObservacionesEspeciales,
    rutaLogoIzquierdo,
    rutaLogoDerecho,
    observaciones,
    cantidadEtiqImprimir,
    alergenosGuarnicion,
    alergenosPrincipal,
    ingredientesGuarnicion,
    ingredientesPrincipal,
    texto01,
    texto02,
    texto03,
    texto04,
    plantilla,
    pesoNetoTotal,
    unidadMedida,
    mostrarPuedeContener,
    puedeContener,
    multiporcion,
    updateStates,
    state
  ) => {
    try {
      updateStates({
        loadingSpingImprimirEtiquetas: true,
        textoLoadingSpingImprimirEtiquetas: "Imprimiendo etiquetas ...",
        loadingButtonLanzarImpresion: true,
      })

      const nombrePrincipal =
        state.selectedRowsRecetas.length > 0
          ? state.selectedRowsRecetas
          : "";
      const nameGuarnicion =
        state.selectedRowsGuarnicion.length > 0
          ? state.selectedRowsGuarnicion
          : "";
      const listaLineasProduccion = state.listaLineasProduccion;
      const listaCatalogoId = state.listaCatalogoId;
      const datosCocinaImprimir = state.datosCocinaImprimir;
      const ingredientesPrincipalEtiqueta =
        state.ingredientesPrincipalEtiqueta;
      const ingredientesGuarnicionEtiqueta =
        state.ingredientesGuarnicionEtiqueta;
      const valueTextAreaAlergenosPrincipal =
        state.valueTextAreaAlergenosPrincipal;
      const valueTextAreaAlergenosGuarnicion =
        state.valueTextAreaAlergenosGuarnicion;
      const infoNutricionalReceta = state.infoNutricionalReceta;
      const infoNutricionalReceta100 = state.infoNutricionalReceta100;

      const codLote1 = codLote.substring(
        codLote.indexOf("-") + 1,
        codLote.length
      );
      const receta = codLote1.substring(
        codLote1.indexOf("-") + 1,
        codLote1.length
      );

      let nombreGurnicion = ""

      if (nameGuarnicion.length !== 0) {
        if (state.selectedRowsRecetas.length === 0) {
          nombreGurnicion = nameGuarnicion[0].NOM_PLATO;
        } else {
          nombreGurnicion =
            nameGuarnicion[0].NOM_PLATO.substr(0, 4) === "CON "
              ? " " + nameGuarnicion[0].NOM_PLATO
              : " CON " + nameGuarnicion[0].NOM_PLATO;
        }
      }

      const functionsRelatedToTemplates = {
        "plantilla-1": "imprimirEtiquetasGeneral",
        "plantilla-2": "imprimirEtiquetasGeneral",
        "plantilla-3": "imprimirEtiquetasGeneralPlantilla3",
        "plantilla-4": "imprimirEtiquetasGeneralPlantilla4",
        "plantilla-5": "imprimirEtiquetasGeneralPlantilla5",
        "plantilla-6": "imprimirEtiquetasGeneralPlantilla6",
        "plantilla-7": "imprimirEtiquetasGeneralPlantilla7",
        "plantilla-8": "imprimirEtiquetasGeneralPlantilla8",
        "plantilla-9": "imprimirEtiquetasGeneralPlantilla9",
      }

      plantillas[functionsRelatedToTemplates[plantilla]](
        codLineaProduccion,
        codCliente,
        fechaElaboracion,
        totalDiasCaducidad,
        fechaCaducidad,
        codLote,
        codCocina,
        codObservacionesEspeciales,
        rutaLogoIzquierdo,
        rutaLogoDerecho,
        observaciones,
        cantidadEtiqImprimir,
        alergenosGuarnicion,
        alergenosPrincipal,
        ingredientesGuarnicion,
        ingredientesPrincipal,
        texto01,
        texto02,
        texto03,
        texto04,
        plantilla,
        infoNutricionalReceta,
        valueTextAreaAlergenosGuarnicion,
        valueTextAreaAlergenosPrincipal,
        ingredientesGuarnicionEtiqueta,
        ingredientesPrincipalEtiqueta,
        datosCocinaImprimir,
        nombrePrincipal,
        nameGuarnicion,
        nombreGurnicion,
        listaLineasProduccion,
        listaCatalogoId,
        infoNutricionalReceta100,
        pesoNetoTotal,
        unidadMedida,
        receta,
        mostrarPuedeContener,
        puedeContener,
        multiporcion
      )

      llamarActualizarEstadosSpingImprimirEtiquetas(updateStates);
    } catch (e) {
      updateStates({
        loadingSpingImprimirEtiquetas: false,
        textoLoadingSpingImprimirEtiquetas: "",
        loadingButtonLanzarImpresion: false,
      })

      Modal.error({
        title: "Error",
        content:
          `Ha ocurrido el siguiente error al Imprimir las Etiquetas: ${e}. Contactar con el administrador.`
      })
    }
}

const llamarActualizarEstadosSpingImprimirEtiquetas = (updateStates) => {
    setTimeout(() => {
        updateStates({
        loadingSpingImprimirEtiquetas: false,
        loadingButtonLanzarImpresion: false,
        tabPaneActiva: "1",
      });
    }, 2000);
}

export const onChangeCheckMultiporcion = (e, updateStates) => {
    const { checked } = e.target
    updateStates({
      checkedMultiporcion: checked,
      disabledPesoNeto: checked === true ? true : false,
    })
}

export const onChangeDatePickerFechaElaboracion = (date, formRef, state) => {

    const camposFormularioOpciones = formRef.current.getFieldsValue();

    if (camposFormularioOpciones.nameLineaProduccion === undefined && camposFormularioOpciones.nameCocina === undefined) {

      Modal.warning({
        title: "Advertencia",
        content: "Debes seleccionar una Línea de Producción primero.",
      })

      return

    }

    const diaCaducidad = Object.values(
      camposFormularioOpciones.nameLineaProduccion.split(".", 2)
    )

    const lote =
      moment(camposFormularioOpciones.fechaElaboracion).format("YYYY") +
      moment(camposFormularioOpciones.fechaElaboracion).format("MM") +
      moment(camposFormularioOpciones.fechaElaboracion).format("DD")

    const codigoPlatoLote =
      state.selectedRowKeysRecetas.length != 0
        ? state.selectedRowKeysRecetas
        : state.selectedRowKeysGuarnicion;

    const datoCocinaSeleccionada = camposFormularioOpciones.nameCocina;

    const cocina =
      datoCocinaSeleccionada === undefined
        ? ""
        : Object.values(camposFormularioOpciones.nameCocina.split(".", 2))

    formRef.current.setFieldsValue({
      fechaCaducidad: moment(date).add(diaCaducidad[1], "d"),
      codigoLote:
        cocina === ""
          ? `${lote}-${codigoPlatoLote}`
          : `${lote}-${cocina[1]}-${codigoPlatoLote}`
    })
}

export const onChangeLineaProduccion = (value, formRef, updateStates, state) => {
    updateStates({
      tabPaneActiva: "1",
      errorFieldNameLineaProduccion: false,
    })

    const camposFormularioOpciones = formRef.current.getFieldsValue();

    const diaCaducidad = Object.values(value.split(".", 2));

    const diaCaducidadGlobal = Object.values(
      camposFormularioOpciones.nameLineaProduccion.split(".", 2)
    );

    const lote =
      moment(camposFormularioOpciones.fechaElaboracion).format("YYYY") +
      moment(camposFormularioOpciones.fechaElaboracion).format("MM") +
      moment(camposFormularioOpciones.fechaElaboracion).format("DD");

    const datoCocinaSeleccionada = camposFormularioOpciones.nameCocina;

    const cocina =
      datoCocinaSeleccionada === undefined
        ? ""
        : Object.values(camposFormularioOpciones.nameCocina.split(".", 2));

    const codigoPlatoLote =
      state.selectedRowKeysRecetas.length !== 0
        ? state.selectedRowKeysRecetas
        : state.selectedRowKeysGuarnicion;

    formRef.current.setFieldsValue({
      fechaCaducidad: moment(camposFormularioOpciones.fechaElaboracion).add(diaCaducidadGlobal[1], "d"),
      codigoLote:
        cocina === ""
          ? `${lote}-${codigoPlatoLote}`
          : `${lote}-${cocina[1]}-${codigoPlatoLote}`,
      dias: diaCaducidad[1],
      nameLineaProduccion: value,
    })

    if (state.cargarFomrularioPrimeraVez === 0 || state.cargarFomrularioPrimeraVez === 2) {
      updateStates({
        cargarFomrularioPrimeraVez: 1,
      })
    }
}

export const onChangeSelectCocina = (formRef, state) => {
    const camposFormularioOpciones = formRef.current.getFieldsValue();

    if (camposFormularioOpciones.nameLineaProduccion != null) {
      const diaCaducidad = Object.values(
        camposFormularioOpciones.nameLineaProduccion.split(".", 2)
      );

      const cocina = Object.values(
        camposFormularioOpciones.nameCocina.split(".", 2)
      )

      const lote =
        moment(camposFormularioOpciones.fechaElaboracion).format("YYYY") +
        moment(camposFormularioOpciones.fechaElaboracion).format("MM") +
        moment(camposFormularioOpciones.fechaElaboracion).format("DD");

      const codigoPlatoLote =
        state.selectedRowKeysRecetas.length != 0
          ? state.selectedRowKeysRecetas
          : state.selectedRowKeysGuarnicion;

      formRef.current.setFieldsValue({
        fechaCaducidad: moment(camposFormularioOpciones.fechaElaboracion).add(diaCaducidad[1], "d"),
        codigoLote: `${lote}-${cocina[1]}-${codigoPlatoLote}`
      });
    } else {
      Modal.warning({
        title: "Advertencia",
        content: "Debes seleccionar una Línea de Producción.",
      });
    }
}