"use strict";
import React from "react"
import { Button, Divider, Table, Tooltip, Drawer } from "antd"
import { EditOutlined, FormOutlined } from "@ant-design/icons"
import { DrawerModificarDatosClientesForm } from "./DrawerModificarDatosClientesForm";
import { DrawerInsertarClientesForm } from "./DrawerInsertarClientesForm";
import { EliminarDatosClientesTooltip } from "./EliminarDatosClientes";

export const DatosClientesBotonera = (props) => {

    const {
        enviarDatosClientes,
        getColumnSearchProps,
        handleChangeDatos,
        onChildrenDrawerCloseClientes,
        onChildrenDrawerCloseModificarDatosClientes,
        onCloseDrawerClientes,
        selectCatalogosUsuario,
        showChildrenDrawerClientes,
        showChildrenDrawerModificarDatosClientes,
        showDrawerClientes,
        state,
        updateStates
    } = props

    const textoAnadirClientes = <span>Añadir Datos de Clientes</span>
    const textoEditarClientes = <span>Editar Datos de Clientes</span>

    const columnsDatosClientes = [
        {
            title: "Código",
            dataIndex: "CODIGO_CLIENTES",
            key: "CODIGO_CLIENTES",
            ellipsis: true,
            width: 100,
        },
        {
            title: "Nombre Cliente",
            dataIndex: "NOMBRE_CLIENTES",
            key: "NOMBRE_CLIENTES",
            ellipsis: true,
            width: 400,
            ...getColumnSearchProps("NOMBRE_CLIENTES", "Buscar Nombre Datos Empresas"),
            sorter: (a, b) => a.NOMBRE_CLIENTES.length - b.NOMBRE_CLIENTES.length,
            sortDirections: ["descend", "ascend"],
            showSorterTooltip: false,
        },
    ];

    return (
        <Drawer
            title="Datos de Clientes"
            width={"50%"}
            closable={false}
            onClose={onCloseDrawerClientes}
            visible={state.visibleDrawerClientes}
        >
            <Tooltip placement="topLeft" title={textoAnadirClientes}>
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerClientes}
                >
                    <FormOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />

            <Tooltip placement="topLeft" title={textoEditarClientes}>
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerModificarDatosClientes}
                >
                    <EditOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />
            <EliminarDatosClientesTooltip
                state={state}
                showDrawerClientes={showDrawerClientes}
            />
            <Drawer
                title="Añadir Datos de Clientes"
                width={450} //420
                closable={false}
                onClose={onChildrenDrawerCloseClientes}
                visible={state.childrenDrawerClientes}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DrawerInsertarClientesForm
                    enviarDatosClientes={enviarDatosClientes}
                    onChildrenDrawerCloseClientes={onChildrenDrawerCloseClientes}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                />
            </Drawer>

            <Drawer
                title="Modificar Datos de Clientes"
                width={450} //420
                closable={false}
                onClose={onChildrenDrawerCloseModificarDatosClientes}
                visible={state.childrenDrawerModificarDatosClientes}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DrawerModificarDatosClientesForm
                    onChildrenDrawerCloseModificarDatosClientes={onChildrenDrawerCloseModificarDatosClientes}
                    state={state}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                    updateStates={updateStates}
                />
            </Drawer>

            <Divider orientation="center">Listado de Clientes</Divider>

            <Table
                rowSelection={{
                    type: "radio",
                    ...state.rowSelectionDatosClientes,
                }}
                rowKey={(record) => record.CODIGO_CLIENTES}
                size="small"
                columns={columnsDatosClientes}
                dataSource={state.datosClientes}
                onChange={handleChangeDatos}
                bordered
                pagination={{ defaultPageSize: 20 }}
            />
        </Drawer>
    )
}