import React, { Component, Table, TableHead, TableRow, TableCell, TableBody } from 'react';
import { database } from '../services/database.service';
import { AutoComplete, Carousel, Image } from 'antd';
import { userService } from '../services';
import ReactDOM from 'react-dom';



class InicioWeb extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        //Siempre un render y dentro del () el código jsx
        return (
            //<Carousel autoplay>
            <Carousel autoplay width="60%">
                <section className="ant-carousel slick-slide">
                    <center>
                        <img width="55%" src="src/assets/images/banner3.jpg"
                            className="ls-bg" alt="Imagen banner 3" />
                    </center>
                </section>
                <section className="ant-carousel slick-slide">
                    <center>
                        <img width="55%" src="src/assets/images/banner2.jpg"
                            className="ls-bg" alt="Imagen banner 2" />
                    </center>
                </section>
                <section className="ant-carousel slick-slide">
                    <center>
                        <img width="55%" src="src/assets/images/banner4.jpg"
                            className="ls-bg" alt="Imagen banner 4" />
                    </center>
                </section>
                <section className="ant-carousel slick-slide">
                    <center>
                        <img width="55%" src="src/assets/images/banner7.jpg"
                            className="ls-bg" alt="Imagen banner 7" />
                    </center>
                </section>
            </Carousel>

            // <center>
            //             <Image width={'55%'}  height={'45%'} src="src/assets/images/banner2.jpg" alt="" />
            //             </center>

            /*<div >
                <center>
                    <Image src="src/assets/images/banner3.jpg" alt="" />
                </center>
            </div>
            <div className = "ant-carousel slick-slide">
                    <center>
                        <Image xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }} width={'50%'} src="src/assets/images/banner3.jpg" alt=""/>
                    </center>
                </div>

            {/*<div>
                    <Image  width={'40%'} src="src/assets/images/banner2.jpg" />
            </div>
            <div>
                    <Image  width={'40%'} src="src/assets/images/banner3.jpg" />
            </div>

            <div className = "ant-carousel slick-slide">
                    <center>
                        <img xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }} width="1000" height="360vh" src="src/assets/images/banner2.jpg" 
                        className="ls-bg" alt=""/>
                    </center>
                </div>
                <div className = "ant-carousel slick-slide">
                    <center>
                        <img width="1000" height="360vh" src="src/assets/images/banner3.jpg" 
                        className="ls-bg" alt=""/>
                    </center>
                </div>
                 <div className = "ant-carousel slick-slide">
                    <center>
                        <img width="1000" height="360vh" src="src/assets/images/banner4.jpg" 
                        className="ls-bg" alt=""/>
                    </center>
                </div>
                <div className = "ant-carousel slick-slide">
                    <center>
                        <img width="1000" height="360vh" src="src/assets/images/banner7.jpg" 
                        className="ls-bg" alt=""/>
                    </center>
                </div> */
            //</Carousel>
        );
    }
}


//Renderizamos los datos en nuestra vista
export { InicioWeb };

