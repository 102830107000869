import { message } from "antd";

export const messages = {

    success: (msgSuccess) => {
        message.success(msgSuccess);
    },

    error: (msgError) => {
        message.error(msgError);
    },

    warning: (msgWarning) => {
        message.warning(msgWarning);
    }
}