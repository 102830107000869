import React, { useState, useRef } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Space,
  Button,
  Tag,
  Modal,
  ConfigProvider,
} from "antd";
import EditableCell from "../EditableCell";
import Highlighter from "react-highlight-words";
import { SearchOutlined, DatabaseOutlined } from "@ant-design/icons";
import { database } from "../../services/database.service";
import { userService } from "../../services";
import { messages } from "../../_utility";

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <DatabaseOutlined style={{ fontSize: 20 }} />
    <p>Datos No Encontrados</p>
  </div>
);

function TableCustomer({ listRecipes, editCubiertos, handler, ...props }) {
  const originData = listRecipes;

  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar dieta...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancelar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      cubiertos: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        const {
          catalogo,
          cod_centro,
          cubiertos,
          desc_dieta,
          estado,
          id,
          id_padre,
          key,
          intake_id,
        } = item;

        const datos = {
          p_catalogo: catalogo,
          p_id_dieta: id,
          p_desc_dieta: desc_dieta,
          p_cod_centro: cod_centro,
          p_id_padre: id_padre,
          p_cubiertos: row.cubiertos,
          p_eliminado: "N",
          p_usuario: JSON.parse(localStorage.getItem("user")).sAMAccountName,
          p_id_ingesta: intake_id,
        };

        if (editCubiertos === true) {
          database.actualizarDietasClientes(datos).then((valor) => {
            const newEstadoCubiertos = { estado: "OK", ...row };
            newData.splice(index, 1, { ...item, ...newEstadoCubiertos });
            setData(newData);
            setEditingKey("");
            handler(newData);
            messages.success("Cubiertos actualizados correctamente.");
          });
        } else {
          const newEstadoCubiertos = { estado: "UOT", ...row }; //UOT -> Updata Only One
          newData.splice(index, 1, { ...item, ...newEstadoCubiertos });
          setData(newData);
          setEditingKey("");
          handler(newData);
          messages.success("Cubiertos actualizados correctamente.");
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);

      Modal.error({
        title: "Error",
        content: "Ha ocurrido el siguiente error: " + errInfo,
        onOk() {},
      });
    }
  };

  const columns = [
    {
      title: "Ingesta",
      dataIndex: "intake_description",
      width: "15%",
      editable: false,
    },
    {
      title: "Descripción Dieta",
      dataIndex: "desc_dieta",
      width: "50%",
      editable: false,
      ...getColumnSearchProps("desc_dieta"),
    },
    {
      title: "Estado",
      key: "estado",
      dataIndex: "estado",
      render: (estado) => {
        return (
          <Tag
            color={
              estado === "N"
                ? "green"
                : estado === "OK"
                ? "geekblue"
                : estado === "UOT"
                ? "volcano"
                : "red"
            }
            key={estado}
          >
            {estado === "N"
              ? "NUEVO"
              : estado === "OK"
              ? "EN USO"
              : estado === "UOT"
              ? "ACTUALIZADO"
              : "ELIMINADO"}
          </Tag>
        );
      },
    },
    {
      title: "Cubiertos",
      dataIndex: "cubiertos",
      width: "15%",
      editable: true,
    },
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save(record.key);
              }}
              style={{
                marginRight: 8,
              }}
            >
              Guardar
            </Typography.Link>
            <Popconfirm title="¿Seguro de cancelar?" onConfirm={cancel}>
              <a>Cancelar</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Editar
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable.toString(),
        inputType: col.dataIndex === "cubiertos" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return listRecipes.length != 0 ? (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        title={() =>
          listRecipes[0].id_padre != null
            ? `Cliente: ${listRecipes[0].desc_dieta.substring(7)} (${
                listRecipes[0].catalogo
              } - ${listRecipes[0].cod_centro})`
            : `Cliente: `
        }
        bordered
        size="small"
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  ) : (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table columns={columns} />
    </ConfigProvider>
  );
}

export default TableCustomer;
