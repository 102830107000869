"use strict";
import React, { useEffect, useState } from "react";
import { database } from "../../../services/database.service";
import {
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Button,
  Divider,
  Modal,
  Select,
  Table,
  Tooltip,
  Checkbox,
  Tabs,
  DatePicker,
} from "antd";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import { ARRAY_OF_TEMPLATES } from "../../../utility/constants";
import {
  editarIngredientesTotal,
  llamarImprimirEtiquetasGeneral,
  onChangeCheckMultiporcion,
  onChangeDatePickerFechaElaboracion,
  onChangeLineaProduccion,
  onChangeSelectCocina,
} from "./opcionesFormFunctions";

moment.locale("es");
const dateFormat = "DD-MM-YYYY";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { confirm } = Modal;

const advertencia = `ATENCIÓN:  esta etiqueta está INCOMPLETA, verifique y complete la información que se muestra frente a las materias primas utilizadas. Comuníquelo para que se introduzca la información necesaria en JDE.

Existen subingredientes (ingredientes de los ingredintes) que no están rellenos ni verificados como monoingredientes.

`;
const columnsInfoNutricional = [
  {
    title: "Desc. Nutrientes",
    dataIndex: "descripcionNutrientes",
    key: "descripcionNutrientes",
    ellipsis: true,
  },
  {
    title: "Principal",
    dataIndex: "principal",
    key: "principal",
    align: "center",
    ellipsis: true,
  },
  {
    title: "Guarnición",
    dataIndex: "guarnicion",
    key: "guarnicion",
    align: "center",
    ellipsis: true,
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "center",
    ellipsis: true,
  },
];

export const DrawerOpcionesForm = (props) => {
  const { formRef, onClose, state, updateStates } = props;
  const [form] = Form.useForm();
  const [logos, setLogos] = useState([]);
  let inicializarValoresFormOpciones = {};

  useEffect(() => {
    database
      .getEtiquetas()
      .then((value) => setLogos(value.response))
      .catch((err) =>
        console.log({ error: err, message: "ERROR CARGANDO LOS LOGOS" })
      );
  }, []);

  if (state.cargarFomrularioPrimeraVez === 0) {
    const codigoPlatoLote =
      state.selectedRowKeysRecetas.length != 0
        ? state.selectedRowKeysRecetas
        : state.selectedRowKeysGuarnicion;

    inicializarValoresFormOpciones = {
      dias: "0",
      fechaElaboracion: moment(Date.now()),
      fechaCaducidad: moment(Date.now()),
      codigoLote: `${moment(Date.now()).format("YYYY")}${moment(
        Date.now()
      ).format("MM")}${moment(Date.now()).format("DD")}-${codigoPlatoLote}`,
      ingredientesPrincipal: state.valueTextAreaIngredientesTotal,
      ingredientesGuarnicion: state.valueTextAreaGuarnicion ?? '',
      alergenosPrincipal: state.valueTextAreaAlergenosPrincipal,
      alergenosGuarnicion: state.valueTextAreaAlergenosGuarnicion,
      adPlatoPrincipal:
        state.valueTextAreaAdvertenciaPrincipal.length === 0
          ? ""
          : `${advertencia}${state.valueTextAreaAdvertenciaPrincipal}`,
      adGuarnicion:
        state.valueTextAreaAdvertenciaGuarnicion.length === 0
          ? ""
          : `${advertencia}${state.valueTextAreaAdvertenciaGuarnicion}`,
      pesoNeto: state.valuePesoNetoTotal,
      cantidadEtiqImprimir: "1",
    };
  } else if (state.cargarFomrularioPrimeraVez === 2) {
    let substringCodigoLote = state.valuesFomrularioPrimeraVez.codigoLote;
    const recetaActual =
      state.selectedRowKeysRecetas.length != 0
        ? state.selectedRowKeysRecetas[0]
        : state.selectedRowKeysGuarnicion[0];
    inicializarValoresFormOpciones = {
      nameLineaProduccion: state.valuesFomrularioPrimeraVez.nameLineaProduccion,
      nameCliente: state.valuesFomrularioPrimeraVez.nameCliente,
      dias: state.valuesFomrularioPrimeraVez.dias,
      fechaElaboracion: state.valuesFomrularioPrimeraVez.fechaElaboracion,
      fechaCaducidad: state.valuesFomrularioPrimeraVez.fechaCaducidad,
      codigoLote: substringCodigoLote.substring(0, 16) + recetaActual,
      nameCocina: state.valuesFomrularioPrimeraVez.nameCocina,
      plantilla: state.valuesFomrularioPrimeraVez.plantilla,
      observacionesEspeciales:
        state.valuesFomrularioPrimeraVez.observacionesEspeciales,
      logoIzquierdo: state.valuesFomrularioPrimeraVez.logoIzquierdo,
      logoDerecho: state.valuesFomrularioPrimeraVez.logoDerecho,
      observaciones: state.valuesFomrularioPrimeraVez.observaciones,
      cantidadEtiqImprimir:
        state.valuesFomrularioPrimeraVez.cantidadEtiqImprimir,
      ingredientesPrincipal: state.valueTextAreaIngredientesTotal,
      alergenosPrincipal: state.valueTextAreaAlergenosPrincipal,
      ingredientesGuarnicion: state.valueTextAreaGuarnicion ?? '',
      adPlatoPrincipal:
        state.valueTextAreaAdvertenciaPrincipal.length === 0
          ? ""
          : `${advertencia}${state.valueTextAreaAdvertenciaPrincipal}`,
      adGuarnicion:
        state.valueTextAreaAdvertenciaGuarnicion.length === 0
          ? ""
          : `${advertencia}${state.valueTextAreaAdvertenciaGuarnicion}`,
      alergenosGuarnicion: state.valueTextAreaAlergenosGuarnicion,
      texto01: state.valuesFomrularioPrimeraVez.texto01,
      texto02: state.valuesFomrularioPrimeraVez.texto02,
      texto03: state.valuesFomrularioPrimeraVez.texto03,
      texto04: state.valuesFomrularioPrimeraVez.texto04,
      pesoNeto: state.valuePesoNetoTotal,
    };
  } else {
    const valoresFormulario = formRef.current.getFieldsValue();
    const cantidadValoresFormulario = Object.values(
      formRef.current.getFieldsValue()
    );
    const substringCodigoLote = valoresFormulario.codigoLote;
    const recetaActual =
      state.selectedRowKeysRecetas.length != 0
        ? state.selectedRowKeysRecetas[0]
        : state.selectedRowKeysGuarnicion[0];

    if (cantidadValoresFormulario.length === 13) {
      inicializarValoresFormOpciones = {
        nameLineaProduccion: valoresFormulario.nameLineaProduccion,
        nameCliente: valoresFormulario.nameCliente,
        dias: valoresFormulario.dias,
        fechaElaboracion: valoresFormulario.fechaElaboracion,
        fechaCaducidad: valoresFormulario.fechaCaducidad,
        codigoLote: substringCodigoLote.substring(0, 16) + recetaActual,
        nameCocina: valoresFormulario.nameCocina,
        plantilla: valoresFormulario.plantilla,
        observacionesEspeciales: valoresFormulario.observacionesEspeciales,
        logoIzquierdo: valoresFormulario.logoIzquierdo,
        logoDerecho: valoresFormulario.logoDerecho,
        observaciones: valoresFormulario.observaciones,
        cantidadEtiqImprimir: valoresFormulario.cantidadEtiqImprimir,
        ingredientesPrincipal: state.valueTextAreaIngredientesTotal,
        alergenosPrincipal: state.valueTextAreaAlergenosPrincipal,
        adPlatoPrincipal:
          state.valueTextAreaAdvertenciaPrincipal.length === 0
            ? ""
            : `${advertencia}${state.valueTextAreaAdvertenciaPrincipal}`,
        adGuarnicion:
          state.valueTextAreaAdvertenciaGuarnicion.length === 0
            ? ""
            : `${advertencia}${state.valueTextAreaAdvertenciaGuarnicion}`,
        ingredientesGuarnicion: state.valueTextAreaGuarnicion ?? '',
        alergenosGuarnicion: state.valueTextAreaAlergenosGuarnicion,
        texto01: valoresFormulario.texto01,
        texto02: valoresFormulario.texto02,
        texto03: valoresFormulario.texto03,
        texto04: valoresFormulario.texto04,
        pesoNeto: state.valuePesoNetoTotal,
      };
    } else if (cantidadValoresFormulario.length === 18) {
      const codigoPlatoLote =
        state.selectedRowKeysRecetas.length != 0
          ? state.selectedRowKeysRecetas
          : state.selectedRowKeysGuarnicion;
      inicializarValoresFormOpciones = {
        nameLineaProduccion: valoresFormulario.nameLineaProduccion,
        nameCliente: valoresFormulario.nameCliente,
        dias: valoresFormulario.dias,
        fechaElaboracion:
          valoresFormulario.fechaElaboracion === "undefined"
            ? moment(Date.now())
            : valoresFormulario.fechaElaboracion,
        fechaCaducidad:
          valoresFormulario.fechaCaducidad === "undefined"
            ? moment(Date.now())
            : valoresFormulario.fechaCaducidad,
        codigoLote:
          valoresFormulario.codigoLote === "undefined"
            ? moment(Date.now()).format("YYYY") +
              moment(Date.now()).format("MM") +
              moment(Date.now()).format("DD") +
              "-" +
              codigoPlatoLote
            : substringCodigoLote.substring(0, 16) + recetaActual,
        nameCocina: valoresFormulario.nameCocina,
        plantilla: valoresFormulario.plantilla,
        observacionesEspeciales: valoresFormulario.observacionesEspeciales,
        logoIzquierdo: valoresFormulario.logoIzquierdo,
        logoDerecho: valoresFormulario.logoDerecho,
        observaciones: valoresFormulario.observaciones,
        cantidadEtiqImprimir: valoresFormulario.cantidadEtiqImprimir,
        ingredientesPrincipal: valoresFormulario.ingredientesPrincipal,
        alergenosPrincipal: valoresFormulario.alergenosPrincipal,
        ingredientesGuarnicion: valoresFormulario.ingredientesGuarnicion,
        adPlatoPrincipal:
          state.valueTextAreaAdvertenciaPrincipal.length === 0
            ? ""
            : `${advertencia}${state.valueTextAreaAdvertenciaPrincipal}`,
        adGuarnicion:
          state.valueTextAreaAdvertenciaGuarnicion.length === 0
            ? ""
            : `${advertencia}${state.valueTextAreaAdvertenciaGuarnicion}`,
        alergenosGuarnicion: valoresFormulario.alergenosGuarnicion,
        texto01: valoresFormulario.texto01,
        texto02: valoresFormulario.texto02,
        texto03: valoresFormulario.texto03,
        texto04: valoresFormulario.texto04,
        pesoNeto: state.valuePesoNetoTotal,
      };
    } else {
      inicializarValoresFormOpciones = {
        nameLineaProduccion: valoresFormulario.nameLineaProduccion,
        nameCliente: valoresFormulario.nameCliente,
        dias: valoresFormulario.dias,
        fechaElaboracion: valoresFormulario.fechaElaboracion,
        fechaCaducidad: valoresFormulario.fechaCaducidad,
        codigoLote: substringCodigoLote.substring(0, 16) + recetaActual,
        nameCocina: valoresFormulario.nameCocina,
        plantilla: valoresFormulario.plantilla,
        observacionesEspeciales: valoresFormulario.observacionesEspeciales,
        logoIzquierdo: valoresFormulario.logoIzquierdo,
        logoDerecho: valoresFormulario.logoDerecho,
        observaciones: valoresFormulario.observaciones,
        cantidadEtiqImprimir: valoresFormulario.cantidadEtiqImprimir,
        ingredientesPrincipal: valoresFormulario.ingredientesPrincipal,
        alergenosPrincipal: valoresFormulario.alergenosPrincipal,
        ingredientesGuarnicion: valoresFormulario.ingredientesGuarnicion,
        adPlatoPrincipal:
          state.valueTextAreaAdvertenciaPrincipal.length === 0
            ? ""
            : `${advertencia}${state.valueTextAreaAdvertenciaPrincipal}`,
        adGuarnicion:
          state.valueTextAreaAdvertenciaGuarnicion.length === 0
            ? ""
            : `${advertencia}${state.valueTextAreaAdvertenciaGuarnicion}`,
        alergenosGuarnicion: valoresFormulario.alergenosGuarnicion,
        texto01: valoresFormulario.texto01,
        texto02: valoresFormulario.texto02,
        texto03: valoresFormulario.texto03,
        texto04: valoresFormulario.texto04,
        pesoNeto: state.valuePesoNetoTotal,
      };
    }
  }

  useEffect(() => {
    form.setFieldsValue(inicializarValoresFormOpciones);
  }, [state]);

  const selectAfter = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
        defaultValue="g"
      >
        <Option value="g">g</Option>
        <Option value="kg">kg</Option>
      </Select>
    </Form.Item>
  );

  const onFinishFailed = (values, errorFields, outOfDate) => {
    const errorFieldsObligatorio = values.errorFields.length;

    if (errorFieldsObligatorio != 0) {
      Modal.error({
        title: "Error",
        content:
          "Debes completar los campos obligatorios en la pestaña Opciones.",
      });
    }
  };

  const onFinishImprimirEtiquetas = (values) => {
    if (values.nameLineaProduccion === undefined) {
      Modal.warning({
        title: "Advertencia",
        content: "Debes lanzar la impresión desde la pestaña Opciones.",
      });
    } else {
      const substringCodigoLote = values.codigoLote;

      const recetaActual =
        state.selectedRowKeysRecetas.length != 0
          ? state.selectedRowKeysRecetas[0]
          : state.selectedRowKeysGuarnicion[0];

      const alergenosGuarnicion =
        values.alergenosGuarnicion === undefined
          ? state.valueTextAreaAlergenosGuarnicion
          : values.alergenosGuarnicion;
      const alergenosPrincipal = values.alergenosPrincipal;
      const cantidadEtiqImprimir = values.cantidadEtiqImprimir;
      const codLote = substringCodigoLote.substring(0, 16) + recetaActual; // +
      const totalDiasCaducidad = values.dias;
      const fechaCaducidad = moment(values.fechaCaducidad).format(dateFormat);
      const fechaElaboracion = moment(values.fechaElaboracion).format(
        dateFormat
      );

      
      const ingredientesGuarnicion =
        values.ingredientesGuarnicion === undefined
          ? state.valueTextAreaGuarnicion
          : values.ingredientesGuarnicion;

          console.log("ESTO QUE", ingredientesGuarnicion)
      const ingredientesPrincipal =
        values.ingredientesPrincipal === undefined
          ? state.valueTextAreaIngredientesTotal
          : values.ingredientesPrincipal;
      const rutaLogoDerecho = values.logoDerecho;
      const rutaLogoIzquierdo = values.logoIzquierdo;
      const codCliente =
        values.nameCliente == null ? "" : values.nameCliente.split(".", 2);
      const codCocina = values.nameCocina;
      const codLineaProduccion = values.nameLineaProduccion.split(".", 1);
      const observaciones = values.observaciones;
      const codObservacionesEspeciales =
        values.observacionesEspeciales == null
          ? ""
          : values.observacionesEspeciales;
      const plantilla = values.plantilla;
      const texto01 = values.texto01;
      const texto02 = values.texto02;
      const texto03 = values.texto03;
      const texto04 = values.texto04;
      const pesoNetoTotal = values.pesoNeto;
      const unidadMedida = values.prefix === undefined ? "g" : values.prefix;
      const mostrarPuedeContener = values.puedeContener === "S" ? "S" : "N";
      let puedeContener = "";
      let statePuedeContenerState;

      if (ingredientesGuarnicion?.length > 0 && ingredientesPrincipal?.length > 0) {
        statePuedeContenerState = state.puedeContenerState;
      } else if(state.puedeContenerState === null) {
        statePuedeContenerState = ""
      } else if (typeof state.puedeContenerState === "string") {
        statePuedeContenerState = state.puedeContenerState.split(",");
      } else {
        statePuedeContenerState = state.puedeContenerState
      }

      const multiporcion = state.checkedMultiporcion;

      const fechaElabor = new Date(
        moment(values.fechaElaboracion).format("YYYY"),
        moment(values.fechaElaboracion).format("MM"),
        moment(values.fechaElaboracion).format("DD")
      );
      const f = new Date();
      const hoy = new Date(f.getFullYear(), f.getMonth() + 1, f.getDate());

      if (hoy < fechaElabor || hoy > fechaElabor) {
        confirm({
          title: "Advertencia: Fecha Elaboración",
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              {hoy > fechaElabor ? (
                <p>
                  La fecha de elaboración es{" "}
                  <span style={{ fontWeight: 500, color: "#f3000c" }}>
                    ANTERIOR
                  </span>{" "}
                  a la fecha de hoy {f.getDate()}-{f.getMonth() + 1}-
                  {f.getFullYear()}.
                </p>
              ) : (
                <p>
                  La fecha de elaboración es{" "}
                  <span style={{ fontWeight: 500, color: "#e1c11a" }}>
                    POSTERIOR
                  </span>{" "}
                  a la fecha de hoy {f.getDate()}-{f.getMonth() + 1}-
                  {f.getFullYear()}.
                </p>
              )}
            </div>
          ),
          onOk: () => {
            if (statePuedeContenerState.length > 0) {
              const beforeLastIndex = statePuedeContenerState.length - 1;
              statePuedeContenerState.forEach((state, index) => {
                if (index === 0) {
                  puedeContener += `${state} (Trazas)`;
                } else {
                  puedeContener += `, ${state} ${
                    beforeLastIndex === index ? "(Trazas)." : "(Trazas)"
                  }`;
                }
              });
            }

            updateStates({
              cargarFomrularioPrimeraVez: 2,
              valuesFomrularioPrimeraVez: values,
              txtAreaIngredientesTotal: true,
              txtAreaAlergenosTotal: true,
              txtAreaIngredientesGuarnicion: true,
              botonEditarIngredientesTotal: false,
              txtAreaAlergenosGuarnicion: true,
            });

            llamarImprimirEtiquetasGeneral(
              codLineaProduccion,
              codCliente,
              fechaElaboracion,
              totalDiasCaducidad,
              fechaCaducidad,
              codLote,
              codCocina,
              codObservacionesEspeciales,
              rutaLogoIzquierdo,
              rutaLogoDerecho,
              observaciones,
              cantidadEtiqImprimir,
              alergenosGuarnicion,
              alergenosPrincipal,
              ingredientesGuarnicion,
              ingredientesPrincipal,
              texto01,
              texto02,
              texto03,
              texto04,
              plantilla,
              pesoNetoTotal,
              unidadMedida,
              mostrarPuedeContener,
              puedeContener,
              multiporcion,
              updateStates,
              state
            );
          },
          onCancel() {},
          cancelText: "Cancelar",
          okText: "Aceptar",
        });
      } else {
        if (statePuedeContenerState.length > 0) {
          const beforeLastIndex = statePuedeContenerState.length - 1;
          statePuedeContenerState.forEach((state, index) => {
            if (index === 0) {
              puedeContener += `${state} (Trazas)`;
            } else {
              puedeContener += `, ${state} ${
                beforeLastIndex === index ? "(Trazas)." : "(Trazas)"
              }`;
            }
          });
        }

        updateStates({
          cargarFomrularioPrimeraVez: 2,
          valuesFomrularioPrimeraVez: values,
          txtAreaIngredientesTotal: true,
          txtAreaAlergenosTotal: true,
          txtAreaIngredientesGuarnicion: true,
          botonEditarIngredientesTotal: false,
          txtAreaAlergenosGuarnicion: true,
        });

        llamarImprimirEtiquetasGeneral(
          codLineaProduccion,
          codCliente,
          fechaElaboracion,
          totalDiasCaducidad,
          fechaCaducidad,
          codLote,
          codCocina,
          codObservacionesEspeciales,
          rutaLogoIzquierdo,
          rutaLogoDerecho,
          observaciones,
          cantidadEtiqImprimir,
          alergenosGuarnicion,
          alergenosPrincipal,
          ingredientesGuarnicion,
          ingredientesPrincipal,
          texto01,
          texto02,
          texto03,
          texto04,
          plantilla,
          pesoNetoTotal,
          unidadMedida,
          mostrarPuedeContener,
          puedeContener,
          multiporcion,
          updateStates,
          state
        );
      }
    }
  };

  return (
    <Form
      onFinishFailed={onFinishFailed}
      layout="vertical"
      ref={formRef}
      onFinish={onFinishImprimirEtiquetas}
      form={form}
      initialValues={inicializarValoresFormOpciones}
    >
      <Tabs tabPosition={"top"} defaultActiveKey={state.tabPaneActiva}>
        <TabPane tab="Opciones" key="1">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="nameLineaProduccion"
                label="Línea de Producción:"
                rules={[{ required: true, message: "Campo obligatorio." }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Por favor selecciona la Línea de Producción"
                  onChange={(value) =>
                    onChangeLineaProduccion(value, formRef, updateStates, state)
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.optionLineaProduccion}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                hasFeedback
                name="nameCliente"
                label="Nombre del Cliente:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Por favor selecciona el Cliente"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.optionDatosClientes}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                hasFeedback
                name="fechaElaboracion"
                label="Fecha Elaboración:"
                rules={[{ required: true, message: "Campo obligatorio." }]}
              >
                <DatePicker
                  locale={locale}
                  onChange={(date) =>
                    onChangeDatePickerFechaElaboracion(date, formRef, state)
                  }
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item
                name="dias"
                label="Días:"
                rules={[{ required: true, message: "Campo obligatorio." }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name="fechaCaducidad"
                label="Fecha Caducidad:"
                rules={[{ required: true, message: "Campo obligatorio." }]}
              >
                <DatePicker locale={locale} format={dateFormat} disabled />
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item
                name="codigoLote"
                label="Código del Lote:"
                rules={[{ required: true, message: "Campo obligatorio." }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="nameCocina"
                label="Nombre de la Cocina:"
                rules={[{ required: true, message: "Campo obligatorio." }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Por favor selecciona la Cocina"
                  onChange={() => onChangeSelectCocina(formRef, state)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.optionDatosEmpresas}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                hasFeedback
                name="plantilla"
                label="Plantilla:"
                rules={[{ required: true, message: "Campo obligatorio." }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Por favor selecciona la plantilla"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ARRAY_OF_TEMPLATES.map((template, index) => (
                    <Option
                      key={`${index + 1}`}
                      label={`plantilla${index + 1}`}
                      value={`plantilla-${index + 1}`}
                    >
                      {template}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                name="observacionesEspeciales"
                label="Observaciones Especiales:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Por favor selecciona la(s) Observaciones Especiales"
                  mode="tags"
                >
                  {state.optionObsEspeciales}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="logoIzquierdo"
                label="Logo Izquierdo:"
                rules={[{ required: false, message: "Campo obligatorio." }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Por favor selecciona el Logo Izquierdo"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {logos?.map(({ NOMBRE, TAG, URL }) => (
                    <Option key={TAG} label={TAG} value={URL}>
                      {NOMBRE}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                hasFeedback
                name="logoDerecho"
                label="Logo Derecho:"
                rules={[{ required: false, message: "Campo obligatorio." }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Por favor selecciona el Logo Derecho"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {logos?.map(({ NOMBRE, TAG, URL }) => (
                    <Option key={TAG} label={TAG} value={URL}>
                      {NOMBRE}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="observaciones"
                label="Observaciones:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea rows={4} autoSize={{ minRows: 2, maxRows: 4 }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                name="pesoNeto"
                label="Peso Neto:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <Input
                  disabled={state.disabledPesoNeto}
                  addonAfter={selectAfter}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="multiporcion"
                label="¿Es multiporción?"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <Checkbox
                  checked={state.checkedMultiporcion}
                  onChange={(e) => onChangeCheckMultiporcion(e, updateStates)}
                  style={{ marginLeft: "20px" }}
                >
                  Multiporción
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="puedeContener"
                label="Mostrar Puede Contener:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <Select
                  placeholder="Seleccionar puede contener"
                  defaultValue="N"
                >
                  <Option key="1" value="S">
                    Sí
                  </Option>
                  <Option key="0" value="N">
                    No
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Datos Adicionales</Divider>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                name="texto01"
                label="Texto-01:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea rows={2} autoSize={{ minRows: 2, maxRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                name="texto02"
                label="Texto-02:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea rows={2} autoSize={{ minRows: 2, maxRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                name="texto03"
                label="Texto-03:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea rows={2} autoSize={{ minRows: 2, maxRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                name="texto04"
                label="Texto-04:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea rows={2} autoSize={{ minRows: 2, maxRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>

        <TabPane tab="Cálculos" key="3">
          <Divider orientation="left">Información Nutricional</Divider>

          <Table
            columns={columnsInfoNutricional}
            size={"small"}
            dataSource={state.infoNutricionalReceta}
            pagination={false}
            bordered={false}
          />

          <Divider orientation="left">Información Nutricional 100g</Divider>

          <Table
            style={{ marginBottom: "20px" }}
            columns={columnsInfoNutricional}
            size={"small"}
            dataSource={state.infoNutricionalReceta100}
            pagination={false}
            bordered={false}
          />
        </TabPane>

        <TabPane tab="Ingredientes" key="4">
          <Row gutter={8}>
            <Col span={24}>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Tooltip title="Editar Ingredientes">
                  <Button
                    style={{ marginRight: 8 }}
                    size="small"
                    icon={<EditOutlined />}
                    type="dashed"
                    shape="circle"
                    onClick={() =>
                      editarIngredientesTotal(updateStates, formRef)
                    }
                    danger
                    disabled={state.botonEditarIngredientesTotal}
                  />
                </Tooltip>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                name="ingredientesPrincipal"
                label="Ingredientes Principal:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea autoSize disabled={state.txtAreaIngredientesTotal} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            </Col>
            <Col span={24}>
              <Form.Item
                name="alergenosPrincipal"
                label="Alérgenos Principal:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea autoSize disabled={state.txtAreaAlergenosTotal} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            </Col>
            <Col span={24}>
              <Form.Item
                name="ingredientesGuarnicion"
                label="Ingredientes Guarnición:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea
                  autoSize
                  disabled={state.txtAreaIngredientesGuarnicion}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            </Col>
            <Col span={24}>
              <Form.Item
                name="alergenosGuarnicion"
                label="Alérgenos Guarnición:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea
                  autoSize
                  disabled={state.txtAreaAlergenosGuarnicion}
                />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>

        <TabPane
          tab={state.colorTabPanelAdvertencia}
          key="5"
          disabled={state.disabledTabPanelAdevrtencia}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="adPlatoPrincipal"
                label="Plato Principal:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 14 }}
                  disabled
                  style={state.colorAdvertenciaPrincipalTA}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="adGuarnicion"
                label="Guarnición:"
                rules={[{ required: false, message: "Campo no obligatorio." }]}
              >
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 14 }}
                  disabled
                  style={state.colorAdvertenciaGuarnicionTA}
                />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
      </Tabs>

      <Row gutter={16}>
        <Col span={7}>
          <span>Nº Etiquetas Imprimir:</span>
        </Col>

        <Col span={5}>
          <Form.Item
            name="cantidadEtiqImprimir"
            rules={[{ required: false, message: "Campo no obligatorio." }]}
          >
            <InputNumber min={1} max={1000} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <div
            style={{
              textAlign: "right",
              marginBottom: "20px",
            }}
          >
            <Button
              onClick={onClose}
              style={{ marginRight: 8 }}
              disabled={state.disabledBotonCancelar}
            >
              Cancelar
            </Button>
            <Button
              disabled={state.disabledBotonAceptar}
              loading={state.loadingButtonLanzarImpresion}
              type="primary"
              htmlType="submit"
            >
              Aceptar
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
