import XLSX from 'xlsx';
import moment from 'moment';


export const exportToExcel = {
    exportarExcel
};
const dateFormat = "DD/MM/YYYY";

function Workbook() {
    if (!(this instanceof Workbook))
        return new Workbook()

    this.SheetNames = []

    this.Sheets = {}
}

const download = (url, name) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}


function s2ab(s) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i = 0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF

    return buf
}

async function exportarExcel(data, nameDocument, current, center, catalog, centerName) {
    const wb = new Workbook();

    switch (current) {
        case 0:
            data.forEach( ( { sheetData, sheetName } ) => {
                let arrayData = sheetData.map((element, index) => ({
                    "No.": index + 1,
                    "Catálogo": element.catalogo,
                    "Código Centro": center,
                    "Centro": centerName,
                    "Código Dieta": element.id,
                    "Dieta": element.desc_dieta,
                    "Cubiertos": element.cubiertos,
                    "Estado": element.estado === 'N' ? 'NUEVO' : element.estado === 'OK' ? 'EN USO' : element.estado === 'UOT' ? 'ACTUALIZADO' : 'ELIMINADO'
                }))
            
                wb.SheetNames.push(sheetName)
                wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(arrayData)
            })
            break;
        
        case 1:
            data.forEach( ( { sheetData, sheetName } ) => {
                let arrayData = sheetData.map((element, index) => ({
                    "No.": index + 1,
                    "Catálogo": catalog,
                    "Código Centro": center,
                    "Centro": centerName,
                    "Código Receta": element.codigo_receta,
                    "Receta": element.receta,
                    "Código Dieta": element.codigo_dieta,
                    "(Menú) Dieta": element.dieta,
                    "Cubiertos": element.cubiertos,
                    "Opción": element.opcion,
                    "Alérgenos": element.alergenos
                }))
            
                wb.SheetNames.push(sheetName)
                wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(arrayData)
            })
            break;
            
        case 2:
            data.forEach( ( { sheetData, sheetName } ) => {
                let arrayData = sheetData.map((element, index) => ({
                    "No.": index + 1,
                    "Catálogo": catalog,
                    "Código Centro": center,
                    "Centro": centerName,
                    "Código Receta": element.codigo_receta,
                    "Receta": element.receta,
                    "Código Ingrediente": element.codigo_ingrediente,
                    "Ingrediente": element.ingrediente,
                    "Código Proveedor": element.codigo_proveedor,
                    "Proveedor": element.proveedor,
                    "Código Dieta": element.codigo_dieta,
                    "(Menú) Dieta": element.dieta,
                    "Cubiertos": element.cubiertos,
                    "Cantidad (g)": element.cantidad,
                    "Cantidad x Cubiertos (g)": element.cantidad_racion,
                    "Ingesta": element.ingesta,
                    "Opción": element.opcion,
                    "Fecha Servicio": moment(new Date(element.fecha_servicio)).format("DD-MM-YYYY"),
                    "Alérgenos": element.alergenos
                }))
            
                wb.SheetNames.push(sheetName)
                wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(arrayData)
            })
            break;
        
        case 3:
            data.forEach( ( { sheetData, sheetName } ) => {
                let arrayData = sheetData.map((element, index) => ({
                    "No.": index + 1,
                    "Catálogo": catalog,
                    "Código Centro": center,
                    "Centro": centerName,
                    "Código Ingrediente": element.codigo_ingrediente,
                    "Ingrediente": element.ingrediente,
                    "Código Proveedor": element.codigo_proveedor,
                    "Proveedor": element.proveedor,
                    "Cantidad (g)": element.total,
                    "Total": (element.total / element.gramos_unidad).toFixed(2),
                    "Unidad Medida": element.um_origen,
                    "gramos_unidad": element.gramos_unidad,
                    "Alérgenos": element.alergenos,
                    "Ingesta": element.ingesta
                }))
            
                wb.SheetNames.push(sheetName)
                wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(arrayData)
            })
            break;
    }

    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary', Author: "Alejandro Vázquez Chávez" });
    let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
    download(url, nameDocument + moment(Date.now()).format('YYYYMMDD') + moment(Date.now()).format('HH:mm') + '.xlsx');
}