"use strict";
import React, { useEffect } from "react"
import { Form, Row, Col, Input, Button, Select } from "antd"
import { enviarModificarDatosClientes } from "../functions";

export const DrawerModificarDatosClientesForm = (props) => {
    const { onChildrenDrawerCloseModificarDatosClientes, state, selectCatalogosUsuario, updateStates } = props

    const rowsModificarDatosClientes = state.selectedRowsDatosClientes
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue({
            nameClientes: rowsModificarDatosClientes[0].NOMBRE_CLIENTES,
            catalogo: catalogoActualCentro,
        })
    }, [state.selectedRowsDatosClientes])

    let catalogoActualCentro
    state.listaOptionsCatalogosUsuario.forEach(({ CATALOGO, NOMBRE }) => {

        if (rowsModificarDatosClientes[0].CATALOGO === CATALOGO) {
            catalogoActualCentro = `${CATALOGO}.${NOMBRE}`
        }

    })

    const mostrarDatosClientes = {
        nameClientes: rowsModificarDatosClientes[0].NOMBRE_CLIENTES,
        catalogo: catalogoActualCentro,
    };

    const onFinishActualizarDatosClientes = (values) => {
        const nameClientes = values.nameClientes
        const catalogo = values.catalogo.toString()

        const catalogoConPunto = catalogo.indexOf(".")

        const codigoDatosClientes =
            rowsModificarDatosClientes[0].CODIGO_CLIENTES.toString()

        const catalog = catalogoConPunto === -1 ? catalogo : catalogo.substring(0, catalogo.indexOf("."))

        enviarModificarDatosClientes(
            codigoDatosClientes,
            nameClientes,
            catalog,
            updateStates
        )
    }

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinishActualizarDatosClientes}
            form={form}
            initialValues={mostrarDatosClientes}
        >
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameClientes"
                        label="Nombre Cliente:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir nombre del cliente" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="catalogo"
                        label="Catálogo:"
                        rules={[
                            {
                                required: true,
                                message: "Campo obligatorio.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Por favor, seleccionar catálogo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {selectCatalogosUsuario}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={onChildrenDrawerCloseModificarDatosClientes}
                            style={{ marginRight: 8 }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state.loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}