import React, { Component } from 'react'
import { database } from '../services/database.service'
import { Form, Row, Col, Button, Select, Spin, Modal } from 'antd'
import { userService } from '../services'
import TableCustomer from '../components/commons/TableCustomer'

class GestionInformes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      children: '',
      listaCentrosUsuarios: [],
      statusCentrosUsuarios: null,
      user: {},
      users: [],
      employee: {},
      employees: [],
      loadingSpingGestionClientes: false,
      textoloadingSpingGestionClientes: '',
      seleccionadoHojaProduccion: false,
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
      datosTablaDietasCliente: [],
    }
  }

  componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem('user')),
      users: { loading: true },
      employee: JSON.parse(localStorage.getItem('employee')),
      employees: { loading: true },
      loadingSpingGestionClientes: true,
      textoloadingSpingGestionClientes: 'Cargando pantalla Gestionar Informes...',
      seleccionadoHojaProduccion: false,
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
      datosTablaDietasCliente: [],
    })
    this.getListaCentro()
  }

  onSelect = (value, option) => {
    this.agregarCampo(value)
  }

  updateLoadinStates = (loadin, loadingSpingGestionClientes, textoloadingSpingGestionClientes) => {
    this.setState({
      loadin,
      loadingSpingGestionClientes,
      textoloadingSpingGestionClientes,
    })
  }

  getListaCentro = () => {
    this.setState({
      loadingSpingGestionClientes: true,
      textoloadingSpingGestionClientes: 'Cargando pantalla Gestionar Dietas de los Clientes...',
    })

    const sAMAccountName = JSON.parse(localStorage.getItem('user')).sAMAccountName.toUpperCase()
    database.obtenerCentrosUsuarioMongoDB(sAMAccountName).then(res => {
      const { message, status } = res.response

      if (status !== 'success' || !message?.length > 0) {
        Modal.error({
          title: 'Error',
          content: `Ha ocurrido el siguiente error: ${message}`,
        })
      }

      if (status === 'success' && message.length >= 0) {
        this.setState({
          listaCentrosUsuarios: message.length === 0 ? [] : message,
          statusCentrosUsuarios: message.length === 0 ? null : 'success',
          loadingSpingGestionClientes: false,
          textoloadingSpingGestionClientes: '',
        })
      }
    })
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRows, selectedRowKeys })
  }

  /**
   * Recupero las dietas del cliente.
   * @param {*} catalogo
   * @param {*} centro
   */
  recuperarDietasCliente = (catalogo, centro) => {
    this.setState({
      loadingSpingGestionClientes: true,
      textoloadingSpingGestionClientes: 'Cargando datos...',
      selectedRows: [],
      selectedRowKeys: [],
      datosTablaDietasCliente: [],
    })

    const datosMenusHojaGestion = {
      pCatalogo: catalogo,
      pCentro: centro,
    }

    try {
      database.obtenerDietasClientesComensales(datosMenusHojaGestion).then(valor => {
        const { message, status } = valor.response

        if (status !== 'success' || !message?.length > 0) {
          this.updateLoadinStates(false, false, '')

          Modal.error({
            title: 'Error',
            content: 'Ha ocurrido el siguiente error: ' + message,
            onOk() {},
          })
        }

        if (status === 'success' && message.length > 0) {
          const datosTablaMenus = message.map((dieta, index) => {
            return {
              key: index,
              catalogo: dieta.CATALOGO,
              cod_centro: dieta.COD_CENTRO,
              desc_dieta: dieta.DESC_CENTRO,
              id: dieta.ID,
              intake_description: dieta.INGESTA_DESCRIPCION,
              intake_id: dieta.INGESTA_ID,
              id_padre: dieta.ID_PADRE,
              cubiertos: dieta.CUBIERTOS !== null ? dieta.CUBIERTOS : 0,
              estado: dieta.ESTADO,
            }
          })

          this.setState({
            datosTablaDietasCliente: datosTablaMenus,
          })
          this.updateLoadinStates(false, false, '')
        }

        if (status === 'success' && message.length === 0) {
          this.updateLoadinStates(false, false, '')

          Modal.info({
            title: 'Notificación',
            content: 'El Centro seleccionado no tiene dietes creadas en Menuplanner.',
            onOk() {},
          })
        }
      })
    } catch (e) {
      this.updateLoadinStates(false, false, '')

      Modal.error({
        title: 'Error',
        content: `Ha ocurrido el siguiente error al Recuperar las recetas del Menú seleccionado: ${e}. Contactar con el administrador.`,
      })
    }
  }

  render() {
    let resultadoCentros = this.state.listaCentrosUsuarios.map(({ CATALOGO, COD_CENTRO, DESC_CENTRO }) => {
      return (
        <Select.Option
          key={`${CATALOGO}.${COD_CENTRO}`}
          label={`${COD_CENTRO}.${DESC_CENTRO}`}
          title={`${COD_CENTRO}.${DESC_CENTRO}`}
        >
          {`(${CATALOGO}-${COD_CENTRO}) ${DESC_CENTRO}`}
        </Select.Option>
      )
    })

    const DynamicFormNestItem = () => {
      const [form] = Form.useForm()

      const onFinish = values => {
        const catalogoCentro = values.selectCentro.split('.')
        const catalogo = catalogoCentro[0]
        const centro = catalogoCentro[1]

        this.recuperarDietasCliente(catalogo, centro)
      }

      return (
        <Form form={form} name='advanced_search' className='ant-advanced-search-form' onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={8} key={1}>
              <Form.Item hasFeedback name='selectCentro' rules={[{ required: true, message: 'Campo obligatorio' }]}>
                <Select
                  showSearch
                  placeholder='Seleccionar Centro'
                  optionFilterProp='children'
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {resultadoCentros}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} key={2}>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Aceptar
                </Button>
                <Button
                  key='back'
                  onClick={() => {
                    form.resetFields()
                  }}
                  style={{
                    float: 'left',
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )
    }

    return (
      <div>
        <Spin
          spinning={this.state.loadingSpingGestionClientes}
          delay={500}
          tip={this.state.textoloadingSpingGestionClientes}
        >
          <DynamicFormNestItem />
          {this.state.datosTablaDietasCliente.length != 0 ? (
            <TableCustomer listRecipes={this.state.datosTablaDietasCliente} editCubiertos={true} />
          ) : (
            ''
          )}
        </Spin>
      </div>
    )
  }
}

export default GestionInformes
