"use strict";
import React from "react"
import { Form, Row, Col, Input, Button, Select } from "antd"

export const DrawerInsertarLineasProduccionForm = (props) => {
    const { enviarLineasProduccion, onChildrenDrawerCloseLineasProduccion, selectCatalogosUsuario } = props

    const [form] = Form.useForm()

    const onFinishInsertarLineasProduccion = (values) => {
        const { nameLineaProduccion, tipo, descripcionTipo, diasCaducidad, textoFCaducidad, condicionesConservacion, modoEmpleo } = values
        const catalogo = values.selectCatalogo;

        enviarLineasProduccion(
            nameLineaProduccion,
            tipo,
            descripcionTipo,
            diasCaducidad,
            textoFCaducidad,
            condicionesConservacion,
            modoEmpleo,
            catalogo
        )
    }

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinishInsertarLineasProduccion}
            form={form}
        >
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameLineaProduccion"
                        label="Nombre:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir nombre línea de producción" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={8}>
                    <Form.Item
                        hasFeedback
                        name="tipo"
                        label="Tipo:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el tipo" />
                    </Form.Item>
                </Col>

                <Col span={16}>
                    <Form.Item
                        hasFeedback
                        name="descripcionTipo"
                        label="Descripción Tipo:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir la descripción tipo" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={8}>
                    <Form.Item
                        hasFeedback
                        name="diasCaducidad"
                        label="Días Caducidad:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir los días de caducidad" />
                    </Form.Item>
                </Col>

                <Col span={16}>
                    <Form.Item
                        hasFeedback
                        name="textoFCaducidad"
                        label="Texto Fecha Caducidad:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el texto fecha de caducidad" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="condicionesConservacion"
                        label="Condiciones Conservación:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir condiciones de conservación" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="modoEmpleo"
                        label="Modo Empleo:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el modo de empleo" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="selectCatalogo"
                        label="Catálogo:"
                        rules={[
                            {
                                required: true,
                                message: "Campo obligatorio.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Por favor, seleccionar catálogo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {selectCatalogosUsuario}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={onChildrenDrawerCloseLineasProduccion}
                            style={{ marginRight: 8 }}
                        >
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Aceptar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};