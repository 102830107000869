export const sortArrayOfObjects = (array, prop, order) => {
    return array.sort((a, b) => {
      if (a[prop] > b[prop]) {
        return order.toUpperCase() === 'ASC' ? 1 : -1
      } else if (a[prop] < b[prop]) {
        return order.toUpperCase() === 'ASC' ? -1 : 1
      }
      return 0
    })
}

export const calculateTotalIngredients = (listIngredients, listRecipes, isByIngest) => {
  return listIngredients.map((ingredientRecipe) => {
      let totalByIngredient = 0
      let ingredientUniqueRecipe = {}

      listRecipes.foreach((ingredient, index) => {
        if(isByIngest){
          if (ingredientRecipe.codigo_ingrediente === ingredient.codigo_ingrediente && ingredientRecipe.codigo_proveedor === ingredient.codigo_proveedor && ingredientRecipe.ingesta === ingredient.ingesta) {
            totalByIngredient += ingredient.total
          }
        } else {
          if (ingredientRecipe.codigo_ingrediente === ingredient.codigo_ingrediente && ingredientRecipe.codigo_proveedor === ingredient.codigo_proveedor) {
            totalByIngredient += ingredient.total
          }
        }

        if (index === listRecipes.length - 1) {
          ingredientUniqueRecipe = {
            ...ingredientRecipe,
            total: Number(totalByIngredient).toFixed(2),
          }
          return ingredientUniqueRecipe
        }
      })
      return ingredientUniqueRecipe
    }
  )
}