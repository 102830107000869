"use strict";
import React from "react"
import { Form, Row, Col, Input, Button, Select } from "antd"

export const DrawerInsertarObsEspecialesForm = (props) => {
    const { enviarObsEspeciales, onChildrenDrawerCloseObsEspeciales, selectCatalogosUsuario } = props

    const [form] = Form.useForm();

    const onFinishInsertarObsEspeciales = (values) => {
        const nameObsEspeciales = values.nameObsEspeciales;
        const catalogo = values.selectCatalogo;

        enviarObsEspeciales(nameObsEspeciales, catalogo);
    };

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinishInsertarObsEspeciales}
            form={form}
        >
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameObsEspeciales"
                        label="Descripción Observaciones:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir la descripción de las observaciones" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="selectCatalogo"
                        label="Catálogo:"
                        rules={[
                            {
                                required: true,
                                message: "Campo obligatorio.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Por favor, seleccionar catálogo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {selectCatalogosUsuario}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={onChildrenDrawerCloseObsEspeciales}
                            style={{ marginRight: 8 }}
                        >
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Aceptar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};