import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Layout, Menu, Row, Breadcrumb, Spin, Modal, notification, Space, Form, Col, Button, Select } from 'antd'
import { database } from '../services/database.service'
import { InicioWeb } from '../menus/InicioWeb'
import BuscarPorPlato from '../menus/BuscarPorPlato'
import BuscarPorMenu from '../menus/BuscarPorMenu'
import ImprimirRecetasMenu from '../menus/ImprimirRecetasMenu'
import ImprimirRecetasBloque from '../menus/ImprimirRecetasBloque'
import ImprimirEtiquetas from '../menus/ImprimirEtiquetas/'
import GestionUsuarios from '../menus/GestionUsuario'
import GestionInformes from '../menus/GestionInformes'
import GestionClientes from '../menus/GestionClientes'

import {
  LogoutOutlined,
  HomeOutlined,
  CoffeeOutlined,
  TeamOutlined,
  PrinterOutlined,
  PieChartOutlined,
  CalendarOutlined,
  SearchOutlined,
  UserOutlined,
  SmileOutlined,
  QuestionCircleOutlined,
  FileSyncOutlined,
  BarcodeOutlined,
  IdcardOutlined,
} from '@ant-design/icons'
import moment from 'moment'

const Option = Select.Option
moment.locale('es')

const descriptivoWeb = 'CookBook - Consultas e Impresiones MenuPlanner v2.6.6.1'
const ALL_ROLLES_ACCEPTED = [1, 2, 3, 4]

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      users: [],
      employee: {},
      employees: [],
      collapsed: true,
      showCompras: false,
      showOperaciones: false,
      current: 'home',
      disabled: true,
      loadingSpingBuscarPlato: false,
      textoLoadingSpingBuscarPlato: '',
      visibleModalSeletImpresionRecetas: false,
      visibleModalSeletTipoMenu: false,
      mostrarConsultas: true,
      mostrarEtiquetas: true,
      mostrarImprimirRecetas: true,
      mostrarGUsuarios: true,
      showForAnyRole: false,
    }
  }

  /**
   * Sacame todo lo que tengas que sacar de la API antes de imprimir Pantalla Buscar Por Plato */
  componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem('user')),
      users: { loading: true },
      employee: JSON.parse(localStorage.getItem('employee')),
      employees: { loading: true },
      isLoaded: true,
      loadingSpingBuscarPlato: true,
      textoLoadingSpingBuscarPlato: 'Cargando centros asignados en JDEdwards...',
      mostrarConsultas: true,
      mostrarEtiquetas: true,
      mostrarImprimirRecetas: true,
      mostrarGUsuarios: true,
      showForAnyRole: false,
    })

    this.obtenerRolesUsuarioWebConi()
    this.removeCentrosUsuario()
  }

  openNotificationBienvenida = usuario => {
    notification.open({
      message: 'Mensaje de Bienvenida',
      description:
        'Hola, ' + usuario + '. Has accedido a la web de Consultas de Recetas, Menús e Impresiones de Menuplanner.',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      duration: 9,
    })
  }

  obtenerRolesUsuarioWebConi = async () => {
    try {
      const usuarioNotificacion = JSON.parse(localStorage.getItem('user'))
      this.openNotificationBienvenida(usuarioNotificacion.name)

      const res = await database.obtenerUsuarioRoles(JSON.parse(localStorage.getItem('user')).sAMAccountName)

      if (!res) {
        throw new Error('Accesso Denegado.')
      }

      let respEliminarCentrosUsuario = res.response
      let arrayUsuarioRolesAsignados = respEliminarCentrosUsuario.message
      let rol

      if (respEliminarCentrosUsuario.status === 'success' && arrayUsuarioRolesAsignados.length != 0) {
        for (let i = 0; i < arrayUsuarioRolesAsignados.length; i++) {
          rol = arrayUsuarioRolesAsignados[i].ID_ROL

          if (ALL_ROLLES_ACCEPTED.includes(rol)) {
            this.setState({ showForAnyRole: true })
          }

          if (rol == 1 || rol == 2 || rol == 3) {
            this.setState({ mostrarConsultas: false })
          }

          if (rol == 1 || rol == 4) {
            this.setState({ mostrarEtiquetas: false })
          }

          if (rol == 1 || rol == 2) {
            this.setState({ mostrarImprimirRecetas: false })
          }

          if (rol == 1) {
            this.setState({ mostrarGUsuarios: false })
          }
        }

        this.setState({
          loadingSpingBuscarPlato: false,
          textoLoadingSpingBuscarPlato: '',
        })
      } else {
        this.setState({
          loadingSpingBuscarPlato: false,
          textoLoadingSpingBuscarPlato: '',
        })

        Modal.warning({
          title: 'Advertencia',
          content:
            'El usuario ' +
            JSON.parse(localStorage.getItem('user')).name +
            ' no tiene roles asignados para usar CookBook. Por favor contactar con el Administrador.',
        })
      }
    } catch (e) {
      this.setState({
        loadingSpingBuscarPlato: false,
        textoLoadingSpingBuscarPlato: '',
      })

      Modal.error({
        title: 'Error',
        content: `Ha ocurrido el siguiente error durante la actualización de los centros en MongoDB: ${e.message.toUpperCase()}`,
        onOk: () => {
          localStorage.clear()
          window.location.replace(`${window.location.origin}/login`)
        },
      })
    }
  }

  removeCentrosUsuario = () => {
    try {
      database
        .eliminarCentrosUsuarioMongoDB(JSON.parse(localStorage.getItem('user')).sAMAccountName.toUpperCase())
        .then(res => {
          let respEliminarCentrosUsuario = res.response
          if (respEliminarCentrosUsuario.status === 'success') {
            this.setState({ loadingSpingBuscarPlato: false })
            this.grabarCentrosUsuario()
          } else {
            Modal.error({
              title: 'Error',
              content:
                'No se ha podido actualizar los centros asigandos en MongoDB. Ha ocurrido el siguiente error: ' +
                listadoCentrosInsertados.message,
            })
            this.setState({
              loadingSpingBuscarPlato: false,
              textoLoadingSpingBuscarPlato: '',
            })
          }
        })
    } catch (e) {
      this.setState({
        loadingSpingBuscarPlato: false,
        textoLoadingSpingBuscarPlato: '',
      })

      Modal.warning({
        title: 'Error',
        content: 'Ha ocurrido el siguiente duarnte la actualización de los centros en MongoDB: ' + e,
      })
    }
  }

  grabarCentrosUsuario = () => {
    try {
      database.getCentrosUsuarios(JSON.parse(localStorage.getItem('user')).sAMAccountName).then(res => {
        let listadoCentrosUsuario = res.response

        if (listadoCentrosUsuario.length != 0) {
          try {
            database.insertarCentrosusuarioMongoDB(listadoCentrosUsuario).then(res => {
              let listadoCentrosInsertados = res.response

              if (listadoCentrosInsertados.status === 'success') {
                this.setState({
                  loadingSpingBuscarPlato: false,
                  textoLoadingSpingBuscarPlato: '',
                })
              } else {
                this.setState({
                  loadingSpingBuscarPlato: false,
                  textoLoadingSpingBuscarPlato: '',
                })

                Modal.error({
                  title: 'Error',
                  content:
                    'No se ha podido grabar los centros asigandos en MongoDB. Ha ocurrido el siguiente error: ' +
                    listadoCentrosInsertados.message,
                })
              }
            })
          } catch (e) {
            this.setState({
              loadingSpingBuscarPlato: false,
              textoLoadingSpingBuscarPlato: '',
            })
            Modal.warning({
              title: 'Error',
              content: 'Ha ocurrido el siguiente error al grabar los centros en mongoDB: ' + e,
            })
          }
        } else {
          this.setState({
            loadingSpingBuscarPlato: false,
            textoLoadingSpingBuscarPlato: '',
          })
          Modal.warning({
            title: 'Advertencia',
            content: 'No tienes centros asigandos en JDEdwards.',
          })
        }
      })
    } catch (e) {
      this.setState({
        loadingSpingBuscarPlato: false,
        textoLoadingSpingBuscarPlato: '',
      })
      Modal.warning({
        title: 'Error',
        content: 'Ha ocurrido el siguiente duarnte la autenticación: ' + e,
      })
    }
  }

  handleClick = e => {
    this.setState({
      current: e.key,
    })
  }

  onCollapse = collapsed => {
    this.setState({ collapsed })
  }

  updateDisabled = () => {
    this.setState({
      disabled: false,
    })
  }

  changeTab = tabName => {
    if (tabName == 'InicioWeb') {
      ReactDOM.render(<InicioWeb />, document.getElementById('tab'))
      ReactDOM.render(
        <div>
          {' '}
          <h5
            style={{
              textAlign: 'left',
              font: 'Oswald,sans-serif',
              color: '#222',
              line: '.5em',
              marginBottom: '.8em',
              backgroundColor: 'transparent',
            }}
          >
            <em>{descriptivoWeb}</em>
          </h5>
        </div>,
        document.getElementById('title')
      )
    } else if (tabName == 'BuscaCentro') {
      ReactDOM.render(<BuscaCentro />, document.getElementById('tab'))
      ReactDOM.render('Busca Centro', document.getElementById('title'))
    } else if (tabName == 'BuscarPorPlato') {
      ReactDOM.render(<BuscarPorPlato />, document.getElementById('tab'))
      ReactDOM.render(
        <div>
          {' '}
          <h5
            style={{
              textAlign: 'left',
              font: 'Oswald,sans-serif',
              color: '#222',
              line: '.5em',
              marginBottom: '.8em',
              backgroundColor: 'transparent',
            }}
          >
            <em>CookBook</em> - Búsqueda por Plato
          </h5>
        </div>,
        document.getElementById('title')
      )
    } else if (tabName == 'BuscarPorMenu') {
      ReactDOM.render(<BuscarPorMenu />, document.getElementById('tab'))
      ReactDOM.render(
        <div>
          {' '}
          <h5
            style={{
              textAlign: 'left',
              font: 'Oswald,sans-serif',
              color: '#222',
              line: '.5em',
              marginBottom: '.8em',
              backgroundColor: 'transparent',
            }}
          >
            <em>CookBook</em> - Búsqueda por Menú
          </h5>
        </div>,
        document.getElementById('title')
      )
    } else if (tabName == 'ImprimirRecetasMenu') {
      if (this.state.visibleModalSeletImpresionRecetas === true) {
        ReactDOM.render(<ImprimirRecetasMenu />, document.getElementById('tab'))
        ReactDOM.render(
          <div>
            {' '}
            <h5
              style={{
                textAlign: 'left',
                font: 'Oswald,sans-serif',
                color: '#222',
                line: '.5em',
                marginBottom: '.8em',
                backgroundColor: 'transparent',
              }}
            >
              <em>CookBook</em> - Imprimir Recetas por Menú
            </h5>
          </div>,
          document.getElementById('title')
        )

        this.setState({
          visibleModalSeletImpresionRecetas: false,
        })
      } else {
        this.setState({
          visibleModalSeletImpresionRecetas: true,
        })
      }
    } else if (tabName == 'ImprimirRecetasBloque') {
      if (this.state.visibleModalSeletImpresionRecetas === true) {
        ReactDOM.render(<ImprimirRecetasBloque />, document.getElementById('tab'))
        ReactDOM.render(
          <div>
            {' '}
            <h5
              style={{
                textAlign: 'left',
                font: 'Oswald,sans-serif',
                color: '#222',
                line: '.5em',
                marginBottom: '.8em',
                backgroundColor: 'transparent',
              }}
            >
              <em>CookBook</em> - Imprimir Recetas por Bloques
            </h5>
          </div>,
          document.getElementById('title')
        )

        this.setState({
          visibleModalSeletImpresionRecetas: false,
        })
      } else {
        this.setState({
          visibleModalSeletImpresionRecetas: true,
        })
      }
    } else if (tabName == 'ImprimirEtiquetas') {
      ReactDOM.render(<ImprimirEtiquetas />, document.getElementById('tab'))
      ReactDOM.render(
        <div>
          {' '}
          <h5
            style={{
              textAlign: 'left',
              font: 'Oswald,sans-serif',
              color: '#222',
              line: '.5em',
              marginBottom: '.8em',
              backgroundColor: 'transparent',
            }}
          >
            <em>CookBook</em> - Imprimir Etiquetas de los Platos
          </h5>
        </div>,
        document.getElementById('title')
      )
    } else if (tabName == 'GestionClientes') {
      ReactDOM.render(<GestionClientes />, document.getElementById('tab'))
      ReactDOM.render(
        <div>
          {' '}
          <h5
            style={{
              textAlign: 'left',
              font: 'Oswald,sans-serif',
              color: '#222',
              line: '.5em',
              marginBottom: '.8em',
              backgroundColor: 'transparent',
            }}
          >
            <em>CookBook</em> - Gestión de Clientes
          </h5>
        </div>,
        document.getElementById('title')
      )
    } else if (tabName == 'GestionInformes') {
      ReactDOM.render(<GestionInformes />, document.getElementById('tab'))
      ReactDOM.render(
        <div>
          {' '}
          <h5
            style={{
              textAlign: 'left',
              font: 'Oswald,sans-serif',
              color: '#222',
              line: '.5em',
              marginBottom: '.8em',
              backgroundColor: 'transparent',
            }}
          >
            <em>CookBook</em> - Gestión de Informes
          </h5>
        </div>,
        document.getElementById('title')
      )
    } else if (tabName == 'GestionUsuarios') {
      ReactDOM.render(<GestionUsuarios />, document.getElementById('tab'))
      ReactDOM.render(
        <div>
          {' '}
          <h5
            style={{
              textAlign: 'left',
              font: 'Oswald,sans-serif',
              color: '#222',
              line: '.5em',
              marginBottom: '.8em',
              backgroundColor: 'transparent',
            }}
          >
            <em>CookBook</em> - Gestión de Usuarios
          </h5>
        </div>,
        document.getElementById('title')
      )
    }
  }

  /**
   * Cancelar la modal Seleccionar si vas a imprimir por Menú o por bloques las Recetas.
   */
  handleCancelModalSeletImpresionRecetas = () => {
    this.setState({
      visibleModalSeletImpresionRecetas: false,
    })

    let mostrar = 'InicioWeb'
    this.changeTab(mostrar)
  }

  /**
   * Cancelar la modal Seleccionar si vas a imprimir por Menú o por bloques las Recetas.
   */
  handleCancelModalSeletTipoMenu = () => {
    this.setState({
      visibleModalSeletTipoMenu: false,
    })

    let mostrar = 'InicioWeb'
    this.changeTab(mostrar)
  }

  render() {
    const {
      error,
      isLoaded,
      loadingSpingBuscarPlato,
      textoLoadingSpingBuscarPlato,
      user,
      visibleModalSeletImpresionRecetas,
      visibleModalSeletTipoMenu,
      mostrarConsultas,
      mostrarImprimirRecetas,
      mostrarEtiquetas,
      mostrarGUsuarios,
      showForAnyRole,
    } = this.state
    const { Header, Content, Footer, Sider } = Layout
    const SubMenu = Menu.SubMenu

    const SeleccionarTipoMenuForm = () => {
      const [form] = Form.useForm()

      const onFinish = values => {
        let tipoMenuSeleccionado = values.selectTipoMenu
        this.changeTab(tipoMenuSeleccionado)
      }

      return (
        <Form form={form} name='advanced_search' className='ant-advanced-search-form' onFinish={onFinish}>
          <Row gutter={24}>
            <Col key={4} span={24}>
              <Form.Item
                hasFeedback
                name='selectTipoMenu'
                rules={[
                  {
                    required: true,
                    message: 'Input something!',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Seleccionar Tipo Menú'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key='1' value='BuscarPorMenu'>
                    Buscar Menú Mensual
                  </Option>
                  <Option key='2' value='BuscarPorMenuCiclo'>
                    Buscar Menú de Ciclo
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'right',
              }}
            >
              <Button type='primary' htmlType='submit'>
                Aceptar
              </Button>
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => {
                  form.resetFields()
                }}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Form>
      )
    }

    //Form para seleccionar si imprimir recetas por Menú o por bloques.

    const SeleccionarTipoImpresionRecetasForm = () => {
      const [form] = Form.useForm()

      const onFinish = values => {
        let tipoImpresionSeleccionado = values.selectTipoImpresion
        this.changeTab(tipoImpresionSeleccionado)
      }

      return (
        <Form form={form} name='advanced_search' className='ant-advanced-search-form' onFinish={onFinish}>
          <Row gutter={24}>
            <Col key={4} span={24}>
              <Form.Item
                hasFeedback
                name='selectTipoImpresion'
                rules={[
                  {
                    required: true,
                    message: 'Input something!',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Seleccionar Impresión'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key='1' value='ImprimirRecetasMenu'>
                    Imprimir Recetas por Menú
                  </Option>
                  <Option key='2' value='ImprimirRecetasBloque'>
                    Imprimir Recetas por Bloques
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'right',
              }}
            >
              <Button type='primary' htmlType='submit'>
                Aceptar
              </Button>
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={this.handleCancelModalSeletImpresionRecetas}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Form>
      )
    }

    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div> Cargando ... </div>
    } else {
      return (
        <div>
          <Spin spinning={loadingSpingBuscarPlato} delay={500} tip={textoLoadingSpingBuscarPlato}>
            <Layout style={{ minHeight: '100vh' }}>
              <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                <div className='logo'>
                  <img src='src/assets/images/CompassGroupNegativoES600x300.png' />
                </div>

                <Menu theme='dark' selectedKeys={[this.state.current]} mode='inline' onClick={this.handleClick}>
                  <Menu.Item key='1' onClick={() => this.changeTab('InicioWeb')} name='InicioWeb'>
                    <HomeOutlined />
                    <span>Inicio</span>
                  </Menu.Item>

                  <SubMenu
                    disabled={mostrarConsultas}
                    key='sub1'
                    title={
                      <span>
                        {' '}
                        <SearchOutlined />
                        <span>Consulta Menús/Recetas</span>
                      </span>
                    }
                  >
                    <Menu.Item key='2' onClick={() => this.changeTab('BuscarPorPlato')} name='BuscarPorPlato'>
                      <CoffeeOutlined />
                      <span>Buscar por Plato</span>
                    </Menu.Item>
                    <Menu.Item key='3' onClick={() => this.changeTab('BuscarPorMenu')} name='BuscarPorMenu'>
                      <CalendarOutlined />
                      <span>Buscar por Menú</span>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    disabled={
                      mostrarImprimirRecetas == false || mostrarEtiquetas == false || mostrarGUsuarios == false
                        ? false
                        : true
                    }
                    key='sub2'
                    title={
                      <span>
                        {' '}
                        <PrinterOutlined />
                        <span>Gestión de Impresión</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      disabled={mostrarImprimirRecetas}
                      key='4'
                      onClick={() => this.changeTab('ImprimirRecetasMenu')}
                      name='ImprimirRecetasMenu'
                    >
                      <PieChartOutlined />
                      <span>Imprimir Recetas</span>
                    </Menu.Item>

                    <Menu.Item
                      disabled={mostrarEtiquetas}
                      key='5'
                      onClick={() => this.changeTab('ImprimirEtiquetas')}
                      name='ImprimirEtiquetas'
                    >
                      <BarcodeOutlined />
                      <span>Imprimir Etiquetas</span>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item
                    disabled={!showForAnyRole}
                    key='6'
                    onClick={() => this.changeTab('GestionClientes')}
                    name='GestionClientes'
                  >
                    <IdcardOutlined />
                    <span>Gestión de Clientes</span>
                  </Menu.Item>

                  <Menu.Item
                    disabled={!showForAnyRole}
                    key='7'
                    onClick={() => (window.location.href = '/v2/#/calculator')}
                    name='GestionInformes'
                  >
                    <FileSyncOutlined />
                    <span>Gestión de Informes</span>
                  </Menu.Item>

                  <Menu.Item
                    disabled={mostrarGUsuarios}
                    key='8'
                    onClick={() => this.changeTab('GestionUsuarios')}
                    name='GestionUsuarios'
                  >
                    <TeamOutlined />
                    <span>Gestión de Usuarios</span>
                  </Menu.Item>
                </Menu>
              </Sider>

              <Layout className='site-layout'>
                <Header style={{ background: '#fff', padding: 0 }}>
                  <div>
                    <Link to='/login'>
                      <p className='head_name' style={{ color: '#364d79' }}>
                        <LogoutOutlined type='logout' />
                        <strong> Salir</strong>
                      </p>
                    </Link>
                    <p className='head_name_user'>
                      Hola, <UserOutlined /> {user.name}
                    </p>
                    <p className='head_name'>
                      <a
                        className='enlace-fondo-blanco'
                        href='./src/assets/documents/manual_CookBook.pdf'
                        target='blank'
                      >
                        <QuestionCircleOutlined />
                        <strong> Ayuda</strong>
                      </a>
                    </p>
                  </div>
                </Header>

                <Content style={{ margin: '0 16px' }}>
                  <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item id='title'>
                      <div>
                        <h5
                          style={{
                            textAlign: 'left',
                            font: 'Oswald,sans-serif',
                            color: '#222',
                            line: '.5em',
                            marginBottom: '.8em',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <em>{descriptivoWeb}</em>
                        </h5>
                      </div>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div style={{ padding: 24, background: '#fff' }} id='tab'>
                    <InicioWeb />
                  </div>
                </Content>

                <Footer style={{ textAlign: 'center' }}>
                  ©{' '}
                  <a className='enlace-fondo-blanco' href={process.env.COMPASS_LANDING_URL} target='blank'>
                    Compass Group España
                  </a>{' '}
                  {moment(Date.now()).format('YYYY')}. Todos los derechos reservados.
                </Footer>
              </Layout>
            </Layout>

            <Modal
              visible={visibleModalSeletImpresionRecetas}
              title='Seleccionar Impresión de Recetas'
              maskClosable={false}
              width={420}
              footer={null}
              closable={true}
              onCancel={this.handleCancelModalSeletImpresionRecetas}
            >
              <Space direction='vertical'>
                <p>Debes seleccionar si la impresión de las recetas es por menú o por bloques.</p>
                <SeleccionarTipoImpresionRecetasForm />
              </Space>
            </Modal>

            <Modal
              visible={visibleModalSeletTipoMenu}
              title='Seleccionar Tipo de Menú'
              width={420}
              footer={null}
              closable={true}
              onCancel={this.handleCancelModalSeletTipoMenu}
            >
              <Space direction='vertical'>
                <p>Debes seleccionar si el menú a visualizar es mensual o de ciclo.</p>
                <SeleccionarTipoMenuForm />
              </Space>
            </Modal>
          </Spin>
        </div>
      )
    }
  }
}

export default HomePage
