"use strict";
import React from "react"
import { Button, Divider, Tooltip, Drawer } from "antd"
import { EditOutlined, FormOutlined } from "@ant-design/icons"
import { DrawerInsertarLineasProduccionForm } from "./DrawerInsertarLineasProduccionForm";
import { DrawerModificarLineasProduccionForm } from "./DrawerModificarLineasProduccionForm";
import { EliminarDatosLineasDeProduccion } from "./EliminarDatosLineasDeProduccion";

const textoAnadirLineasProduccion = <span>Añadir Líneas de Producción</span>
const textoEditarLineasProduccion = <span>Editar Líneas de Producción</span>

export const DatosLineasDeProduccionBotonera = (props) => {
    const {
        enviarLineasProduccion,
        onChildrenDrawerCloseLineasProduccion,
        onChildrenDrawerCloseModificarLineasProduccion,
        onCloseDrawerLineasProduccion,
        selectCatalogosUsuario,
        showChildrenDrawerLineasProduccion,
        showChildrenDrawerModificarLineasProduccion,
        showDrawerLineasProduccion,
        state,
        updateStates
    } = props

    return (
        <Drawer
            title="Líneas de Producción"
            width={"50%"}
            closable={false}
            onClose={onCloseDrawerLineasProduccion}
            visible={state.visibleDrawerLineasProduccion}
        >
            <Tooltip
                placement="topLeft"
                title={textoAnadirLineasProduccion}
            >
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerLineasProduccion}
                >
                    <FormOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />

            <Tooltip
                placement="topLeft"
                title={textoEditarLineasProduccion}
            >
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerModificarLineasProduccion}
                >
                    <EditOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />

            <EliminarDatosLineasDeProduccion
                showDrawerLineasProduccion={showDrawerLineasProduccion}
                state={state}
            />

            <Drawer
                title="Añadir Líneas de Producción"
                width={450}
                closable={false}
                onClose={onChildrenDrawerCloseLineasProduccion}
                visible={state.childrenDrawerLineasProduccion}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DrawerInsertarLineasProduccionForm
                    enviarLineasProduccion={enviarLineasProduccion}
                    onChildrenDrawerCloseLineasProduccion={onChildrenDrawerCloseLineasProduccion}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                />
            </Drawer>

            <Drawer
                title="Modificar Líneas de Producción"
                width={450}
                closable={false}
                onClose={onChildrenDrawerCloseModificarLineasProduccion}
                visible={state.childrenDrawerModificarLineasProduccion}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DrawerModificarLineasProduccionForm
                    state={state}
                    updateStates={updateStates}
                    onChildrenDrawerCloseModificarLineasProduccion={onChildrenDrawerCloseModificarLineasProduccion}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                />
            </Drawer>

            <div id="tablaLP"></div>
        </Drawer>
    )
}