"use strict";
import React from "react"
import { Modal, } from "antd"
import { database } from "../../services/database.service"
import moment from "moment"

moment.locale("es")

const colorAdvertenciaTextArea = {
  textAlign: "center",
  border: "2px solid red",
}
const colorTabPanelAdvertenciaRed = (
  <span style={{ color: "red" }}>Advertencia</span>
)
const colorTabPanelAdvertenciaGris = (
  <span style={{ color: "rgba(0, 0, 0, 0.25)" }}>Advertencia</span>
)

export const getDatosPlatoPrincipalGuarnicionSeleccionada = (updateStates, state) => {

  initializeStates(updateStates)

  const codRecetaPlatoPrincipal =
    state.selectedRowKeysRecetas.length === 1 ? state.selectedRowKeysRecetas[0] : 'null'

  const codrecetaGuarnicion =
    state.selectedRowKeysGuarnicion.length === 1 ? state.selectedRowKeysGuarnicion[0] : 'null'

  database.obtenerDatosPlatoPrincipalGuarnicion(codRecetaPlatoPrincipal, codrecetaGuarnicion).then(
    (res) => {
      const listaPrincipalGuarnicion = res.response

      if (listaPrincipalGuarnicion.status === "success") {

        const { datosPlatoPrincipal, datosGuarnicion } = crearArrayPrincipales(listaPrincipalGuarnicion)

        let advertenciaGuarnicon = (datosGuarnicion.length !== 0 && datosGuarnicion[0].ALERTA_INGRED === 1) ?
          advertenciaGuarnicon = datosGuarnicion[0].TEXTO_ALERTA :
          ""
        actualizarPuedeContenerState(datosPlatoPrincipal, datosGuarnicion, updateStates)

        let advertenciaPrincipal = ""
        if (datosPlatoPrincipal.length !== 0 && datosPlatoPrincipal[0].ALERTA_INGRED === 1) {
          advertenciaPrincipal = datosPlatoPrincipal[0].TEXTO_ALERTA
        }

        let habilitarTabPanelAdvertencia = false
        let colorTabPanel = colorTabPanelAdvertenciaRed

        if (advertenciaPrincipal.length === 0 && advertenciaGuarnicon.length === 0) {
          habilitarTabPanelAdvertencia = true
          colorTabPanel = colorTabPanelAdvertenciaGris
        }

        const alertaGuarnicion = datosGuarnicion.length === 0
          ? ""
          : datosGuarnicion[0].ALERTA_INGRED

        let alergenosPrincipal = ""
        if (datosPlatoPrincipal.length !== 0 && datosPlatoPrincipal[0].ALERGENOS !== null) {
          alergenosPrincipal = datosPlatoPrincipal[0].ALERGENOS;
        }

        const aleGuarnicion =
          datosGuarnicion.length == 0 ? "" : datosGuarnicion[0].ALERGENOS;

        let alergenosGuarnicion =
          aleGuarnicion == null ? "" : aleGuarnicion;

        const nomeGuarnicion =
          datosGuarnicion.length == 0 ? "" : datosGuarnicion[0].NOMBRE;

        const ingGuarnicion = datosGuarnicion.length === 0
          ? ""
          : datosGuarnicion[0].SUBINGREDIENTES;

        const ingPrincipalTotal = datosPlatoPrincipal.length === 0
          ? ""
          : `INGREDIENTES PRINCIPAL:${datosPlatoPrincipal[0].SUBINGREDIENTES}`

        let alerTotal = ""
        if (alergenosPrincipal.length !== 0 && alergenosGuarnicion.length !== 0) {
          alerTotal = (alergenosPrincipal.length === 0 || alergenosPrincipal + alergenosGuarnicion.length === 0)
            ? ""
            : `, ${alergenosGuarnicion}`
        } else {
          alerTotal = alergenosPrincipal.length !== 0
            ? alergenosPrincipal
            : alergenosGuarnicion
        }

        const contiene = alerTotal

        const pesoNetoPrincipal =
          datosPlatoPrincipal.length == 0 ? 0 : datosPlatoPrincipal[0].PESON

        const pesoNetoGuarnicion =
          datosGuarnicion.length == 0 ? 0 : datosGuarnicion[0].PESON

        let colorAdvertenciaPrincipalTA = ''
        if (datosPlatoPrincipal[0]?.ALERTA_INGRED === 1) {
          colorAdvertenciaPrincipalTA = colorAdvertenciaTextArea
        }

        updateStates({
          valueTextAreaIngredientesAlergenosTotal:
            `${ingPrincipalTotal}
            ${ingGuarnicion}
            ${contiene}`,
          ingredientesPrincipalEtiqueta:
            datosPlatoPrincipal.length === 0 ? "" : datosPlatoPrincipal[0].SUBINGREDIENTES,
          ingredientesGuarnicionEtiqueta: ingGuarnicion ?? '',
          nombrePrincipal: datosPlatoPrincipal.length === 0 ? "" : datosPlatoPrincipal[0].NOMBRE,
          nombreGuarnicion: nomeGuarnicion,
          valueTextAreaAlergenosPrincipal: alergenosPrincipal,
          valueTextAreaGuarnicion: ingGuarnicion ?? '',
          deshabilitarBotonEditarIngGuarnicion: ingGuarnicion?.length > 0 ? false : true,
          deshabilitarBotonEditarAlergenosGuarnicion: alergenosGuarnicion.length > 0 ? false : true,
          valueTextAreaAlergenosGuarnicion: alergenosGuarnicion,
          valuePesoNetoTotal: pesoNetoPrincipal + pesoNetoGuarnicion,
          valueTextAreaIngredientesTotal: datosPlatoPrincipal.length === 0 ? "" : datosPlatoPrincipal[0].SUBINGREDIENTES,
          valueTextAreaAdvertenciaPrincipal: advertenciaPrincipal,
          valueTextAreaAdvertenciaGuarnicion: advertenciaGuarnicon,
          colorAdvertenciaPrincipalTA: colorAdvertenciaPrincipalTA,
          colorAdvertenciaGuarnicionTA: alertaGuarnicion === 1 ? colorAdvertenciaTextArea : "",
          colorTabPanelAdvertencia: colorTabPanel,
          disabledTabPanelAdevrtencia: habilitarTabPanelAdvertencia,
        })

        const calculosNutricionalesReceta = generateArrayCalculosNutricionales(
          datosPlatoPrincipal.length, datosGuarnicion, datosPlatoPrincipal
        ) 
        
        const calculosNutricionalesReceta100 = generateArrayCalculosNutricionales100(
          datosPlatoPrincipal.length, datosGuarnicion, datosPlatoPrincipal
        )

        updateStates({
          infoNutricionalReceta: calculosNutricionalesReceta,
          infoNutricionalReceta100: calculosNutricionalesReceta100,
        });

      } else {
        Modal.error({
          title: "Error",
          content: "No se pudo recuperar datos de la receta para mostrar.",
        });
        updateStates({
          visible: false,
        });
      }

    })
}

const initializeStates = (updateStates) =>{
  updateStates({
    valueTextAreaIngredientesAlergenosTotal: "",
    nombrePrincipal: "",
    nombreGuarnicion: "",
    valueTextAreaAlergenosPrincipal: "",
    valueTextAreaAlergenosGuarnicion: "",
    ingredientesGuarnicionEtiqueta: "",
    ingredientesPrincipalEtiqueta: "",
    valueTextAreaIngredientesTotal: "",
    valueTextAreaGuarnicion: "",
    valueTextAreaAdvertenciaPrincipal: "",
    valueTextAreaAdvertenciaGuarnicion: "",
    colorAdvertenciaPrincipalTA: "",
    colorAdvertenciaGuarnicionTA: "",
    colorTabPanelAdvertencia: "",
    disabledTabPanelAdevrtencia: "",
  })
}

const crearArrayPrincipales = (lista) => {
  const datosPlatoPrincipal = []
  const datosGuarnicion = []

  lista.message.forEach(element => {
    const objAux = {
      CATALOGO: element.CATALOGO,
      CODIGO: element.CODIGO,
      NOMBRE: element.NOMBRE,
      PESOT: element.PESOT,
      PESON: element.PESON,
      CALORIASR: element.CALORIASR,
      KJULIOSR: element.KJULIOSR,
      PROTEINASR: element.PROTEINASR,
      GRASAS: element.GRASAS,
      SATURADAS: element.SATURADAS,
      MONOINSATURADAS: element.MONOINSATURADAS,
      POLIINSATURADAS: element.POLIINSATURADAS,
      HIDRATOSR: element.HIDRATOSR,
      FIBRA_R: element.FIBRA_R,
      AZUCARES_R: element.AZUCARES_R,
      SAL_R: element.SAL_R,
      PLATO: element.PLATO,
      ALERGENOS: element.ALERGENOS,
      SUBINGREDIENTES: element.SUBINGREDIENTES,
      ELABORACION_FRIO: element.ELABORACION_FRIO,
      ALMIDON: element.ALMIDON,
      POLIALCOHOLES: element.POLIALCOHOLES,
      ALERTA_NOMBRE: element.ALERTA_NOMBRE,
      ALERTA_INGRED: element.ALERTA_INGRED,
      TEXTO_ALERTA: element.TEXTO_ALERTA,
      PUEDE_CONTENER: element.PUEDE_CONTENER,
      CONTIENE_STRAZAS: element.CONTIENE_STRAZAS,
    }

    element.PLATO != "GUARNICION" ? datosPlatoPrincipal.push(objAux) : datosGuarnicion.push(objAux)
  })

  return { datosPlatoPrincipal, datosGuarnicion }
}

const actualizarPuedeContenerState = (datosPlatoPrincipal, datosGuarnicion, updateStates) => {
  if (datosPlatoPrincipal.length != 0 && datosGuarnicion.length != 0) {

    const alergenosPrincipal = datosPlatoPrincipal[0].CONTIENE_STRAZAS === null
      ? ""
      : datosPlatoPrincipal[0].CONTIENE_STRAZAS

    const alergenosGuarnicion = datosGuarnicion[0].CONTIENE_STRAZAS === null
      ? ""
      : datosGuarnicion[0].CONTIENE_STRAZAS

    const arrayConRepetidosAlergenos = alergenosGuarnicion.concat(",").concat(alergenosPrincipal).split(",")

    const arrayConRepetidos = datosGuarnicion[0].PUEDE_CONTENER
      .concat(",")
      .concat(datosPlatoPrincipal[0].PUEDE_CONTENER)
      .split(",")

    let distintos = [...new Set(arrayConRepetidos)]

    distintos = distintos.filter(
      (item) => !arrayConRepetidosAlergenos.includes(item.trim())
    )

    updateStates({ puedeContenerState: distintos });
  } else {

    updateStates({
      puedeContenerState: datosPlatoPrincipal.length != 0 ?
        datosPlatoPrincipal[0].PUEDE_CONTENER :
        datosGuarnicion[0].PUEDE_CONTENER,
    })

  }
}

const calculosNutricionalesRecetaAux = [
  {
    key: "1",
    descripcionNutrientes: "nPesoT",
    nombreProp: "PESOT"
  },
  {
    key: "2",
    descripcionNutrientes: "nPesoN",
    nombreProp: "PESON"
  },
  {
    key: "3",
    descripcionNutrientes: "nCaloriasR",
    nombreProp: "CALORIASR"
  },
  {
    key: "4",
    descripcionNutrientes: "nKjuliosR",
    nombreProp: "KJULIOSR"
  },
  {
    key: "5",
    descripcionNutrientes: "nProteinasR",
    nombreProp: "PROTEINASR"
  },
  {
    key: "6",
    descripcionNutrientes: "nGrasas",
    nombreProp: "GRASAS"
  },
  {
    key: "7",
    descripcionNutrientes: "nSaturadas",
    nombreProp: "SATURADAS"
  },
  {
    key: "8",
    descripcionNutrientes: "nMonoinsaturados",
    nombreProp: "MONOINSATURADAS"
  },
  {
    key: "9",
    descripcionNutrientes: "nPolinsaturados",
    nombreProp: "POLIINSATURADAS"
  },
  {
    key: "10",
    descripcionNutrientes: "nHidratosR",
    nombreProp: "HIDRATOSR"
  },
  {
    key: "11",
    descripcionNutrientes: "nFibraR",
    nombreProp: "FIBRA_R"
  },
  {
    key: "12",
    descripcionNutrientes: "nAzucaresR",
    nombreProp: "AZUCARES_R"
  },
  {
    key: "13",
    descripcionNutrientes: "nSalR",
    nombreProp: "SAL_R"
  },
  {
    key: "14",
    descripcionNutrientes: "nAlmidónR",
    nombreProp: "ALMIDON"
  },
  {
    key: "15",
    descripcionNutrientes: "nPolialcoholesR",
    nombreProp: "POLIALCOHOLES"
  },
]

const generateArrayCalculosNutricionales = (datosPlatoPrincipalLength, datosGuarnicion, datosPlatoPrincipal) => {
  return calculosNutricionalesRecetaAux.map(({ key, descripcionNutrientes, nombreProp }) => {
    let elementAux = {
      key,
      descripcionNutrientes,
      principal: datosPlatoPrincipalLength === 0 ? "" : Number(datosPlatoPrincipal[0][nombreProp].toFixed(2)),
      guarnicion: datosGuarnicion[0] == null ? "" : Number(datosGuarnicion[0][nombreProp].toFixed(2)),
      total: "",
    }

    if(datosPlatoPrincipalLength === 0){

      elementAux.total = ( descripcionNutrientes === 'nPesoT' || descripcionNutrientes === 'nPesoN' )
        ? datosGuarnicion[0][nombreProp].toFixed(2)
        : datosGuarnicion[0] == null
          ? ""
          : datosGuarnicion[0][nombreProp].toFixed(2)

    }else{

      elementAux.total = datosGuarnicion[0] == null
        ? Number( datosPlatoPrincipal[0][nombreProp].toFixed(2) )
        : Number( ( datosPlatoPrincipal[0][nombreProp] + datosGuarnicion[0][nombreProp] ).toFixed(2) )

    }

    return elementAux

  })
}

const generateArrayCalculosNutricionales100 = (datosPlatoPrincipalLength, datosGuarnicion, datosPlatoPrincipal) => {
  return calculosNutricionalesRecetaAux.slice(2).map(({ key, descripcionNutrientes, nombreProp }) => {
    let elementAux = {
      key,
      descripcionNutrientes,
      principal: datosPlatoPrincipalLength === 0 
        ? "" 
        : Number(( (datosPlatoPrincipal[0][nombreProp] / datosPlatoPrincipal[0].PESOT) * 100 ).toFixed(2)),
      guarnicion: datosGuarnicion[0] == null
        ? ""
        : Number(( (datosGuarnicion[0][nombreProp] / datosGuarnicion[0].PESOT) * 100 ).toFixed(2) ),
      total: "",
    }

    if(datosPlatoPrincipalLength === 0){

      elementAux.total = datosGuarnicion[0] == null
        ? Number(( (datosPlatoPrincipal[0][nombreProp] / datosPlatoPrincipal[0].PESOT) * 100 ).toFixed(2))
        : Number(( datosGuarnicion[0][nombreProp] / datosGuarnicion[0].PESOT) * 100 ).toFixed(2)

    }else{

      elementAux.total = datosGuarnicion[0] == null
        ? Number(( (datosPlatoPrincipal[0][nombreProp] / datosPlatoPrincipal[0].PESOT) * 100 ).toFixed(2))
        : Number(
          ( 
            (datosPlatoPrincipal[0][nombreProp] / datosPlatoPrincipal[0].PESOT) * 100 + 
            (datosGuarnicion[0][nombreProp] / datosGuarnicion[0].PESOT) 
            * 100
          ).toFixed(2)
        )

    }

    return elementAux
  })
}
