import {
  ALLERGENS,
  ARRAY_OF_BOLD_LETTER,
  ARRAY_OF_BORDERS,
  ARRAY_OF_DESCRIPTION_BORDERS,
  ARRAY_OF_DESCRIPTIVES,
  ARRAY_OF_MARKS,
  ARRAY_OF_OTHER_DESCRIPTORS,
  ARRAY_OF_PLATE_OPTION,
  ARRAY_OF_TEXTS_CONSERVATION_AND_CONSUMPTION,
  ARRAY_OF_TITLE_MARKS,
  NUTRITIONAL_VALUE_TABLE,
} from './constants'
import { FuncionesAuxiliares } from './funcionesAuxiliares'
let SVGtoPDF = require('svg-to-pdfkit')
import axios from 'axios'

export const pieGraph = (row) => {
  const { PROTEINAS, LIPIDOS, FIBRA, HIDRATOS_C } = row

  const total = PROTEINAS + LIPIDOS + FIBRA + HIDRATOS_C

  let arcoGrafico = ''
  let leyendaGrafico = ''
  let etiquetaGrafico = ''

  let oGrafico = [
    {
      nombre: 'Proteinas',
      porcentaje: (PROTEINAS / total) * 100,
      color: '#0140CA',
    },
    {
      nombre: 'Lípidos',
      porcentaje: (LIPIDOS / total) * 100,
      color: '#DD1812',
    },
    {
      nombre: 'Fibra',
      porcentaje: (FIBRA / total) * 100,
      color: '#16A6FE',
    },
    {
      nombre: 'Hidratos',
      porcentaje: (HIDRATOS_C / total) * 100,
      color: '#6ab150',
    },
  ]

  let arrayInitialAngles = Array() // angulos iniciales
  let arrayEndAngles = Array() // angulos finales

  oGrafico.forEach((element, index) => {
    const { porcentaje, color, nombre } = element

    // calcula el valor del ángulo en radianes
    arrayEndAngles[index] = (porcentaje * 360) / 100

    if (index > 0) {
      arrayEndAngles[index] += arrayEndAngles[index - 1]
      arrayInitialAngles[index] = arrayEndAngles[index - 1]
    } else {
      arrayInitialAngles[index] = 0
    }

    arcoGrafico += FuncionesAuxiliares.nuevoGajo(arrayInitialAngles[index], arrayEndAngles[index], color, index)

    leyendaGrafico += FuncionesAuxiliares.leyenda(index * 30, color, nombre)

    if (porcentaje > 4) {
      etiquetaGrafico += FuncionesAuxiliares.nuevaEtiqueta(
        arrayInitialAngles[index],
        arrayEndAngles[index],
        Math.round(porcentaje * 100) / 100 + '%',
        index
      )
    }
  })

  return { arcoGrafico, leyendaGrafico, etiquetaGrafico }
}

export const printSVG = (arcoGrafico, leyendaGrafico, etiquetaGrafico, doc) => {
  /* Creo el SVG del gráfico: svg, svgEtiqueta, svgLeyenda. */
  const svg = ` <svg id = "lienzoSVG" width="260" height="250">` + arcoGrafico + `</svg>`
  const svgEtiqueta =
    ` <svg id = "lienzoSVGEtiqueta" width="260" height="250">` +
    etiquetaGrafico +
    `<style><![CDATA[ text { font: bold 20px; } ]]></style></svg>`
  const svgLeyenda = ` <svg id = "lienzoSVGLeyenda" width="260" height="250">` + leyendaGrafico + `</svg>`

  /* Imprimo el SVG del gráfico: svg, svgEtiqueta, svgLeyenda en el documento. */
  SVGtoPDF(doc, svg, 70, 442, {
    width: 130,
    height: 130,
    preserveAspectRatio: 'xMinYMin',
  })
  SVGtoPDF(doc, svgEtiqueta, 100, 470, {
    width: 70,
    height: 70,
    preserveAspectRatio: 'xMinYMin',
  })
  SVGtoPDF(doc, svgLeyenda, 250, 470, {
    width: 130,
    height: 130,
    preserveAspectRatio: 'xMinYMin',
  })
}

export const impressionsBeforeTheIngredients = (row, doc, logo, currentDate, allergenInformation) => {
  //Logo compañía
  doc.image(`${logo}`, 250, 70, { scale: 0.5 })

  //Fecha de impresión
  doc
    .fontSize(10)
    .font('Helvetica')
    .text(`Fecha impresión: ${currentDate} `, 25, 595, { width: 230, height: 300 })
    .moveDown()

  //Codificación receta-catálogo
  doc
    .fontSize(10)
    .text(`${row.CODIGO_RECETA}` + '-' + `${row.CATALOGO}`, 680, 595, { align: 'center', width: 80, height: 900 })
    .moveDown()

  //Información sobre los alérgenos
  doc
    .fontSize(10)
    .font('Helvetica')
    .fillColor('blue')
    .text(`${allergenInformation}`, 25, 570, { width: 740, height: 300 })
    .moveDown()

  //Nombre receta
  doc
    .fontSize(18)
    .font('Helvetica-Bold')
    .fillColor('black')
    .text(`${row.NCOMERCIAL_RECETA}`, 25, 40, { width: 510, height: 60 })
    .moveDown()

  //Descriptivo Elaboración receta
  if (row.ELABORACION != null) {
    doc
      .fontSize(10)
      .font('Helvetica')
      .fillColor('black')
      .text(`${row.ELABORACION}`, 425, 210, { width: 340, height: 175 })
      .moveDown()
  }
}

export const printsIngredientsParagraph = (row, doc, listaIngredientesRecetas, listaTotalIngredientesRecetas) => {
  let resultDoc = doc
  let ingredientesParrafo = ''

  listaIngredientesRecetas.forEach((ingrediente) => {
    const { COD_RECETA: codReceta, INGREDIENTE, CANTIDAD } = ingrediente

    listaTotalIngredientesRecetas.forEach((ingredienteTotal) => {
      const { COD_RECETA: codRecetaTotal } = ingredienteTotal

      if (codRecetaTotal === codReceta) {
        let arrayAux = `${INGREDIENTE} (${CANTIDAD}) `
        ingredientesParrafo = ingredientesParrafo === '' ? arrayAux : `, ${arrayAux}`
      }
    })
  })

  if (ingredientesParrafo.length === 0) {
    listaIngredientesRecetas.forEach((ingrediente) => {
      const { ROWNUMBER, COD_RECETA, INGREDIENTE, CANTIDAD } = ingrediente

      if (COD_RECETA === row.CODIGO_RECETA) {
        let distanceDifference = ROWNUMBER === 1 ? 0 : 12 * ROWNUMBER - 12

        resultDoc = printIngredientParagraph(resultDoc, distanceDifference, INGREDIENTE, CANTIDAD)
      }
    })
  }

  //INGREDIENTE en párrafo
  resultDoc.fontSize(9).font('Helvetica').text(ingredientesParrafo, 31, 215, { width: 345, height: 250 }).moveDown()

  //Descriptivo PRODUCTO - ACTUALIZADO
  doc
    .fontSize(10)
    .font('Helvetica-Bold')
    .fillColor('black')
    .text('PRODUCTO', 131, 204, { width: 345, height: 195 })
    .moveDown()

  //Descriptivo PESO BRUTO (g) - ACTUALIZADO
  doc
    .fontSize(10)
    .font('Helvetica-Bold')
    .fillColor('black')
    .text('PESO BRUTO (g)', 293, 195, { width: 345, height: 195 })
    .moveDown()

  //Descriptivo Para 100 raciones - ACTUALIZADO
  doc
    .fontSize(8)
    .font('Helvetica')
    .fillColor('black')
    .text('(Para 100 raciones)', 298, 205, { width: 345, height: 195 })
    .moveDown()

  return resultDoc
}

export const printIngredientParagraph = (doc, distanceDifference, INGREDIENTE, CANTIDAD) => {
  let resultDoc = doc

  //Marcar título de INGREDIENTE b SUMAR 16
  resultDoc
    .lineCap('butt')
    .moveTo(27, 218 + distanceDifference)
    .lineTo(290, 218 + distanceDifference)
    .lineWidth(10)
    .fillAndStroke('blue', '#dbe8ff')
    .stroke()

  //Marcar PESO INGREDIENTE b SUMAR 16
  resultDoc
    .lineCap('butt')
    .moveTo(373, 218 + distanceDifference)
    .lineTo(292, 218 + distanceDifference)
    .lineWidth(10)
    .stroke()

  //Borde INGREDIENTE b SUMAR 16
  resultDoc
    .lineWidth(0.5)
    .fillAndStroke('black')
    .roundedRect(27, 213 + distanceDifference, 262, 10)
    .stroke()

  //Borde PESO INGREDIENTE b SUMAR 16
  resultDoc
    .lineWidth(0.5)
    .fillAndStroke('black')
    .roundedRect(292, 213 + distanceDifference, 80, 10)
    .stroke()

  //INGREDIENTE b SUMAR 16
  resultDoc
    .fontSize(10)
    .font('Helvetica')
    .fillColor('black')
    .text(INGREDIENTE, 31, 215 + distanceDifference, { width: 260, height: 10 })
    .moveDown()

  //PESO INGREDIENTE b SUMAR 16
  resultDoc
    .fontSize(10)
    .text(CANTIDAD, 296, 215 + distanceDifference, { width: 73, height: 10, align: 'right' })
    .moveDown()

  return resultDoc
}

export const buildAsciiRecipeImage = (listaFotosRecetas, selectedRow) => {
  let ascii = ''

  if (listaFotosRecetas.length === 0) {
    return ascii
  }

  listaFotosRecetas.forEach((image) => {
    const { RECETA_ID, FOTO } = image

    if (selectedRow.CODIGO_RECETA === RECETA_ID) {
      let buf = Buffer.from(FOTO)

      ascii = ascii === '' ? buf : Buffer.concat([ascii, buf])
    }
  })

  return ascii
}

export const printRecipeImage = (doc, ascii) => {
  let stringBuffer = ''

  /* Si la receta no tiene imagen, se imprime una por defecto. */
  if (ascii === '') {
    doc.image('images/imagenPorDefectoP1-2.png', 545, 20, { width: 228, height: 150 })

    return {
      b64: stringBuffer,
      error: false,
    }
  }

  stringBuffer = Buffer.from(ascii).toString('base64')
  let bufferImage = new Buffer(stringBuffer, 'base64')

  const isError = !bufferImage.includes('PNG')
  if (isError) {
    return {
      b64: '',
      error: isError,
    }
  }

  doc.image(bufferImage, 545, 20, { width: 228, height: 150 })
  return {
    b64: stringBuffer,
    error: isError,
  }
}

export const printRecipeAllergens = (doc, selectedRow) => {
  doc.image('images/alergenosIconos.png', 423, 415, { scale: 1, width: 340 })

  if (selectedRow.DECLARACION_ALERGENOS === 'INCOMPLETA') {
    doc
      .fontSize(45)
      .font('Helvetica-Bold')
      .fillColor('red')
      .text('INCOMPLETA', 445, 470, { width: 510, height: 60 })
      .moveDown()

    return
  }

  markNotIncompleteAllergens(doc, selectedRow)
}

const markNotIncompleteAllergens = (doc, selectedRow) => {
  const arrayOfAllergens = Object.keys(ALLERGENS)
  const arrayFillAndStrokeProperty = ['GLUTEN']
  const arrayMoveDownProperty = [
    'GLUTEN',
    'CRUSTACEOS',
    'FRUTOS_CASCARAS',
    'APIO_Y_DERIVADOS',
    'MOSTAZA_Y_DERIVADOS',
    'SESAMO',
    'MOLUSCOS',
    'ALTRAMUCES',
  ]

  arrayOfAllergens.forEach((allergen) => {
    if (selectedRow[allergen] === 'SI') {
      doc
        .lineCap('round')
        .moveTo(ALLERGENS[allergen].moveToX, ALLERGENS[allergen].propY)
        .lineTo(ALLERGENS[allergen].lineToX, ALLERGENS[allergen].propY)
        .lineWidth(10)
        .stroke()
      arrayFillAndStrokeProperty.includes(allergen) && doc.fillAndStroke('black', '#000000').stroke()
    } else if (selectedRow.GLUTEN === 'TRAZAS') {
      doc
        .fontSize(9)
        .font('Helvetica-Bold')
        .text('TRAZAS', ALLERGENS[allergen].textX, ALLERGENS[allergen].textY, { width: 510, height: 60 })
      arrayMoveDownProperty.includes(allergen) && doc.moveDown()
    }
  })
}

export const printMarksAndBorders = (doc) => {
  //Color azul del borde
  doc.lineWidth(2).fillAndStroke('blue', '#003cac')

  ARRAY_OF_BORDERS.forEach(({ x, y, w, h }) => {
    doc.roundedRect(x, y, w, h).stroke()
  })

  ARRAY_OF_MARKS.forEach(({ moveToX, lineToX, propY, lineWidth }) => {
    doc.lineCap('round').moveTo(moveToX, propY).lineTo(lineToX, propY).lineWidth(lineWidth).stroke()
  })
}

export const printDescriptive = (doc, selectedRow) => {
  //Descriptivo opción plato
  ARRAY_OF_PLATE_OPTION.forEach(({ optionName, fontSize }) => {
    if (selectedRow[optionName] === 1) {
      doc
        .fontSize(fontSize)
        .font('Helvetica-Bold')
        .fillColor('white')
        .text(`${optionName}`, 20, 143, { align: 'center', width: 150 })
        .moveDown()
    }
  })

  //Otras descripciones
  ARRAY_OF_OTHER_DESCRIPTORS.forEach(({ nameDescriptor, x, y, width }) => {
    doc.fontSize(12).font('Helvetica-Bold').fillColor('white').text(`${nameDescriptor}`, x, y, { width }).moveDown()
  })
}

export const printHeaderSecondPage = (doc, b64, ascii, companyLogo, selectedRow) => {
  //Logo compañía
  doc.image(`${companyLogo}`, 150, 25, { scale: 0.5 })

  //Imagen receta segunda hoja
  doc.image(ascii === '' ? 'images/imagenPorDefectoP1-2.png' : new Buffer(b64, 'base64'), 475, 20, {
    width: 295,
    height: 205,
  })

  //Nombre receta
  doc
    .fontSize(selectedRow.NCOMERCIAL_RECETA.length > 70 ? 14 : 18)
    .font('Helvetica-Bold')
    .fillColor('black')
    .text(`${selectedRow.NCOMERCIAL_RECETA}`, 25, 145, {
      align: 'left',
      width: selectedRow.NCOMERCIAL_RECETA.length > 70 ? 440 : 435,
      height: 55,
    })
    .moveDown()

  //Código receta
  doc
    .fontSize(18)
    .font('Helvetica')
    .text(`${selectedRow.CODIGO_RECETA}`, 20, 196, { align: 'center', width: 90 })
    .moveDown()
}

export const printFooterSecondPage = (doc, selectedRow, currentDate) => {
  //Fecha de impresión
  doc
    .fontSize(10)
    .font('Helvetica')
    .text(`Fecha impresión: ${currentDate}`, 25, 595, { width: 230, height: 300 })
    .moveDown()

  //Codificación receta-catálogo
  doc
    .fontSize(10)
    .text(`${selectedRow.CODIGO_RECETA}` + '-' + `${selectedRow.CATALOGO}`, 680, 595, {
      align: 'center',
      width: 80,
      height: 900,
    })
    .moveDown()
}

export const printTablesPriorNutritionalValue = (doc, selectedRow, printFamilyPlate) => {
  //Texto conservación y consumo
  ARRAY_OF_TEXTS_CONSERVATION_AND_CONSUMPTION.forEach(({ text, x, y, width }) => {
    doc.fontSize(12).text(text, x, y, { align: 'left', width, height: 55 }).moveDown()
  })

  //Impresión peso neto
  doc
    .fontSize(12)
    .font('Helvetica')
    .text(`${Number(selectedRow.PESON.toFixed(2))}`, 585, 280)
    .moveDown()

  //Impresión peso bruto
  doc
    .fontSize(12)
    .text(`${Number(selectedRow.PESOT.toFixed(2))}`, 585, 310)
    .moveDown()

  //Impresión familia del plato
  if (printFamilyPlate === true) {
    doc.fontSize(12).text(`${selectedRow.DESC_FAMILIA}`, 615, 340).moveDown()

    doc.fontSize(12).text('Familia del plato:', 515, 340).moveDown()
  }

  //Letra Bold
  ARRAY_OF_BOLD_LETTER.forEach(({ text, x, y }) => {
    doc.fontSize(12).font('Helvetica-Bold').text(text, x, y).moveDown()
  })

  //Bordes de: concervación y consumo / peso y familia / valoración nutricional
  ARRAY_OF_DESCRIPTION_BORDERS.forEach(({ x, y, w, h }) => {
    doc.lineWidth(2).fillAndStroke('blue', '#003cac').roundedRect(x, y, w, h).stroke()
  })

  //Marcar: título de concervación y consumo / título de peso y familia / título de valoración nutricional
  ARRAY_OF_TITLE_MARKS.forEach(({ moveToX, lineToX, propY }) => {
    doc.lineCap('round').moveTo(moveToX, propY).lineTo(lineToX, propY).lineWidth(25).stroke()
  })

  ARRAY_OF_DESCRIPTIVES.forEach(({ text, x }) => {
    doc.fontSize(12).font('Helvetica-Bold').fillColor('white').text(text, x, 240).moveDown()
  })
}

export const printTablesNutritionalValue = (doc, selectedRow) => {
  //Descriptivo CONSERVACIÓN Y CONSUMO
  doc
    .fontSize(12)
    .font('Helvetica-Bold')
    .fillColor('white')
    .text('VALORACIÓN NUTRICIONAL', 35, 395)
    .lineWidth(25)
    .moveDown()

  NUTRITIONAL_VALUE_TABLE.forEach(({ valueName, titleMark, descriptive, valueMark, value }) => {
    //Marcar título
    doc
      .lineCap('butt')
      .moveTo(titleMark.moveToX, titleMark.propY)
      .lineTo(titleMark.lineToX, titleMark.propY)
      .lineWidth(18)
      .fillAndStroke('blue', '#dbe8ff')
      .stroke()

    //Descriptivo
    doc
      .fontSize(9)
      .font('Helvetica')
      .fillColor('black')
      .text(descriptive.text, descriptive.x, descriptive.y)
      .lineWidth(18)
      .moveDown()

    //Marcar valor
    doc
      .lineCap('butt')
      .moveTo(valueMark.moveToX, valueMark.propY)
      .lineTo(valueMark.lineToX, valueMark.propY)
      .lineWidth(18)
      .stroke()

    //Valor
    const valueText =
      valueName === 'KCALORIAS'
        ? `${Number(selectedRow[valueName].toFixed(2))}` + '/' + `${Number(selectedRow.CALORIAS.toFixed(2))}`
        : `${Number(selectedRow[valueName].toFixed(2))}`

    doc
      .fontSize(9)
      .font('Helvetica')
      .text(valueText, value.x, value.y, { width: 65, height: 20, align: 'right' })
      .moveDown()
  })
}

export const getBufferLogo = async (url) => {
  const cdnUrl = process.env.CDN_URL ?? ''
  const path = cdnUrl + url

  const image = await axios.get(path, { responseType: 'arraybuffer' })
  return Buffer.from(image.data, 'binary')
}
