"use strict";
import React from "react"
import { Button, Divider, Table, Tooltip, Drawer } from "antd"
import { EditOutlined, FormOutlined } from "@ant-design/icons"
import { DrawerModificarObsEspecialesForm } from "./DrawerModificarObsEspecialesForm";
import { DrawerInsertarObsEspecialesForm } from "./DrawerInsertarObsEspecialesForm";
import { EliminarDatosObsEspeciales } from "./EliminarDatosObsEspeciales";

const textoAnadirObsEspeciales = <span>Añadir Observaciones Especiales</span>
const textoEditarObsEspeciales = <span>Editar Observaciones Especiales</span>

export const DatosObsEspecialesBotonera = (props) => {
    const {
        enviarObsEspeciales,
        getColumnSearchProps,
        handleChangeDatos,
        onChildrenDrawerCloseModificarObsEspeciales,
        onChildrenDrawerCloseObsEspeciales,
        onCloseDrawerObsEspeciales,
        selectCatalogosUsuario,
        showChildrenDrawerModificarObsEspeciales,
        showChildrenDrawerObsEspeciales,
        showDrawerObsEspeciales,
        state,
        updateStates
    } = props

    const columnsObsEspeciales = [
        {
            title: "Código",
            dataIndex: "CODIGO_OBS_ESPECIALES",
            key: "CODIGO_OBS_ESPECIALES",
            ellipsis: true,
            width: 100,
        },
        {
            title: "Descripción Observaciones Especiales",
            dataIndex: "NOMBRE_OBS_ESPECIALES",
            key: "NOMBRE_OBS_ESPECIALES",
            ellipsis: true,
            width: 600,
            ...getColumnSearchProps("NOMBRE_OBS_ESPECIALES", "Buscar Nombre Datos Empresas"),
            sorter: (a, b) =>
                a.NOMBRE_OBS_ESPECIALES.length - b.NOMBRE_OBS_ESPECIALES.length,
            sortDirections: ["descend", "ascend"],
            showSorterTooltip: false,
        },
    ];

    return (
        <Drawer
            title="Observaciones Especiales"
            width={"50%"}
            closable={false}
            onClose={onCloseDrawerObsEspeciales}
            visible={state.visibleDrawerObsEspeciales}
        >
            <Tooltip placement="topLeft" title={textoAnadirObsEspeciales}>
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerObsEspeciales}
                >
                    <FormOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />

            <Tooltip placement="topLeft" title={textoEditarObsEspeciales}>
                <Button
                    size="default"
                    style={{
                        boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                        borderColor: "#fff",
                        border: 0,
                        backgroundColor: "#fff",
                    }}
                    type="dashed"
                    onClick={showChildrenDrawerModificarObsEspeciales}
                >
                    <EditOutlined />
                </Button>
            </Tooltip>

            <Divider type="vertical" />
            <EliminarDatosObsEspeciales
                state={state}
                showDrawerObsEspeciales={showDrawerObsEspeciales}
            />

            <Drawer
                title="Añadir Observaciones Especiales"
                width={450}
                closable={false}
                onClose={onChildrenDrawerCloseObsEspeciales}
                visible={state.childrenDrawerObsEspeciales}
                bodyStyle={{ paddingBottom: 80 }}
            >

                <DrawerInsertarObsEspecialesForm
                    enviarObsEspeciales={enviarObsEspeciales}
                    onChildrenDrawerCloseObsEspeciales={onChildrenDrawerCloseObsEspeciales}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                />
            </Drawer>

            <Drawer
                title="Modificar Observaciones Especiales"
                width={450}
                closable={false}
                onClose={onChildrenDrawerCloseModificarObsEspeciales}
                visible={state.childrenDrawerModificarObsEspeciales}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <DrawerModificarObsEspecialesForm
                    state={state}
                    updateStates={updateStates}
                    onChildrenDrawerCloseModificarObsEspeciales={onChildrenDrawerCloseModificarObsEspeciales}
                    selectCatalogosUsuario={selectCatalogosUsuario}
                />
            </Drawer>

            <Divider orientation="center">
                Listado de Observaciones Especiales
            </Divider>

            <Table
                rowSelection={{
                    type: "radio",
                    ...state.rowSelectionObsEspeciales,
                }}
                rowKey={(record) => record.CODIGO_OBS_ESPECIALES}
                size="small"
                columns={columnsObsEspeciales}
                dataSource={state.datosObsEspeciales}
                onChange={handleChangeDatos}
                bordered
                pagination={{ defaultPageSize: 20 }}
            />
        </Drawer>
    )
}