import { authHeader } from "../helpers";
import { Modal } from "antd";

export const userService = {
  login,
  secure,
  getAllUsersAD,
};

const BASE_URL = process.env.USER_SERVICE_API_URL;

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(BASE_URL + "/login", requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user) {
        user.authdata = user.token;
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    })
    .catch((response) => {
      console.log("login");
      console.log(response);
      if (response === "Unauthorized")
        return Promise.reject(
          "Usuario o contraseña incorrecta. Por favor, inténtelo de nuevo."
        );
      else alert("No es posible comunicarse con el servidor");
      //message.error('No es posible comunicarse con el servidor', 5);
    });
}

function getAllUsersAD(parametroBusquedaUsuario) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ parametroBusquedaUsuario }),
  };

  return fetch(BASE_URL + "/all-user", requestOptions).then((response) =>
    response.json().then((response) => ({ response }))
  );
}

function secure() {
  console.log("ini header:");
  console.log(authHeader());
  console.log("fin header:");
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(BASE_URL + "/secure", requestOptions)
    .then(handleResponse)
    .catch((response) => {
      console.log("secure");
      console.log(response);
      if (
        response == "Unauthorized" ||
        response == "Es necesario el token de autenticación"
      ) {
        localStorage.removeItem("user");
        Modal.error({
          title: "Error",
          content: "Sesión caducada...",
        });
        location.reload(true);
      } else alert("No es posible comunicarse con el servidor");
    });
}

function handleResponse(response, status) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (response.status == "401") {
      return Promise.reject(response.statusText);
    } else {
      return data;
    }
  });
}
