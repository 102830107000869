"use strict";
import React from "react"
import { Button, Modal, Tooltip } from "antd"
import { database } from "../../../services/database.service"
import { userService } from "../../../services"
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons"

export const EliminarDatosEmpresasTooltip = (props) => {
    const { state, showDrawerDatosEmpresa } = props
    const textoEliminarDatosEmpresas = <span>Eliminar Datos de Empresas</span>

    return (
        <Tooltip placement="topLeft" title={textoEliminarDatosEmpresas}>
            <Button
                size="default"
                style={{
                    boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                    borderColor: "#fff",
                    border: 0,
                    backgroundColor: "#fff",
                }}
                type="dashed"
                onClick={() => showConfirmModalEliminarDatosEmpresas(state, showDrawerDatosEmpresa)}
            >
                <DeleteOutlined />
            </Button>
        </Tooltip>
    )
}

const showConfirmModalEliminarDatosEmpresas = (state, showDrawerDatosEmpresa) => {
    if (state.selectedRowKeysDatosEmpresas.length !== 1 || state.selectedRowsDatosEmpresas.length !== 1) {
        Modal.error({
            title: "Error",
            content: "Debes seleccionar el centro que deseas eliminar.",
        })

        return
    }

    const codigo =
        state.selectedRowsDatosEmpresas[0].CODIGO_UCP.toString();

    Modal.confirm({
        title:
            `¿Deseas eliminar los Datos de Empresa seleccionado: ${state.selectedRowsDatosEmpresas[0].NOMBRE_UCP}?`,
        icon: <ExclamationCircleOutlined />,
        content: "Esta acción eliminará los Datos de Empresa seleccionado.",
        okText: "Aceptar",
        cancelText: "Cancelar",
        onOk: () => {
            userService.secure().then;
            {
                database.eliminarDatosEmpresas(
                    codigo,
                    JSON.parse(localStorage.getItem("user")).sAMAccountName
                ).then((res) => {
                    const resEliminarDatosEmpresa = Object.values(res.response);

                    if (resEliminarDatosEmpresa[0] === "success") {

                        Modal.success({
                            title: "Éxito",
                            content: resEliminarDatosEmpresa[1],
                        })

                        return showDrawerDatosEmpresa()

                    } else {

                        Modal.error({
                            title: "Error",
                            content: resEliminarDatosEmpresa[1],
                        })

                    }
                },
                    (error) => console.log(error)
                )
            }
        },
        onCancel() {
            console.log("Se ha cancelado la acción de eliminar Datos Empresas.");
        }
    })
}
