import React, { useState } from "react";
import { Table, Popconfirm, Form, Typography } from "antd";
import EditableCell from "../EditableCell";

function TableRecipiesDiets({ listRecipes, loading, onSave }) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      cubiertos: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const handleSave = async (record) => {
    const row = await form.validateFields();
    const listRecipesUpdated = listRecipes.map((recipe) => {
      if (recipe.key === record.key) {
        recipe.cubiertos = row.cubiertos;
      }
      return recipe;
    });
    setEditingKey("");
    onSave(listRecipesUpdated);
  };

  const columns = [
    {
      title: "Código Receta",
      dataIndex: "codigo_receta",
      width: 150,
    },
    {
      title: "Receta",
      dataIndex: "receta",
      width: 400,
    },
    {
      title: "Alérgenos",
      dataIndex: "alergenos",
      width: 500,
      render: (alergenos) => {
        return alergenos === "INCOMPLETA" ? (
          <span style={{ color: "#f3000c", marginBottom: 10 }}>
            Declaración de alérgenos INCOMPLETA.
          </span>
        ) : (
          alergenos
        );
      },
    },
    {
      title: "Opción",
      dataIndex: "opcion",
      width: 150,
    },
    {
      title: "Menús  (Basal/Dietas)",
      dataIndex: "dieta",
      width: 400,
    },
    {
      title: "No. Cubiertos",
      dataIndex: "cubiertos",
      inputType: "number",
      width: 130,
      editable: true,
    },
    {
      title: "Acción",
      dataIndex: "operation",
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                handleSave(record);
              }}
              style={{
                marginRight: 8,
              }}
            >
              Guardar
            </Typography.Link>
            <Popconfirm title="¿Seguro de cancelar?" onConfirm={cancel}>
              <a>Cancelar</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Editar
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable.toString(),
        inputType: col.dataIndex === "cubiertos" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        bordered
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={listRecipes}
        size="small"
        columns={mergedColumns}
        rowClassName="editable-row"
        loading={loading}
        scroll={{ x: "calc(750px + 50%)", y: 300 }}
      />
    </Form>
  );
}

export default TableRecipiesDiets;
