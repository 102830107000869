"use strict";
import React, { Component } from "react"
import ReactDOM from "react-dom"
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Spin,
  Divider,
  Modal,
  Select,
  Table,
  Tooltip,
  ConfigProvider,
  BackTop,
  Checkbox,
  Drawer,
} from "antd"
import { database } from "../../services/database.service"
import Highlighter from "react-highlight-words"
import {
  DatabaseOutlined,
  PrinterOutlined,
  ProfileOutlined,
  ContactsOutlined,
  ScheduleOutlined,
  LineOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons"
import moment from "moment"
import { DatosEmpresasBotonera } from "./DatosEmpresa";
import { DatosClientesBotonera } from "./DatosClientes";
import { DatosLineasDeProduccionBotonera } from "./LineasDeProduccion";
import { DatosObsEspecialesBotonera } from "./ObservacionesEspeciales";
import { getListaIdiomas, getlistaOptionsCatalogosUsuario } from "./functions"
import { DrawerOpcionesForm } from "./DrawerOpcionesForm/";
import { getDatosPlatoPrincipalGuarnicionSeleccionada } from "./getDatosPlatoPrincipalGuarnicionSeleccionada";

moment.locale("es")
const { confirm } = Modal
const textoIconImprimirE = <span>Imprimir Etiquetas</span>
const textoIconDatosEmpresa = <span>Datos Empresa</span>
const textoIconClientes = <span>Clientes</span>
const textoIconLineasProduccion = <span>Líneas de Producción</span>
const textoIconObsEspeciales = <span>Observaciones Especiales</span>

const codEmpresa = "null"

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <DatabaseOutlined style={{ fontSize: 20 }} />
    <p>Datos No Encontrados</p>
  </div>
)

class ImprimirEtiquetas extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      users: [],
      employee: {},
      employees: [],
      loading: false,
      listaOptionsCatalogosUsuario: [],
      statusListaCatalogosUsuario: "",
      optionsCatalogosUsuario: [],
      loadingSpingImprimirEtiquetas: false,
      textoLoadingSpingImprimirEtiquetas: "",
      listaIdiomas: [],
      statusListaIdiomas: "",
      selectedRowsRecetas: [],
      selectedRowKeysRecetas: [],
      selectedRowsGuarnicion: [],
      selectedRowKeysGuarnicion: [],
      selectedRowsDatosEmpresas: [],
      selectedRowKeysDatosEmpresas: [],
      listaRecetasIdiomas: [],
      tabPosition: "top",
      visible: false,
      error: "",
      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,
      visibleDrawerDatosEmpresa: false,
      childrenDrawerDatosEmpresa: false,
      listaDatosEmpresas: [],
      datosEmpresas: [],
      rowSelectionDatosEmpresas: {},
      accionDatosEmpresas: "insertar",
      childrenDrawerModificarDatosEmpresa: false,
      visibleDrawerClientes: false,
      childrenDrawerClientes: false,
      listaDatosClientes: [],
      datosClientes: [],
      rowSelectionDatosClientes: {},
      selectedRowsDatosClientes: [],
      selectedRowKeysDatosClientes: [],
      childrenDrawerModificarDatosClientes: false,
      visibleDrawerLineasProduccion: false,
      childrenDrawerLineasProduccion: false,
      listaLineasProduccion: [],
      datosLineasProduccion: [],
      rowSelectionLineasProduccion: {},
      selectedRows: [],
      selectedRowKeys: [],
      childrenDrawerModificarLineasProduccion: false,
      visibleDrawerObsEspeciales: false,
      childrenDrawerObsEspeciales: false,
      listaObsEspeciales: [],
      datosObsEspeciales: [],
      rowSelectionObsEspeciales: {},
      selectedRowsObsEspeciales: [],
      selectedRowKeysObsEspeciales: [],
      childrenDrawerModificarObsEspeciales: false,
      optionLineaProduccion: [],
      optionDatosClientes: [],
      optionDatosEmpresas: [],
      optionObsEspeciales: [],
      diasCaducidadLP: 0,
      codLP: 0,
      fechaCaducidadState: "",
      fechaElaboracionState: "",
      message: "",
      infoNutricionalReceta: [],
      infoNutricionalReceta100: [],
      valueTextAreaIngredientesAlergenosTotal: "",
      valueTextAreaAlergenosPrincipal: "",
      valueTextAreaAlergenosGuarnicion: "",
      valueTextAreaIngredientesTotal: "",
      valueTextAreaGuarnicion: "",
      valueTextAreaAdvertenciaPrincipal: "",
      valueTextAreaAdvertenciaGuarnicion: "",
      colorAdvertenciaGuarnicionTA: "",
      colorAdvertenciaPrincipalTA: "",
      disabledTabPanelAdevrtencia: false,
      colorTabPanelAdvertencia: "",
      datosCocinaImprimir: [],
      ingredientesGuarnicionEtiqueta: "",
      ingredientesPrincipalEtiqueta: "",
      nombrePrincipal: "",
      nombreGuarnicion: "",
      loadingButtonLanzarImpresion: false,
      txtAreaAlergenosTotal: true,
      txtAreaAlergenosGuarnicion: true,
      txtAreaAlergenosGuarnicion: true,
      txtAreaIngredientesGuarnicion: true,
      txtAreaIngredientesTotal: true,
      tabPaneActiva: "1",
      deshabilitarBotonEditarIngGuarnicion: true,
      deshabilitarBotonEditarAlergenosGuarnicion: true,
      valueNameLineaProduccion: null,
      cargarFomrularioPrimeraVez: 0,
      valueFechaCaducidad: null,
      valueCodigoLote: null,
      valueDias: null,
      botonEditarIngredientesTotal: false,
      valueNameCliente: null,
      valuefechaElaboracion: null,
      botonEditarAlergenosTotal: false,
      valuesFomrularioPrimeraVez: "",
      errorFieldNameLineaProduccion: true,
      listaCatalogoId: [],
      defaultCatalogoSelecionado: "",
      valueTextArea01: "",
      valuePesoNetoTotal: 0,
      catalogoSeleccionado: "",
      puedeContenerState: "",
      checkedMultiporcion: false,
      disabledPesoNeto: false,
    }
  }

  formRef = React.createRef()

  componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem("user")),
      users: { loading: true },
      employee: JSON.parse(localStorage.getItem("employee")),
      employees: { loading: true },
      loadingSpingImprimirEtiquetas: true,
      textoLoadingSpingImprimirEtiquetas: "Cargando pantalla para imprimir etiquetas...",
      txtAreaAlergenosTotal: true,
      txtAreaAlergenosGuarnicion: true,
      texttxtAreaAlergenosTotal: true,
      txtAreaIngredientesGuarnicion: true,
      txtAreaIngredientesTotal: true,
      tabPaneActiva: "1",
      deshabilitarBotonEditarIngGuarnicion: true,
      deshabilitarBotonEditarAlergenosGuarnicion: true,
    })

    getlistaOptionsCatalogosUsuario(this.updateStates)
    getListaIdiomas(this.updateStates)
  }

  handleResponse = (res) => {
    const response = Object.values(res.response);

    this.setState({ loading: false });

    if (response[0] === "success") {
      Modal.success({
        title: "Éxito",
        content: response[1],
      })

      return
    }

    Modal.error({
      title: "Error",
      content: response[1],
    })

  }

  updateStates = (newStateValues) => {
    this.setState(newStateValues)
  }

  /* Manejar la paginación al mostrar el resultado de la busqueda de las recetas. */
  handleChangeDatos = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleSearchRecetas = (idioma, catalogo, catalogoSleccionado) => {

    this.setState({
      loadingSpingImprimirEtiquetas: true,
      textoLoadingSpingImprimirEtiquetas: `Cargando recetas del catálogo ${catalogo} ...`,
      checkedBoxGuarnicion: false,
    });

    database.getListadoRecetasNCIdiomasConTipoPlato(catalogo, idioma)
      .then(
        (res) => {

          this.setState({
            listaRecetasIdiomas: res.response,
          })

          const dataTabla = []

          this.state.listaRecetasIdiomas.forEach(({ COD_RECETA, NOM_RECETA, NOM_PLATO, TIPO_PLATO }) => {
            if (TIPO_PLATO != "GUARNICION") {
              dataTabla.push({
                COD_RECETA: COD_RECETA,
                NOM_RECETA: NOM_RECETA,
                NOM_PLATO: NOM_PLATO,
                TIPO_PLATO: TIPO_PLATO,
              });
            }
          })

          const rowSelectionRecetas = {
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRowsRecetas: selectedRows,
                selectedRowKeysRecetas: selectedRowKeys,
              })
            }
          }

          const columns = [
            {
              title: "Código Receta",
              dataIndex: "COD_RECETA",
              key: "COD_RECETA",
              defaultSortOrder: "descend",
              ellipsis: true,
              width: 130,
              fixed: "left",
              ...this.getColumnSearchProps("COD_RECETA", "Buscar Código Receta"),
            },
            {
              title: "Nombre Receta",
              dataIndex: "NOM_RECETA",
              key: "NOM_RECETA",
              ellipsis: true,
              width: 490,
              ...this.getColumnSearchProps("NOM_RECETA", "Buscar Nombre Receta"),
              sorter: (a, b) => a.NOM_RECETA.length - b.NOM_RECETA.length,
              sortDirections: ["descend", "ascend"],
              showSorterTooltip: false,
            },
            {
              title: "Nombre Plato",
              dataIndex: "NOM_PLATO",
              key: "NOM_PLATO",
              ellipsis: true,
              width: 490,
            },
            {
              title: "Tipo Plato",
              dataIndex: "TIPO_PLATO",
              key: "TIPO_PLATO",
              ellipsis: true,
              width: 130,
            },
          ];

          ReactDOM.render(
            <div>
              <ConfigProvider renderEmpty={customizeRenderEmpty}>

                <Tooltip placement="topLeft" title={textoIconImprimirE}>
                  <Button
                    size="default"
                    style={{
                      boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                      borderColor: "#fff",
                      border: 0,
                      backgroundColor: "#fff",
                    }}
                    onClick={this.showModalConfigImpresion}
                    type="dashed"
                  >
                    <PrinterOutlined />
                  </Button>
                </Tooltip>

                <Table
                  title={() => "Plato Principal"}
                  rowSelection={{ type: "radio", ...rowSelectionRecetas }}
                  size={"small"}
                  rowKey={(record) => record.COD_RECETA}
                  columns={columns}
                  dataSource={dataTabla}
                  scroll={{ x: 650, y: 600 }}
                  onChange={this.handleChangeDatos}
                  locale={{
                    filterConfirm: "Aceptar",
                    filterReset: "Cancelar",
                    emptyText: "No Datos",
                  }}
                />
              </ConfigProvider>
            </div>,

            document.getElementById("infoRecetasNomComerciales")
          );

          this.setState({
            loading: false,
            loadingSpingImprimirEtiquetas: false,
            textoloadingSpingImprimirEtiquetas: "",
            catalogoSeleccionado: catalogoSleccionado,
            catalogoSeleccionadoId: catalogo,
          });
        },
        (error) => this.setState({ error, loading: true })
      )

    this.modalConfigurar();
  };

  /* Insertar los datos de Empresas */
  enviarDatosEmpresas = (
    nameEmpresa,
    nameSocial,
    direccion,
    cif,
    registroSanitario,
    idEmpresa,
    selectCatalogo
  ) => {
    this.setState({ loading: true });

    database
      .insertarDatosEmpresas(
        nameEmpresa,
        nameSocial,
        direccion,
        cif,
        registroSanitario,
        JSON.parse(localStorage.getItem("user")).sAMAccountName,
        idEmpresa,
        selectCatalogo
      )
      .then(
        (res) => this.handleResponse(res),
        (error) => this.setState({ error: error, loading: false })
      );


    this.showDrawerDatosEmpresa();
    this.onChildrenDrawerCloseDatosEmpresa();
  };

  /* Insertar los datos de Clientes */
  enviarDatosClientes = (nameCliente, catalogo) => {
    this.setState({ loading: true });

    database
      .insertarClientes(
        nameCliente,
        JSON.parse(localStorage.getItem("user")).sAMAccountName,
        catalogo
      )
      .then(
        (res) => this.handleResponse(res),
        (error) => this.setState({ error: error, loading: false })
      )

    this.showDrawerClientes();
    this.onChildrenDrawerCloseClientes();
  };

  /* Insertar los datos de Observaciones Especiales */
  enviarObsEspeciales = (ObsEspeciales, catalogo) => {
    this.setState({ loading: true });

    database
      .insertarObsEspeciales(
        ObsEspeciales,
        JSON.parse(localStorage.getItem("user")).sAMAccountName,
        catalogo
      )
      .then(
        (res) => this.handleResponse(res),
        (error) => this.setState({ error: error, loading: false })
      );


    this.showDrawerObsEspeciales();
    this.onChildrenDrawerCloseObsEspeciales();
  };

  /* Insertar los Líneas de Producción */
  enviarLineasProduccion = (
    nameLineaProduccion,
    tipo,
    descripcionTipo,
    diasCaducidad,
    textoFCaducidad,
    condicionesConservacion,
    modoEmpleo,
    catalogo
  ) => {
    this.setState({ loading: true });

    database
      .insertarLineasProduccion(
        nameLineaProduccion,
        tipo,
        descripcionTipo,
        diasCaducidad,
        textoFCaducidad,
        condicionesConservacion,
        modoEmpleo,
        JSON.parse(localStorage.getItem("user")).sAMAccountName,
        catalogo
      )
      .then(
        (res) => this.handleResponse(res),
        (error) => this.setState({ error: error, loading: false })
      );


    this.showDrawerLineasProduccion();
    this.onChildrenDrawerCloseLineasProduccion();
  };

  /* Mostrar el checkbox de Guarnicion renderizando a la vista */
  modalConfigurar = () => {
    ReactDOM.render(
      <div>
        <Divider />

        <Checkbox
          checked={false}
          onChange={this.onChangeCheckboxGuarnicion}
          style={{ marginBottom: 20 }}
        >
          Guarnición
        </Checkbox>
      </div>,

      document.getElementById("infoRecetasNomComercialesGuarnicion")
    );
  };

  /* Mostrar el checkbox de Guarnicion renderizando a la vista las guarniciones del catálogo seleccionado */
  onChangeCheckboxGuarnicion = (e) => {
    const checkedGU = e.target.checked

    if (checkedGU === false) {
      this.modalConfigurar()
      this.setState({
        selectedRowsGuarnicion: [],
        selectedRowKeysGuarnicion: [],
      })

      return
    }

    const dataTablaGuarnicion = [];
    this.state.listaRecetasIdiomas.forEach(({ TIPO_PLATO, COD_RECETA, NOM_RECETA, NOM_PLATO }) => {
      if (TIPO_PLATO === "GUARNICION") {
        dataTablaGuarnicion.push({
          COD_RECETA,
          NOM_RECETA,
          NOM_PLATO,
        })
      }
    })

    const rowSelectionGuarnicion = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowsGuarnicion: selectedRows,
          selectedRowKeysGuarnicion: selectedRowKeys,
        });
      },
    };

    const columnsGuarnicion = [
      {
        title: "Código Receta",
        dataIndex: "COD_RECETA",
        key: "COD_RECETA",
        defaultSortOrder: "descend",
        ellipsis: true,
        width: 130,
        fixed: "left",
        ...this.getColumnSearchProps("COD_RECETA", "Buscar Código Receta"),
      },
      {
        title: "Nombre Receta",
        dataIndex: "NOM_RECETA",
        key: "NOM_RECETA",
        ellipsis: true,
        width: 490,
        ...this.getColumnSearchProps("NOM_RECETA", "Buscar Nombre Receta"),
      },
      {
        title: "Nombre Plato",
        dataIndex: "NOM_PLATO",
        key: "NOM_PLATO",
        ellipsis: true,
        width: 490,
      },
    ];

    ReactDOM.render(
      <div>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>

          <Divider />

          <Checkbox
            checked={true}
            onChange={this.onChangeCheckboxGuarnicion}
            style={{ marginBottom: 20 }}
          >
            Guarnición
          </Checkbox>

          <Table
            title={() => "Guarnición"}
            rowSelection={{ type: "radio", ...rowSelectionGuarnicion }}
            size={"small"}
            rowKey={(record) => record.COD_RECETA}
            columns={columnsGuarnicion}
            dataSource={dataTablaGuarnicion}
            scroll={{ x: 650, y: 600 }}
            onChange={this.handleChangeDatos}
            locale={{
              filterConfirm: "Aceptar",
              filterReset: "Cancelar",
              emptyText: "No Datos",
            }}
          />
        </ConfigProvider>
      </div>,

      document.getElementById("infoRecetasNomComercialesGuarnicion")
    );
  }

  /* Mostrar Multi-level drawer actualizando el estado visible */
  multiLevelDrawer = () => {
    this.getListaLineasProduccion();
    this.getListaDatosClientes();
    this.getListaDatosEmpresas();
    this.getListaObsEspeciales();
    getDatosPlatoPrincipalGuarnicionSeleccionada(this.updateStates, this.state)
    this.setState({
      visible: true,
    });
  }

  showModalConfigImpresion = () => {
    const tieneRecetas = this.state.selectedRowKeysRecetas.length > 0
    const tieneGuarnicion = this.state.selectedRowKeysGuarnicion.length > 0

    if (tieneRecetas && tieneGuarnicion) {
      this.multiLevelDrawer()

      return
    }

    let title = 'Advertencia'
    let content = 'Debes seleccionar un plato antes de Configurar la Impresión.'
    let openDrawer = false
    if (tieneRecetas && !tieneGuarnicion) {
      title = '¿El Plato Principal tiene Guarnición?'
      content = 'Recuerda: Si el Plato Principal lleva guarnición debes seleccionarla antes de configurar e imprimir la etiqueta.'
      openDrawer = true
    }

    if (!tieneRecetas && tieneGuarnicion) {
      title = '¿La Guarnición tiene Plato Principal?'
      content = 'Recuerda: Si la Guarnición lleva Plato Principal debes seleccionarla antes de configurar e imprimir la etiqueta.'
      openDrawer = true
    }

    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: content,
      onOk: () => {
        if (openDrawer) {
          this.multiLevelDrawer()
        }
      },
      onCancel() { },
      cancelText: "Cancelar",
      okText: "Aceptar",
    })
  };

  changeTabPosition = (tabPosition) => {
    this.setState({ tabPosition });
  };

  onClose = () => {
    this.setState({
      visible: false,
      tabPaneActiva: "1",
      txtAreaAlergenosTotal: true,
      txtAreaIngredientesTotal: true,
      txtAreaAlergenosGuarnicion: true,
      txtAreaIngredientesGuarnicion: true,
      botonEditarIngredientesTotal: false,
      botonEditarAlergenosTotal: false,
      deshabilitarBotonEditarIngGuarnicion: false,
      deshabilitarBotonEditarAlergenosGuarnicion: false,
    });
  };

  getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Cancelar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  showDrawerDatosEmpresa = () => {
    this.setState({
      visibleDrawerDatosEmpresa: true,
    });

    const listaCatalogoId = this.state.listaCatalogoId;

    database.obtenerDatosEmpresas(codEmpresa, listaCatalogoId).then((res) => {
      this.setState({
        listaDatosEmpresas: res.response,
      });

      const dataTablaDatosEmpresas = this.state.listaDatosEmpresas.map(companyData => ({
        CODIGO_UCP: companyData.CODIGO_UCP,
        ID_UCP: companyData.ID_UCP,
        NOMBRE_UCP: companyData.NOMBRE_UCP,
        NOMBRE_SOCIAL_UCP: companyData.NOMBRE_SOCIAL_UCP,
        DIRECCION_UCP: companyData.DIRECCION_UCP,
        CIF_UCP: companyData.CIF_UCP,
        REGISTRO_SANITARIO_UCP: companyData.REGISTRO_SANITARIO_UCP,
        CATALOGO: companyData.CATALOGO,
      }))

      const constRowSelectionDatosEmpresas = {
        onChange: (selectedRowKeys, selectedRows) => {
          this.setState({
            selectedRowsDatosEmpresas: selectedRows,
            selectedRowKeysDatosEmpresas: selectedRowKeys,
          });
        },
      };

      this.setState({
        datosEmpresas: dataTablaDatosEmpresas,
        rowSelectionDatosEmpresas: constRowSelectionDatosEmpresas,
      });
    });

  }

  onCloseDrawerDatosEmpresa = () => {
    this.setState({
      visibleDrawerDatosEmpresa: false,
    });
  };

  showDrawerClientes = () => {
    this.setState({
      visibleDrawerClientes: true,
    });

    const listaCatalogoId = this.state.listaCatalogoId;

    database.obtenerDatosClientes(listaCatalogoId).then((res) => {
      this.setState({
        listaDatosClientes: res.response,
      });

      const dataTablaDatosClientes = this.state.listaDatosClientes.map(clientData => ({
        CODIGO_CLIENTES: clientData.CODIGO_CLIENTES,
        NOMBRE_CLIENTES: clientData.NOMBRE_CLIENTES,
        CATALOGO: clientData.CATALOGO,
      }))

      const constRowSelectionDatosClientes = {
        onChange: (selectedRowKeys, selectedRows) => {
          this.setState({
            selectedRowsDatosClientes: selectedRows,
            selectedRowKeysDatosClientes: selectedRowKeys,
          });
        },
      };

      this.setState({
        datosClientes: dataTablaDatosClientes,
        rowSelectionDatosClientes: constRowSelectionDatosClientes,
      });
    });

  };

  onCloseDrawerClientes = () => {
    this.setState({
      visibleDrawerClientes: false,
    });
  };

  showDrawerObsEspeciales = () => {
    this.setState({
      visibleDrawerObsEspeciales: true,
    });

    const listaCatalogoId = this.state.listaCatalogoId;
    database.obtenerObsEspeciales(listaCatalogoId).then((res) => {
      this.setState({
        listaObsEspeciales: res.response,
      });

      const dataTablaObsEspeciales = this.state.listaObsEspeciales.map(specialsData => ({
        CODIGO_OBS_ESPECIALES: specialsData.CODIGO_OBS_ESPECIALES,
        NOMBRE_OBS_ESPECIALES: specialsData.NOMBRE_OBS_ESPECIALES,
        CATALOGO: specialsData.CATALOGO,
      }))

      const constRowSelectionObsEspeciales = {
        onChange: (selectedRowKeys, selectedRows) => {
          this.setState({
            selectedRowsObsEspeciales: selectedRows,
            selectedRowKeysObsEspeciales: selectedRowKeys,
          })
        },
      };

      this.setState({
        datosObsEspeciales: dataTablaObsEspeciales,
        rowSelectionObsEspeciales: constRowSelectionObsEspeciales,
      });
    });

  };

  onCloseDrawerObsEspeciales = () => {
    this.setState({
      visibleDrawerObsEspeciales: false,
    });
  };

  showDrawerLineasProduccion = () => {
    this.setState({
      visibleDrawerLineasProduccion: true,
    });

    const listaCatalogoId = this.state.listaCatalogoId;
    database.obtenerLineasProduccion(listaCatalogoId).then((res) => {
      this.setState({
        listaLineasProduccion: res.response,
      });

      const dataTablaLineasProduccion = this.state.listaLineasProduccion.map(productionLine => ({
        CODIGO_LINEA_PRODUCCION: productionLine.CODIGO_LINEA_PRODUCCION,
        NOMBRE_LINEA_PRODUCCION: productionLine.NOMBRE_LINEA_PRODUCCION,
        TIPO_FECHA_CADUCIDAD: productionLine.TIPO_FECHA_CADUCIDAD,
        DESC_TIPO_FECHA_CADUCIDAD: productionLine.DESC_TIPO_FECHA_CADUCIDAD,
        TEXTO_FECHA_CADUCIDAD: productionLine.TEXTO_FECHA_CADUCIDAD,
        DIAS_CADUCIDAD: productionLine.DIAS_CADUCIDAD,
        CONDICIONES_CONSERVACION: productionLine.CONDICIONES_CONSERVACION,
        MODO_EMPLEO: productionLine.MODO_EMPLEO,
        CATALOGO: productionLine.CATALOGO,
      }))

      const constRowSelectionLineasProduccion = {
        onChange: (selectedRowKeys, selectedRows) => {
          this.setState({
            selectedRows: selectedRows,
            selectedRowKeys: selectedRowKeys,
          });
        },
      };

      const columnsLineasProduccion = [
        {
          title: "Código",
          dataIndex: "CODIGO_LINEA_PRODUCCION",
          key: "CODIGO_LINEA_PRODUCCION",
          ellipsis: true,
          width: 80,
        },
        {
          title: "Nombre",
          dataIndex: "NOMBRE_LINEA_PRODUCCION",
          key: "NOMBRE_LINEA_PRODUCCION",
          ellipsis: true,
          width: 350,
          ...this.getColumnSearchProps("NOMBRE_LINEA_PRODUCCION", "Buscar Nombre Datos Empresas"),
          sorter: (a, b) =>
            a.NOMBRE_LINEA_PRODUCCION.length -
            b.NOMBRE_LINEA_PRODUCCION.length,
          sortDirections: ["descend", "ascend"],
          showSorterTooltip: false,
        },
        {
          title: "Tipo Fecha Caducidad",
          dataIndex: "TIPO_FECHA_CADUCIDAD",
          key: "TIPO_FECHA_CADUCIDAD",
          ellipsis: true,
          width: 150,
        },
        {
          title: "Descripción Tipo Fecha Caducidad",
          dataIndex: "DESC_TIPO_FECHA_CADUCIDAD",
          key: "DESC_TIPO_FECHA_CADUCIDAD",
          ellipsis: true,
          width: 350,
        },
        {
          title: "Texto Fecha Caducidad",
          dataIndex: "TEXTO_FECHA_CADUCIDAD",
          key: "TEXTO_FECHA_CADUCIDAD",
          ellipsis: true,
          width: 400,
        },
        {
          title: "Dias Caducidad",
          dataIndex: "DIAS_CADUCIDAD",
          key: "DIAS_CADUCIDAD",
          ellipsis: true,
          width: 120,
        },
        {
          title: "Condiciones Conservación",
          dataIndex: "CONDICIONES_CONSERVACION",
          key: "CONDICIONES_CONSERVACION",
          ellipsis: true,
          width: 350,
        },
        {
          title: "Modo Empleo",
          dataIndex: "MODO_EMPLEO",
          key: "MODO_EMPLEO",
          ellipsis: true,
          width: 350,
        },
      ];

      setTimeout(() => {
        this.setState({
          selectedRowKeys: [],
          selectedRow: [],
          loading: false,
        });
      }, 1000);

      ReactDOM.render(
        <div>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Divider orientation="center">
              Listado de Líneas de Producción
            </Divider>

            <Table
              rowSelection={{
                type: "radio",
                ...constRowSelectionLineasProduccion,
              }}
              rowKey={(record) => record.CODIGO_LINEA_PRODUCCION}
              size="small"
              columns={columnsLineasProduccion}
              dataSource={dataTablaLineasProduccion}
              onChange={this.handleChangeDatos}
              bordered
              pagination={{ defaultPageSize: 20 }}
            />
          </ConfigProvider>
        </div>,

        document.getElementById("tablaLP")
      );

      this.setState({
        datosLineasProduccion: dataTablaLineasProduccion,
        rowSelectionLineasProduccion: constRowSelectionLineasProduccion,
      });
    });

  };

  onCloseDrawerLineasProduccion = () => {
    this.setState({
      visibleDrawerLineasProduccion: false,
    });
  };

  showChildrenDrawerDatosEmpresa = () => {
    this.setState({
      childrenDrawerDatosEmpresa: true,
    });
  };

  onChildrenDrawerCloseDatosEmpresa = () => {
    this.setState({
      childrenDrawerDatosEmpresa: false,
    });
  };

  showChildrenDrawerClientes = () => {
    this.setState({
      childrenDrawerClientes: true,
    });
  };

  onChildrenDrawerCloseClientes = () => {
    this.setState({
      childrenDrawerClientes: false,
    });
  };

  showChildrenDrawerObsEspeciales = () => {
    this.setState({
      childrenDrawerObsEspeciales: true,
    });
  };

  onChildrenDrawerCloseObsEspeciales = () => {
    this.setState({
      childrenDrawerObsEspeciales: false,
    });
  };

  showChildrenDrawerLineasProduccion = () => {
    this.setState({
      childrenDrawerLineasProduccion: true,
    });
  };

  onChildrenDrawerCloseLineasProduccion = () => {
    this.setState({
      childrenDrawerLineasProduccion: false,
    });
  };

  showChildrenDrawerModificarDatosEmpresa = () => {
    if (this.state.selectedRowKeysDatosEmpresas.length === 1 && this.state.selectedRowsDatosEmpresas.length === 1) {
      this.setState({
        childrenDrawerModificarDatosEmpresa: true,
      })

      return
    }

    Modal.error({
      title: "Error",
      content: "Debes seleccionar un centro para editarlo.",
    })
  }

  onChildrenDrawerCloseModificarDatosEmpresa = () => {
    this.setState({
      childrenDrawerModificarDatosEmpresa: false,
    })
  }

  showChildrenDrawerModificarDatosClientes = () => {
    if (this.state.selectedRowKeysDatosClientes.length === 1 && this.state.selectedRowsDatosClientes.length === 1) {
      this.setState({
        childrenDrawerModificarDatosClientes: true,
      })

      return
    }

    Modal.error({
      title: "Error",
      content: "Debes seleccionar un cliente para editarlo.",
    })
  };

  onChildrenDrawerCloseModificarDatosClientes = () => {
    this.setState({
      childrenDrawerModificarDatosClientes: false,
    });
  };

  showChildrenDrawerModificarObsEspeciales = () => {
    if (this.state.selectedRowKeysObsEspeciales.length === 1 && this.state.selectedRowsObsEspeciales.length === 1) {
      this.setState({
        childrenDrawerModificarObsEspeciales: true,
      })

      return
    }

    Modal.error({
      title: "Error",
      content: "Debes seleccionar una Observación Especial para editarla.",
    });

  };

  onChildrenDrawerCloseModificarObsEspeciales = () => {
    this.setState({
      childrenDrawerModificarObsEspeciales: false,
    });
  };

  showChildrenDrawerModificarLineasProduccion = () => {
    if (this.state.selectedRowKeys.length === 1 && this.state.selectedRows.length === 1) {
      this.setState({
        childrenDrawerModificarLineasProduccion: true,
      })

      return
    }

    Modal.error({
      title: "Error",
      content: "Debes seleccionar una Línea de Producción para editarla.",
    })

  };

  onChildrenDrawerCloseModificarLineasProduccion = () => {
    this.setState({
      childrenDrawerModificarLineasProduccion: false,
    });
  };

  getListaLineasProduccion = () => {
    const catalogoSeleccionadoId = [this.state.catalogoSeleccionadoId];

    database.obtenerLineasProduccion(catalogoSeleccionadoId).then((res) => {
      this.setState({
        listaLineasProduccion: res.response,
      });

      let resultadoLineaProduccion = this.state.listaLineasProduccion.map(
        ({ CODIGO_LINEA_PRODUCCION, DIAS_CADUCIDAD, NOMBRE_LINEA_PRODUCCION }) => (
          <Select.Option
            key={`${CODIGO_LINEA_PRODUCCION}.${DIAS_CADUCIDAD}`}
            label="nameLineasProduccion"
            title={`${CODIGO_LINEA_PRODUCCION}.${NOMBRE_LINEA_PRODUCCION}`}
          >
            {NOMBRE_LINEA_PRODUCCION}
          </Select.Option>
        ))

      this.setState({
        optionLineaProduccion: resultadoLineaProduccion,
      });
    });

  };

  getListaDatosClientes = () => {
    const listaCatalogoId = this.state.listaCatalogoId;

    database.obtenerDatosClientes(listaCatalogoId).then((res) => {
      this.setState({
        listaDatosClientes: res.response,
      });

      const resultadoDatosClientes = []
      this.state.listaDatosClientes.forEach(
        ({ CODIGO_CLIENTES, NOMBRE_CLIENTES }, index) => {
          if (index === 0) {
            resultadoDatosClientes.push(
              <Select.Option key="0" label="" title="" value={null}>
                {" "}
              </Select.Option>
            )
          }

          resultadoDatosClientes.push(
            <Select.Option
              key={`${CODIGO_CLIENTES}.${NOMBRE_CLIENTES}`}
              label={`${CODIGO_CLIENTES}.${NOMBRE_CLIENTES}`}
              title={`${CODIGO_CLIENTES}.${NOMBRE_CLIENTES}`}
            >
              {NOMBRE_CLIENTES}
            </Select.Option>
          );
        })

      this.setState({
        optionDatosClientes: resultadoDatosClientes,
      });
    });

  };

  getListaDatosEmpresas = () => {
    const listaCatalogoId = this.state.listaCatalogoId;

    database.obtenerDatosEmpresas(codEmpresa, listaCatalogoId).then((res) => {
      this.setState({
        listaDatosEmpresas: res.response,
      });

      const resultadoDatosEmpresas = this.state.listaDatosEmpresas.map(
        ({ CODIGO_UCP, ID_UCP, NOMBRE_UCP }) => (
          <Select.Option
            key={`${CODIGO_UCP}.${ID_UCP}`}
            label={`${CODIGO_UCP}.${NOMBRE_UCP}`}
            title={`${CODIGO_UCP}.${NOMBRE_UCP}`}
          >
            {ID_UCP +
              ` - ` +
              NOMBRE_UCP}
          </Select.Option>
        ))

      this.setState({
        optionDatosEmpresas: resultadoDatosEmpresas,
      });
    });

  };

  getListaObsEspeciales = () => {
    const listaCatalogoId = this.state.listaCatalogoId;

    database.obtenerObsEspeciales(listaCatalogoId).then((res) => {
      this.setState({
        listaObsEspeciales: res.response,
      });

      const resultadoObsEspeciales = this.state.listaObsEspeciales.map(({ CODIGO_OBS_ESPECIALES, NOMBRE_OBS_ESPECIALES }) => (
        <Select.Option
          key={`${CODIGO_OBS_ESPECIALES}.${NOMBRE_OBS_ESPECIALES}`}
          label={`${CODIGO_OBS_ESPECIALES}.${NOMBRE_OBS_ESPECIALES}`}
          title={`${CODIGO_OBS_ESPECIALES}.${NOMBRE_OBS_ESPECIALES}`}
        >
          {NOMBRE_OBS_ESPECIALES}
        </Select.Option>
      ))

      this.setState({
        optionObsEspeciales: resultadoObsEspeciales,
      });
    });
  }

  render() {
    const { Option } = Select;

    /* De esta forma obtengo el estado actual que tiene cada elemento. */
    const {
      loadingSpingImprimirEtiquetas,
      textoLoadingSpingImprimirEtiquetas,
    } = this.state;

    const selectCatalogosUsuario = this.state.listaOptionsCatalogosUsuario.map(({ CATALOGO, NOMBRE }) => (
      <Select.Option
        key={`${CATALOGO}.${NOMBRE}`}
        label={NOMBRE}
        title={NOMBRE}
      >
        {`${CATALOGO}.${NOMBRE}`}
      </Select.Option>
    ))

    const resultadoIdiomas = this.state.listaIdiomas.map(({ COD_IDIOMA, DESC_IDIOMA }) => (
      <Select.Option
        key={COD_IDIOMA}
        label={`${COD_IDIOMA}.${DESC_IDIOMA}`}
        title={`${COD_IDIOMA}.${DESC_IDIOMA}`}
      >
        {DESC_IDIOMA}
      </Select.Option>
    ))

    const hasSelected = this.state.catalogoSeleccionado.length > 0;

    const AdvancedSearchForm = () => {
      const [form] = Form.useForm();

      const onFinish = (values) => {
        const volresRecetasIdiomas = Object.values(values);

        const n = volresRecetasIdiomas[0].indexOf(".");
        const catalogo = volresRecetasIdiomas[0].substring(0, n);

        const selectIdiomas = volresRecetasIdiomas[1];
        const selectCatalogo = catalogo;

        this.handleSearchRecetas(
          selectIdiomas,
          selectCatalogo,
          volresRecetasIdiomas[0]
        );
      };

      return (
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <Row gutter={8}>
            <Col
              xs={{ span: 12, offset: 1 }}
              lg={{ span: 8, offset: 1 }}
              style={{ float: "left" }}
            >
              <Form.Item
                hasFeedback
                name="select-Catalogo"
                rules={[
                  {
                    required: true,
                    message: "Campo obligatorio",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seleccionar Catálogo"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectCatalogosUsuario}
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 12, offset: 1 }}
              lg={{ span: 6, offset: 1 }}
              style={{ float: "left" }}
            >
              <Form.Item
                hasFeedback
                name="select-Idiomas"
                rules={[
                  {
                    required: true,
                    message: "Campo obligatorio.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seleccionar Idioma"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {resultadoIdiomas}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }}>
              <Button
                key="back"
                onClick={() => {
                  form.resetFields();
                }}
                style={{ float: "left" }}
              >
                Cancelar
              </Button>

              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                style={{ float: "left", marginLeft: 20 }}
              >
                Aceptar
              </Button>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col
              xs={{ span: 12, offset: 1 }}
              lg={{ span: 14, offset: 1 }}
              style={{ float: "left", marginTop: 0 }}
            >
              <span>
                {" "}
                {hasSelected
                  ? `Catálogo Seleccionado: ${this.state.catalogoSeleccionado}`
                  : ""}
              </span>
            </Col>
          </Row>
        </Form>
      );
    };

    return (
      <div>
        <Spin
          spinning={loadingSpingImprimirEtiquetas}
          delay={500}
          tip={textoLoadingSpingImprimirEtiquetas}
        >
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div>
              <AdvancedSearchForm />

              <Row>
                <Col span={24}>
                  <div style={{ float: "right" }}>

                    <Tooltip placement="topLeft" title={textoIconDatosEmpresa}>
                      <Button
                        size="default"
                        style={{
                          boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                          borderColor: "#fff",
                          border: 0,
                          backgroundColor: "#fff",
                        }}
                        onClick={this.showDrawerDatosEmpresa}
                        type="dashed"
                      >
                        <ProfileOutlined />
                      </Button>
                    </Tooltip>

                    <Divider type="vertical" />

                    <Tooltip placement="topLeft" title={textoIconClientes}>
                      <Button
                        size="default"
                        style={{
                          boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                          borderColor: "#fff",
                          border: 0,
                          backgroundColor: "#fff",
                        }}
                        onClick={this.showDrawerClientes}
                        type="dashed"
                      >
                        <ContactsOutlined />
                      </Button>
                    </Tooltip>

                    <Divider type="vertical" />

                    <Tooltip
                      placement="topLeft"
                      title={textoIconLineasProduccion}
                    >
                      <Button
                        size="default"
                        style={{
                          boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                          borderColor: "#fff",
                          border: 0,
                          backgroundColor: "#fff",
                        }}
                        onClick={this.showDrawerLineasProduccion}
                        type="dashed"
                      >
                        <LineOutlined />
                      </Button>
                    </Tooltip>

                    <Divider type="vertical" />

                    <Tooltip placement="topLeft" title={textoIconObsEspeciales}>
                      <Button
                        size="default"
                        style={{
                          boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                          borderColor: "#fff",
                          border: 0,
                          backgroundColor: "#fff",
                        }}
                        onClick={this.showDrawerObsEspeciales}
                        type="dashed"
                      >
                        <ScheduleOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </Col>
              </Row>

              <div id="infoRecetasNomComerciales">
                {/* Se muestra los resultados de la búsqueda de las recetas. */}
              </div>
              <div id="infoRecetasNomComercialesGuarnicion">
                {/* Se muestra las recetas que son guarnición. */}
              </div>
              <Drawer
                title="Configurar Impresión"
                width={630}
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
              >
                <DrawerOpcionesForm
                  formRef={this.formRef}
                  onClose={this.onClose}
                  state={this.state}
                  updateStates={this.updateStates}
                />
              </Drawer>

              <DatosEmpresasBotonera
                enviarDatosEmpresas={this.enviarDatosEmpresas}
                getColumnSearchProps={this.getColumnSearchProps}
                handleChangeDatos={this.handleChangeDatos}
                onChildrenDrawerCloseDatosEmpresa={this.onChildrenDrawerCloseDatosEmpresa}
                onChildrenDrawerCloseModificarDatosEmpresa={this.onChildrenDrawerCloseModificarDatosEmpresa}
                onCloseDrawerDatosEmpresa={this.onCloseDrawerDatosEmpresa}
                selectCatalogosUsuario={selectCatalogosUsuario}
                showChildrenDrawerDatosEmpresa={this.showChildrenDrawerDatosEmpresa}
                showChildrenDrawerModificarDatosEmpresa={this.showChildrenDrawerModificarDatosEmpresa}
                showDrawerDatosEmpresa={this.showDrawerDatosEmpresa}
                state={this.state}
                updateStates={this.updateStates}
              />

              <DatosClientesBotonera
                enviarDatosClientes={this.enviarDatosClientes}
                getColumnSearchProps={this.getColumnSearchProps}
                handleChangeDatos={this.handleChangeDatos}
                onChildrenDrawerCloseClientes={this.onChildrenDrawerCloseClientes}
                onChildrenDrawerCloseModificarDatosClientes={this.onChildrenDrawerCloseModificarDatosClientes}
                onCloseDrawerClientes={this.onCloseDrawerClientes}
                selectCatalogosUsuario={selectCatalogosUsuario}
                showChildrenDrawerClientes={this.showChildrenDrawerClientes}
                showChildrenDrawerModificarDatosClientes={this.showChildrenDrawerModificarDatosClientes}
                showDrawerClientes={this.showDrawerClientes}
                state={this.state}
                updateStates={this.updateStates}
              />

              <DatosLineasDeProduccionBotonera
                enviarLineasProduccion={this.enviarLineasProduccion}
                onChildrenDrawerCloseLineasProduccion={this.onChildrenDrawerCloseLineasProduccion}
                onChildrenDrawerCloseModificarLineasProduccion={this.onChildrenDrawerCloseModificarLineasProduccion}
                onCloseDrawerLineasProduccion={this.onCloseDrawerLineasProduccion}
                selectCatalogosUsuario={selectCatalogosUsuario}
                showChildrenDrawerLineasProduccion={this.showChildrenDrawerLineasProduccion}
                showChildrenDrawerModificarLineasProduccion={this.showChildrenDrawerModificarLineasProduccion}
                showDrawerLineasProduccion={this.showDrawerLineasProduccion}
                state={this.state}
                updateStates={this.updateStates}
              />

              <DatosObsEspecialesBotonera
                enviarObsEspeciales={this.enviarObsEspeciales}
                getColumnSearchProps={this.getColumnSearchProps}
                handleChangeDatos={this.handleChangeDatos}
                onChildrenDrawerCloseModificarObsEspeciales={this.onChildrenDrawerCloseModificarObsEspeciales}
                onChildrenDrawerCloseObsEspeciales={this.onChildrenDrawerCloseObsEspeciales}
                onCloseDrawerObsEspeciales={this.onCloseDrawerObsEspeciales}
                selectCatalogosUsuario={selectCatalogosUsuario}
                showChildrenDrawerModificarObsEspeciales={this.showChildrenDrawerModificarObsEspeciales}
                showChildrenDrawerObsEspeciales={this.showChildrenDrawerObsEspeciales}
                showDrawerObsEspeciales={this.showDrawerObsEspeciales}
                state={this.state}
                updateStates={this.updateStates}
              />
            </div>
          </ConfigProvider>
        </Spin>
        <BackTop />
      </div>
    );
  }
}

export default ImprimirEtiquetas;
