'use strict'
import React, { Component, TableHead, TableRow, TableCell, TableBody } from 'react'
import ReactDOM from 'react-dom'
import {
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Button,
  Spin,
  Divider,
  message,
  Modal,
  Progress,
  Cascader,
  Select,
  AutoComplete,
  Table,
  notification,
  Tooltip,
  ConfigProvider,
  BackTop,
  Checkbox,
  Drawer,
  Tabs,
  DatePicker,
  Popconfirm,
} from 'antd'
import { database } from '../services/database.service'
import { userService } from '../services'
import { CSVDownload, CSVLink } from 'react-csv'
import Highlighter from 'react-highlight-words'
import {
  DatabaseOutlined,
  FileExcelOutlined,
  PrinterOutlined,
  ProfileOutlined,
  ContactsOutlined,
  ScheduleOutlined,
  EditOutlined,
  LineOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  ClearOutlined,
  FormOutlined,
  DeleteOutlined,
  CodeSandboxCircleFilled,
} from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment from 'moment'
import PDFDocument from 'pdfkit'
import blobStream from 'blob-stream'
import axios from 'axios'
import { getBufferLogo } from './pdfBuildFunctions'
const largo = 102 * 2.83465
const ancho = 102 * 2.83465
const ancho75 = 75 * 2.83465
var largo43 = 43 * 2.83465
var ancho91 = 91 * 2.83465
//import  { llamarActualizarEstadosSpingImprimirEtiquetas }  from '../menus/ImprimirEtiquetas';

export const plantillas = {
  imprimirEtiquetasGeneralPlantilla3,
  imprimirEtiquetasGeneralPlantilla4,
  imprimirEtiquetasGeneralPlantilla5,
  imprimirEtiquetasGeneralPlantilla6,
  imprimirEtiquetasGeneralPlantilla7,
  imprimirEtiquetasGeneralPlantilla8,
  imprimirEtiquetasGeneralPlantilla9,
  //secure,
  imprimirEtiquetasGeneral,
}

async function imprimirEtiquetasGeneral(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  receta,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    var loteCodigoBarra =
      codLote.substring(2, 8) +
      fechaCaducidad.substring(8, 10) +
      fechaCaducidad.substring(3, 5) +
      fechaCaducidad.substring(0, 2) +
      receta

    console.log('loteCodigoBarra: ', loteCodigoBarra)
    console.log('receta ', receta)

    var caracteresPar = 0
    var caracteresImpares = 0

    for (let i = 0; i < loteCodigoBarra.length + 1; i++) {
      if (i % 2 === 0) {
        caracteresPar += Number(loteCodigoBarra.charAt(i))
      } else if (i % 2 != 0) {
        caracteresImpares += Number(loteCodigoBarra.charAt(i))
      }
    }

    var caracteresParPor1 = caracteresPar * 1

    var caracteresImparesPor3 = caracteresImpares * 3

    var sumaResultado = caracteresParPor1 + caracteresImparesPor3

    var decenaSuperior = sumaResultado - (sumaResultado % 10) + 10

    var calcularDigitoControl = decenaSuperior - sumaResultado

    var loteACodificarDigitoControl = loteCodigoBarra + calcularDigitoControl

    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      (res) => {
        const datosCocinaImprimir = res.response

        database.obtenerBarcode(loteACodificarDigitoControl).then(
          (res) => {
            var barcodeImagen = res.response

            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAbarcodeImagen')
            console.log(barcodeImagen)
            console.log('fechaCaducidad')
            console.log(fechaCaducidad)

            setTimeout(async () => {
              // this.setState({
              //     loadingSpingImprimirEtiquetas: true,
              //     textoLoadingSpingImprimirEtiquetas: 'Imprimiendo etiquetas ...',
              //     loadingButtonLanzarImpresion: true
              // });

              var observacionesTotales = ''
              var condConservacion = ''
              var modoEmpleo = ''
              console.log(infoNutricionalReceta100)
              // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
              // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
              // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

              if (codObservacionesEspeciales.length != 0) {
                for (let i = 0; i < codObservacionesEspeciales.length; i++) {
                  var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

                  observacionesTotales += observacion[1] + '.\n'
                }
              } else {
                observacionesTotales = ''
              }

              //observacionesTotales = observacionesTotales.length > 0 ? observacionesTotales : '';

              if (listaLineasProduccion.length != 0) {
                for (let a = 0; a < listaLineasProduccion.length; a++) {
                  if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
                    condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
                    modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
                  }
                }
              }

              // console.log('codLineaProduccion: ', codLineaProduccion);
              // console.log('codCliente: ', codCliente[1]);
              // console.log('fechaElaboracion: ', fechaElaboracion);//OK
              // console.log('totalDiasCaducidad: ', totalDiasCaducidad);
              // console.log('fechaCaducidad: ', fechaCaducidad);//OK
              // console.log('codLote: ', codLote);//OK
              // console.log('codCocina: ', codCocina);
              // //console.log('codPlantilla: ', codPlantilla);//Por ahora no se usa
              // console.log('codObservacionesEspeciales: ', codObservacionesEspeciales);
              // console.log('observacionesTotales: ', observacionesTotales);
              // console.log('rutaLogoIzquierdo: ', rutaLogoIzquierdo);//OK
              // console.log('rutaLogoDerecho: ', rutaLogoDerecho);//OK
              // console.log('observaciones: ', observaciones);//OK
              // console.log('infoNutricionalReceta: ', infoNutricionalReceta);//OK
              // console.log('infoNutricionalReceta100: ', infoNutricionalReceta100);//OK,
              // //console.log('ingredientesAlergenosTotal: ' + ingredientesAlergenosTotal);
              //console.log('alergenoTotal: ' + alergenoTotal);

              //var listaCatalogoId = this.state.listaCatalogoId;

              // create a document and pipe to a blob
              var doc = new PDFDocument({
                //layout: "landscape",
                size: [largo, ancho],
                margin: 5,
                info: {
                  Title: 'Etiquetas',
                  Author: 'Alejandro Vázquez Chávez',
                },
              })
              var stream = doc.pipe(blobStream())

              for (let e = 0; e < cantidadEtiqImprimir; e++) {
                doc
                  .fontSize(7)
                  .font('Helvetica')
                  .fillColor('black')
                  .text('SERVICIO DE CATERING Y COLECTIVIDADES', 68, 13, {
                    width: 280,
                    height: 60,
                  })

                //Dirección Cocina
                doc
                  .fontSize(6)
                  .font('Helvetica')
                  .fillColor('black')
                  .text(datosCocinaImprimir[0].DIRECCION_UCP, 80, 23, {
                    width: 135,
                    height: 20,
                    align: 'center',
                  })

                //Nombre Cocina
                doc
                  .fontSize(5.5)
                  .font('Helvetica')
                  .fillColor('black')
                  .text(
                    datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
                      ' - ' +
                      'NRGSA: ' +
                      datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
                    60,
                    38,
                    {
                      width: 162,
                      height: 10,
                      align: 'center',
                    }
                  )

                //Logo izquierdo
                if (rutaLogoIzquierdo != undefined || rutaLogoIzquierdo != null) {
                  const buffer = await getBufferLogo(rutaLogoIzquierdo)
                  doc.image(buffer, 13, 5, {
                    scale: 0.12,
                  })
                }

                //Logo derecho
                if (rutaLogoDerecho != undefined || rutaLogoDerecho != null) {
                  const buffer = await getBufferLogo(rutaLogoDerecho)
                  doc.image(buffer, 232, 5, {
                    scale: 0.12,
                  })
                }

                //Número de Registro Sanitario Cocina
                // doc
                //     .fontSize(5.5)
                //     .font('Helvetica')
                //     .fillColor('black')
                //     .text('NRGSA: ' + datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP, 13, 55, {
                //         width: 100,
                //         height: 12
                //     });

                //Nombre Cliente
                if (codCliente.length != 0) {
                  doc
                    .fontSize(8)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('CLIENTE: ' + codCliente[1] === null ? '' : codCliente[1], 8, 48, {
                      continued: true,
                    })
                    .text('\n')
                }

                var plato =
                  nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

                // doc
                //     .fontSize(9)
                //     .font('Helvetica-Bold')
                //     .fillColor('black')
                //     .text(plato , 13, 74, {
                //         width: 260,
                //         height: heightPlato
                //     });

                //Nombre Plato
                if (ingredientesPrincipal != null) {
                  doc
                    .fontSize(9)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text(plato, 8, codCliente.length != 0 ? 58 : 53, {
                      width: 264,
                      continued: true,
                    })

                  if (texto01 != undefined && texto01.length > 0) {
                    doc.text('\n').fontSize(9).font('Helvetica-Bold').text(texto01, {
                      width: 260,
                      align: 'justify',
                      continued: true,
                    })
                  }
                  if (ingredientesPrincipal.length > 0) {
                    doc
                      .text('\n')
                      .fontSize(7.2)
                      .font('Helvetica-Bold')
                      .fillColor('black')
                      .text('INGREDIENTES', {
                        width: 264,
                        align: 'justify',
                        continued: true,
                      })
                      .font('Helvetica')
                      .text(': ' + ingredientesPrincipal)
                  }
                } else {
                  if (ingredientesPrincipal.length > 0) {
                    doc
                      .text('\n')
                      .fontSize(9)
                      .font('Helvetica-Bold')
                      .fillColor('black')
                      .text(plato, 8, 74, {
                        width: 264,
                        continued: false,
                      })
                      .text('\n')
                      .fontSize(7.2)
                      .font('Helvetica-Bold')
                      .fillColor('black')
                      .text('INGREDIENTES', {
                        width: 264,
                        align: 'justify',
                        continued: true,
                      })
                      .font('Helvetica')
                      .text(': ' + ingredientesPrincipalEtiqueta)
                  }
                }
                //   .font('Helvetica-Bold')
                //   .text('INGREDIENTES GUARNICIÓN: ', {
                //     width: 264,
                //     align: 'justify',
                //     continued: true
                //   }).font('Helvetica')
                //   .text(ingredientesGuarnicionEtiqueta.length)
                //   .font('Helvetica-Bold')
                //   .fillColor('black')
                //   .text('CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos', {
                //     width: 264,
                //     align: 'justify'
                //   });

                // var margenTopIngredientes = heightPlato === 30 ? 95 : 84
                // //Ingredientes Principal
                // doc
                //     .fontSize(6.5)
                //     .font('Helvetica-Bold')
                //     .fillColor('black')
                //     .text('INGREDIENTES PRINCIPAL: ', 13, margenTopIngredientes , {
                //         width: 260,
                //         height: 70,
                //         align: 'justify',
                //         continued: true
                //     }).font('Helvetica')
                //     .text(ingredientesPrincipalEtiqueta)

                //Ingredientes Guarnición - Para ponerlo consecutivo tengo que calcular el desplazamiento.
                if (ingredientesGuarnicion.length != 0) {
                  if (ingredientesPrincipal.length > 0) {
                    doc
                      .fontSize(7.2)
                      .font('Helvetica-Bold')
                      .fillColor('black')
                      .text('INGREDIENTES GUARNICIÓN: ', {
                        width: 264,
                        align: 'justify',
                        continued: true,
                      })
                      .font('Helvetica')
                      .text(ingredientesGuarnicion)
                  } else {
                    doc
                      .text('\n')
                      .fontSize(7.2)
                      .font('Helvetica-Bold')
                      .fillColor('black')
                      .text('INGREDIENTES GUARNICIÓN: ', {
                        width: 264,
                        align: 'justify',
                        continued: true,
                      })
                      .font('Helvetica')
                      .text(ingredientesGuarnicion)
                  }
                } else if (ingredientesGuarnicionEtiqueta.length != 0) {
                  if (ingredientesPrincipal.length > 0) {
                    doc
                      .fontSize(7.2)
                      .font('Helvetica-Bold')
                      .fillColor('black')
                      .text('INGREDIENTES GUARNICIÓN: ', {
                        width: 264,
                        align: 'justify',
                        continued: true,
                      })
                      .font('Helvetica')
                      .text(ingredientesGuarnicionEtiqueta)
                  } else {
                    doc
                      .text('\n')
                      .fontSize(7.2)
                      .font('Helvetica-Bold')
                      .fillColor('black')
                      .text('INGREDIENTES GUARNICIÓN: ', {
                        width: 264,
                        align: 'justify',
                        continued: true,
                      })
                      .font('Helvetica')
                      .text(ingredientesGuarnicionEtiqueta)
                  }
                }


                if(!ingredientesGuarnicion && !ingredientesPrincipal) {
                  console.log("CASO ESPECIAl 1")
                  doc
                      .text("\n")

                }

                var alergenosTotal = ''
                if (alergenosPrincipal != null) {
                  alergenosTotal += alergenosPrincipal
                } else {
                  alergenosTotal += valueTextAreaAlergenosPrincipal
                }

                if (alergenosGuarnicion != null) {
                  alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
                } else {
                  alergenosTotal += valueTextAreaAlergenosGuarnicion
                }

                //Alérgenos
                if (alergenosTotal != '' && alergenosTotal.length >= 3) {
                  //mostrarPuedeContener, puedeContener
                  doc
                    .fontSize(7.2)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
                      width: 264,
                      align: 'justify',
                      continued: true,
                    })
                    .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
                      width: 264,
                      align: 'justify',
                      continued: texto02 != undefined ? true : false,
                    })
                }

                //Alérgenos Puede Contener
                //console.log('puedeContener--------------------------------------' + puedeContener);
                if (mostrarPuedeContener === 'S') {
                  doc
                    .fontSize(7.2)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('PUEDE CONTENER: ', {
                      width: 264,
                      align: 'justify',
                      continued: true,
                    })
                    .text(puedeContener, {
                      width: 264,
                      align: 'justify',
                      continued: texto02 != undefined ? true : false,
                    })
                }

                if (texto02 != undefined) {
                  doc.text('\n').font('Helvetica').fontSize(8).text(texto02, {
                    width: 264,
                    align: 'justify',
                    continued: false,
                  })
                }

                //, texto02, texto03, texto04)

                //Texto 03
                doc.fontSize(8).font('Helvetica-Bold').text(texto03, 8, 188, {
                  width: 153,
                  height: 10,
                  align: 'justify',
                  continued: false,
                })

                //Texto 04
                doc.fontSize(7).font('Helvetica').text(texto04, 164, 188, {
                  width: 153,
                  height: 10,
                  align: 'justify',
                  continued: false,
                })

                //Fecha Elaboración y Caducidad
                doc
                  .roundedRect(8, 197, 139, 20)
                  .stroke()
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .text('FECHA ELABORACIÓN:', 14, 200)
                  .text(fechaElaboracion, 104, 200)
                  .text('FECHA CADUCIDAD:', 14, 210)
                  .text(fechaCaducidad, 104, 210)

                console.log('condConservacion')
                console.log(condConservacion)
                //Fecha Elaboración y Caducidad
                doc
                  .fontSize(6.9)
                  .font('Helvetica')
                  .text(condConservacion + '\n', 8, 221, {
                    width: 142,
                    align: 'justify',
                  })
                  .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
                    width: 142,
                  })
                  .text(observacionesTotales != undefined ? observacionesTotales : '', {
                    width: 142,
                  })
                  .text(observaciones != undefined ? observaciones : '', {
                    continued: false,
                    width: 142,
                  })

                //Lote depende de la plantilla ;

                if (plantilla === 'plantilla-1') {
                  doc
                    .fontSize(6.9)
                    .font('Helvetica-Bold')
                    .text('LOTE: ', 8, 274, {
                      width: 153,
                      height: 10,
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(codLote, {
                      continued: false,
                    })
                    .fontSize(6.9)
                    .font('Helvetica-Bold')
                    .text(
                      multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
                      92,
                      263,
                      {
                        width: 153,
                        height: 10,
                      }
                    )
                } else if (plantilla === 'plantilla-2') {
                  console.log('barcodeImagen')
                  console.log(barcodeImagen)
                  var imagenBase64 = barcodeImagen.message
                  doc
                    .fontSize(6.9)
                    .font('Helvetica-Bold')
                    .text(
                      multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
                      92,
                      255,
                      {
                        width: 153,
                        height: 10,
                      }
                    )
                    .image(new Buffer(imagenBase64.replace('data:image/png;base64,', ''), 'base64'), 8, 263, {
                      height: 23,
                      width: 147,
                    })
                }

                //Borde Descriptivo Inf. Nutricional
                doc
                  .roundedRect(157, 197, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .font('Helvetica-Bold')
                  .stroke()
                  .fontSize(6.9)
                  .text('Inf. Nutricional', 158, 199)

                //Borde Descriptivo Energía(Kj/Kcal
                doc
                  .roundedRect(157, 206, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Energía(Kj/Kcal)', 158, 208)

                //Borde Descriptivo Grasas(g)
                doc
                  .roundedRect(157, 215, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Grasas(g)', 158, 217)

                //Borde Descriptivo Saturadas
                doc
                  .roundedRect(157, 224, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Saturadas', 158, 226)

                //Borde Descriptivo Hidr. Carbono(g)
                doc
                  .roundedRect(157, 233, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Hidr. Carbono(g)', 158, 235)

                //Borde Descriptivo Azúcares
                doc
                  .roundedRect(157, 242, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Azúcares', 158, 244)

                //Borde Descriptivo Fibra
                doc
                  .roundedRect(157, 251, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Fibra', 158, 253)

                //Borde Descriptivo Proteínas
                doc
                  .roundedRect(157, 260, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Proteínas(g)', 158, 262)

                //Borde Descriptivo Sal(g)
                doc
                  .roundedRect(157, 269, 54, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .text('Sal(g)', 158, 271)

                //Borde Descriptivo por100G
                doc
                  .roundedRect(211, 197, 35, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text('Por 100g      /ml', 212, 199)
                  .stroke()

                //Borde por100G Energía Number(((datosGuarnicion[0].POLIALCOHOLES / datosGuarnicion[0].PESOT) * 100).toFixed(2))
                console.log('infoNutricionalReceta100')
                console.log(infoNutricionalReceta100)
                doc
                  .roundedRect(211, 206, 32, 9)
                  .stroke()
                  .fontSize(6.9)
                  .font('Helvetica')
                  .fillColor('black')
                  .text(
                    Math.round(Number(infoNutricionalReceta100[1].total)) +
                      '/' +
                      Math.round(Number(infoNutricionalReceta100[0].total)),
                    212,
                    208,
                    {
                      width: 30,
                      height: 10,
                      align: 'center',
                    }
                  )

                //Borde por100G Grasas
                doc.roundedRect(211, 215, 32, 9).stroke().text(infoNutricionalReceta100[3].total, 212, 217, {
                  width: 30,
                  height: 10,
                  align: 'center',
                })

                //Borde por100G Saturados
                doc.roundedRect(211, 224, 32, 9).stroke().text(infoNutricionalReceta100[4].total, 212, 226, {
                  width: 30,
                  height: 10,
                  align: 'center',
                })

                //Borde por100G Hid_Carbonos
                doc.roundedRect(211, 233, 32, 9).stroke().text(infoNutricionalReceta100[7].total, 212, 235, {
                  width: 30,
                  height: 10,
                  align: 'center',
                })

                //Borde por100G Azúcares
                doc.roundedRect(211, 242, 32, 9).stroke().text(infoNutricionalReceta100[9].total, 212, 244, {
                  width: 30,
                  height: 10,
                  align: 'center',
                })

                //Borde por100G Fibra
                doc.roundedRect(211, 251, 32, 9).stroke().text(infoNutricionalReceta100[8].total, 212, 253, {
                  width: 30,
                  height: 10,
                  align: 'center',
                })

                //Borde por100G Proteínas
                doc.roundedRect(211, 260, 32, 9).stroke().text(infoNutricionalReceta100[2].total, 212, 262, {
                  width: 30,
                  height: 10,
                  align: 'center',
                })

                //Borde por100G Sal
                doc.roundedRect(211, 269, 32, 9).stroke().text(infoNutricionalReceta100[10].total, 212, 271, {
                  width: 30,
                  height: 10,
                  align: 'center',
                })

                //Borde Descriptivo porRación
                doc
                  .roundedRect(243, 197, 35, 9)
                  .fillAndStroke('#f4f4f4', 'black')
                  .fillAndStroke('black')
                  .stroke()
                  .fontSize(6.9)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text('Ración', 246, 199)

                //Borde porRación Energía
                doc
                  .roundedRect(243, 206, 35, 9)
                  .stroke()
                  .fontSize(6.9)
                  .font('Helvetica')
                  .fillColor('black')
                  .text(
                    Math.round(Number(infoNutricionalReceta[3].total)) +
                      '/' +
                      Math.round(Number(infoNutricionalReceta[2].total)),
                    244,
                    208,
                    {
                      width: 33,
                      height: 10,
                      align: 'center',
                    }
                  )

                //Borde porRación Grasas
                doc.roundedRect(243, 215, 35, 9).stroke().text(infoNutricionalReceta[5].total, 244, 217, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

                //Borde porRación Saturados
                doc.roundedRect(243, 224, 35, 9).stroke().text(infoNutricionalReceta[6].total, 244, 226, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

                //Borde porRación Hid_Carbonos
                doc.roundedRect(243, 233, 35, 9).stroke().text(infoNutricionalReceta[9].total, 244, 235, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

                //Borde porRación Azúcares
                doc.roundedRect(243, 242, 35, 9).stroke().text(infoNutricionalReceta[11].total, 244, 244, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

                //Borde porRación Fibra
                doc.roundedRect(243, 251, 35, 9).stroke().text(infoNutricionalReceta[10].total, 244, 253, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

                //Borde porRación Proteínas
                doc.roundedRect(243, 260, 35, 9).stroke().text(infoNutricionalReceta[4].total, 244, 262, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

                //Borde porRación Sal
                doc.roundedRect(243, 269, 35, 9).stroke().text(infoNutricionalReceta[12].total, 244, 271, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

                if (cantidadEtiqImprimir != 1 && e != cantidadEtiqImprimir - 1) {
                  //Agrego la segunda página
                  doc.addPage()
                }
              }

              // Obtengo el blob cuando está hecho todo.
              doc.end()

              stream.on('finish', function () {
                //   // // get a blob you can do whatever you like with
                //   // const blob = stream.toBlob('application/pdf');

                //   // or get a blob URL for display in the browser
                const url = stream.toBlobURL('application/pdf')
                // abrir un PDF en una pestaña nueva
                window.open(url, '_blank')
                // redirigir la pestaña actual a otra URL
                //window.location.href = 'http://ejemplo.com';

                //llamarActualizarEstadosSpingImprimirEtiquetas();
              })
            }, 3000)
          },
          (error) => console.log(error)
        ) ////////////////////////////////7
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}

async function imprimirEtiquetasGeneralPlantilla3(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    // this.setState({
    //     loadingSpingImprimirEtiquetas: true,
    //     textoLoadingSpingImprimirEtiquetas: 'Imprimiendo etiquetas ...',
    //     loadingButtonLanzarImpresion: true
    // });
    var observacionesTotales = ''
    var condConservacion = ''
    var modoEmpleo = ''
    console.log(infoNutricionalReceta100)
    // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
    // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
    // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

    if (codObservacionesEspeciales.length != 0) {
      for (let i = 0; i < codObservacionesEspeciales.length; i++) {
        var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

        observacionesTotales += observacion[1] + '.\n'
      }
    } else {
      observacionesTotales = ''
    }

    //observacionesTotales = observacionesTotales.length > 0 ? observacionesTotales : '';

    if (listaLineasProduccion.length != 0) {
      for (let a = 0; a < listaLineasProduccion.length; a++) {
        if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
          condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
          modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
        }
      }
    }

    // console.log('codLineaProduccion: ', codLineaProduccion);
    // console.log('codCliente: ', codCliente[1]);
    // console.log('fechaElaboracion: ', fechaElaboracion);//OK
    // console.log('totalDiasCaducidad: ', totalDiasCaducidad);
    // console.log('fechaCaducidad: ', fechaCaducidad);//OK
    // console.log('codLote: ', codLote);//OK
    // console.log('codCocina: ', codCocina);
    // //console.log('codPlantilla: ', codPlantilla);//Por ahora no se usa
    // console.log('codObservacionesEspeciales: ', codObservacionesEspeciales);
    // console.log('observacionesTotales: ', observacionesTotales);
    // console.log('rutaLogoIzquierdo: ', rutaLogoIzquierdo);//OK
    // console.log('rutaLogoDerecho: ', rutaLogoDerecho);//OK
    // console.log('observaciones: ', observaciones);//OK
    // console.log('infoNutricionalReceta: ', infoNutricionalReceta);//OK
    // console.log('infoNutricionalReceta100: ', infoNutricionalReceta100);//OK,
    // //console.log('ingredientesAlergenosTotal: ' + ingredientesAlergenosTotal);
    //console.log('alergenoTotal: ' + alergenoTotal);

    //var listaCatalogoId = this.state.listaCatalogoId;

    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      (res) => {
        const datosCocinaImprimir = res.response

        database.obtenerBarcode(codLote).then(
          async (res) => {
            var barcodeImagen = res.response

            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAbarcodeImagen')
            console.log(barcodeImagen)

            // create a document and pipe to a blob
            var doc = new PDFDocument({
              //layout: "landscape",
              size: [largo, ancho],
              margin: 2,
              info: {
                Title: 'Etiquetas',
                Author: 'Alejandro Vázquez Chávez',
              },
            })
            var stream = doc.pipe(blobStream())

            for (let e = 0; e < cantidadEtiqImprimir; e++) {
              doc
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text('SERVICIO DE CATERING Y COLECTIVIDADES', 30, 8, {
                  width: 250,
                  height: 10,
                  align: 'center',
                })

              //Dirección Cocina
              doc
                .fontSize(6)
                .font('Helvetica')
                .fillColor('black')
                .text(datosCocinaImprimir[0].DIRECCION_UCP, 37, 16, {
                  width: 250,
                  height: 20,
                  align: 'center',
                  continued: true,
                })
                .text('\n')
                .fontSize(5.5) //Nombre Cocina
                .font('Helvetica')
                .fillColor('black')
                .text(
                  datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
                    ' - ' +
                    'NRGSA: ' +
                    datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
                  {
                    width: 250,
                    height: 10,
                    align: 'center',
                  }
                )

              //Logo izquierdo
              if (rutaLogoIzquierdo != undefined || rutaLogoIzquierdo != null) {
                const buffer = await getBufferLogo(rutaLogoIzquierdo)
                doc.image(buffer, 8, 3, {
                  scale: 0.09,
                })
              }

              // //Logo derecho
              // if (rutaLogoDerecho != undefined || rutaLogoDerecho != null) {
              //     doc
              //         .image(rutaLogoDerecho, 232, 5, {
              //             scale: 0.12
              //         });
              // }

              //Número de Registro Sanitario Cocina
              // doc
              //     .fontSize(5.5)
              //     .font('Helvetica')
              //     .fillColor('black')
              //     .text('NRGSA: ' + datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP, 13, 55, {
              //         width: 100,
              //         height: 12
              //     });

              //Nombre Cliente
              // if (codCliente.length != 0) {
              //     doc
              //         .fontSize(8)
              //         .font('Helvetica-Bold')
              //         .fillColor('black')
              //         .text('CLIENTE: ' + codCliente[1] === null ? '' : codCliente[1],  13, 48, {
              //             continued: true
              //         })
              //         .text('\n')

              // }

              var plato =
                nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

              // doc
              //     .fontSize(9)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text(plato , 13, 74, {
              //         width: 260,
              //         height: heightPlato
              //     });

              //Nombre Plato
              if (ingredientesPrincipal != null) {
                doc.fontSize(8.5).font('Helvetica-Bold').fillColor('black').text(plato, 7, 35, {
                  //codCliente.length != 0 ? 58 : 53, {
                  width: 274,
                  continued: true,
                })

                if (texto01 != undefined && texto01.length > 0) {
                  doc.text('\n').fontSize(8.5).font('Helvetica-Bold').text(texto01, {
                    width: 275,
                    align: 'justify',
                    continued: true,
                  })
                }
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipal)
                }
              } else {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(8.5)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text(plato, 7, 35, {
                      width: 274,
                      continued: false,
                    })
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipalEtiqueta)
                }
              }
              //   .font('Helvetica-Bold')
              //   .text('INGREDIENTES GUARNICIÓN: ', {
              //     width: 264,
              //     align: 'justify',
              //     continued: true
              //   }).font('Helvetica')
              //   .text(ingredientesGuarnicionEtiqueta.length)
              //   .font('Helvetica-Bold')
              //   .fillColor('black')
              //   .text('CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos', {
              //     width: 264,
              //     align: 'justify'
              //   });

              // var margenTopIngredientes = heightPlato === 30 ? 95 : 84
              // //Ingredientes Principal
              // doc
              //     .fontSize(6.5)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text('INGREDIENTES PRINCIPAL: ', 13, margenTopIngredientes , {
              //         width: 260,
              //         height: 70,
              //         align: 'justify',
              //         continued: true
              //     }).font('Helvetica')
              //     .text(ingredientesPrincipalEtiqueta)

              //Ingredientes Guarnición - Para ponerlo consecutivo tengo que calcular el desplazamiento.
              if (ingredientesGuarnicion.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                }
              } else if (ingredientesGuarnicionEtiqueta.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                }
              }
              if(!ingredientesGuarnicion && !ingredientesPrincipal) {
                console.log("CASO ESPECIAl 2")
                doc
                    .text("\n")

              }

              var alergenosTotal = ''
              if (alergenosPrincipal != null) {
                alergenosTotal += alergenosPrincipal
              } else {
                alergenosTotal += valueTextAreaAlergenosPrincipal
              }

              if (alergenosGuarnicion != null) {
                alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
              } else {
                alergenosTotal += valueTextAreaAlergenosGuarnicion
              }

              //Alérgenos
              if (alergenosTotal != '' && alergenosTotal.length >= 3) {
                //mostrarPuedeContener, puedeContener
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              //Alérgenos Puede Contener
              //console.log('puedeContener--------------------------------------' + puedeContener);
              if (mostrarPuedeContener === 'S') {
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text('PUEDE CONTENER: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(puedeContener, {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              if (texto02 != undefined) {
                doc.text('\n').font('Helvetica').fontSize(7.5).text(texto02, {
                  width: 274,
                  align: 'justify',
                  continued: false,
                })
              }

              //, texto02, texto03, texto04)

              //Texto 03
              doc.fontSize(7.5).font('Helvetica-Bold').text(texto03, 15, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Texto 04
              doc.fontSize(7).font('Helvetica').text(texto04, 170, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Fecha Elaboración y Caducidad
              doc
                .roundedRect(7, 201, 139, 20)
                .stroke()
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .text('FECHA ELABORACIÓN:', 13, 204)
                .text(fechaElaboracion, 105, 204)
                .text('FECHA CADUCIDAD:', 13, 214)
                .text(fechaCaducidad, 105, 214)

              console.log('condConservacion')
              console.log(condConservacion)
              //Fecha Elaboración y Caducidad
              doc
                .fontSize(6.9)
                .font('Helvetica')
                .text(condConservacion + '\n', 7, 224, {
                  width: 142,
                  align: 'justify',
                })
                .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
                  width: 142,
                })
                .text(observacionesTotales != undefined ? observacionesTotales : '', {
                  width: 142,
                })
                .text(observaciones != undefined ? observaciones : '', {
                  continued: false,
                  width: 142,
                })

              //Lote depende de la plantilla ;

              doc
                .fontSize(6.9)
                .font('Helvetica-Bold')
                .text('LOTE: ', 7, 275, {
                  width: 153,
                  height: 10,
                  continued: true,
                })
                .font('Helvetica')
                .text(codLote, {
                  continued: false,
                })
                .fontSize(6.9)
                .font('Helvetica-Bold')
                .text(
                  multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
                  85,
                  269,
                  {
                    width: 153,
                    height: 10,
                  }
                )

              //Borde Descriptivo Inf. Nutricional
              doc
                .roundedRect(154, 201, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .stroke()
                .fontSize(6.9)
                .text('Inf. Nutricional', 155, 203)

              //Borde Descriptivo Energía(Kj/Kcal
              doc
                .roundedRect(154, 210, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Energía(Kj/Kcal)', 155, 212)

              //Borde Descriptivo Grasas(g)
              doc
                .roundedRect(154, 219, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Grasas(g)', 155, 221)

              //Borde Descriptivo Saturadas
              doc
                .roundedRect(154, 228, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Saturadas', 155, 230)

              //Borde Descriptivo Hidr. Carbono(g)
              doc
                .roundedRect(154, 237, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Hidr. Carbono(g)', 155, 239)

              //Borde Descriptivo Azúcares
              doc
                .roundedRect(154, 246, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Azúcares', 155, 248)

              //Borde Descriptivo Fibra
              doc
                .roundedRect(154, 255, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Fibra', 155, 257)

              //Borde Descriptivo Proteínas
              doc
                .roundedRect(154, 264, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Proteínas(g)', 155, 266)

              //Borde Descriptivo Sal(g)
              doc
                .roundedRect(154, 273, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Sal(g)', 155, 275)

              //Borde Descriptivo por100G
              doc
                .roundedRect(208, 201, 39, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Por100g/ml', 209, 203)
                .stroke()

              //Borde por100G Energía Number(((datosGuarnicion[0].POLIALCOHOLES / datosGuarnicion[0].PESOT) * 100).toFixed(2))
              console.log('infoNutricionalReceta100')
              console.log(infoNutricionalReceta100)
              doc
                .roundedRect(208, 210, 39, 9)
                .stroke()
                .fontSize(6.9)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta100[1].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta100[0].total)),
                  209,
                  212,
                  {
                    width: 33,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde por100G Grasas
              doc.roundedRect(208, 219, 39, 9).stroke().text(infoNutricionalReceta100[3].total, 210, 221, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Saturados
              doc.roundedRect(208, 228, 39, 9).stroke().text(infoNutricionalReceta100[4].total, 210, 230, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Hid_Carbonos
              doc.roundedRect(208, 237, 39, 9).stroke().text(infoNutricionalReceta100[7].total, 210, 239, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Azúcares
              doc.roundedRect(208, 246, 39, 9).stroke().text(infoNutricionalReceta100[9].total, 210, 248, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Fibra
              doc.roundedRect(208, 255, 39, 9).stroke().text(infoNutricionalReceta100[8].total, 210, 257, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Proteínas
              doc.roundedRect(208, 264, 39, 9).stroke().text(infoNutricionalReceta100[2].total, 210, 266, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Sal
              doc
                .roundedRect(208, 273, 39, 9) //211
                .stroke()
                .text(infoNutricionalReceta100[10].total, 210, 275, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

              //Borde Descriptivo porRación
              doc
                .roundedRect(247, 201, 35, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .fontSize(6.9)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Ración', 248, 203)

              //Borde porRación Energía
              doc
                .roundedRect(247, 210, 35, 9)
                .stroke()
                .fontSize(6.9)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta[3].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta[2].total)),
                  248,
                  212,
                  {
                    width: 33,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde porRación Grasas
              doc.roundedRect(247, 219, 35, 9).stroke().text(infoNutricionalReceta[5].total, 248, 221, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Saturados
              doc.roundedRect(247, 228, 35, 9).stroke().text(infoNutricionalReceta[6].total, 248, 230, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Hid_Carbonos
              doc.roundedRect(247, 237, 35, 9).stroke().text(infoNutricionalReceta[9].total, 248, 239, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Azúcares
              doc.roundedRect(247, 246, 35, 9).stroke().text(infoNutricionalReceta[11].total, 248, 248, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Fibra
              doc.roundedRect(247, 255, 35, 9).stroke().text(infoNutricionalReceta[10].total, 248, 257, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Proteínas
              doc.roundedRect(247, 264, 35, 9).stroke().text(infoNutricionalReceta[4].total, 248, 266, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Sal
              doc.roundedRect(247, 273, 35, 9).stroke().text(infoNutricionalReceta[12].total, 248, 275, {
                //4
                width: 33,
                height: 10,
                align: 'center',
              })

              if (cantidadEtiqImprimir != 1 && e != cantidadEtiqImprimir - 1) {
                //Agrego la segunda página
                doc.addPage()
              }
            }

            // Obtengo el blob cuando está hecho todo.
            doc.end()

            stream.on('finish', function () {
              //   // // get a blob you can do whatever you like with
              //   // const blob = stream.toBlob('application/pdf');

              //   // or get a blob URL for display in the browser
              const url = stream.toBlobURL('application/pdf')
              // abrir un PDF en una pestaña nueva
              window.open(url, '_blank')
              // redirigir la pestaña actual a otra URL
              //window.location.href = 'http://ejemplo.com';

              //llamarActualizarEstadosSpingImprimirEtiquetas();
            })
          },
          (error) => console.log(error)
        )
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}

async function imprimirEtiquetasGeneralPlantilla4(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    // this.setState({
    //     loadingSpingImprimirEtiquetas: true,
    //     textoLoadingSpingImprimirEtiquetas: 'Imprimiendo etiquetas ...',
    //     loadingButtonLanzarImpresion: true
    // });
    var observacionesTotales = ''
    var condConservacion = ''
    var modoEmpleo = ''
    console.log(infoNutricionalReceta100)
    // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
    // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
    // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

    if (codObservacionesEspeciales.length != 0) {
      for (let i = 0; i < codObservacionesEspeciales.length; i++) {
        var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

        observacionesTotales += observacion[1] + '.\n'
      }
    } else {
      observacionesTotales = ''
    }

    if (listaLineasProduccion.length != 0) {
      for (let a = 0; a < listaLineasProduccion.length; a++) {
        if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
          condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
          modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
        }
      }
    }

    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      (res) => {
        const datosCocinaImprimir = res.response

        database.obtenerBarcode(codLote).then(
          async (res) => {
            var barcodeImagen = res.response

            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAbarcodeImagen')
            console.log(barcodeImagen)

            // create a document and pipe to a blob
            var doc = new PDFDocument({
              //layout: "landscape",
              size: [largo, ancho75],
              margin: 2,
              info: {
                Title: 'Etiquetas',
                Author: 'Alejandro Vázquez Chávez',
              },
            })
            var stream = doc.pipe(blobStream())

            for (let e = 0; e < cantidadEtiqImprimir; e++) {
              doc
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text('SERVICIO DE CATERING Y COLECTIVIDADES', 30, 8, {
                  width: 250,
                  height: 10,
                  align: 'center',
                })

              //Dirección Cocina
              doc
                .fontSize(6)
                .font('Helvetica')
                .fillColor('black')
                .text(datosCocinaImprimir[0].DIRECCION_UCP, 37, 16, {
                  width: 250,
                  height: 20,
                  align: 'center',
                  continued: true,
                })
                .text('\n')
                .fontSize(5.5) //Nombre Cocina
                .font('Helvetica')
                .fillColor('black')
                .text(
                  datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
                    ' - ' +
                    'NRGSA: ' +
                    datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
                  {
                    width: 250,
                    height: 10,
                    align: 'center',
                  }
                )

              //Logo izquierdo
              if (rutaLogoIzquierdo != undefined || rutaLogoIzquierdo != null) {
                const buffer = await getBufferLogo(rutaLogoIzquierdo)
                doc.image(buffer, 8, 3, {
                  scale: 0.09,
                })
              }

              // //Logo derecho
              // if (rutaLogoDerecho != undefined || rutaLogoDerecho != null) {
              //     doc
              //         .image(rutaLogoDerecho, 232, 5, {
              //             scale: 0.12
              //         });
              // }

              //Número de Registro Sanitario Cocina
              // doc
              //     .fontSize(5.5)
              //     .font('Helvetica')
              //     .fillColor('black')
              //     .text('NRGSA: ' + datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP, 13, 55, {
              //         width: 100,
              //         height: 12
              //     });

              //Nombre Cliente
              // if (codCliente.length != 0) {
              //     doc
              //         .fontSize(8)
              //         .font('Helvetica-Bold')
              //         .fillColor('black')
              //         .text('CLIENTE: ' + codCliente[1] === null ? '' : codCliente[1],  13, 48, {
              //             continued: true
              //         })
              //         .text('\n')

              // }

              var plato =
                nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

              // doc
              //     .fontSize(9)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text(plato , 13, 74, {
              //         width: 260,
              //         height: heightPlato
              //     });

              //Nombre Plato
              if (ingredientesPrincipal != null) {
                doc.fontSize(8.3).font('Helvetica-Bold').fillColor('black').text(plato, 7, 35, {
                  //codCliente.length != 0 ? 58 : 53, {
                  width: 274,
                  continued: true,
                })

                if (texto01 != undefined && texto01.length > 0) {
                  doc.text('\n').fontSize(8.3).font('Helvetica-Bold').text(texto01, {
                    width: 275,
                    align: 'justify',
                    continued: true,
                  })
                }
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipal)
                }
              } else {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(8.3)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text(plato, 7, 35, {
                      width: 274,
                      continued: false,
                    })
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipalEtiqueta)
                }
              }
              //   .font('Helvetica-Bold')
              //   .text('INGREDIENTES GUARNICIÓN: ', {
              //     width: 264,
              //     align: 'justify',
              //     continued: true
              //   }).font('Helvetica')
              //   .text(ingredientesGuarnicionEtiqueta.length)
              //   .font('Helvetica-Bold')
              //   .fillColor('black')
              //   .text('CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos', {
              //     width: 264,
              //     align: 'justify'
              //   });

              // var margenTopIngredientes = heightPlato === 30 ? 95 : 84
              // //Ingredientes Principal
              // doc
              //     .fontSize(6.5)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text('INGREDIENTES PRINCIPAL: ', 13, margenTopIngredientes , {
              //         width: 260,
              //         height: 70,
              //         align: 'justify',
              //         continued: true
              //     }).font('Helvetica')
              //     .text(ingredientesPrincipalEtiqueta)

              //Ingredientes Guarnición - Para ponerlo consecutivo tengo que calcular el desplazamiento.
              if (ingredientesGuarnicion.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                }
              } else if (ingredientesGuarnicionEtiqueta.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                }
              }
              if(!ingredientesGuarnicion && !ingredientesPrincipal) {
                console.log("CASO ESPECIAl 3")
                doc
                    .text("\n")

              }

              var alergenosTotal = ''
              if (alergenosPrincipal != null) {
                alergenosTotal += alergenosPrincipal
              } else {
                alergenosTotal += valueTextAreaAlergenosPrincipal
              }

              if (alergenosGuarnicion != null) {
                alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
              } else {
                alergenosTotal += valueTextAreaAlergenosGuarnicion
              }

              //Alérgenos
              if (alergenosTotal != '' && alergenosTotal.length >= 3) {
                //mostrarPuedeContener, puedeContener
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              //Alérgenos Puede Contener
              //console.log('puedeContener--------------------------------------' + puedeContener);
              if (mostrarPuedeContener === 'S') {
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text('PUEDE CONTENER: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(puedeContener, {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              if (texto02 != undefined) {
                doc.text('\n').font('Helvetica').fontSize(7.1).text(texto02, {
                  width: 274,
                  align: 'justify',
                  continued: false,
                })
              }

              //, texto02, texto03, texto04)

              //Texto 03
              doc.fontSize(7.3).font('Helvetica-Bold').text(texto03, 15, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Texto 04
              doc.fontSize(7).font('Helvetica').text(texto04, 170, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Fecha Elaboración y Caducidad
              doc
                .roundedRect(7, 120, 139, 20)
                .stroke()
                .fontSize(7)
                .font('Helvetica-Bold')
                .text('FECHA ELABORACIÓN:', 13, 123)
                .text(fechaElaboracion, 103, 123)
                .text('FECHA CADUCIDAD:', 13, 133)
                .text(fechaCaducidad, 103, 133)

              console.log('condConservacion')
              console.log(condConservacion)
              //Fecha Elaboración y Caducidad
              doc
                .fontSize(6.8)
                .font('Helvetica')
                .text(condConservacion + '\n', 7, 144, {
                  width: 142,
                  align: 'justify',
                })
                .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
                  width: 142,
                })
                .text(observacionesTotales != undefined ? observacionesTotales : '', {
                  width: 142,
                })
                .text(observaciones != undefined ? observaciones : '', {
                  continued: false,
                  width: 142,
                })

              //Lote depende de la plantilla ;

              doc
                .fontSize(6.8)
                .font('Helvetica-Bold')
                .text('LOTE: ', 8, 197, {
                  width: 153,
                  height: 10,
                  continued: true,
                })
                .font('Helvetica')
                .text(codLote, {
                  continued: false,
                })
                .fontSize(6.8)
                .font('Helvetica-Bold')
                .text(
                  multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
                  85,
                  190,
                  {
                    width: 153,
                    height: 10,
                  }
                )

              //Borde Descriptivo Inf. Nutricional
              doc
                .roundedRect(150, 120, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .stroke()
                .fontSize(6.8)
                .text('Inf. Nutricional', 151, 122)

              //Borde Descriptivo Energía(Kj/Kcal
              doc
                .roundedRect(150, 129, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Energía(Kj/Kcal)', 151, 131)

              //Borde Descriptivo Grasas(g)
              doc
                .roundedRect(150, 138, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Grasas(g)', 151, 140)

              //Borde Descriptivo Saturadas
              doc
                .roundedRect(150, 147, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Saturadas', 151, 149)

              //Borde Descriptivo Hidr. Carbono(g)
              doc
                .roundedRect(150, 156, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Hidr. Carbono(g)', 151, 158)

              //Borde Descriptivo Azúcares
              doc
                .roundedRect(150, 165, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Azúcares', 151, 167)

              //Borde Descriptivo Fibra
              doc
                .roundedRect(150, 174, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Fibra', 151, 176)

              //Borde Descriptivo Proteínas
              doc
                .roundedRect(150, 183, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Proteínas(g)', 151, 185)

              //Borde Descriptivo Sal(g)
              doc
                .roundedRect(150, 192, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Sal(g)', 151, 194)

              //Borde Descriptivo por100G
              doc
                .roundedRect(204, 120, 39, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Por 100g/ml', 204, 122)
                .stroke()

              //Borde por100G Energía Number(((datosGuarnicion[0].POLIALCOHOLES / datosGuarnicion[0].PESOT) * 100).toFixed(2))
              console.log('infoNutricionalReceta100')
              console.log(infoNutricionalReceta100)
              doc
                .roundedRect(204, 129, 39, 9)
                .stroke()
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta100[1].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta100[0].total)),
                  205,
                  131,
                  {
                    width: 34,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde por100G Grasas
              doc.roundedRect(204, 138, 39, 9).stroke().text(infoNutricionalReceta100[3].total, 205, 140, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Saturados
              doc.roundedRect(204, 147, 39, 9).stroke().text(infoNutricionalReceta100[4].total, 205, 149, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Hid_Carbonos
              doc.roundedRect(204, 156, 39, 9).stroke().text(infoNutricionalReceta100[7].total, 205, 158, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Azúcares
              doc.roundedRect(204, 165, 39, 9).stroke().text(infoNutricionalReceta100[9].total, 205, 167, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Fibra
              doc.roundedRect(204, 174, 39, 9).stroke().text(infoNutricionalReceta100[8].total, 205, 176, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Proteínas
              doc.roundedRect(204, 183, 39, 9).stroke().text(infoNutricionalReceta100[2].total, 205, 185, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Sal
              doc.roundedRect(204, 192, 39, 9).stroke().text(infoNutricionalReceta100[10].total, 205, 194, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde Descriptivo porRación
              doc
                .roundedRect(243, 120, 38, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .fontSize(6.8)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Ración', 244, 122)

              //Borde porRación Energía
              doc
                .roundedRect(243, 129, 38, 9)
                .stroke()
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta[3].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta[2].total)),
                  244,
                  131,
                  {
                    width: 33,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde porRación Grasas
              doc.roundedRect(243, 138, 38, 9).stroke().text(infoNutricionalReceta[5].total, 244, 140, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Saturados
              doc.roundedRect(243, 147, 38, 9).stroke().text(infoNutricionalReceta[6].total, 244, 149, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Hid_Carbonos
              doc.roundedRect(243, 156, 38, 9).stroke().text(infoNutricionalReceta[9].total, 244, 158, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Azúcares
              doc.roundedRect(243, 165, 38, 9).stroke().text(infoNutricionalReceta[11].total, 244, 167, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Fibra
              doc.roundedRect(243, 174, 38, 9).stroke().text(infoNutricionalReceta[10].total, 244, 176, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Proteínas
              doc.roundedRect(243, 183, 38, 9).stroke().text(infoNutricionalReceta[4].total, 244, 185, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Sal
              doc.roundedRect(243, 192, 38, 9).stroke().text(infoNutricionalReceta[12].total, 244, 194, {
                //4
                width: 33,
                height: 10,
                align: 'center',
              })

              if (cantidadEtiqImprimir != 1 && e != cantidadEtiqImprimir - 1) {
                //Agrego la segunda página
                doc.addPage()
              }
            }

            // Obtengo el blob cuando está hecho todo.
            doc.end()

            stream.on('finish', function () {
              //   // // get a blob you can do whatever you like with
              //   // const blob = stream.toBlob('application/pdf');

              //   // or get a blob URL for display in the browser
              const url = stream.toBlobURL('application/pdf')
              // abrir un PDF en una pestaña nueva
              window.open(url, '_blank')
              // redirigir la pestaña actual a otra URL
              //window.location.href = 'http://ejemplo.com';

              //llamarActualizarEstadosSpingImprimirEtiquetas();
            })
          },
          (error) => console.log(error)
        )
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}

async function imprimirEtiquetasGeneralPlantilla5(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  receta,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      async (res) => {
        const datosCocinaImprimir = res.response

        var observacionesTotales = ''
        var condConservacion = ''
        var modoEmpleo = ''
        console.log(infoNutricionalReceta100)
        // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
        // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
        // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

        if (codObservacionesEspeciales.length != 0) {
          for (let i = 0; i < codObservacionesEspeciales.length; i++) {
            var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

            observacionesTotales += observacion[1] + '.\n'
          }
        } else {
          observacionesTotales = ''
        }

        //observacionesTotales = observacionesTotales.length > 0 ? observacionesTotales : '';

        if (listaLineasProduccion.length != 0) {
          for (let a = 0; a < listaLineasProduccion.length; a++) {
            if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
              condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
              modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
            }
          }
        }

        // create a document and pipe to a blob
        var doc = new PDFDocument({
          //layout: "landscape",
          size: [largo, ancho],
          margin: 5,
          info: {
            Title: 'Etiquetas',
            Author: 'Alejandro Vázquez Chávez',
          },
        })
        var stream = doc.pipe(blobStream())

        for (let e = 0; e < cantidadEtiqImprimir; e++) {
          //Logo izquierdo
          if (rutaLogoIzquierdo != undefined || rutaLogoIzquierdo != null) {
            const buffer = await getBufferLogo(rutaLogoIzquierdo)
            doc.image(buffer, 19, 0, {
              scale: 0.12,
            })
          }

          //Logo derecho
          if (rutaLogoDerecho != undefined || rutaLogoDerecho != null) {
            const buffer = await getBufferLogo(rutaLogoDerecho)
            doc.image(buffer, 215, 0, {
              scale: 0.14,
            })
          }

          doc.fontSize(7).font('Helvetica').fillColor('black').text('SERVICIO DE CATERING Y COLECTIVIDADES', 60, 8, {
            width: 250,
            height: 60,
          })

          //Dirección Cocina
          doc.fontSize(6).font('Helvetica').fillColor('black').text(datosCocinaImprimir[0].DIRECCION_UCP, 60, 18, {
            width: 135,
            height: 20,
            align: 'center',
          })

          //Nombre Cocina
          doc
            .fontSize(5.5)
            .font('Helvetica')
            .fillColor('black')
            .text(
              datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
                ' - ' +
                'NRGSA: ' +
                datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
              60,
              33,
              {
                width: 155,
                height: 10,
                align: 'center',
              }
            )

          //Número de Registro Sanitario Cocina
          // doc
          //     .fontSize(5.5)
          //     .font('Helvetica')
          //     .fillColor('black')
          //     .text('NRGSA: ' + datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP, 13, 55, {
          //         width: 100,
          //         height: 12
          //     });

          //Nombre Cliente
          if (codCliente.length != 0) {
            doc
              .fontSize(8)
              .font('Helvetica-Bold')
              .fillColor('black')
              .text('CLIENTE: ' + codCliente[1] === null ? '' : codCliente[1], 18, 43, {
                continued: true,
              })
              .text('\n')
          }

          var plato = nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

          // doc
          //     .fontSize(9)
          //     .font('Helvetica-Bold')
          //     .fillColor('black')
          //     .text(plato , 13, 74, {
          //         width: 260,
          //         height: heightPlato
          //     });

          //Nombre Plato
          if (ingredientesPrincipal != null) {
            doc
              .fontSize(9)
              .font('Helvetica-Bold')
              .fillColor('black')
              .text(plato, 18, codCliente.length != 0 ? 53 : 43, {
                width: 240,
                continued: true,
              })

            if (texto01 != undefined && texto01.length > 0) {
              doc.text('\n').fontSize(9).font('Helvetica-Bold').text(texto01, {
                width: 240,
                align: 'justify',
                continued: true,
              })
            }
            if (ingredientesPrincipal.length > 0) {
              doc
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES', {
                  width: 240,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(': ' + ingredientesPrincipal)
            }
          } else {
            if (ingredientesPrincipal.length > 0) {
              doc
                .text('\n')
                .fontSize(9)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text(plato, 18, 60, {
                  width: 240,
                  continued: false,
                })
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES', {
                  width: 240,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(': ' + ingredientesPrincipalEtiqueta)
            }
          }
          //   .font('Helvetica-Bold')
          //   .text('INGREDIENTES GUARNICIÓN: ', {
          //     width: 264,
          //     align: 'justify',
          //     continued: true
          //   }).font('Helvetica')
          //   .text(ingredientesGuarnicionEtiqueta.length)
          //   .font('Helvetica-Bold')
          //   .fillColor('black')
          //   .text('CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos', {
          //     width: 264,
          //     align: 'justify'
          //   });

          // var margenTopIngredientes = heightPlato === 30 ? 95 : 84
          // //Ingredientes Principal
          // doc
          //     .fontSize(6.5)
          //     .font('Helvetica-Bold')
          //     .fillColor('black')
          //     .text('INGREDIENTES PRINCIPAL: ', 13, margenTopIngredientes , {
          //         width: 260,
          //         height: 70,
          //         align: 'justify',
          //         continued: true
          //     }).font('Helvetica')
          //     .text(ingredientesPrincipalEtiqueta)

          //Ingredientes Guarnición - Para ponerlo consecutivo tengo que calcular el desplazamiento.
          if (ingredientesGuarnicion.length != 0) {
            if (ingredientesPrincipal.length > 0) {
              doc
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 240,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicion)
            } else {
              doc
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 240,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicion)
            }
          } else if (ingredientesGuarnicionEtiqueta.length != 0) {
            if (ingredientesPrincipal.length > 0) {
              doc
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 240,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicionEtiqueta)
            } else {
              doc
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 240,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicionEtiqueta)
            }
          }

          if(!ingredientesGuarnicion && !ingredientesPrincipal) {
            console.log("CASO ESPECIAl 4")
            doc
                .text("\n")

          }

          var alergenosTotal = ''
          if (alergenosPrincipal != null) {
            alergenosTotal += alergenosPrincipal
          } else {
            alergenosTotal += valueTextAreaAlergenosPrincipal
          }

          if (alergenosGuarnicion != null) {
            alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
          } else {
            alergenosTotal += valueTextAreaAlergenosGuarnicion
          }

          //Alérgenos
          if (alergenosTotal != '' && alergenosTotal.length >= 3) {
            //mostrarPuedeContener, puedeContener
            doc
              .fontSize(7.1)
              .font('Helvetica-Bold')
              .fillColor('black')
              .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
                width: 240,
                align: 'justify',
                continued: true,
              })
              .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
                width: 240,
                align: 'justify',
                continued: texto02 != undefined ? true : false,
              })
          }

          //Alérgenos Puede Contener
          console.log('mostrarPuedeContener--------------------------------------' + mostrarPuedeContener)
          console.log('puedeContener--------------------------------------' + puedeContener)
          if (mostrarPuedeContener == 'S') {
            doc
              .fontSize(7.1)
              .font('Helvetica-Bold')
              .fillColor('black')
              .text('PUEDE CONTENER: ', {
                width: 240,
                align: 'justify',
                continued: true,
              })
              .text(puedeContener, {
                width: 240,
                align: 'justify',
                continued: texto02 != undefined ? true : false,
              })
          }

          if (texto02 != undefined) {
            doc.text('\n').font('Helvetica').fontSize(8).text(texto02, {
              width: 240,
              align: 'justify',
              continued: false,
            })
          }

          //, texto02, texto03, texto04)

          //Texto 03
          doc.fontSize(8).font('Helvetica-Bold').text(texto03, 18, 188, {
            width: 157,
            height: 10,
            align: 'justify',
            continued: false,
          })

          //Texto 04
          doc.fontSize(7).font('Helvetica').text(texto04, 153, 189, {
            width: 140,
            height: 10,
            align: 'justify',
            continued: false,
          })

          //Fecha Elaboración y Caducidad--------------------------------------
          doc
            .roundedRect(18, 197, 129, 18)
            .stroke()
            .fontSize(7.2)
            .font('Helvetica-Bold')
            .text('FECHA ELABORACIÓN:', 20, 200)
            .text(fechaElaboracion, 108, 200)
            .text('FECHA CADUCIDAD:', 20, 208)
            .text(fechaCaducidad, 108, 208)

          console.log('condConservacion')
          console.log(condConservacion)
          //Fecha Elaboración y Caducidad
          doc
            .fontSize(6.9)
            .font('Helvetica')
            .text(condConservacion + '\n', 18, 217, {
              width: 132,
              align: 'justify',
            })
            .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
              width: 132,
            })
            .text(observacionesTotales != undefined ? observacionesTotales : '', {
              width: 132,
            })
            .text(observaciones != undefined ? observaciones : '', {
              continued: false,
              width: 132,
            })

          //Lote depende de la plantilla ;

          doc
            .fontSize(6.9)
            .font('Helvetica-Bold')
            .text('LOTE: ', 18, 274, {
              width: 153,
              height: 10,
              continued: true,
            })
            .font('Helvetica')
            .text(codLote, {
              continued: false,
            })
          doc
            .fontSize(6.9)
            .font('Helvetica-Bold')
            .text(
              multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
              92,
              263,
              {
                width: 153,
                height: 10,
              }
            )

          //Borde Descriptivo Inf. Nutricional
          doc
            .roundedRect(153, 197, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .font('Helvetica-Bold')
            .stroke()
            .fontSize(6.7)
            .text('Inf. Nutricional', 154, 199)

          //Borde Descriptivo Energía(Kj/Kcal
          doc
            .roundedRect(153, 206, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Energía(Kj/Kcal)', 154, 208)

          //Borde Descriptivo Grasas(g)
          doc
            .roundedRect(153, 215, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Grasas(g)', 154, 217)

          //Borde Descriptivo Saturadas
          doc
            .roundedRect(153, 224, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Saturadas', 154, 226)

          //Borde Descriptivo Hidr. Carbono(g)
          doc
            .roundedRect(153, 233, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Hidr.Carbono(g)', 154, 235)

          //Borde Descriptivo Azúcares
          doc
            .roundedRect(153, 242, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Azúcares', 154, 244)

          //Borde Descriptivo Fibra
          doc
            .roundedRect(153, 251, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Fibra', 154, 253)

          //Borde Descriptivo Proteínas
          doc
            .roundedRect(153, 260, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Proteínas(g)', 154, 262)

          //Borde Descriptivo Sal(g)
          doc
            .roundedRect(153, 269, 51, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Sal(g)', 154, 271)

          //Borde Descriptivo por100G
          doc
            .roundedRect(204, 197, 30, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .font('Helvetica-Bold')
            .fillColor('black')
            .text('Por 100g      /ml', 205, 199)
            .stroke()

          doc
            .roundedRect(204, 206, 30, 9)
            .stroke()
            .fontSize(6.7)
            .font('Helvetica')
            .fillColor('black')
            .text(
              Math.round(Number(infoNutricionalReceta100[1].total)) +
                '/' +
                Math.round(Number(infoNutricionalReceta100[0].total)),
              205,
              208,
              {
                width: 30,
                height: 10,
                align: 'center',
              }
            )

          //Borde por100G Grasas
          doc.roundedRect(204, 215, 30, 9).stroke().text(infoNutricionalReceta100[3].total, 205, 217, {
            width: 30,
            height: 10,
            align: 'center',
          })

          //Borde por100G Saturados
          doc.roundedRect(204, 224, 30, 9).stroke().text(infoNutricionalReceta100[4].total, 205, 226, {
            width: 30,
            height: 10,
            align: 'center',
          })

          //Borde por100G Hid_Carbonos
          doc.roundedRect(204, 233, 30, 9).stroke().text(infoNutricionalReceta100[7].total, 205, 235, {
            width: 30,
            height: 10,
            align: 'center',
          })

          //Borde por100G Azúcares
          doc.roundedRect(204, 242, 30, 9).stroke().text(infoNutricionalReceta100[9].total, 205, 244, {
            width: 30,
            height: 10,
            align: 'center',
          })

          //Borde por100G Fibra
          doc.roundedRect(204, 251, 30, 9).stroke().text(infoNutricionalReceta100[8].total, 205, 253, {
            width: 30,
            height: 10,
            align: 'center',
          })

          //Borde por100G Proteínas
          doc.roundedRect(204, 260, 30, 9).stroke().text(infoNutricionalReceta100[2].total, 205, 262, {
            width: 30,
            height: 10,
            align: 'center',
          })

          //Borde por100G Sal
          doc.roundedRect(204, 269, 30, 9).stroke().text(infoNutricionalReceta100[10].total, 205, 271, {
            width: 30,
            height: 10,
            align: 'center',
          })

          //Borde Descriptivo porRación
          doc
            .roundedRect(234, 197, 31, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .fontSize(6.7)
            .font('Helvetica-Bold')
            .fillColor('black')
            .text('Ración', 235, 199)

          //Borde porRación Energía
          doc
            .roundedRect(234, 206, 31, 9)
            .stroke()
            .fontSize(6.7)
            .font('Helvetica')
            .fillColor('black')
            .text(
              Math.round(Number(infoNutricionalReceta[3].total)) +
                '/' +
                Math.round(Number(infoNutricionalReceta[2].total)),
              235,
              208,
              {
                width: 31,
                height: 10,
                align: 'center',
              }
            )

          //Borde porRación Grasas
          doc.roundedRect(234, 215, 31, 9).stroke().text(infoNutricionalReceta[5].total, 235, 217, {
            width: 31,
            height: 10,
            align: 'center',
          })

          //Borde porRación Saturados
          doc.roundedRect(234, 224, 31, 9).stroke().text(infoNutricionalReceta[6].total, 235, 226, {
            width: 31,
            height: 10,
            align: 'center',
          })

          //Borde porRación Hid_Carbonos
          doc.roundedRect(234, 233, 31, 9).stroke().text(infoNutricionalReceta[9].total, 235, 235, {
            width: 31,
            height: 10,
            align: 'center',
          })

          //Borde porRación Azúcares
          doc.roundedRect(234, 242, 31, 9).stroke().text(infoNutricionalReceta[11].total, 235, 244, {
            width: 31,
            height: 10,
            align: 'center',
          })

          //Borde porRación Fibra
          doc.roundedRect(234, 251, 31, 9).stroke().text(infoNutricionalReceta[10].total, 235, 253, {
            width: 31,
            height: 10,
            align: 'center',
          })

          //Borde porRación Proteínas
          doc.roundedRect(234, 260, 31, 9).stroke().text(infoNutricionalReceta[4].total, 235, 262, {
            width: 31,
            height: 10,
            align: 'center',
          })

          //Borde porRación Sal
          doc.roundedRect(234, 269, 31, 9).stroke().text(infoNutricionalReceta[12].total, 235, 271, {
            width: 31,
            height: 10,
            align: 'center',
          })

          if (cantidadEtiqImprimir != 1 && e != cantidadEtiqImprimir - 1) {
            //Agrego la segunda página
            doc.addPage()
          }
        }

        // Obtengo el blob cuando está hecho todo.
        doc.end()

        stream.on('finish', function () {
          //   // // get a blob you can do whatever you like with
          //   // const blob = stream.toBlob('application/pdf');

          //   // or get a blob URL for display in the browser
          const url = stream.toBlobURL('application/pdf')
          // abrir un PDF en una pestaña nueva
          window.open(url, '_blank')
          // redirigir la pestaña actual a otra URL
          //window.location.href = 'http://ejemplo.com';

          //llamarActualizarEstadosSpingImprimirEtiquetas();
        })

        // console.log('str.substring(6, 10) + str.substring(3, 5) + str.substring(0, 2)');
        // console.log(fechaCaducidad.substring(6, 10) + fechaCaducidad.substring(3, 5) + fechaCaducidad.substring(0, 2));
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}

async function imprimirEtiquetasGeneralPlantilla6(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  receta,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    // this.setState({
    //     loadingSpingImprimirEtiquetas: true,
    //     textoLoadingSpingImprimirEtiquetas: 'Imprimiendo etiquetas ...',
    //     loadingButtonLanzarImpresion: true
    // });
    var observacionesTotales = ''
    var condConservacion = ''
    var modoEmpleo = ''
    console.log(infoNutricionalReceta100)
    // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
    // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
    // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

    if (codObservacionesEspeciales.length != 0) {
      for (let i = 0; i < codObservacionesEspeciales.length; i++) {
        var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

        observacionesTotales += observacion[1] + '.\n'
      }
    } else {
      observacionesTotales = ''
    }

    if (listaLineasProduccion.length != 0) {
      for (let a = 0; a < listaLineasProduccion.length; a++) {
        if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
          condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
          modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
        }
      }
    }

    var loteCodigoBarra =
      codLote.substring(2, 8) +
      fechaCaducidad.substring(8, 10) +
      fechaCaducidad.substring(3, 5) +
      fechaCaducidad.substring(0, 2) +
      receta

    console.log('loteCodigoBarra: ', loteCodigoBarra)
    console.log('receta ', receta)

    var caracteresPar = 0
    var caracteresImpares = 0

    for (let i = 0; i < loteCodigoBarra.length + 1; i++) {
      if (i % 2 === 0) {
        caracteresPar += Number(loteCodigoBarra.charAt(i))
      } else if (i % 2 != 0) {
        caracteresImpares += Number(loteCodigoBarra.charAt(i))
      }
    }

    var caracteresParPor1 = caracteresPar * 1

    var caracteresImparesPor3 = caracteresImpares * 3

    var sumaResultado = caracteresParPor1 + caracteresImparesPor3

    var decenaSuperior = sumaResultado - (sumaResultado % 10) + 10

    var calcularDigitoControl = decenaSuperior - sumaResultado

    var loteACodificarDigitoControl = loteCodigoBarra + calcularDigitoControl

    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      (res) => {
        const datosCocinaImprimir = res.response

        database.obtenerBarcode(loteACodificarDigitoControl).then(
          async (res) => {
            var barcodeImagen = res.response

            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAbarcodeImagen')
            console.log(barcodeImagen)

            // create a document and pipe to a blob
            var doc = new PDFDocument({
              //layout: "landscape",
              size: [largo, ancho75],
              margin: 2,
              info: {
                Title: 'Etiquetas',
                Author: 'Alejandro Vázquez Chávez',
              },
            })
            var stream = doc.pipe(blobStream())

            for (let e = 0; e < cantidadEtiqImprimir; e++) {
              doc
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text('SERVICIO DE CATERING Y COLECTIVIDADES', 30, 8, {
                  width: 250,
                  height: 10,
                  align: 'center',
                })

              //Dirección Cocina
              doc
                .fontSize(6)
                .font('Helvetica')
                .fillColor('black')
                .text(datosCocinaImprimir[0].DIRECCION_UCP, 37, 16, {
                  width: 250,
                  height: 20,
                  align: 'center',
                  continued: true,
                })
                .text('\n')
                .fontSize(5.5) //Nombre Cocina
                .font('Helvetica')
                .fillColor('black')
                .text(
                  datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
                    ' - ' +
                    'NRGSA: ' +
                    datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
                  {
                    width: 250,
                    height: 10,
                    align: 'center',
                  }
                )

              //Logo izquierdo
              if (rutaLogoIzquierdo != undefined || rutaLogoIzquierdo != null) {
                const buffer = await getBufferLogo(rutaLogoIzquierdo)
                doc.image(buffer, 8, 3, {
                  scale: 0.09,
                })
              }

              // //Logo derecho
              // if (rutaLogoDerecho != undefined || rutaLogoDerecho != null) {
              //     doc
              //         .image(rutaLogoDerecho, 232, 5, {
              //             scale: 0.12
              //         });
              // }

              //Número de Registro Sanitario Cocina
              // doc
              //     .fontSize(5.5)
              //     .font('Helvetica')
              //     .fillColor('black')
              //     .text('NRGSA: ' + datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP, 13, 55, {
              //         width: 100,
              //         height: 12
              //     });

              //Nombre Cliente
              // if (codCliente.length != 0) {
              //     doc
              //         .fontSize(8)
              //         .font('Helvetica-Bold')
              //         .fillColor('black')
              //         .text('CLIENTE: ' + codCliente[1] === null ? '' : codCliente[1],  13, 48, {
              //             continued: true
              //         })
              //         .text('\n')

              // }

              var plato =
                nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

              // doc
              //     .fontSize(9)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text(plato , 13, 74, {
              //         width: 260,
              //         height: heightPlato
              //     });

              //Nombre Plato
              if (ingredientesPrincipal != null) {
                doc.fontSize(8.3).font('Helvetica-Bold').fillColor('black').text(plato, 7, 35, {
                  //codCliente.length != 0 ? 58 : 53, {
                  width: 274,
                  continued: true,
                })

                if (texto01 != undefined && texto01.length > 0) {
                  doc.text('\n').fontSize(8.3).font('Helvetica-Bold').text(texto01, {
                    width: 275,
                    align: 'justify',
                    continued: true,
                  })
                }
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipal)
                }
              } else {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(8.3)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text(plato, 7, 35, {
                      width: 274,
                      continued: false,
                    })
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipalEtiqueta)
                }
              }
              //   .font('Helvetica-Bold')
              //   .text('INGREDIENTES GUARNICIÓN: ', {
              //     width: 264,
              //     align: 'justify',
              //     continued: true
              //   }).font('Helvetica')
              //   .text(ingredientesGuarnicionEtiqueta.length)
              //   .font('Helvetica-Bold')
              //   .fillColor('black')
              //   .text('CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos', {
              //     width: 264,
              //     align: 'justify'
              //   });

              // var margenTopIngredientes = heightPlato === 30 ? 95 : 84
              // //Ingredientes Principal
              // doc
              //     .fontSize(6.5)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text('INGREDIENTES PRINCIPAL: ', 13, margenTopIngredientes , {
              //         width: 260,
              //         height: 70,
              //         align: 'justify',
              //         continued: true
              //     }).font('Helvetica')
              //     .text(ingredientesPrincipalEtiqueta)

              //Ingredientes Guarnición - Para ponerlo consecutivo tengo que calcular el desplazamiento.
              if (ingredientesGuarnicion.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                }
              } else if (ingredientesGuarnicionEtiqueta.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                }
              }

              if(!ingredientesGuarnicion && !ingredientesPrincipal) {
                console.log("CASO ESPECIAl 5")
                doc
                    .text("\n")

              }

              var alergenosTotal = ''
              if (alergenosPrincipal != null) {
                alergenosTotal += alergenosPrincipal
              } else {
                alergenosTotal += valueTextAreaAlergenosPrincipal
              }

              if (alergenosGuarnicion != null) {
                alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
              } else {
                alergenosTotal += valueTextAreaAlergenosGuarnicion
              }

              //Alérgenos
              if (alergenosTotal != '' && alergenosTotal.length >= 3) {
                //mostrarPuedeContener, puedeContener
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              //Alérgenos Puede Contener
              //console.log('puedeContener--------------------------------------' + puedeContener);
              if (mostrarPuedeContener === 'S') {
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text('PUEDE CONTENER: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(puedeContener, {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              if (texto02 != undefined) {
                doc.text('\n').font('Helvetica').fontSize(7.1).text(texto02, {
                  width: 274,
                  align: 'justify',
                  continued: false,
                })
              }

              //, texto02, texto03, texto04)

              //Texto 03
              doc.fontSize(7.3).font('Helvetica-Bold').text(texto03, 15, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Texto 04
              doc.fontSize(7).font('Helvetica').text(texto04, 170, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Fecha Elaboración y Caducidad
              doc
                .roundedRect(7, 120, 139, 20)
                .stroke()
                .fontSize(7)
                .font('Helvetica-Bold')
                .text('FECHA ELABORACIÓN:', 13, 123)
                .text(fechaElaboracion, 103, 123)
                .text('FECHA CADUCIDAD:', 13, 133)
                .text(fechaCaducidad, 103, 133)

              console.log('condConservacion')
              console.log(condConservacion)
              //Fecha Elaboración y Caducidad
              doc
                .fontSize(6.8)
                .font('Helvetica')
                .text(condConservacion + '\n', 7, 144, {
                  width: 142,
                  align: 'justify',
                })
                .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
                  width: 142,
                })
                .text(observacionesTotales != undefined ? observacionesTotales : '', {
                  width: 142,
                })
                .text(observaciones != undefined ? observaciones : '', {
                  continued: false,
                  width: 142,
                })

              //Lote depende de la plantilla ;

              console.log('barcodeImagen')
              console.log(barcodeImagen)
              var imagenBase64 = barcodeImagen.message
              doc
                .fontSize(6.9)
                .font('Helvetica-Bold')
                .text(
                  multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
                  85,
                  180,
                  {
                    width: 153,
                    height: 10,
                  }
                )
                .image(new Buffer(imagenBase64.replace('data:image/png;base64,', ''), 'base64'), 8, 187, {
                  height: 21,
                  width: 138,
                })

              // doc
              //     .fontSize(6.8)
              //     .font('Helvetica-Bold')
              //     .text('LOTE: ', 8, 197, {
              //         width: 153,
              //         height: 10,
              //         continued: true
              //     })
              //     .font('Helvetica')
              //     .text(codLote, {
              //         continued: false
              //     })
              //     .fontSize(6.8)
              //     .font('Helvetica-Bold')
              //     .text(multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '', 85, 190, {
              //         width: 153,
              //         height: 10,
              //     });

              //Borde Descriptivo Inf. Nutricional
              doc
                .roundedRect(150, 120, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .stroke()
                .fontSize(6.8)
                .text('Inf. Nutricional', 151, 122)

              //Borde Descriptivo Energía(Kj/Kcal
              doc
                .roundedRect(150, 129, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Energía(Kj/Kcal)', 151, 131)

              //Borde Descriptivo Grasas(g)
              doc
                .roundedRect(150, 138, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Grasas(g)', 151, 140)

              //Borde Descriptivo Saturadas
              doc
                .roundedRect(150, 147, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Saturadas', 151, 149)

              //Borde Descriptivo Hidr. Carbono(g)
              doc
                .roundedRect(150, 156, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Hidr. Carbono(g)', 151, 158)

              //Borde Descriptivo Azúcares
              doc
                .roundedRect(150, 165, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Azúcares', 151, 167)

              //Borde Descriptivo Fibra
              doc
                .roundedRect(150, 174, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Fibra', 151, 176)

              //Borde Descriptivo Proteínas
              doc
                .roundedRect(150, 183, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Proteínas(g)', 151, 185)

              //Borde Descriptivo Sal(g)
              doc
                .roundedRect(150, 192, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Sal(g)', 151, 194)

              //Borde Descriptivo por100G
              doc
                .roundedRect(204, 120, 39, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Por 100g/ml', 204, 122)
                .stroke()

              //Borde por100G Energía Number(((datosGuarnicion[0].POLIALCOHOLES / datosGuarnicion[0].PESOT) * 100).toFixed(2))
              console.log('infoNutricionalReceta100')
              console.log(infoNutricionalReceta100)
              doc
                .roundedRect(204, 129, 39, 9)
                .stroke()
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta100[1].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta100[0].total)),
                  205,
                  131,
                  {
                    width: 34,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde por100G Grasas
              doc.roundedRect(204, 138, 39, 9).stroke().text(infoNutricionalReceta100[3].total, 205, 140, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Saturados
              doc.roundedRect(204, 147, 39, 9).stroke().text(infoNutricionalReceta100[4].total, 205, 149, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Hid_Carbonos
              doc.roundedRect(204, 156, 39, 9).stroke().text(infoNutricionalReceta100[7].total, 205, 158, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Azúcares
              doc.roundedRect(204, 165, 39, 9).stroke().text(infoNutricionalReceta100[9].total, 205, 167, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Fibra
              doc.roundedRect(204, 174, 39, 9).stroke().text(infoNutricionalReceta100[8].total, 205, 176, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Proteínas
              doc.roundedRect(204, 183, 39, 9).stroke().text(infoNutricionalReceta100[2].total, 205, 185, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde por100G Sal
              doc.roundedRect(204, 192, 39, 9).stroke().text(infoNutricionalReceta100[10].total, 205, 194, {
                width: 34,
                height: 10,
                align: 'center',
              })

              //Borde Descriptivo porRación
              doc
                .roundedRect(243, 120, 38, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .fontSize(6.8)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Ración', 244, 122)

              //Borde porRación Energía
              doc
                .roundedRect(243, 129, 38, 9)
                .stroke()
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta[3].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta[2].total)),
                  244,
                  131,
                  {
                    width: 33,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde porRación Grasas
              doc.roundedRect(243, 138, 38, 9).stroke().text(infoNutricionalReceta[5].total, 244, 140, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Saturados
              doc.roundedRect(243, 147, 38, 9).stroke().text(infoNutricionalReceta[6].total, 244, 149, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Hid_Carbonos
              doc.roundedRect(243, 156, 38, 9).stroke().text(infoNutricionalReceta[9].total, 244, 158, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Azúcares
              doc.roundedRect(243, 165, 38, 9).stroke().text(infoNutricionalReceta[11].total, 244, 167, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Fibra
              doc.roundedRect(243, 174, 38, 9).stroke().text(infoNutricionalReceta[10].total, 244, 176, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Proteínas
              doc.roundedRect(243, 183, 38, 9).stroke().text(infoNutricionalReceta[4].total, 244, 185, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Sal
              doc.roundedRect(243, 192, 38, 9).stroke().text(infoNutricionalReceta[12].total, 244, 194, {
                //4
                width: 33,
                height: 10,
                align: 'center',
              })

              if (cantidadEtiqImprimir != 1 && e != cantidadEtiqImprimir - 1) {
                //Agrego la segunda página
                doc.addPage()
              }
            }

            // Obtengo el blob cuando está hecho todo.
            doc.end()

            stream.on('finish', function () {
              //   // // get a blob you can do whatever you like with
              //   // const blob = stream.toBlob('application/pdf');

              //   // or get a blob URL for display in the browser
              const url = stream.toBlobURL('application/pdf')
              // abrir un PDF en una pestaña nueva
              window.open(url, '_blank')
              // redirigir la pestaña actual a otra URL
              //window.location.href = 'http://ejemplo.com';

              //llamarActualizarEstadosSpingImprimirEtiquetas();
            })
          },
          (error) => console.log(error)
        )
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}

async function imprimirEtiquetasGeneralPlantilla7(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  receta,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    // this.setState({
    //     loadingSpingImprimirEtiquetas: true,
    //     textoLoadingSpingImprimirEtiquetas: 'Imprimiendo etiquetas ...',
    //     loadingButtonLanzarImpresion: true
    // });
    var observacionesTotales = ''
    var condConservacion = ''
    var modoEmpleo = ''
    console.log(infoNutricionalReceta100)
    // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
    // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
    // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

    if (codObservacionesEspeciales.length != 0) {
      for (let i = 0; i < codObservacionesEspeciales.length; i++) {
        var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

        observacionesTotales += observacion[1] + '.\n'
      }
    } else {
      observacionesTotales = ''
    }

    if (listaLineasProduccion.length != 0) {
      for (let a = 0; a < listaLineasProduccion.length; a++) {
        if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
          condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
          modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
        }
      }
    }

    var loteCodigoBarra =
      codLote.substring(2, 8) +
      fechaCaducidad.substring(8, 10) +
      fechaCaducidad.substring(3, 5) +
      fechaCaducidad.substring(0, 2) +
      receta

    console.log('loteCodigoBarra: ', loteCodigoBarra)
    console.log('receta ', receta)

    var caracteresPar = 0
    var caracteresImpares = 0

    for (let i = 0; i < loteCodigoBarra.length + 1; i++) {
      if (i % 2 === 0) {
        caracteresPar += Number(loteCodigoBarra.charAt(i))
      } else if (i % 2 != 0) {
        caracteresImpares += Number(loteCodigoBarra.charAt(i))
      }
    }

    var caracteresParPor1 = caracteresPar * 1

    var caracteresImparesPor3 = caracteresImpares * 3

    var sumaResultado = caracteresParPor1 + caracteresImparesPor3

    var decenaSuperior = sumaResultado - (sumaResultado % 10) + 10

    var calcularDigitoControl = decenaSuperior - sumaResultado

    var loteACodificarDigitoControl = loteCodigoBarra + calcularDigitoControl

    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      (res) => {
        const datosCocinaImprimir = res.response

        var doc = new PDFDocument({
          layout: 'landscape',
          size: [largo43, ancho91],
          margin: 5,
          info: {
            Title: 'Etiquetas',
            Author: 'Alejandro Vázquez Chávez',
          },
        })
        var stream = doc.pipe(blobStream())

        doc.fontSize(6).font('Helvetica').fillColor('black').text('SERVICIO DE CATERING Y COLECTIVIDADES', 23, 5, {
          width: 200,
          height: 10,
          align: 'center',
        })

        //Dirección Cocina
        doc.fontSize(6).font('Helvetica').fillColor('black').text(datosCocinaImprimir[0].DIRECCION_UCP, 23, 12, {
          width: 200,
          height: 20,
          align: 'center',
        })

        //Logo izquierdo
        doc.image('images/logosEtiquetas/logosSinColores/Eurest.jpg', 3, 2, {
          scale: 0.07,
        })

        //Logo derecho
        doc.image('images/logosEtiquetas/logosColores/Eurest.jpg', 220, 2, {
          scale: 0.07,
        })

        //Nombre Cocina
        doc
          .fontSize(5.5)
          .font('Helvetica')
          .fillColor('black')
          .text(
            datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
              ' - ' +
              'NRGSA: ' +
              datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
            23,
            20,
            {
              width: 200,
              height: 10,
              align: 'center',
            }
          )

        var plato = nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

        doc
          .fontSize(7.1)
          .font('Helvetica-Bold')
          .fillColor('black')
          .text(plato, 5, 30, {
            width: 243,
            continued: false,
          })
          .fontSize(6.2)
          .font('Helvetica-Bold')
          .fillColor('black')
          .text('INGREDIENTES: ', {
            width: 243,
            align: 'justify',
            continued: true,
          })
          .font('Helvetica')
          .text(ingredientesPrincipal + '.')
          .font('Helvetica-Bold')

        if (ingredientesGuarnicionEtiqueta.length != 0) {
          doc
            .text('INGREDIENTES GUARNICIÓN: ', {
              width: 243,
              align: 'justify',
              continued: true,
            })
            .font('Helvetica')
            .text(ingredientesGuarnicionEtiqueta, {
              width: 243,
              align: 'justify',
            })
        }

        

        var alergenosTotal = ''
        if (alergenosPrincipal != null) {
          alergenosTotal += alergenosPrincipal
        } else {
          alergenosTotal += valueTextAreaAlergenosPrincipal
        }

        if (alergenosGuarnicion != null) {
          alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
        } else {
          alergenosTotal += valueTextAreaAlergenosGuarnicion
        }

        //Alérgenos
        // if (alergenosTotal != '' && alergenosTotal.length >= 3) { //mostrarPuedeContener, puedeContener
        //     doc
        //         .fontSize(7.2)
        //         .font('Helvetica-Bold')
        //         .fillColor('black')
        //         .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
        //             width: 264,
        //             align: 'justify',
        //             continued: true,
        //         })
        //         .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
        //             width: 264,
        //             align: 'justify',
        //             continued: texto02 != undefined ? true : false,
        //         })

        // }

        if (alergenosTotal.length > 0) {
          doc
            .font('Helvetica-Bold')
            .fillColor('black')
            .text('CONTIENE: ' + alergenosTotal + '.', {
              width: 243,
              align: 'justify',
            })
        }

        doc.fontSize(6).text('FECHA ELABORACIÓN: ' + fechaElaboracion + '  FECHA CADUCIDAD: ' + fechaCaducidad, {
          width: 243,
        })

        //Fecha Elaboración y Caducidad
        doc
          .fontSize(5.5)
          .font('Helvetica')
          .text(condConservacion, {
            width: 243,
            align: 'justify',
          })
          .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
            width: 243,
            align: 'justify',
          })
          .text(observacionesTotales != undefined ? observacionesTotales : '', {
            width: 243,
            align: 'justify',
          })
          .text(observaciones != undefined ? observaciones : '', {
            width: 243,
            align: 'justify',
          })
          .text(
            'LOTE: ' + codLote + '                           Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + 'g',
            {
              width: 243,
              height: 10,
              continued: true,
            }
          )

        // Obtengo el blob cuando está hecho todo.
        doc.end()

        stream.on('finish', function () {
          //   // // get a blob you can do whatever you like with
          //   // const blob = stream.toBlob('application/pdf');

          //   // or get a blob URL for display in the browser
          const url = stream.toBlobURL('application/pdf')
          // abrir un PDF en una pestaña nueva
          window.open(url, '_blank')
          // redirigir la pestaña actual a otra URL
          //window.location.href = 'http://ejemplo.com';

          //llamarActualizarEstadosSpingImprimirEtiquetas();
        })
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}

async function imprimirEtiquetasGeneralPlantilla8(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  receta,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    // this.setState({
    //     loadingSpingImprimirEtiquetas: true,
    //     textoLoadingSpingImprimirEtiquetas: 'Imprimiendo etiquetas ...',
    //     loadingButtonLanzarImpresion: true
    // });
    var observacionesTotales = ''
    var condConservacion = ''
    var modoEmpleo = ''
    console.log(infoNutricionalReceta100)
    // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
    // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
    // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

    if (codObservacionesEspeciales.length != 0) {
      for (let i = 0; i < codObservacionesEspeciales.length; i++) {
        var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

        observacionesTotales += observacion[1] + '.\n'
      }
    } else {
      observacionesTotales = ''
    }

    //observacionesTotales = observacionesTotales.length > 0 ? observacionesTotales : '';

    if (listaLineasProduccion.length != 0) {
      for (let a = 0; a < listaLineasProduccion.length; a++) {
        if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
          condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
          modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
        }
      }
    }

    var loteCodigoBarra =
      codLote.substring(2, 8) +
      fechaCaducidad.substring(8, 10) +
      fechaCaducidad.substring(3, 5) +
      fechaCaducidad.substring(0, 2) +
      receta

    console.log('loteCodigoBarra: ', loteCodigoBarra)
    console.log('receta ', receta)

    var caracteresPar = 0
    var caracteresImpares = 0

    for (let i = 0; i < loteCodigoBarra.length + 1; i++) {
      if (i % 2 === 0) {
        caracteresPar += Number(loteCodigoBarra.charAt(i))
      } else if (i % 2 != 0) {
        caracteresImpares += Number(loteCodigoBarra.charAt(i))
      }
    }

    var caracteresParPor1 = caracteresPar * 1

    var caracteresImparesPor3 = caracteresImpares * 3

    var sumaResultado = caracteresParPor1 + caracteresImparesPor3

    var decenaSuperior = sumaResultado - (sumaResultado % 10) + 10

    var calcularDigitoControl = decenaSuperior - sumaResultado

    var loteACodificarDigitoControl = loteCodigoBarra + calcularDigitoControl

    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      (res) => {
        const datosCocinaImprimir = res.response

        database.obtenerBarcode(loteACodificarDigitoControl).then(
          async (res) => {
            var barcodeImagen = res.response

            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAbarcodeImagen')
            console.log(barcodeImagen)

            // create a document and pipe to a blob
            var doc = new PDFDocument({
              //layout: "landscape",
              size: [largo, ancho],
              margin: 2,
              info: {
                Title: 'Etiquetas',
                Author: 'Alejandro Vázquez Chávez',
              },
            })
            var stream = doc.pipe(blobStream())

            for (let e = 0; e < cantidadEtiqImprimir; e++) {
              doc
                .fontSize(6.8)
                .font('Helvetica')
                .fillColor('black')
                .text('SERVICIO DE CATERING Y COLECTIVIDADES', 30, 8, {
                  width: 250,
                  height: 10,
                  align: 'center',
                })

              //Dirección Cocina
              doc
                .fontSize(6)
                .font('Helvetica')
                .fillColor('black')
                .text(datosCocinaImprimir[0].DIRECCION_UCP, 37, 16, {
                  width: 250,
                  height: 20,
                  align: 'center',
                  continued: true,
                })
                .text('\n')
                .fontSize(5.5) //Nombre Cocina
                .font('Helvetica')
                .fillColor('black')
                .text(
                  datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
                    ' - ' +
                    'NRGSA: ' +
                    datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
                  {
                    width: 250,
                    height: 10,
                    align: 'center',
                  }
                )

              //Logo izquierdo
              if (rutaLogoIzquierdo != undefined || rutaLogoIzquierdo != null) {
                const buffer = await getBufferLogo(rutaLogoIzquierdo)
                doc.image(buffer, 8, 3, {
                  scale: 0.09,
                })
              }

              // //Logo derecho
              // if (rutaLogoDerecho != undefined || rutaLogoDerecho != null) {
              //     doc
              //         .image(rutaLogoDerecho, 232, 5, {
              //             scale: 0.12
              //         });
              // }

              //Número de Registro Sanitario Cocina
              // doc
              //     .fontSize(5.5)
              //     .font('Helvetica')
              //     .fillColor('black')
              //     .text('NRGSA: ' + datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP, 13, 55, {
              //         width: 100,
              //         height: 12
              //     });

              //Nombre Cliente
              // if (codCliente.length != 0) {
              //     doc
              //         .fontSize(8)
              //         .font('Helvetica-Bold')
              //         .fillColor('black')
              //         .text('CLIENTE: ' + codCliente[1] === null ? '' : codCliente[1],  13, 48, {
              //             continued: true
              //         })
              //         .text('\n')

              // }

              var plato =
                nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

              // doc
              //     .fontSize(9)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text(plato , 13, 74, {
              //         width: 260,
              //         height: heightPlato
              //     });

              //Nombre Plato
              if (ingredientesPrincipal != null) {
                doc.fontSize(8.5).font('Helvetica-Bold').fillColor('black').text(plato, 7, 35, {
                  //codCliente.length != 0 ? 58 : 53, {
                  width: 274,
                  continued: true,
                })

                if (texto01 != undefined && texto01.length > 0) {
                  doc.text('\n').fontSize(8.5).font('Helvetica-Bold').text(texto01, {
                    width: 275,
                    align: 'justify',
                    continued: true,
                  })
                }
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipal)
                }
              } else {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .text('\n')
                    .fontSize(8.5)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text(plato, 7, 35, {
                      width: 274,
                      continued: false,
                    })
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(': ' + ingredientesPrincipalEtiqueta)
                }
              }
              //   .font('Helvetica-Bold')
              //   .text('INGREDIENTES GUARNICIÓN: ', {
              //     width: 264,
              //     align: 'justify',
              //     continued: true
              //   }).font('Helvetica')
              //   .text(ingredientesGuarnicionEtiqueta.length)
              //   .font('Helvetica-Bold')
              //   .fillColor('black')
              //   .text('CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos', {
              //     width: 264,
              //     align: 'justify'
              //   });

              // var margenTopIngredientes = heightPlato === 30 ? 95 : 84
              // //Ingredientes Principal
              // doc
              //     .fontSize(6.5)
              //     .font('Helvetica-Bold')
              //     .fillColor('black')
              //     .text('INGREDIENTES PRINCIPAL: ', 13, margenTopIngredientes , {
              //         width: 260,
              //         height: 70,
              //         align: 'justify',
              //         continued: true
              //     }).font('Helvetica')
              //     .text(ingredientesPrincipalEtiqueta)

              //Ingredientes Guarnición - Para ponerlo consecutivo tengo que calcular el desplazamiento.
              if (ingredientesGuarnicion.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicion)
                }
              } else if (ingredientesGuarnicionEtiqueta.length != 0) {
                if (ingredientesPrincipal.length > 0) {
                  doc
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                } else {
                  doc
                    .text('\n')
                    .fontSize(7.1)
                    .font('Helvetica-Bold')
                    .fillColor('black')
                    .text('INGREDIENTES GUARNICIÓN: ', {
                      width: 274,
                      align: 'justify',
                      continued: true,
                    })
                    .font('Helvetica')
                    .text(ingredientesGuarnicionEtiqueta)
                }
              }

              if(!ingredientesGuarnicion && !ingredientesPrincipal) {
                console.log("CASO ESPECIAl 7")
                doc
                    .text("\n")

              }

              var alergenosTotal = ''
              if (alergenosPrincipal != null) {
                alergenosTotal += alergenosPrincipal
              } else {
                alergenosTotal += valueTextAreaAlergenosPrincipal
              }

              if (alergenosGuarnicion != null) {
                alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
              } else {
                alergenosTotal += valueTextAreaAlergenosGuarnicion
              }

              //Alérgenos
              if (alergenosTotal != '' && alergenosTotal.length >= 3) {
                //mostrarPuedeContener, puedeContener
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              //Alérgenos Puede Contener
              //console.log('puedeContener--------------------------------------' + puedeContener);
              if (mostrarPuedeContener === 'S') {
                doc
                  .fontSize(7.2)
                  .font('Helvetica-Bold')
                  .fillColor('black')
                  .text('PUEDE CONTENER: ', {
                    width: 264,
                    align: 'justify',
                    continued: true,
                  })
                  .text(puedeContener, {
                    width: 264,
                    align: 'justify',
                    continued: texto02 != undefined ? true : false,
                  })
              }

              if (texto02 != undefined) {
                doc.text('\n').font('Helvetica').fontSize(7.5).text(texto02, {
                  width: 274,
                  align: 'justify',
                  continued: false,
                })
              }

              //, texto02, texto03, texto04)

              //Texto 03
              doc.fontSize(7.5).font('Helvetica-Bold').text(texto03, 15, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Texto 04
              doc.fontSize(7).font('Helvetica').text(texto04, 170, 188, {
                width: 153,
                height: 10,
                align: 'justify',
                continued: false,
              })

              //Fecha Elaboración y Caducidad
              doc
                .roundedRect(7, 201, 139, 20)
                .stroke()
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .text('FECHA ELABORACIÓN:', 13, 204)
                .text(fechaElaboracion, 105, 204)
                .text('FECHA CADUCIDAD:', 13, 214)
                .text(fechaCaducidad, 105, 214)

              console.log('condConservacion')
              console.log(condConservacion)
              //Fecha Elaboración y Caducidad
              doc
                .fontSize(6.9)
                .font('Helvetica')
                .text(condConservacion + '\n', 7, 224, {
                  width: 142,
                  align: 'justify',
                })
                .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
                  width: 142,
                })
                .text(observacionesTotales != undefined ? observacionesTotales : '', {
                  width: 142,
                })
                .text(observaciones != undefined ? observaciones : '', {
                  continued: false,
                  width: 142,
                })

              //Lote depende de la plantilla ;

              var imagenBase64 = barcodeImagen.message
              doc
                .fontSize(6.9)
                .font('Helvetica-Bold')
                .text(
                  multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
                  92,
                  255,
                  {
                    width: 153,
                    height: 10,
                  }
                )
                .image(new Buffer(imagenBase64.replace('data:image/png;base64,', ''), 'base64'), 8, 263, {
                  height: 23,
                  width: 147,
                })

              // doc
              //     .fontSize(6.9)
              //     .font('Helvetica-Bold')
              //     .text('LOTE: ', 7, 275, {
              //         width: 153,
              //         height: 10,
              //         continued: true
              //     })
              //     .font('Helvetica')
              //     .text(codLote, {
              //         continued: false
              //     })
              //     .fontSize(6.9)
              //     .font('Helvetica-Bold')
              //     .text(multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '', 85, 269, {
              //         width: 153,
              //         height: 10,
              //     });

              //Borde Descriptivo Inf. Nutricional
              doc
                .roundedRect(154, 201, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .stroke()
                .fontSize(6.9)
                .text('Inf. Nutricional', 155, 203)

              //Borde Descriptivo Energía(Kj/Kcal
              doc
                .roundedRect(154, 210, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Energía(Kj/Kcal)', 155, 212)

              //Borde Descriptivo Grasas(g)
              doc
                .roundedRect(154, 219, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Grasas(g)', 155, 221)

              //Borde Descriptivo Saturadas
              doc
                .roundedRect(154, 228, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Saturadas', 155, 230)

              //Borde Descriptivo Hidr. Carbono(g)
              doc
                .roundedRect(154, 237, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Hidr. Carbono(g)', 155, 239)

              //Borde Descriptivo Azúcares
              doc
                .roundedRect(154, 246, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Azúcares', 155, 248)

              //Borde Descriptivo Fibra
              doc
                .roundedRect(154, 255, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Fibra', 155, 257)

              //Borde Descriptivo Proteínas
              doc
                .roundedRect(154, 264, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Proteínas(g)', 155, 266)

              //Borde Descriptivo Sal(g)
              doc
                .roundedRect(154, 273, 54, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .text('Sal(g)', 155, 275)

              //Borde Descriptivo por100G
              doc
                .roundedRect(208, 201, 39, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Por100g/ml', 209, 203)
                .stroke()

              //Borde por100G Energía Number(((datosGuarnicion[0].POLIALCOHOLES / datosGuarnicion[0].PESOT) * 100).toFixed(2))
              console.log('infoNutricionalReceta100')
              console.log(infoNutricionalReceta100)
              doc
                .roundedRect(208, 210, 39, 9)
                .stroke()
                .fontSize(6.9)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta100[1].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta100[0].total)),
                  209,
                  212,
                  {
                    width: 33,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde por100G Grasas
              doc.roundedRect(208, 219, 39, 9).stroke().text(infoNutricionalReceta100[3].total, 210, 221, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Saturados
              doc.roundedRect(208, 228, 39, 9).stroke().text(infoNutricionalReceta100[4].total, 210, 230, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Hid_Carbonos
              doc.roundedRect(208, 237, 39, 9).stroke().text(infoNutricionalReceta100[7].total, 210, 239, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Azúcares
              doc.roundedRect(208, 246, 39, 9).stroke().text(infoNutricionalReceta100[9].total, 210, 248, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Fibra
              doc.roundedRect(208, 255, 39, 9).stroke().text(infoNutricionalReceta100[8].total, 210, 257, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Proteínas
              doc.roundedRect(208, 264, 39, 9).stroke().text(infoNutricionalReceta100[2].total, 210, 266, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde por100G Sal
              doc
                .roundedRect(208, 273, 39, 9) //211
                .stroke()
                .text(infoNutricionalReceta100[10].total, 210, 275, {
                  width: 33,
                  height: 10,
                  align: 'center',
                })

              //Borde Descriptivo porRación
              doc
                .roundedRect(247, 201, 35, 9)
                .fillAndStroke('#f4f4f4', 'black')
                .fillAndStroke('black')
                .stroke()
                .fontSize(6.9)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('Ración', 248, 203)

              //Borde porRación Energía
              doc
                .roundedRect(247, 210, 35, 9)
                .stroke()
                .fontSize(6.9)
                .font('Helvetica')
                .fillColor('black')
                .text(
                  Math.round(Number(infoNutricionalReceta[3].total)) +
                    '/' +
                    Math.round(Number(infoNutricionalReceta[2].total)),
                  248,
                  212,
                  {
                    width: 33,
                    height: 10,
                    align: 'center',
                  }
                )

              //Borde porRación Grasas
              doc.roundedRect(247, 219, 35, 9).stroke().text(infoNutricionalReceta[5].total, 248, 221, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Saturados
              doc.roundedRect(247, 228, 35, 9).stroke().text(infoNutricionalReceta[6].total, 248, 230, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Hid_Carbonos
              doc.roundedRect(247, 237, 35, 9).stroke().text(infoNutricionalReceta[9].total, 248, 239, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Azúcares
              doc.roundedRect(247, 246, 35, 9).stroke().text(infoNutricionalReceta[11].total, 248, 248, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Fibra
              doc.roundedRect(247, 255, 35, 9).stroke().text(infoNutricionalReceta[10].total, 248, 257, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Proteínas
              doc.roundedRect(247, 264, 35, 9).stroke().text(infoNutricionalReceta[4].total, 248, 266, {
                width: 33,
                height: 10,
                align: 'center',
              })

              //Borde porRación Sal
              doc.roundedRect(247, 273, 35, 9).stroke().text(infoNutricionalReceta[12].total, 248, 275, {
                //4
                width: 33,
                height: 10,
                align: 'center',
              })

              if (cantidadEtiqImprimir != 1 && e != cantidadEtiqImprimir - 1) {
                //Agrego la segunda página
                doc.addPage()
              }
            }

            // Obtengo el blob cuando está hecho todo.
            doc.end()

            stream.on('finish', function () {
              //   // // get a blob you can do whatever you like with
              //   // const blob = stream.toBlob('application/pdf');

              //   // or get a blob URL for display in the browser
              const url = stream.toBlobURL('application/pdf')
              // abrir un PDF en una pestaña nueva
              window.open(url, '_blank')
              // redirigir la pestaña actual a otra URL
              //window.location.href = 'http://ejemplo.com';

              //llamarActualizarEstadosSpingImprimirEtiquetas();
            })
          },
          (error) => console.log(error)
        )
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}

async function imprimirEtiquetasGeneralPlantilla9(
  codLineaProduccion,
  codCliente,
  fechaElaboracion,
  totalDiasCaducidad,
  fechaCaducidad,
  codLote,
  codCocina,
  codObservacionesEspeciales,
  rutaLogoIzquierdo,
  rutaLogoDerecho,
  observaciones,
  cantidadEtiqImprimir,
  alergenosGuarnicion,
  alergenosPrincipal,
  ingredientesGuarnicion,
  ingredientesPrincipal,
  texto01,
  texto02,
  texto03,
  texto04,
  plantilla,
  infoNutricionalReceta,
  valueTextAreaAlergenosGuarnicion,
  valueTextAreaAlergenosPrincipal,
  ingredientesGuarnicionEtiqueta,
  ingredientesPrincipalEtiqueta,
  datosCocinaImprimir,
  nombrePrincipal,
  nameGuarnicion,
  nombreGurnicion,
  listaLineasProduccion,
  listaCatalogoId,
  infoNutricionalReceta100,
  pesoNetoTotal,
  unidadMedida,
  receta,
  mostrarPuedeContener,
  puedeContener,
  multiporcion
) {
  //alergenosGuarnicion, alergenosPrincipal, ingredientesAlergenosTotal, ingredientesGuarnicion, ingredientesPrincipal
  try {
    var observacionesTotales = ''
    var condConservacion = ''
    var modoEmpleo = ''
    console.log(infoNutricionalReceta100)
    // var nombrePrincipal = this.state.selectedRowsRecetas.length > 0 ? this.state.selectedRowsRecetas : '';
    // var nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
    // var nombreGurnicion = nameGuarnicion.length != 0 ? ' CON ' + nameGuarnicion[0].NOM_PLATO : '';

    if (codObservacionesEspeciales.length != 0) {
      for (let i = 0; i < codObservacionesEspeciales.length; i++) {
        var observacion = Object.values(codObservacionesEspeciales[i].split('.', 2))

        observacionesTotales += observacion[1] + '.\n'
      }
    } else {
      observacionesTotales = ''
    }

    //observacionesTotales = observacionesTotales.length > 0 ? observacionesTotales : '';

    if (listaLineasProduccion.length != 0) {
      for (let a = 0; a < listaLineasProduccion.length; a++) {
        if (listaLineasProduccion[a].CODIGO_LINEA_PRODUCCION == codLineaProduccion[0]) {
          condConservacion += listaLineasProduccion[a].CONDICIONES_CONSERVACION + '.'
          modoEmpleo += listaLineasProduccion[a].MODO_EMPLEO + '.'
        }
      }
    }

    database.obtenerDatosEmpresas(codCocina, listaCatalogoId).then(
      async (res) => {
        const datosCocinaImprimir = res.response

        // create a document and pipe to a blob
        var doc = new PDFDocument({
          //layout: "landscape",
          size: [largo, ancho],
          margin: 2,
          info: {
            Title: 'Etiquetas',
            Author: 'Alejandro Vázquez Chávez',
          },
        })
        var stream = doc.pipe(blobStream())

        for (let e = 0; e < cantidadEtiqImprimir; e++) {
          doc.fontSize(6.8).font('Helvetica').fillColor('black').text('SERVICIO DE CATERING Y COLECTIVIDADES', 30, 8, {
            width: 250,
            height: 10,
            align: 'center',
          })

          //Dirección Cocina
          doc
            .fontSize(6)
            .font('Helvetica')
            .fillColor('black')
            .text(datosCocinaImprimir[0].DIRECCION_UCP, 37, 16, {
              width: 250,
              height: 20,
              align: 'center',
              continued: true,
            })
            .text('\n')
            .fontSize(5.5) //Nombre Cocina
            .font('Helvetica')
            .fillColor('black')
            .text(
              datosCocinaImprimir[0].NOMBRE_SOCIAL_UCP +
                ' - ' +
                'NRGSA: ' +
                datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP,
              {
                width: 250,
                height: 10,
                align: 'center',
              }
            )

          //Logo izquierdo
          if (rutaLogoIzquierdo != undefined || rutaLogoIzquierdo != null) {
            const buffer = await getBufferLogo(rutaLogoIzquierdo)
            doc.image(buffer, 8, 3, {
              scale: 0.09,
            })
          }

          // //Logo derecho
          if (rutaLogoDerecho != undefined || rutaLogoDerecho != null) {
            doc.image(rutaLogoDerecho, 232, 5, {
              scale: 0.12,
            })
          }

          //Número de Registro Sanitario Cocina
          // doc
          //     .fontSize(5.5)
          //     .font('Helvetica')
          //     .fillColor('black')
          //     .text('NRGSA: ' + datosCocinaImprimir[0].REGISTRO_SANITARIO_UCP, 13, 55, {
          //         width: 100,
          //         height: 12
          //     });

          //Nombre Cliente
          // if (codCliente.length != 0) {
          //     doc
          //         .fontSize(8)
          //         .font('Helvetica-Bold')
          //         .fillColor('black')
          //         .text('CLIENTE: ' + codCliente[1] === null ? '' : codCliente[1],  13, 48, {
          //             continued: true
          //         })
          //         .text('\n')

          // }

          var plato = nombrePrincipal.length === 0 ? nombreGurnicion : nombrePrincipal[0].NOM_PLATO + nombreGurnicion

          // doc
          //     .fontSize(9)
          //     .font('Helvetica-Bold')
          //     .fillColor('black')
          //     .text(plato , 13, 74, {
          //         width: 260,
          //         height: heightPlato
          //     });

          //Nombre Plato
          if (ingredientesPrincipal != null) {
            doc.fontSize(8.5).font('Helvetica-Bold').fillColor('black').text(plato, 7, 35, {
              //codCliente.length != 0 ? 58 : 53, {
              width: 274,
              continued: true,
            })

            if (texto01 != undefined && texto01.length > 0) {
              doc.text('\n').fontSize(8.5).font('Helvetica-Bold').text(texto01, {
                width: 275,
                align: 'justify',
                continued: true,
              })
            }
            if (ingredientesPrincipal.length > 0) {
              doc
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES', {
                  width: 274,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(': ' + ingredientesPrincipal)
            }
          } else {
            if (ingredientesPrincipal.length > 0) {
              doc
                .text('\n')
                .fontSize(8.5)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text(plato, 7, 35, {
                  width: 274,
                  continued: false,
                })
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES', {
                  width: 274,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(': ' + ingredientesPrincipalEtiqueta)
            }
          }
          //   .font('Helvetica-Bold')
          //   .text('INGREDIENTES GUARNICIÓN: ', {
          //     width: 264,
          //     align: 'justify',
          //     continued: true
          //   }).font('Helvetica')
          //   .text(ingredientesGuarnicionEtiqueta.length)
          //   .font('Helvetica-Bold')
          //   .fillColor('black')
          //   .text('CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos CONTIENE: Gluten, Soja (Trazas), Leche y derivados (Trazas), Frutos de Cáscara (Trazas), Granos de Sésamo (Trazas), Dióxido de Azufre y Sulfitos', {
          //     width: 264,
          //     align: 'justify'
          //   });

          // var margenTopIngredientes = heightPlato === 30 ? 95 : 84
          // //Ingredientes Principal
          // doc
          //     .fontSize(6.5)
          //     .font('Helvetica-Bold')
          //     .fillColor('black')
          //     .text('INGREDIENTES PRINCIPAL: ', 13, margenTopIngredientes , {
          //         width: 260,
          //         height: 70,
          //         align: 'justify',
          //         continued: true
          //     }).font('Helvetica')
          //     .text(ingredientesPrincipalEtiqueta)

          //Ingredientes Guarnición - Para ponerlo consecutivo tengo que calcular el desplazamiento.
          if (ingredientesGuarnicion.length != 0) {
            if (ingredientesPrincipal.length > 0) {
              doc
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 274,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicion)
            } else {
              doc
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 274,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicion)
            }
          } else if (ingredientesGuarnicionEtiqueta.length != 0) {
            if (ingredientesPrincipal.length > 0) {
              doc
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 274,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicionEtiqueta)
            } else {
              doc
                .text('\n')
                .fontSize(7.1)
                .font('Helvetica-Bold')
                .fillColor('black')
                .text('INGREDIENTES GUARNICIÓN: ', {
                  width: 274,
                  align: 'justify',
                  continued: true,
                })
                .font('Helvetica')
                .text(ingredientesGuarnicionEtiqueta)
            }
          }

          if(!ingredientesGuarnicion && !ingredientesPrincipal) {
            console.log("CASO ESPECIAl 8")
            doc
                .text("\n")

          }

          var alergenosTotal = ''
          if (alergenosPrincipal != null) {
            alergenosTotal += alergenosPrincipal
          } else {
            alergenosTotal += valueTextAreaAlergenosPrincipal
          }

          if (alergenosGuarnicion != null) {
            alergenosTotal += alergenosPrincipal != undefined ? ', ' + alergenosGuarnicion : alergenosGuarnicion
          } else {
            alergenosTotal += valueTextAreaAlergenosGuarnicion
          }

          //Alérgenos
          if (alergenosTotal != '' && alergenosTotal.length >= 3) {
            //mostrarPuedeContener, puedeContener
            doc
              .fontSize(7.2)
              .font('Helvetica-Bold')
              .fillColor('black')
              .text(alergenosTotal.length <= 1 ? '' : 'CONTIENE: ', {
                width: 264,
                align: 'justify',
                continued: true,
              })
              .text(alergenosTotal.length >= 3 ? alergenosTotal + '. ' : '', {
                width: 264,
                align: 'justify',
                continued: texto02 != undefined ? true : false,
              })
          }

          //Alérgenos Puede Contener
          //console.log('puedeContener--------------------------------------' + puedeContener);
          if (mostrarPuedeContener === 'S') {
            doc
              .fontSize(7.2)
              .font('Helvetica-Bold')
              .fillColor('black')
              .text('PUEDE CONTENER: ', {
                width: 264,
                align: 'justify',
                continued: true,
              })
              .text(puedeContener, {
                width: 264,
                align: 'justify',
                continued: texto02 != undefined ? true : false,
              })
          }

          if (texto02 != undefined) {
            doc.text('\n').font('Helvetica').fontSize(7.5).text(texto02, {
              width: 274,
              align: 'justify',
              continued: false,
            })
          }

          //, texto02, texto03, texto04)

          //Texto 03
          doc.fontSize(7.5).font('Helvetica-Bold').text(texto03, 15, 188, {
            width: 153,
            height: 10,
            align: 'justify',
            continued: false,
          })

          //Texto 04
          doc.fontSize(7).font('Helvetica').text(texto04, 170, 188, {
            width: 153,
            height: 10,
            align: 'justify',
            continued: false,
          })

          //Fecha Elaboración y Caducidad
          doc
            .roundedRect(7, 201, 139, 20)
            .stroke()
            .fontSize(7.1)
            .font('Helvetica-Bold')
            .text('FECHA ELABORACIÓN:', 13, 204)
            .text(fechaElaboracion, 105, 204)
            .text('FECHA CADUCIDAD:', 13, 214)
            .text(fechaCaducidad, 105, 214)

          console.log('condConservacion')
          console.log(condConservacion)
          //Fecha Elaboración y Caducidad
          doc
            .fontSize(6.9)
            .font('Helvetica')
            .text(condConservacion + '\n', 7, 224, {
              width: 142,
              align: 'justify',
            })
            .text(modoEmpleo != undefined ? modoEmpleo + '\n' : '', {
              width: 142,
            })
            .text(observacionesTotales != undefined ? observacionesTotales : '', {
              width: 142,
            })
            .text(observaciones != undefined ? observaciones : '', {
              continued: false,
              width: 142,
            })
            .fontSize(6.9)
            .font('Helvetica-Bold')
            .text(
              multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '',
              92,
              265,
              {
                width: 153,
                height: 10,
              }
            )
            .fontSize(6.9)
            .font('Helvetica-Bold')
            .text('LOTE: ', 7, 275, {
              width: 153,
              height: 10,
              continued: true,
            })
            .font('Helvetica')
            .text(codLote, {
              continued: false,
            })

          // doc
          //     .fontSize(6.9)
          //     .font('Helvetica-Bold')
          //     .text('LOTE: ', 7, 275, {
          //         width: 153,
          //         height: 10,
          //         continued: true
          //     })
          //     .font('Helvetica')
          //     .text(codLote, {
          //         continued: false
          //     })
          //     .fontSize(6.9)
          //     .font('Helvetica-Bold')
          //     .text(multiporcion === false ? 'Peso Neto: ' + Math.round(Number(pesoNetoTotal)) + unidadMedida : '', 85, 269, {
          //         width: 153,
          //         height: 10,
          //     });

          //Borde Descriptivo Inf. Nutricional
          doc
            .roundedRect(154, 201, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .font('Helvetica-Bold')
            .stroke()
            .fontSize(6.9)
            .text('Inf. Nutricional', 155, 203)

          //Borde Descriptivo Energía(Kj/Kcal
          doc
            .roundedRect(154, 210, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Energía(Kj/Kcal)', 155, 212)

          //Borde Descriptivo Grasas(g)
          doc
            .roundedRect(154, 219, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Grasas(g)', 155, 221)

          //Borde Descriptivo Saturadas
          doc
            .roundedRect(154, 228, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Saturadas', 155, 230)

          //Borde Descriptivo Hidr. Carbono(g)
          doc
            .roundedRect(154, 237, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Hidr. Carbono(g)', 155, 239)

          //Borde Descriptivo Azúcares
          doc
            .roundedRect(154, 246, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Azúcares', 155, 248)

          //Borde Descriptivo Fibra
          doc
            .roundedRect(154, 255, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Fibra', 155, 257)

          //Borde Descriptivo Proteínas
          doc
            .roundedRect(154, 264, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Proteínas(g)', 155, 266)

          //Borde Descriptivo Sal(g)
          doc
            .roundedRect(154, 273, 54, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .text('Sal(g)', 155, 275)

          //Borde Descriptivo por100G
          doc
            .roundedRect(208, 201, 39, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .font('Helvetica-Bold')
            .fillColor('black')
            .text('Por100g/ml', 209, 203)
            .stroke()

          //Borde por100G Energía Number(((datosGuarnicion[0].POLIALCOHOLES / datosGuarnicion[0].PESOT) * 100).toFixed(2))
          console.log('infoNutricionalReceta100')
          console.log(infoNutricionalReceta100)
          doc
            .roundedRect(208, 210, 39, 9)
            .stroke()
            .fontSize(6.9)
            .font('Helvetica')
            .fillColor('black')
            .text(
              Math.round(Number(infoNutricionalReceta100[1].total)) +
                '/' +
                Math.round(Number(infoNutricionalReceta100[0].total)),
              209,
              212,
              {
                width: 33,
                height: 10,
                align: 'center',
              }
            )

          //Borde por100G Grasas
          doc.roundedRect(208, 219, 39, 9).stroke().text(infoNutricionalReceta100[3].total, 210, 221, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde por100G Saturados
          doc.roundedRect(208, 228, 39, 9).stroke().text(infoNutricionalReceta100[4].total, 210, 230, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde por100G Hid_Carbonos
          doc.roundedRect(208, 237, 39, 9).stroke().text(infoNutricionalReceta100[7].total, 210, 239, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde por100G Azúcares
          doc.roundedRect(208, 246, 39, 9).stroke().text(infoNutricionalReceta100[9].total, 210, 248, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde por100G Fibra
          doc.roundedRect(208, 255, 39, 9).stroke().text(infoNutricionalReceta100[8].total, 210, 257, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde por100G Proteínas
          doc.roundedRect(208, 264, 39, 9).stroke().text(infoNutricionalReceta100[2].total, 210, 266, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde por100G Sal
          doc
            .roundedRect(208, 273, 39, 9) //211
            .stroke()
            .text(infoNutricionalReceta100[10].total, 210, 275, {
              width: 33,
              height: 10,
              align: 'center',
            })

          //Borde Descriptivo porRación
          doc
            .roundedRect(247, 201, 35, 9)
            .fillAndStroke('#f4f4f4', 'black')
            .fillAndStroke('black')
            .stroke()
            .fontSize(6.9)
            .font('Helvetica-Bold')
            .fillColor('black')
            .text('Ración', 248, 203)

          //Borde porRación Energía
          doc
            .roundedRect(247, 210, 35, 9)
            .stroke()
            .fontSize(6.9)
            .font('Helvetica')
            .fillColor('black')
            .text(
              Math.round(Number(infoNutricionalReceta[3].total)) +
                '/' +
                Math.round(Number(infoNutricionalReceta[2].total)),
              248,
              212,
              {
                width: 33,
                height: 10,
                align: 'center',
              }
            )

          //Borde porRación Grasas
          doc.roundedRect(247, 219, 35, 9).stroke().text(infoNutricionalReceta[5].total, 248, 221, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde porRación Saturados
          doc.roundedRect(247, 228, 35, 9).stroke().text(infoNutricionalReceta[6].total, 248, 230, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde porRación Hid_Carbonos
          doc.roundedRect(247, 237, 35, 9).stroke().text(infoNutricionalReceta[9].total, 248, 239, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde porRación Azúcares
          doc.roundedRect(247, 246, 35, 9).stroke().text(infoNutricionalReceta[11].total, 248, 248, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde porRación Fibra
          doc.roundedRect(247, 255, 35, 9).stroke().text(infoNutricionalReceta[10].total, 248, 257, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde porRación Proteínas
          doc.roundedRect(247, 264, 35, 9).stroke().text(infoNutricionalReceta[4].total, 248, 266, {
            width: 33,
            height: 10,
            align: 'center',
          })

          //Borde porRación Sal
          doc.roundedRect(247, 273, 35, 9).stroke().text(infoNutricionalReceta[12].total, 248, 275, {
            //4
            width: 33,
            height: 10,
            align: 'center',
          })

          if (cantidadEtiqImprimir != 1 && e != cantidadEtiqImprimir - 1) {
            //Agrego la segunda página
            doc.addPage()
          }
        }

        // Obtengo el blob cuando está hecho todo.
        doc.end()

        stream.on('finish', function () {
          //   // // get a blob you can do whatever you like with
          //   // const blob = stream.toBlob('application/pdf');

          //   // or get a blob URL for display in the browser
          const url = stream.toBlobURL('application/pdf')
          // abrir un PDF en una pestaña nueva
          window.open(url, '_blank')
          // redirigir la pestaña actual a otra URL
          //window.location.href = 'http://ejemplo.com';

          //llamarActualizarEstadosSpingImprimirEtiquetas();
        })
        ;(error) => console.log(error)
      },
      (error) => console.log(error)
    )
  } catch (e) {
    Modal.error({
      title: 'Error',
      content: 'Ha ocurrido el siguiente error al Imprimir las Etiquetas: ' + e + '. Contactar con el administrador.',
    })
    console.log(e)
  }
}
