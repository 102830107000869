export const ALLERGENS = {
  GLUTEN: {
    moveToX: 438,
    lineToX: 453,
    propY: 480,
    textX: 426,
    textY: 478,
  },
  CRUSTACEOS: {
    moveToX: 487,
    lineToX: 502,
    propY: 480,
    textX: 475,
    textY: 478,
  },
  HUEVO: {
    moveToX: 536,
    lineToX: 550,
    propY: 480,
    textX: 524,
    textY: 478,
  },
  PESCADO: {
    moveToX: 585,
    lineToX: 599,
    propY: 480,
    textX: 573,
    textY: 478,
  },
  CACAHUTES: {
    moveToX: 632,
    lineToX: 645,
    propY: 480,
    textX: 620,
    textY: 478,
  },
  SOJA: {
    moveToX: 680,
    lineToX: 693,
    propY: 480,
    textX: 668,
    textY: 478,
  },
  LECHE_Y_DERIVADOS: {
    moveToX: 730,
    lineToX: 743,
    propY: 480,
    textX: 717,
    textY: 478,
  },
  FRUTOS_CASCARAS: {
    moveToX: 440,
    lineToX: 455,
    propY: 553,
    textX: 430,
    textY: 549,
  },
  APIO_Y_DERIVADOS: {
    moveToX: 490,
    lineToX: 505,
    propY: 553,
    textX: 479,
    textY: 549,
  },
  MOSTAZA_Y_DERIVADOS: {
    moveToX: 538,
    lineToX: 552,
    propY: 553,
    textX: 526,
    textY: 549,
  },
  SESAMO: {
    moveToX: 587,
    lineToX: 601,
    propY: 553,
    textX: 575,
    textY: 549,
  },
  MOLUSCOS: {
    moveToX: 637,
    lineToX: 651,
    propY: 553,
    textX: 627,
    textY: 549,
  },
  ALTRAMUCES: {
    moveToX: 685,
    lineToX: 698,
    propY: 553,
    textX: 675,
    textY: 549,
  },
  DIXIDO_AZUFRE_Y_SULFITOS: {
    moveToX: 737,
    lineToX: 751,
    propY: 553,
    textX: 725,
    textY: 549,
  },
}

export const ARRAY_OF_BORDERS = [
  {
    x: 20,
    y: 190,
    w: 360,
    h: 220,
  },
  {
    x: 413,
    y: 190,
    w: 360,
    h: 190,
  },
  {
    x: 413,
    y: 400,
    w: 360,
    h: 160,
  },
  {
    x: 20,
    y: 435,
    w: 360,
    h: 125,
  },
]

export const ARRAY_OF_MARKS = [
  {
    moveToX: 35,
    lineToX: 160,
    propY: 150,
    lineWidth: 30,
  },
  {
    moveToX: 40,
    lineToX: 150,
    propY: 190,
    lineWidth: 25,
  },
  {
    moveToX: 40,
    lineToX: 240,
    propY: 435,
    lineWidth: 25,
  },
  {
    moveToX: 435,
    lineToX: 620,
    propY: 190,
    lineWidth: 25,
  },
  {
    moveToX: 435,
    lineToX: 540,
    propY: 400,
    lineWidth: 25,
  },
]

export const ARRAY_OF_PLATE_OPTION = [
  {
    optionName: 'DESAYUNO',
    fontSize: 18,
  },
  {
    optionName: 'MEDIAMA',
    fontSize: 16,
  },
  {
    optionName: 'PRIMERO',
    fontSize: 18,
  },
  {
    optionName: 'SEGUNDO',
    fontSize: 18,
  },
  {
    optionName: 'MERIENDA',
    fontSize: 18,
  },
  {
    optionName: 'POSTRE',
    fontSize: 18,
  },
]

export const ARRAY_OF_OTHER_DESCRIPTORS = [
  {
    nameDescriptor: 'INGREDIENTES',
    x: 40,
    y: 185,
    width: 150,
  },
  {
    nameDescriptor: 'ELABORACIÓN DEL PLATO',
    x: 435,
    y: 185,
    width: 180,
  },
  {
    nameDescriptor: 'PORCENTAJE DE NUTRIENTES',
    x: 40,
    y: 430,
    width: 190,
  },
  {
    nameDescriptor: 'ALÉRGENOS',
    x: 435,
    y: 395,
    width: 190,
  },
]

export const ARRAY_OF_TEXTS_CONSERVATION_AND_CONSUMPTION = [
  {
    text: 'Conservar por debajo de 8º C hasta el consumo, elaborar con la menor antelación.',
    x: 135,
    y: 280,
    width: 330,
  },
  {
    text: 'Platos calientes, realizar un tratamiento térmico por encima de 75º C y mantener por encima de 65º C hasta el consumo.',
    x: 160,
    y: 310,
    width: 310,
  },
  {
    text: 'Consumo inmediato.',
    x: 35,
    y: 358,
    width: 310,
  },
]

export const ARRAY_OF_BOLD_LETTER = [
  {
    text: 'CONSERVACIÓN:',
    x: 30,
    y: 265,
  },
  {
    text: 'CONSUMO:',
    x: 30,
    y: 345,
  },
  {
    text: 'Para platos fríos:',
    x: 35,
    y: 280,
  },
  {
    text: 'Para platos calientes:',
    x: 35,
    y: 310,
  },
  {
    text: 'Peso neto:',
    x: 515,
    y: 280,
  },
  {
    text: 'Peso bruto:',
    x: 515,
    y: 310,
  },
]

export const ARRAY_OF_DESCRIPTIVES = [
  {
    text: 'CONSERVACIÓN Y CONSUMO',
    x: 35,
  },
  {
    text: 'PESO Y FAMILIA DEL PLATO',
    x: 520,
  },
]

export const ARRAY_OF_TITLE_MARKS = [
  {
    moveToX: 40,
    lineToX: 240,
    propY: 245,
  },
  {
    moveToX: 690,
    lineToX: 520,
    propY: 245,
  },
  {
    moveToX: 40,
    lineToX: 240,
    propY: 400,
  },
]

export const ARRAY_OF_DESCRIPTION_BORDERS = [
  { x: 500, y: 245, w: 270, h: 130 },
  { x: 20, y: 245, w: 440, h: 130 },
  { x: 20, y: 400, w: 750, h: 180 },
]

export const NUTRITIONAL_VALUE_TABLE = [
  {
    valueName: 'KCALORIAS',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 424,
    },
    //Descriptivo
    descriptive: {
      text: 'ENERGÍA KCal(kj/kcal)',
      x: 28,
      y: 421,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 424,
    },
    //Valor
    value: {
      x: 140,
      y: 421,
    },
  },
  {
    valueName: 'LIPIDOS',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 445,
    },
    //Descriptivo
    descriptive: {
      text: 'LÍPIDOS (g)',
      x: 28,
      y: 442,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 445,
    },
    //Valor
    value: {
      x: 140,
      y: 442,
    },
  },
  {
    valueName: 'AGS',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 466,
    },
    //Descriptivo
    descriptive: {
      text: 'GRASAS SATURADAS (g)',
      x: 28,
      y: 463,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 466,
    },
    //Valor
    value: {
      x: 140,
      y: 463,
    },
  },
  {
    valueName: 'HIDRATOS_C',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 486,
    },
    //Descriptivo
    descriptive: {
      text: 'HID. DE CARBONO (g)',
      x: 28,
      y: 483,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 486,
    },
    //Valor
    value: {
      x: 140,
      y: 483,
    },
  },
  {
    valueName: 'AZUCARES',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 507,
    },
    //Descriptivo
    descriptive: {
      text: 'AZUCARES(g)',
      x: 28,
      y: 504,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 507,
    },
    //Valor
    value: {
      x: 140,
      y: 504,
    },
  },
  {
    valueName: 'PROTEINAS',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 528,
    },
    //Descriptivo
    descriptive: {
      text: 'PROTEINAS (g)',
      x: 28,
      y: 525,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 528,
    },
    //Valor
    value: {
      x: 140,
      y: 525,
    },
  },
  {
    valueName: 'SAL',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 548,
    },
    //Descriptivo
    descriptive: {
      text: 'SAL (mg)',
      x: 28,
      y: 545,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 548,
    },
    //Valor
    value: {
      x: 140,
      y: 545,
    },
  },
  {
    valueName: 'FIBRA',
    //Marcar título
    titleMark: {
      moveToX: 25,
      lineToX: 140,
      propY: 568,
    },
    //Descriptivo
    descriptive: {
      text: 'FIBRA (g)',
      x: 28,
      y: 564,
    },
    //Marcar valor
    valueMark: {
      moveToX: 142,
      lineToX: 208,
      propY: 568,
    },
    //Valor
    value: {
      x: 140,
      y: 565,
    },
  },
  {
    valueName: 'COLESTEROL',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 424,
    },
    //Descriptivo
    descriptive: {
      text: 'COLESTEROL (mg)',
      x: 215,
      y: 421,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 424,
    },
    //Valor
    value: {
      x: 326,
      y: 421,
    },
  },
  {
    valueName: 'VITA_A',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 444,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA A (mcg)',
      x: 215,
      y: 441,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 444,
    },
    //Valor
    value: {
      x: 326,
      y: 441,
    },
  },
  {
    valueName: 'VITA_B1',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 465,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA B1 (mg)',
      x: 215,
      y: 462,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 465,
    },
    //Valor
    value: {
      x: 326,
      y: 462,
    },
  },
  {
    valueName: 'VITA_B2',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 485,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA B2 (mg)',
      x: 215,
      y: 483,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 485,
    },
    //Valor
    value: {
      x: 326,
      y: 482,
    },
  },
  {
    valueName: 'VITA_B6',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 505,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA B6 (mg)',
      x: 215,
      y: 502,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 505,
    },
    //Valor
    value: {
      x: 326,
      y: 502,
    },
  },
  {
    valueName: 'A_FOLICO',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 526,
    },
    //Descriptivo
    descriptive: {
      text: 'ÁCIDO FÓLICO (mg)',
      x: 215,
      y: 523,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 526,
    },
    //Valor
    value: {
      x: 326,
      y: 523,
    },
  },
  {
    valueName: 'VITA_B12',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 546,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA B12 (mg)',
      x: 215,
      y: 543,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 546,
    },
    //Valor
    value: {
      x: 326,
      y: 543,
    },
  },
  {
    valueName: 'VITA_C',
    //Marcar título
    titleMark: {
      moveToX: 327,
      lineToX: 213,
      propY: 567,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA C (mg)',
      x: 215,
      y: 564,
    },
    //Marcar valor
    valueMark: {
      moveToX: 393,
      lineToX: 329,
      propY: 567,
    },
    //Valor
    value: {
      x: 326,
      y: 564,
    },
  },
  {
    valueName: 'NIACINA',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 424,
    },
    //Descriptivo
    descriptive: {
      text: 'NIACINA (mg)',
      x: 399,
      y: 421,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 424,
    },
    //Valor
    value: {
      x: 509,
      y: 421,
    },
  },
  {
    valueName: 'VITA_D',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 444,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA D (mcg)',
      x: 399,
      y: 441,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 444,
    },
    //Valor
    value: {
      x: 509,
      y: 441,
    },
  },
  {
    valueName: 'VITA_E',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 465,
    },
    //Descriptivo
    descriptive: {
      text: 'VITAMINA E (mg)',
      x: 399,
      y: 462,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 465,
    },
    //Valor
    value: {
      x: 509,
      y: 462,
    },
  },
  {
    valueName: 'RETINOL',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 485,
    },
    //Descriptivo
    descriptive: {
      text: 'RETINOL (mg)',
      x: 399,
      y: 482,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 485,
    },
    //Valor
    value: {
      x: 509,
      y: 482,
    },
  },
  {
    valueName: 'CAROTENO',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 505,
    },
    //Descriptivo
    descriptive: {
      text: 'CAROTENOS (mg)',
      x: 399,
      y: 502,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 505,
    },
    //Valor
    value: {
      x: 509,
      y: 502,
    },
  },
  {
    valueName: 'CALCIO',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 526,
    },
    //Descriptivo
    descriptive: {
      text: 'CALCIO (mg)',
      x: 399,
      y: 523,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 526,
    },
    //Valor
    value: {
      x: 509,
      y: 523,
    },
  },
  {
    valueName: 'FOSFORO',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 546,
    },
    //Descriptivo
    descriptive: {
      text: 'FOSFORO (mg)',
      x: 400,
      y: 543,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 546,
    },
    //Valor
    value: {
      x: 509,
      y: 542,
    },
  },
  {
    valueName: 'HIERRO',
    //Marcar título
    titleMark: {
      moveToX: 510,
      lineToX: 397,
      propY: 567,
    },
    //Descriptivo
    descriptive: {
      text: 'HIERRO (mg)',
      x: 400,
      y: 564,
    },
    //Marcar valor
    valueMark: {
      moveToX: 576,
      lineToX: 512,
      propY: 567,
    },
    //Valor
    value: {
      x: 509,
      y: 562,
    },
  },
  {
    valueName: 'MAGNESIO',
    //Marcar título
    titleMark: {
      moveToX: 695,
      lineToX: 580,
      propY: 424,
    },
    //Descriptivo
    descriptive: {
      text: 'MAGNESIO (mg)',
      x: 583,
      y: 421,
    },
    //Marcar valor
    valueMark: {
      moveToX: 697,
      lineToX: 765,
      propY: 424,
    },
    //Valor
    value: {
      x: 696,
      y: 421,
    },
  },
  {
    valueName: 'POTASIO',
    //Marcar título
    titleMark: {
      moveToX: 695,
      lineToX: 580,
      propY: 444,
    },
    //Descriptivo
    descriptive: {
      text: 'POTASIO (mcg)',
      x: 583,
      y: 441,
    },
    //Marcar valor
    valueMark: {
      moveToX: 697,
      lineToX: 765,
      propY: 444,
    },
    //Valor
    value: {
      x: 696,
      y: 441,
    },
  },
  {
    valueName: 'SODIO',
    //Marcar título
    titleMark: {
      moveToX: 695,
      lineToX: 580,
      propY: 465,
    },
    //Descriptivo
    descriptive: {
      text: 'SODIO (mg)',
      x: 583,
      y: 462,
    },
    //Marcar valor
    valueMark: {
      moveToX: 697,
      lineToX: 765,
      propY: 465,
    },
    //Valor
    value: {
      x: 696,
      y: 462,
    },
  },
  {
    valueName: 'IODO',
    //Marcar título
    titleMark: {
      moveToX: 695,
      lineToX: 580,
      propY: 485,
    },
    //Descriptivo
    descriptive: {
      text: 'YODO (mcg)',
      x: 583,
      y: 482,
    },
    //Marcar valor
    valueMark: {
      moveToX: 697,
      lineToX: 765,
      propY: 485,
    },
    //Valor
    value: {
      x: 696,
      y: 482,
    },
  },
  {
    valueName: 'SELENIO',
    //Marcar título
    titleMark: {
      moveToX: 695,
      lineToX: 580,
      propY: 505,
    },
    //Descriptivo
    descriptive: {
      text: 'SELENIO (mg)',
      x: 583,
      y: 502,
    },
    //Marcar valor
    valueMark: {
      moveToX: 697,
      lineToX: 765,
      propY: 505,
    },
    //Valor
    value: {
      x: 696,
      y: 502,
    },
  },
  {
    valueName: 'ZINC',
    //Marcar título
    titleMark: {
      moveToX: 695,
      lineToX: 580,
      propY: 526,
    },
    //Descriptivo
    descriptive: {
      text: 'ZINC (mg)',
      x: 583,
      y: 523,
    },
    //Marcar valor
    valueMark: {
      moveToX: 697,
      lineToX: 765,
      propY: 526,
    },
    //Valor
    value: {
      x: 696,
      y: 523,
    },
  },
]

export const ONFINISH_BUSCAR_RECETAS_MENUS = [
  {
    title: 'Código menú',
    dataIndex: 'ID_MENU',
    key: 'ID_MENU',
    defaultSortOrder: 'descend',
    ellipsis: true,
    width: 110,
    fixed: 'left',
  },
  {
    title: 'Nombre menú',
    dataIndex: 'NOM_MENU',
    key: 'NOM_MENU',
    ellipsis: true,
    width: 300,
  },
  {
    title: 'Tipo menú',
    dataIndex: 'NOM_TIPO_MENU',
    key: 'NOM_TIPO_MENU',
    ellipsis: true,
    width: 130,
    align: 'center',
  },
  {
    title: 'Estado menú',
    dataIndex: 'NOM_ESTADO_MENU',
    key: 'NOM_ESTADO_MENU',
    ellipsis: true,
    width: 140,
  },
  {
    title: 'Fecha inicio',
    dataIndex: 'FECHA_INICIO',
    key: 'FECHA_INICIO',
    ellipsis: true,
    width: 110,
  },
  {
    title: 'Fecha fin',
    dataIndex: 'FECHA_FIN',
    key: 'FECHA_FIN',
    ellipsis: true,
    width: 110,
  },
  {
    title: 'Usuario creación',
    dataIndex: 'USUARIO_CREACION',
    key: 'USUARIO_CREACION',
    ellipsis: true,
    width: 130,
    align: 'center',
  },
  {
    title: 'Usuario cambio',
    dataIndex: 'USUARIO_CAMBIO',
    key: 'USUARIO_CAMBIO',
    ellipsis: true,
    width: 130,
    align: 'center',
  },
  {
    title: 'Catálogo',
    dataIndex: 'ID_CATALOGO',
    key: 'ID_CATALOGO',
    ellipsis: true,
    width: 110,
    align: 'center',
  },
  {
    title: 'Nombre catálogo',
    dataIndex: 'NOM_CATALOGO',
    key: 'NOM_CATALOGO',
    ellipsis: true,
    width: 180,
  },
  {
    title: 'En producción',
    dataIndex: 'ENPRODUCCIONFILTRO',
    key: 'ENPRODUCCIONFILTRO',
    ellipsis: true,
    width: 140,
    align: 'center',
  },
  {
    title: 'F. inicio producción',
    dataIndex: 'FECHA_INI_PRODUCCION',
    key: 'FECHA_INI_PRODUCCION',
    ellipsis: true,
    width: 140,
    align: 'center',
  },
  {
    title: 'F. fin producción',
    dataIndex: 'FECHA_FIN_PRODUCCION',
    key: 'FECHA_FIN_PRODUCCION',
    ellipsis: true,
    width: 140,
    align: 'center',
  },
]

export const IMPRIMIR_RECETAS_MENU_COLUMNS = [
  {
    title: 'Código Receta',
    dataIndex: 'cod_receta',
  },
  {
    title: 'Nombre Receta',
    dataIndex: 'nombre_receta',
  },
  {
    title: 'Declaración Alérgenos',
    dataIndex: 'dec_alergenos',
  },
]

export const ARRAY_OF_TEMPLATES = [
  '102x102mm',
  '102x102mm (Lote: Code128)',
  '102x102mm (Reducida)',
  '102x75mm',
  '102x102mm (Margen ancho)',
  '102x75mm (Lote: Code128)',
  '90x41mm',
  '102x102mm (Reducida, Lote: Code128, Puede Contener)',
  '102x102mm (Reducida, Puede Contener)',
]

export const ARRAY_OF_NUTRITIONAL_CALCULATIONS = [
  {
    key: '1',
    descripcionNutrientes: 'nPesoT',
    prop: 'PESOT',
  },
  {
    key: '2',
    descripcionNutrientes: 'nPesoN',
    prop: 'PESON',
  },
  {
    key: '3',
    descripcionNutrientes: 'nCaloriasR',
    prop: 'CALORIASR',
  },
  {
    key: '4',
    descripcionNutrientes: 'nKjuliosR',
    prop: 'KJULIOSR',
  },
  {
    key: '5',
    descripcionNutrientes: 'nProteinasR',
    prop: 'PROTEINASR',
  },
  {
    key: '6',
    descripcionNutrientes: 'nGrasas',
    prop: 'GRASAS',
  },
  {
    key: '7',
    descripcionNutrientes: 'nSaturadas',
    prop: 'SATURADAS',
  },
  {
    key: '8',
    descripcionNutrientes: 'nMonoinsaturados',
    prop: 'MONOINSATURADAS',
  },
  {
    key: '9',
    descripcionNutrientes: 'nPoliisaturados',
    prop: 'POLIINSATURADAS',
  },
  {
    key: '10',
    descripcionNutrientes: 'nHidratosR',
    prop: 'HIDRATOSR',
  },
  {
    key: '11',
    descripcionNutrientes: 'nFibraR',
    prop: 'FIBRA_R',
  },
  {
    key: '12',
    descripcionNutrientes: 'nAzucaresR',
    prop: 'AZUCARES_R',
  },
  {
    key: '13',
    descripcionNutrientes: 'nSalR',
    prop: 'SAL_R',
  },
  {
    key: '14',
    descripcionNutrientes: 'nAlmidónR',
    prop: 'ALMIDON',
  },
  {
    key: '15',
    descripcionNutrientes: 'nPolialcoholesR',
    prop: 'POLIALCOHOLES',
  },
]

export const MAINTENANCE_TIME_TO_RELOAD_CHECK = 30000
export const MAINTENANCE_APP_KEY_CHECK = 'cookbook'
