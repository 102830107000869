"use strict";
import React, { useEffect } from "react"
import { Form, Row, Col, Input, Button, Select } from "antd"
import { enviarModificarLineasProduccion } from "../functions";

export const DrawerModificarLineasProduccionForm = (props) => {
    const { state, updateStates, onChildrenDrawerCloseModificarLineasProduccion, selectCatalogosUsuario } = props

    const rowsModificarLineasProduccion = state.selectedRows;
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            nameLineaProduccion: rowsModificarLineasProduccion[0].NOMBRE_LINEA_PRODUCCION,
            tipo: rowsModificarLineasProduccion[0].TIPO_FECHA_CADUCIDAD,
            descripcionTipo: rowsModificarLineasProduccion[0].DESC_TIPO_FECHA_CADUCIDAD,
            diasCaducidad: rowsModificarLineasProduccion[0].DIAS_CADUCIDAD,
            textoFCaducidad: rowsModificarLineasProduccion[0].TEXTO_FECHA_CADUCIDAD,
            condicionesConservacion: rowsModificarLineasProduccion[0].CONDICIONES_CONSERVACION,
            modoEmpleo: rowsModificarLineasProduccion[0].MODO_EMPLEO,
            selectCatalogo: catalogoActualCentro,
        })
    }, [state.selectedRows])

    let catalogoActualCentro;
    state.listaOptionsCatalogosUsuario.forEach(({ CATALOGO, NOMBRE }) => {

        if (rowsModificarLineasProduccion[0].CATALOGO === CATALOGO) {
            catalogoActualCentro = `${CATALOGO}.${NOMBRE}`
        }
    })

    const mostrarLineasProduccion = {
        nameLineaProduccion: rowsModificarLineasProduccion[0].NOMBRE_LINEA_PRODUCCION,
        tipo: rowsModificarLineasProduccion[0].TIPO_FECHA_CADUCIDAD,
        descripcionTipo: rowsModificarLineasProduccion[0].DESC_TIPO_FECHA_CADUCIDAD,
        diasCaducidad: rowsModificarLineasProduccion[0].DIAS_CADUCIDAD,
        textoFCaducidad: rowsModificarLineasProduccion[0].TEXTO_FECHA_CADUCIDAD,
        condicionesConservacion: rowsModificarLineasProduccion[0].CONDICIONES_CONSERVACION,
        modoEmpleo: rowsModificarLineasProduccion[0].MODO_EMPLEO,
        selectCatalogo: catalogoActualCentro,
    };

    const onFinishActualizarLineasProduccion = (values) => {

        const codigoLineasProduccion =
            rowsModificarLineasProduccion[0].CODIGO_LINEA_PRODUCCION.toString()
        const nameLineaProduccion = values.nameLineaProduccion
        const tipo = values.tipo.toString()
        const descripcionTipo = values.descripcionTipo
        const diasCaducidad = values.diasCaducidad.toString()
        const textoFCaducidad = values.modoEmpleo
        const condicionesConservacion = values.condicionesConservacion
        const modoEmpleo = values.modoEmpleo
        const catalogo = values.selectCatalogo.toString()

        const catalogoConPunto = catalogo.indexOf(".")

        const catalog = catalogoConPunto == -1 ? catalogo : catalogo.substring(0, catalogo.indexOf("."))

        enviarModificarLineasProduccion(
            codigoLineasProduccion,
            nameLineaProduccion,
            tipo,
            descripcionTipo,
            diasCaducidad,
            textoFCaducidad,
            condicionesConservacion,
            modoEmpleo,
            catalog,
            updateStates,
            onChildrenDrawerCloseModificarLineasProduccion
        )
    }

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinishActualizarLineasProduccion}
            form={form}
            initialValues={mostrarLineasProduccion}
        >
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameLineaProduccion"
                        label="Nombre:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir nombre línea de producción" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={8}>
                    <Form.Item
                        hasFeedback
                        name="tipo"
                        label="Tipo:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el tipo" />
                    </Form.Item>
                </Col>

                <Col span={16}>
                    <Form.Item
                        hasFeedback
                        name="descripcionTipo"
                        label="Descripción Tipo:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir la descripción tipo" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={8}>
                    <Form.Item
                        hasFeedback
                        name="diasCaducidad"
                        label="Días Caducidad:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir los días de caducidad" />
                    </Form.Item>
                </Col>

                <Col span={16}>
                    <Form.Item
                        hasFeedback
                        name="textoFCaducidad"
                        label="Texto Fecha Caducidad:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el texto fecha de caducidad" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="condicionesConservacion"
                        label="Condiciones Conservación:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir condiciones de conservación" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="modoEmpleo"
                        label="Modo Empleo:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el modo de empleo" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="selectCatalogo"
                        label="Catálogo:"
                        rules={[
                            {
                                required: true,
                                message: "Campo obligatorio.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Por favor, seleccionar catálogo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {selectCatalogosUsuario}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={onChildrenDrawerCloseModificarLineasProduccion}
                            style={{ marginRight: 8 }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state.loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};