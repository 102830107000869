import React, { Component, TableHead, TableRow, TableCell, TableBody } from 'react';
import { userService } from '../services';
import { Alert, Form, Input, Button, Checkbox, Layout, message, Card, Row, Col, Modal, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { database } from '../services/database.service';

//class LoginPageForm extends Component {
class LoginPage extends Component {
    constructor(props) {
        super(props);
        //userService.logout();
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };

        // this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }



    /**
     * Permite escribir por consola lo que se escribe en los inputs
     * */
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    /**
     * Envío datos para que el usuario se autentique,
     */
    onFinish = values => {

        /**
        * Obtenemos los datos de los input de entrada.
        */

        const username = values.username;
        const password = values.password;

        // El pattern que vamos a comprobar
        const pattern = new RegExp('^[A-Z]+$', 'i');

        // El tamaño maximo para nuestro input
        const maximo = 8;

        // Primera validacion, si input esta vacio entonces no es valido
        if (!username) {
            message.error('El campo usuario no debe estar vacío.');
        } else {
            // Segunda validacion, si input es mayor que 35
            if (username.length > maximo) {
                message.error('El campo usuario debe tener 6 caracteres.');
            } else {
                // Tercera validacion, si input contiene caracteres diferentes a los permitidos
                if (!pattern.test(username)) {
                    // Si queremos agregar letras acentuadas y/o letra ñ debemos usar
                    // codigos de Unicode (ejemplo: Ñ: \u00D1  ñ: \u00F1)
                    message.error('El campo usuario solo debe contener letras.');
                } else {
                    // Si pasamos todas la validaciones anteriores, entonces el input es valido

                    this.setState({ submitted: true });

                    // stop here if form is invalid
                    if (!(username && password)) {
                        message.error('Hay algo mal en el formulario.');;
                    }
                    this.setState({
                        loadingSpingBuscarPlato: true,
                        textoLoadingSpingBuscarPlato: 'Cargando pantalla para buscar platos...'
                    });
                    this.setState({ loading: true });

                    userService.login(username, password)
                        .then(
                            user => {

                                const { from } = this.props.location.state || { from: { pathname: "/" } };
                                this.props.history.push(from);
                            },

                            error => this.setState({ error, loading: false, }),
                        );
                }
            }
        }
    };

    /**
     * Lanzo error si hay campos requeridos.
     */
    onFinishFailed = errorInfo => {
        message.error('Campo/s requeridos.', 5);
    };

    // handleSubmit(e) {

    //     e.preventDefault();


    // }

    render() {

        /**
         * Usamos los estados como variables.
         */
        const { loading, error } = this.state;

        const layout = {
            labelCol: {
                span: 24,
            },
            wrapperCol: {
                span: 24,
            },
        };
        const tailLayout = {
            wrapperCol: {
                offset: 5,
                span: 24,
            },
        };



        return (

            <div className="container-login100">

                <div className="wrap-login100">
                    {/*<div className="login100-form-title" >
                        <span className="login100-form-title-1">
         					Iniciar sesión
         				</span> 
                    </div>
                    {/* <Form onSubmit={this.handleSubmit} className="login-form"> */}

                    <Form
                        name="basic"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    //className="login-form"
                    >

                        <Row gutter={8}>
                            <Col span={24}>
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: ' ',
                                        },
                                    ]}
                                >
                                    <center>
                                        <img style={{ width: '60%', display: 'center', marginLeft: 'auto', marginRight: 'auto' }} alt="example" src="src/assets/images/logoCookBook.png" />
                                    </center>

                                </Form.Item>
                            </Col>
                        </Row>


                        <img style={{ width: '100%', marginBottom: 30 }} alt="example" src="src/assets/images/bg-01.jpg" />



                        <Row gutter={8}>
                            <Col span={24}>

                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: ' ',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<UserOutlined />}
                                        placeholder="Usuario"
                                        style={{ width: '76%', marginLeft: '12%' }}
                                    />
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={24}>

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: ' ',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        type="password"
                                        placeholder="Contraseña"
                                        style={{ width: '76%', marginLeft: '12%' }} />

                                </Form.Item>

                            </Col>

                        </Row>

                        <Row gutter={8}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit"
                                        disabled={loading}
                                        style={{ width: '76%', marginLeft: '12%' }}>
                                        Acceder
                                    </Button>
                                    {loading &&
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </Form.Item>

                            </Col>
                        </Row>
                        {error && message.error('Usuario o contraseña incorrecto.') && this.setState({ error: '' })
                        }
                    </Form>

                </div>
            </div>
        );
        //     const { username, password, submitted, loading, error } = this.state;
        //     return (

        //  <div className="limiter">

        // 	<div className="container-login100">         
        // 		<div className="wrap-login100">
        // 			<div className="login100-form-title" >
        // 				{/* <span className="login100-form-title-1">
        // 					Iniciar sesión
        // 				</span> */}
        // 			</div>

        // 			<form className="login100-form"  onSubmit={this.handleSubmit}  name="form">
        // 				<div className="wrap-input100" >


        // 					<input type="text" className="input100" name="username" value={username} onChange={this.handleChange} 
        //                       placeholder="Usuario" minlength="6" maxlength="6"/>
        //                                          {submitted && !username &&
        //                                             <div className="help-block">Usuario requerido.</div>
        //                                         }
        // 				</div>

        // 				<div className="wrap-input100" >
        // 					<input type="password" className="input100" name="password" value={password} onChange={this.handleChange} 
        //                     placeholder="Contraseña"  minlength="1"/>
        //                                          {submitted && !password &&
        //                                                 <div className="help-block">Contraseña requerida.</div>
        //                                         }

        // 				</div>		



        // 				<div className="container-login100-form-btn ">
        // 					<button className="btn btn-primary btn-lg btn-block" disabled={loading}>Acceder</button>
        //                                     {loading &&
        //                                         <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        //                                     }
        // 				</div>
        //                 {error &&

        //                     <Alert
        //                     message="Error"
        //                     description={error}
        //                     type="error"
        //                     showIcon
        //                   />
        //                 }
        // 			</form>
        // 		</div>
        // 	</div>
        // </div>
    }
}

//const LoginPage = Form.create({})(LoginPageForm);
export default LoginPage;