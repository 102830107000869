import React, { Component, useState } from "react";
import { database } from "../services/database.service";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Spin,
  DatePicker,
  ConfigProvider,
  Table,
  Tooltip,
  Modal,
  Steps,
  message,
  Typography,
  Divider,
  Menu,
} from "antd";
import ReactDOM from "react-dom";
import {
  DownOutlined,
  UpOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  DatabaseOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import moment from "moment";
moment.locale("es");
import DietsTable from "../components/commons/DietsTable";
import TableWarehouseOut from "../components/commons/TableWarehouseOut";
import TableCustomer from "../components/commons/TableCustomer";
import TableRecipiesDiets from "../components/commons/TableRecipiesDiets";
import { exportToExcel } from "../_utility";
import { sortArrayOfObjects } from "../_utility/auxiliaryFunctions";

const originData = [];
let arrayMenus = [];

for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    codigo_receta: 32,
    receta: `Edrward ${i}`,
    opcion: `Opcion ${i}`,
    alergenos: `London Park no. ${i}`,
    cubiertos: `Cubierto ${i}`,
    dieta: `Dieta ${i}`,
    ingrediente: `Ingrediente ${i}`,
    proveedor: `Proveedor ${i}`,
    cantidad: `${i}0`,
  });
}

const { Text } = Typography;

const informes = [
  { key: 1, label: "Hoja de Producción", value: "informe-hoja-produccion" },
  { key: 2, label: "Otro Informe", value: "otro-informe" },
];

const ingestas = [
  { key: 1, label: "Desayuno", value: "1" },
  { key: 2, label: "Media Mañana", value: "2" },
  { key: 3, label: "Comida", value: "3" },
  { key: 4, label: "Merienda", value: "4" },
  { key: 5, label: "Cena", value: "5" },
  { key: 6, label: "Resopón", value: "6" },
  { key: -9999, label: "<Sin Ingesta>", value: "-9999" },
];

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <DatabaseOutlined style={{ fontSize: 20 }} />
    <p>Datos No Encontrados</p>
  </div>
);

const dateFormat = "DD/MM/YYYY";
const textoMostrarPlanProduccion = "Visualizar Hoja Producción";
const columnsTablaMenus = [
  {
    title: "Código Menú",
    dataIndex: "MENU_ID",
    key: "KEY",
    defaultSortOrder: "descend",
    ellipsis: true,
    width: 60,
    fixed: "left",
  },
  {
    title: "Nombre Menú",
    dataIndex: "NOMBRE_MENU",
    ellipsis: true,
    width: 280,
  },
  {
    title: "Tipo Dieta",
    dataIndex: "TIPO_DIETA",
    ellipsis: true,
    width: 60,
  },
  {
    title: "Nombre Centro/Dieta",
    dataIndex: "NOMBRE",
    ellipsis: true,
    width: 200,
  },
  {
    title: "Tipo Menú",
    dataIndex: "TIPO_MENU",
    ellipsis: true,
    width: 60,
  },
];

const { Step } = Steps;

const steps = [
  {
    title: "Dietas",
    description: "Relación de dietas y cubiertos del cliente.",
  },
  {
    title: "Recetas/Dietas",
    description: "Relación de platos, dietas y cubiertos.",
  },
  {
    title: "Recetas/Ingredientes/Dietas",
    description: "Relación de platos, gramajes, ingredientes y dietas.",
  },
  {
    title: "Salidas Almacén",
    description: "Visualizar cantidades de productos.",
  },
];

class GestionInformes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      children: "",
      listaCentrosUsuarios: [],
      statusCentrosUsuarios: null,
      user: {},
      users: [],
      employee: {},
      employees: [],
      loadingSpingMenu: true,
      textoLoadingSpingMenu: "",
      datosTablaMenusEstado: [],
      selectedRowKeys: [],
      selectedRows: [],
      catalogo: "",
      centro: "",
      diaServicio: "",
      ingesta: "",
      visibleModalHojaGestion: false,
      current: 0,
      centroSeleccionado: "",
      listaRecetasHojaGestion: [],
      listaRecetasHojaGestionUpdated: [],
      listProductsWarehouseOut: [],
      listProductsWarehouseOutByIngest: [],
      listDietasCliente: [],
      listRecipiesDietsUnique: [],
    };
  }

  async componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem("user")),
      users: { loading: true },
      employee: JSON.parse(localStorage.getItem("employee")),
      employees: { loading: true },
      loadingSpingMenu: true,
      textoLoadingSpingMenu: "Cargando pantalla Gestionar Informes...",
      datosTablaMenusEstado: [],
      selectedRowKeys: [],
      selectedRows: [],
      catalogo: "",
      centro: "",
      diaServicio: "",
      ingesta: "",
      visibleModalHojaGestion: false,
      current: 0,
      centroSeleccionado: "",
      listDietasCliente: [],
    });
    await this.getListaCentro();
  }

  getListaCentro = async () => {
    const accountName = JSON.parse(
      localStorage.getItem("user")
    ).sAMAccountName.toUpperCase();
    const { response } = await database.obtenerCentrosUsuarioMongoDB(
      accountName
    );
    const { status, message } = response;

    if (status === "success") {
      this.setState({ loadingSpingMenu: false, textoLoadingSpingMenu: "" });

      if (message.length > 0) {
        this.setState({
          listaCentrosUsuarios: message,
          statusCentrosUsuarios: "success",
        });
      }
    } else {
      Modal.error({
        title: "Error",
        content: `Ha ocurrido el siguiente error: ${message}`,
      });
    }
  };

  handleCancelModalHojaGestion = () => {
    message.success("¡Proceso completado correctamente!");

    this.setState({
      visibleModalHojaGestion: false,
      loadingSpingMenu: false,
      textoLoadingSpingMenu: "",
      current: 0,
    });
  };

  handleCatchError = (message) => {
    this.setState({
      loadingSpingMenu: false,
      textoLoadingSpingMenu: "",
    });

    Modal.error({
      title: "Error",
      content: message,
    });
  };

  recuperarMenusCentroMensual = async (
    catalogo,
    centro,
    diaServicio,
    ingesta
  ) => {
    this.setState({
      loadingSpingMenu: true,
      textoLoadingSpingMenu: "Cargando datos...",
      diaServicioModal: diaServicio.format(dateFormat),
      selectedRows: [],
      selectedRowKeys: [],
    });

    const datosMenusHojaGestion = {
      pCatalogo: catalogo,
      pCodigoCentro: centro,
      pIngesta: ingesta,
      pFechaServicio: diaServicio,
    };

    try {
      const { response } = await database.obtenerMenusHojaProduccion(
        datosMenusHojaGestion
      );
      const { message, status } = response;

      if (status === "success" && message.length > 0) {
        this.setState({ centroSeleccionado: message[0].NOMBRE });

        const datosTablaMenus = message.map((element) => ({
          MENU_ID: element.MENU_ID,
          KEY: (
            element.MENU_ID +
            element.CODIGO_CENTRO +
            element.ID_CLIENTE
          ).toString(),
          NOMBRE_MENU: element.NOMBRE_MENU,
          TIPO_MENU: element.TIPO_MENU,
          TIPO_DIETA: element.TIPO_DIETA,
          NOMBRE: `${element.CODIGO_CENTRO}.${element.NOMBRE}`,
          FECHA_SERVICIO: element.FECHA_SERVICIO,
          DIA_SEMANA: element.DIA_SEMANA,
          SERVICIO_FINDE: element.SERVICIO_FINDE,
          CATALOGO: element.CENTRO_ID,
          CODIGO_CENTRO: element.CODIGO_CENTRO,
        }));

        this.setState({
          datosTablaMenusEstado: datosTablaMenus,
          loadingSpingMenu: false,
          textoLoadingSpingBuscarMenu: "",
        });

        const rowSelection = {
          onChange: this.onSelectChange,
        };

        ReactDOM.render(
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Tooltip placement="topLeft" title={textoMostrarPlanProduccion}>
              <Button
                size="default"
                style={{
                  boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                  borderColor: "#fff",
                  border: 0,
                  backgroundColor: "#fff",
                }}
                onClick={this.mostarMenuRecetasUnicas}
                type="dashed"
              >
                <FundViewOutlined />
              </Button>
            </Tooltip>
            <Table
              rowSelection={{ type: "checkbox", ...rowSelection }}
              rowKey={(record) => record.KEY}
              size={"small"}
              columns={columnsTablaMenus}
              dataSource={this.state.datosTablaMenusEstado}
              scroll={{ x: 1000, y: 600 }}
            />
          </ConfigProvider>,
          document.getElementById("infoBusquedaPorMenu")
        );
      } else {
        this.setState({
          loadingSpingMenu: false,
          textoLoadingSpingBuscarMenu: "",
        });

        ReactDOM.render(
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table columns={columnsTablaMenus} scroll={{ x: 1000, y: 600 }} />
          </ConfigProvider>,
          document.getElementById("infoBusquedaPorMenu")
        );

        const ingestaTemp = [];
        ingesta.split(",").forEach((element) => {
          const findResult = ingestas.find(
            (elementFind) => elementFind.key === Number(element)
          );
          findResult && ingestaTemp.push(findResult.label);
        });

        Modal.info({
          title: "Notificación",
          content:
            "El Centro seleccionado no tiene menús para el día de servicio: " +
            diaServicio.format(dateFormat) +
            " en la/s ingesta/s " +
            ingestaTemp.join(", ") +
            ".",
          onOk() {},
        });
      }
    } catch (e) {
      const message = `Ha ocurrido el siguiente error al Recuperar las recetas del Menú seleccionado: ${e}. Contactar con el administrador.`;
      this.handleCatchError(message);
    }
  };

  mostarMenuRecetasUnicas = async () => {
    try {
      this.setState({
        loadingSpingMenu: true,
        textoLoadingSpingMenu:
          "Cargando dietas, platos, cubiertos, alérgenos...",
      });

      const parametrosHojaGestion = {
        pCatalogo: this.state.catalogo,
        pCentro: this.state.centro,
      };
      const { response } = await database.obtenerDietasClientesComensales(
        parametrosHojaGestion
      );
      const { status, message } = response;

      if (status === "success" && message.length != 0) {
        const datosTablaDietas = message.map((dieta, index) => ({
          key: index,
          catalogo: dieta.CATALOGO,
          cod_centro: dieta.COD_CENTRO,
          desc_dieta: dieta.DESC_CENTRO,
          id: dieta.ID,
          id_padre: dieta.ID_PADRE,
          cubiertos: dieta.CUBIERTOS !== null ? dieta.CUBIERTOS : 0,
          estado: dieta.ESTADO,
        }));

        this.setState({
          listDietasCliente: datosTablaDietas,
          visibleModalHojaGestion: true,
        });
      } else {
        Modal.info({
          title: "Notificación",
          content:
            "El Centro seleccionado no tiene dietes creadas en Menuplanner.",
          onOk() {},
        });
      }
    } catch (e) {
      const message = `Ha ocurrido el siguiente error al Recuperar las dietas del cleinte seleccionado: ${e}. Contactar con el administrador.`;
      this.handleCatchError(message);
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRows, selectedRowKeys });
  };

  update = (ca) => {
    this.setState({
      listaRecetasHojaGestionUpdated: ca,
    });
  };

  handler = (datosUpdated) => {
    this.update(datosUpdated);
  };

  handlerTableCustomer = (dataUpdated) => {
    this.setState({
      listDietasCliente: dataUpdated,
    });
  };

  handlerTableRecipiesDiets = (dataUpdated) => {
    const hojaGestionUpdated = this.state.listaRecetasHojaGestionUpdated.map(
      (elem) => {
        const index = dataUpdated.findIndex(
          (value) => value.codigo_receta === elem.codigo_receta
        );
        if (index >= 0) {
          elem.cubiertos = dataUpdated[index].cubiertos;
          elem.cantidad_racion = elem.cantidad * dataUpdated[index].cubiertos;
        }
        return elem;
      }
    );

    this.setState({
      ...this.state,
      listRecipiesDietsUnique: dataUpdated,
      listaRecetasHojaGestionUpdated: hojaGestionUpdated,
    });
  };

  obtenerObjetosUnicos = (objetos, comparacion) => {
    return objetos.reduce((a, o) => {
      if (!a.some((p) => comparacion(o, p))) {
        a.push(o);
      }
      return a;
    }, []);
  };

  exportarExcel = () => {
    let dataArray = [];
    switch (this.state.current) {
      case 0:
        dataArray.push({
          sheetData: this.state.listDietasCliente,
          sheetName: "",
        });
        exportToExcel.exportarExcel(
          dataArray,
          "relación-dietas-cubiertos",
          this.state.current,
          this.state.centro,
          this.state.catalogo,
          this.state.centroSeleccionado
        );
        break;

      case 1:
        dataArray.push({
          sheetData: this.state.listRecipiesDietsUnique,
          sheetName: "",
        });
        exportToExcel.exportarExcel(
          dataArray,
          "relación-platos-dietas-cubiertos",
          this.state.current,
          this.state.centro,
          this.state.catalogo,
          this.state.centroSeleccionado
        );
        break;

      case 2:
        dataArray.push({
          sheetData:
            this.state.listaRecetasHojaGestionUpdated.length === 0
              ? this.state.listaRecetasHojaGestion
              : this.state.listaRecetasHojaGestionUpdated,
          sheetName: "",
        });
        exportToExcel.exportarExcel(
          dataArray,
          "relación-platos-ingredientes-dietas",
          this.state.current,
          this.state.centro,
          this.state.catalogo,
          this.state.centroSeleccionado
        );
        break;

      case 3:
        dataArray.push({
          sheetData: this.state.listProductsWarehouseOut,
          sheetName: "Orden por ingrediente",
        });
        dataArray.push({
          sheetData: this.state.listProductsWarehouseOutByIngest,
          sheetName: "Orden por ingesta",
        });

        exportToExcel.exportarExcel(
          dataArray,
          "total-ingredientes-proveedor",
          this.state.current,
          this.state.centro,
          this.state.catalogo,
          this.state.centroSeleccionado
        );
        break;
    }
  };

  render() {
    const { selectedRows, visibleModalHojaGestion, current } = this.state;

    const hasSelected = selectedRows.length > 0;

    const resultadoCentros = this.state.listaCentrosUsuarios.map(
      ({ CATALOGO, COD_CENTRO, DESC_CENTRO }) => (
        <Select.Option
          key={`${CATALOGO}.${COD_CENTRO}`}
          label={`${COD_CENTRO}.${DESC_CENTRO}`}
          title={`${COD_CENTRO}.${DESC_CENTRO}`}
        >
          {`(${CATALOGO}-${COD_CENTRO}) ${DESC_CENTRO}`}
        </Select.Option>
      )
    );

    const conditionalByIngestion = (
      ingredientRecipe,
      ingredient,
      orderByIngestion
    ) => {
      return orderByIngestion
        ? ingredientRecipe.codigo_ingrediente ===
            ingredient.codigo_ingrediente &&
            ingredientRecipe.codigo_proveedor === ingredient.codigo_proveedor &&
            ingredientRecipe.ingesta === ingredient.ingesta
        : ingredientRecipe.codigo_ingrediente ===
            ingredient.codigo_ingrediente &&
            ingredientRecipe.codigo_proveedor === ingredient.codigo_proveedor;
    };

    const newListTotalIngredient = (
      newListRecipes,
      ingredientRecipe,
      orderByIngestion
    ) => {
      let totalByIngredient = 0;
      let ingredientUniqueRecipe = {};

      newListRecipes.map((ingredient, index) => {
        if (
          conditionalByIngestion(ingredientRecipe, ingredient, orderByIngestion)
        ) {
          totalByIngredient += ingredient.total;
        }

        if (index === newListRecipes.length - 1) {
          ingredientUniqueRecipe = {
            ...ingredientRecipe,
            total: Number(totalByIngredient).toFixed(2),
          };
          return ingredientUniqueRecipe;
        }
      });
      return ingredientUniqueRecipe;
    };

    const productWarehouseOut = (product) => {
      return {
        ...product,
        total_comprar: Number(product.total / product.gramos_unidad).toFixed(2),
      };
    };

    const next = async () => {
      try {
        this.setState({
          loadingSpingMenu: true,
        });

        if (this.state.current === 0) {
          this.setState({
            textoLoadingSpingMenu: "Cargando datos...",
            listaRecetasHojaGestion: [],
            listaRecetasHojaGestionUpdated: [],
            listProductsWarehouseOut: [],
            listRecipiesDietsUnique: [],
            current: this.state.current + 1,
          });

          const menusSeleccionados = this.state.selectedRows;

          menusSeleccionados.forEach((element) => {
            arrayMenus.push(element.MENU_ID);
          });

          const uniqueMenus = arrayMenus.filter(
            (val, ind, arr) => arr.indexOf(val) === ind
          );

          const parametrosHojaGestion = {
            pCatalogo: this.state.catalogo,
            pCodigoCentro: this.state.centro,
            pListadoMenus: uniqueMenus.toString(),
            pIngesta: this.state.ingesta.toString(),
            pFechaServicio: this.state.diaServicio,
          };
          const { response } = await database.obtenerDatosInformeHojaProduccion(
            parametrosHojaGestion
          );
          const { message, status } = response;

          if (status === "success" && message.length > 0) {
            const recetas = message;

            const dataRecetas = recetas.map((receta, index) => ({
              key: index.toString(),
              codigo_receta: receta.RECETA_ID,
              receta: receta.NOMBRE_RECETA,
              alergenos: receta.DECLARACION_ALERGENOS,
              opcion: receta.OPCION,
              opcion_id: receta.OPCION_ID,
              ingrediente: `${receta.PRODUCTO} (${receta.COD_PRODUCTO})`,
              codigo_ingrediente: receta.COD_PRODUCTO,
              proveedor: receta.PROVEEDOR,
              codigo_proveedor: receta.COD_PROVEEDOR,
              ingesta: receta.INGESTA,
              fecha_servicio: receta.FECHA_SERVICIO,
              codigo_centro: receta.CODIGO_CENTRO,
              catalogo: receta.CATALOGO,
              posicion: receta.POSICION,
              codigo_dieta: receta.ID_DIETA,
              dieta: receta.DIETA,
              cantidad: receta.CANTIDAD / receta.RACIONES,
              cantidad_racion: receta.CANTIDAD_RACION,
              um_origen: receta.UM_ORIGEN,
              gramos_unidad: receta.GRAMOS_UNIDAD_KG,
              um_destino: receta.UM_DESTINO,
              cubiertos: 0,
            }));

            const listDiets = this.state.listDietasCliente;
            for (let i = 0; i < listDiets.length; i++) {
              const elementI = listDiets[i];
              for (let j = 0; j < dataRecetas.length; j++) {
                const elementJ = dataRecetas[j];
                if (
                  elementI.catalogo === elementJ.catalogo &&
                  elementI.cod_centro === elementJ.codigo_centro &&
                  elementI.id === elementJ.codigo_dieta
                ) {
                  elementJ.cubiertos = elementI.cubiertos;
                  elementJ.cantidad_racion =
                    elementJ.cantidad_racion * elementI.cubiertos;
                }
              }
            }

            const listRecipiesDietsAll = dataRecetas.map((data) => ({
              key: `${data.codigo_receta}${data.codigo_dieta}`,
              codigo_receta: data.codigo_receta,
              receta: data.receta,
              alergenos: data.alergenos,
              codigo_dieta: data.codigo_dieta,
              dieta: data.dieta,
              cubiertos: data.cubiertos,
              opcion: data.opcion,
            }));

            let hash = {};
            const listRecipiesDietsUnique = listRecipiesDietsAll.filter(
              function (current) {
                const exists = !hash[current.key];
                hash[current.key] = true;
                return exists;
              }
            );

            this.setState({
              visibleModalHojaGestion: true,
              listaRecetasHojaGestion: dataRecetas,
              listaRecetasHojaGestionUpdated: dataRecetas,
              listRecipiesDietsUnique: listRecipiesDietsUnique,
              loadingSpingMenu: false,
              textoLoadingSpingMenu: "",
            });
          } else {
            Modal.error({
              title: "Error",
              content: "Ha ocurrido el siguiente error: " + message,
            });
          }

          return;
        }

        if (this.state.current === 1) {
          let newListRecipes = [];
          this.setState({
            loadingSpingMenu: true,
            textoLoadingSpingMenu: "Cargando datos...",
            current: this.state.current + 1,
          });
          let listRecipes = this.state.listaRecetasHojaGestionUpdated;
          setTimeout(() => {
            newListRecipes = listRecipes.map(
              (recipe, index) =>
                (recipe = {
                  ...recipe,
                  total: Number(recipe.cantidad_racion),
                })
            );

            const listIngredients = listRecipes.map((valor, index) => ({
              key: index.toString(),
              codigo_ingrediente: valor.codigo_ingrediente,
              ingrediente: valor.ingrediente,
              codigo_proveedor: valor.codigo_proveedor,
              proveedor: valor.proveedor,
              um_destino: valor.um_destino,
              gramos_unidad: valor.gramos_unidad,
              um_origen: valor.um_origen,
              alergenos: valor.alergenos,
              ingesta: valor.ingesta,
            }));

            const listIngredientsUnique = this.obtenerObjetosUnicos(
              listIngredients,
              (x, y) =>
                x.codigo_ingrediente === y.codigo_ingrediente &&
                x.codigo_proveedor === y.codigo_proveedor
            );

            const listIngredientsUniqueByIngest = this.obtenerObjetosUnicos(
              listIngredients,
              (x, y) =>
                x.codigo_ingrediente === y.codigo_ingrediente &&
                x.codigo_proveedor === y.codigo_proveedor &&
                x.ingesta === y.ingesta
            );

            const newListByTotalIngredients = listIngredientsUnique.map(
              (ingredientRecipe) =>
                newListTotalIngredient(newListRecipes, ingredientRecipe, false)
            );

            const newListByTotalIngredientsByIngest =
              listIngredientsUniqueByIngest.map((ingredientRecipe) =>
                newListTotalIngredient(newListRecipes, ingredientRecipe, true)
              );

            const listProductsWarehouseOut = sortArrayOfObjects(
              newListByTotalIngredients,
              "total",
              "asc"
            );
            const listProductsWarehouseOutByIngest = sortArrayOfObjects(
              newListByTotalIngredientsByIngest,
              "ingesta",
              "asc"
            );
            const newlistProductsWarehouseOut = [];
            const newlistProductsWarehouseOutByIngest = [];

            listProductsWarehouseOut.forEach((product) => {
              newlistProductsWarehouseOut.push(
                (product = productWarehouseOut(product))
              );
            });

            listProductsWarehouseOutByIngest.forEach((product) => {
              newlistProductsWarehouseOutByIngest.push(
                (product = productWarehouseOut(product))
              );
            });

            this.setState({
              listProductsWarehouseOut: newlistProductsWarehouseOut,
              listProductsWarehouseOutByIngest:
                newlistProductsWarehouseOutByIngest,
              loadingSpingMenu: false,
              textoLoadingSpingMenu: "",
            });
          }, 3000);

          return;
        }

        this.setState({
          current: this.state.current + 1,
          loadingSpingMenu: false,
          textoLoadingSpingMenu: "",
        });

        return;
      } catch (e) {
        const message = `Ha ocurrido el siguiente error al Recuperar las recetas del Menú seleccionado: ${e}. Contactar con el administrador.`;
        this.handleCatchError(message);
      }
    };

    const prev = () => {
      this.setState({
        current: this.state.current - 1,
      });
    };

    const AdvancedSearchForm = () => {
      const [expand, setExpand] = useState(false);
      const [form] = Form.useForm();

      const getFields = () => {
        let children = [];

        if (form.getFieldsValue().informe === "informe-hoja-produccion") {
          children.push(
            <Col span={8} key={4}>
              <Form.Item
                hasFeedback
                name="selectCentro"
                rules={[{ required: true, message: "Campo requerido." }]}
              >
                <Select
                  showSearch
                  placeholder="Seleccionar Centro"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {resultadoCentros}
                </Select>
              </Form.Item>
            </Col>,
            <Col span={8} key={2}>
              <Form.Item
                hasFeedback
                name="diaServicio"
                rules={[{ required: true, message: "Campo requerido." }]}
              >
                <DatePicker
                  locale={locale}
                  format={dateFormat}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>,
            <Col span={8} key={1}>
              <Form.Item
                hasFeedback
                name="ingesta"
                rules={[{ required: true, message: "Campo requerido." }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Seleccionar Ingesta"
                  options={ingestas}
                />
              </Form.Item>
            </Col>
          );
        } else {
          children = [];
        }

        return children;
      };

      const onFinish = async (values) => {
        if (values.selectCentro === undefined) {
          Modal.error({
            title: "Error",
            content: "Debes agregar criterios antes de continuar...",
          });
        } else {
          const diaServicio = values.diaServicio;
          const catalogoCentro = values.selectCentro.split(".");
          const catalogo = catalogoCentro[0];
          const ingesta = values.ingesta.toString();
          const centro = catalogoCentro[1];

          this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            datosTablaMenusEstado: [],
            catalogo,
            centro,
            diaServicio,
            ingesta,
            loadingSpingMenu: true,
            textoLoadingSpingMenu: "Cargando datos...",
          });

          await this.recuperarMenusCentroMensual(
            catalogo,
            centro,
            diaServicio,
            ingesta
          );
        }
      };

      return (
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={8} key={1}>
              <Form.Item
                hasFeedback
                name="informe"
                rules={[{ required: true, message: "Campo requerido." }]}
              >
                <Select placeholder="Seleccionar Informe" options={informes} />
              </Form.Item>
            </Col>
            <Col span={8} key={2}>
              <a
                style={{
                  fontSize: 12,
                  color: "#007bff",
                }}
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                {expand ? <UpOutlined /> : <DownOutlined />} Agregar criterios
              </a>
            </Col>
          </Row>
          <Row gutter={24}>{getFields()}</Row>
          <Row>
            <Col
              span={12}
              style={{
                textAlign: "left",
              }}
            >
              <span>
                {" "}
                {hasSelected
                  ? `Menús seleccionados: ${selectedRows.length}`
                  : ""}
              </span>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "right",
              }}
            >
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Aceptar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    };

    const IngestaSeleccionada = () => {
      const ingestaLabel = [];
      for (let i in ingestas) {
        for (let j in this.state.ingesta) {
          if (ingestas[i].value === this.state.ingesta[j]) {
            ingestaLabel.push(ingestas[i].label);
          }
        }
      }
      return ingestaLabel.toString();
    };

    const StepFirstHojaProduccionForm = () => {
      const [form] = Form.useForm();

      return (
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
        >
          <Row gutter={24}>
            <Col span={8} key={1}>
              <span>
                <Text strong>Nombre Centro: </Text>{" "}
                {this.state.centroSeleccionado}
              </span>
            </Col>
            <Col span={6} key={2}>
              <span>
                <Text strong>No. Centro (Catálogo): </Text>{" "}
                {`${this.state.centro} (${this.state.catalogo})`}
              </span>
            </Col>
            <Col span={5} key={3}>
              <span>
                <Text strong>Ingesta: </Text>
                <IngestaSeleccionada />
              </span>
            </Col>
            <Col span={5} key={4}>
              <span>
                <Text strong>Fecha Consumo: </Text>
                {this.state.diaServicio.format(dateFormat)}
              </span>
            </Col>
            <Col span={24} key={5}>
              <Tooltip placement="topLeft" title="Exportar a Excel">
                <Button
                  size="default"
                  style={{
                    boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                    borderColor: "#fff",
                    border: 0,
                    backgroundColor: "#fff",
                  }}
                  onClick={this.exportarExcel}
                  type="dashed"
                >
                  <FileExcelOutlined />
                </Button>
              </Tooltip>
              {this.state.current === 3 ? <Divider type="vertical" /> : ""}
              {this.state.current === 3 ? (
                <Tooltip placement="topLeft" title="Exportar a pdf">
                  <Button
                    size="default"
                    style={{
                      boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                      borderColor: "#fff",
                      border: 0,
                      backgroundColor: "#fff",
                    }}
                    type="dashed"
                  >
                    <FilePdfOutlined />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
            </Col>
            <Col span={24} key={6}>
              <Form.Item>
                {this.state.current === 0 ? (
                  <TableCustomer
                    listRecipes={this.state.listDietasCliente}
                    editCubiertos={false}
                    handler={this.handlerTableCustomer}
                  />
                ) : this.state.current === 1 ? (
                  <TableRecipiesDiets
                    listRecipes={this.state.listRecipiesDietsUnique}
                    loading={this.state.loadingSpingMenu}
                    onSave={this.handlerTableRecipiesDiets}
                  />
                ) : this.state.current === 2 ? (
                  <DietsTable
                    listRecipes={
                      this.state.listaRecetasHojaGestionUpdated.length === 0
                        ? this.state.listaRecetasHojaGestion
                        : this.state.listaRecetasHojaGestionUpdated
                    }
                    handler={this.handler}
                    loading={this.state.loadingSpingMenu}
                  />
                ) : (
                  <TableWarehouseOut
                    dataListProducts={this.state.listProductsWarehouseOut}
                    loading={this.state.loadingSpingMenu}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    };

    return (
      <div>
        <Spin
          spinning={this.state.loadingSpingMenu}
          delay={500}
          tip={this.state.textoLoadingSpingMenu}
        >
          <AdvancedSearchForm />
          <div id="infoBusquedaPorMenu"></div>
          <Modal
            visible={visibleModalHojaGestion}
            title="Informe: Hoja de Producción"
            maskClosable={false}
            width={"95%"}
            style={{ top: 20 }}
            footer={null}
            closable={true}
            onCancel={this.handleCancelModalHojaGestion}
          >
            <Steps current={current} style={{ width: "100%" }}>
              {steps.map((item) => (
                <Step
                  key={item.title}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Steps>
            <Divider type="vertical" />
            <div className="steps-content" style={{ height: "80%" }}>
              {current === 0 ? (
                <StepFirstHojaProduccionForm style={{ marginTop: "30px" }} />
              ) : (
                <StepFirstHojaProduccionForm style={{ marginTop: "30px" }} />
              )}
            </div>
            <div className="steps-action">
              {current > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                  {"< Anterior"}
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  {"Siguiente >"}
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={this.handleCancelModalHojaGestion}
                >
                  Hecho
                </Button>
              )}
            </div>
          </Modal>
        </Spin>
      </div>
    );
  }
}

export default GestionInformes;
