"use strict";
import React from "react"
import { Button, Modal, Tooltip } from "antd"
import { database } from "../../../services/database.service"
import { userService } from "../../../services"
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons"

export const EliminarDatosObsEspeciales = (props) => {
    const { state, showDrawerObsEspeciales } = props

    const textoEliminarObsEspeciales = (<span>Eliminar Observaciones Especiales</span>)

    return (
        <Tooltip placement="topLeft" title={textoEliminarObsEspeciales}>
            <Button
                size="default"
                style={{
                    boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                    borderColor: "#fff",
                    border: 0,
                    backgroundColor: "#fff",
                }}
                type="dashed"
                onClick={() => showConfirmModalEliminarObsEspeciales(state, showDrawerObsEspeciales)}
            >
                <DeleteOutlined />
            </Button>
        </Tooltip>
    )
}

const showConfirmModalEliminarObsEspeciales = (state, showDrawerObsEspeciales) => {
    if (!state.selectedRowKeysObsEspeciales.length === 1 || !state.selectedRowsObsEspeciales.length === 1) {
        Modal.error({
            title: "Error",
            content:
                "Debes seleccionar la Observación Especial que deseas eliminar.",
        });

        return
    }
    const codigo = state.selectedRowsObsEspeciales[0].CODIGO_OBS_ESPECIALES.toString();

    Modal.confirm({
        title:
            `¿Deseas eliminar la observación especial seleccionada: ${state.selectedRowsObsEspeciales[0].NOMBRE_OBS_ESPECIALES}?`,
        icon: <ExclamationCircleOutlined />,
        content:
            "Esta acción eliminará los Datos de la Observación Especial seleccionada.",
        okText: "Aceptar",
        cancelText: "Cancelar",
        onOk: () => {
            userService.secure().then;
            {
                database
                    .eliminarObsEspeciales(
                        codigo,
                        JSON.parse(localStorage.getItem("user")).sAMAccountName
                    ).then((res) => {
                        const resEliminarObsEspeciales = Object.values(res.response);

                        if (resEliminarObsEspeciales[0] === "success") {

                            Modal.success({
                                title: "Éxito",
                                content: resEliminarObsEspeciales[1],
                            })

                            return showDrawerObsEspeciales()

                        } else {

                            Modal.error({
                                title: "Error",
                                content: resEliminarObsEspeciales[1],
                            })

                        }
                    },
                        (error) => console.log(error)
                    );
            }
        },
        onCancel() {
            console.log(
                "Se ha cancelado la acción de eliminar la Observación Especial."
            );
        },
    });
}