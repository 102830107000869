'use strict';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Spin,
    Divider,
    Modal,
    Select,
    Table,
    Tooltip,
    ConfigProvider,
    BackTop,
    Checkbox,
    Space,
    Tabs,
    Typography,
    Transfer
} from "antd";
import { database } from '../services/database.service';
import { userService } from '../services';
import Highlighter from 'react-highlight-words';
import {
    DatabaseOutlined,
    ExclamationCircleOutlined,
    SearchOutlined,
    UserOutlined,
    MailOutlined,
    UserSwitchOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { plantillas } from '../utility/plantillas';
import { ARRAY_OF_NUTRITIONAL_CALCULATIONS } from '../utility/constants';
const { Title } = Typography;

moment.locale('es');
const { confirm } = Modal;
const textoIconDetalleUsuario = <span>Visualizar Detalle Usuario</span>;
const colorAdvertenciaTextArea = { textAlign: 'center', border: '2px solid red', };
const colorTabPanelAdvertenciaRed = <span style={{ color: 'red', }}>Advertencia</span>;
const colorTabPanelAdvertenciaGris = <span style={{ color: 'rgba(0, 0, 0, 0.25)', }}>Advertencia</span>;
const codEmpresa = 'null';

const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
        <DatabaseOutlined style={{ fontSize: 20 }} />
        <p>Datos No Encontrados</p>
    </div>
)

class ImprimirEtiquetas extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            users: [],
            employee: {},
            employees: [],
            loading: false,
            listaOptionsCatalogosUsuario: [],
            statusListaCatalogosUsuario: '',
            optionsCatalogosUsuario: [],
            loadingSpingImprimirEtiquetas: true,
            textoLoadingSpingImprimirEtiquetas: '',
            listaIdiomas: [],
            statusListaIdiomas: '',
            selectedRowsUsuario: [],
            selectedRowKeysUsuario: [],
            selectedRowsGuarnicion: [],
            selectedRowKeysGuarnicion: [],
            selectedRowsDatosEmpresas: [],
            selectedRowKeysDatosEmpresas: [],
            listaRecetasIdiomas: [],
            tabPosition: 'top',
            visible: false,
            error: '',
            searchText: '',
            searchedColumn: '',
            filteredInfo: null,
            sortedInfo: null,
            visibleDrawerDatosEmpresa: false,
            childrenDrawerDatosEmpresa: false,
            listaDatosEmpresas: [],
            datosEmpresas: [],
            rowSelectionDatosEmpresas: {},
            accionDatosEmpresas: 'insertar',
            childrenDrawerModificarDatosEmpresa: false,
            visibleDrawerClientes: false,
            childrenDrawerClientes: false,
            listaDatosClientes: [],
            datosClientes: [],
            rowSelectionDatosClientes: {},
            selectedRowsDatosClientes: [],
            selectedRowKeysDatosClientes: [],
            childrenDrawerModificarDatosClientes: false,
            visibleDrawerLineasProduccion: false,
            childrenDrawerLineasProduccion: false,
            listaLineasProduccion: [],
            datosLineasProduccion: [],
            rowSelectionLineasProduccion: {},
            selectedRows: [],
            selectedRowKeys: [],
            childrenDrawerModificarLineasProduccion: false,
            visibleDrawerObsEspeciales: false,
            childrenDrawerObsEspeciales: false,
            listaObsEspeciales: [],
            datosObsEspeciales: [],
            rowSelectionObsEspeciales: {},
            selectedRowsObsEspeciales: [],
            selectedRowKeysObsEspeciales: [],
            childrenDrawerModificarObsEspeciales: false,
            optionLineaProduccion: [],
            optionDatosClientes: [],
            optionDatosEmpresas: [],
            optionObsEspeciales: [],
            diasCaducidadLP: 0,
            codLP: 0,
            fechaCaducidadState: '',
            fechaElaboracionState: '',
            message: '',
            infoNutricionalReceta: [],
            infoNutricionalReceta100: [],
            valueTextAreaIngredientesAlergenosTotal: '',
            valueTextAreaAlergenosPrincipal: '',
            valueTextAreaAlergenosGuarnicion: '',
            valueTextAreaIngredientesTotal: '',
            valueTextAreaGuarnicion: '',
            valueTextAreaAdvertenciaPrincipal: '',
            valueTextAreaAdvertenciaGuarnicion: '',
            colorAdvertenciaGuarnicionTA: '',
            colorAdvertenciaPrincipalTA: '',
            disabledTabPanelAdevrtencia: false,
            colorTabPanelAdvertencia: '',
            datosCocinaImprimir: [],
            ingredientesGuarnicionEtiqueta: '',
            ingredientesPrincipalEtiqueta: '',
            nombrePrincipal: '',
            nombreGuarnicion: '',
            loadingButtonLanzarImpresion: false,
            txtAreaAlergenosTotal: true,
            txtAreaAlergenosGuarnicion: true,
            txtAreaAlergenosGuarnicion: true,
            txtAreaIngredientesGuarnicion: true,
            txtAreaIngredientesTotal: true,
            tabPaneActiva: '1',
            deshabilitarBotonEditarIngGuarnicion: true,
            deshabilitarBotonEditarAlergenosGuarnicion: true,
            valueNameLineaProduccion: null,
            cargarFomrularioPrimeraVez: 0,
            valueFechaCaducidad: null,
            valueCodigoLote: null,
            valueDias: null,
            botonEditarIngredientesTotal: false,
            valueNameCliente: null,
            valuefechaElaboracion: null,
            botonEditarAlergenosTotal: false,
            valuesFomrularioPrimeraVez: '',
            errorFieldNameLineaProduccion: true,
            listaCatalogoId: [],
            defaultCatalogoSelecionado: '',
            valueTextArea01: '',
            valuePesoNetoTotal: 0,
            catalogoSeleccionado: '',
            puedeContenerState: '',
            checkedMultiporcion: false,
            disabledPesoNeto: false,
            listaSelectAllUsersAD: '',
            listaAllUsersAD: [],
            visibleModalAsignarRolUsuario: false,
            plainOptionsRol: [],
            plainOptionsRolDefault: [],
            mockDataTransferCatalogos: [],
            mockDataTransferCentros: [],
            targetKeysTransferCatalogos: [],
            targetKeysTransferCentros: [],
            transferDefaultCatalogosUsuario: [],
            transferDefaultCentrosUsuario: []
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.setState({
            user: JSON.parse(localStorage.getItem('user')),
            users: { loading: true },
            employee: JSON.parse(localStorage.getItem('employee')),
            loadingSpingImprimirEtiquetas: true,
            textoLoadingSpingImprimirEtiquetas: 'Cargando pantalla para gestionar usuarios...',
            employees: { loading: true },
            txtAreaAlergenosTotal: true,
            txtAreaAlergenosGuarnicion: true,
            texttxtAreaAlergenosTotal: true,
            txtAreaIngredientesGuarnicion: true,
            txtAreaIngredientesTotal: true,
            tabPaneActiva: '1',
            deshabilitarBotonEditarIngGuarnicion: true,
            deshabilitarBotonEditarAlergenosGuarnicion: true,
            listaSelectAllUsersAD: '',
            listaAllUsersAD: [],
            visibleModalAsignarRolUsuario: false,
            plainOptionsRol: [],
            plainOptionsRolDefault: [],
            mockDataTransferCatalogos: [],
            mockDataTransferCentros: [],
            targetKeysTransferCatalogos: [],
            transferDefaultCatalogosUsuario: [],
            transferDefaultCentrosUsuario: []
        });

        this.getlistaOptionsCatalogosUsuario();
        this.getListaIdiomas();
        this.getListUsersActiveDirectory();
        this.recuperarCatalogos();
    }

    handleError = (message) => {
        this.setState({ error: message, loading: false })

        Modal.error({
            title: 'Error',
            content: message
        })
    }

    recuperarCatalogos = async () => {
        try {
            const { response } = await database.obtenerCatalogosActivos()
            const { status, message } = response

            if (status === 'success' && message.length > 0) {

                const mockDataNew = message.map(({ CATALAGO, NOMBRE_CATALOGO }) => ({
                    key: CATALAGO.toString(),
                    title: `(${CATALAGO}) ${NOMBRE_CATALOGO}`,
                    description: `(${CATALAGO}) ${NOMBRE_CATALOGO}`,
                }))

                this.setState({ mockDataTransferCatalogos: mockDataNew });

                this.recuperarCentros();

                return
            }

            this.handleError(message)

        } catch (error) {

            this.handleError(error.message)

        }
    }

    recuperarCentros = async () => {
        try {
            const { response } = await database.obtenerCentrosActivos()
            const { status, message } = response

            if (status === 'success' && message.length > 0) {

                const mockDataNew = message.map(({ CATALOGO, CODIGO_CENTRO, NOMBRE_CENTRO, ID }) => ({
                    key: `${CATALOGO.toString()}.${CODIGO_CENTRO.toString()}.${ID.toString()}`,
                    title: `(${CATALOGO.toString()}-${CODIGO_CENTRO}) ${NOMBRE_CENTRO}`,
                    description: `(${CATALOGO.toString()}-${CODIGO_CENTRO}) ${NOMBRE_CENTRO}`
                }))

                this.setState({
                    mockDataTransferCentros: mockDataNew
                });

                return
            }

            this.handleError(message)

        } catch (error) {

            this.handleError(error.message)

        }
    }

    getListUsersActiveDirectory = async () => {

        this.setState({
            loadingSpingImprimirEtiquetas: true,
            textoLoadingSpingImprimirEtiquetas: 'Cargando pantalla para gestionar usuarios...',
        });

        try {
            const parametroBusqueda = ' '
            const { response } = await userService.getAllUsersAD(parametroBusqueda)
            const { status, mensage } = response

            if (status === 'success') {
                const selectCatalogosUsuario = mensage.map(({ username, fullname }, index) => ((
                    <Select.Option key={`${index}.${username}`} label={fullname} title={fullname}>
                        {fullname}
                    </Select.Option>
                )))

                this.setState({
                    listaSelectAllUsersAD: selectCatalogosUsuario,
                    listaAllUsersAD: mensage,
                    loadingSpingImprimirEtiquetas: false,
                    textoLoadingSpingImprimirEtiquetas: '',
                })
            }
        } catch (error) {
            Modal.error({
                title: 'Error',
                content: `Ha ocurrido el siguiente error: ${error.mensage}`,
            });
        }
    }

    handleCancelModalasignarRolUsuario = () => {
        this.setState({
            visibleModalAsignarRolUsuario: false,
        })
    }


    showModalDetalleRolUsuario = () => {

        if (this.state.selectedRowKeysUsuario.length === 1) {

            this.getRolUsuario();
            this.recuperarCatalogosUsuario();
            this.setState({
                visibleModalAsignarRolUsuario: true,
            })

            return
        }

        if (this.state.selectedRowKeysUsuario.length != 1) {
            confirm({
                title: 'Advertencia',
                icon: <ExclamationCircleOutlined />,
                content: 'Debes seleccionar un usuario para poder visualizar sus detalles.',
                onOk: () => { },
                onCancel() { },
                cancelText: 'Cancelar',
                okText: 'Aceptar',
            });
        }
    }

    recuperarCatalogosUsuario = async () => {
        try {
            const usuario = this.state.selectedRowKeysUsuario[0]
            const { response } = await database.obtenerCatalogosUsuario(usuario)
            const { status, message } = response

            if (status === 'success') {
                const responseCentrosUsuarios = await database.obtenerCentrosUsuario(usuario)
                const statusCentrosUsuarios = responseCentrosUsuarios.response.status
                const messageCentrosUsuarios = responseCentrosUsuarios.response.message

                if (statusCentrosUsuarios === 'success') {
                    const transferCatalogosUsuario = message.map(
                        (element) => element.CATALOGO.toString()
                    )

                    const transferCentrosUsuario = messageCentrosUsuarios.map((element) =>
                        `${element.CATALOGO.toString()}.${element.CODIGO_CENTRO.toString()}.${element.ID_CENTRO.toString()}`
                    )

                    this.setState({
                        transferDefaultCatalogosUsuario: transferCatalogosUsuario,
                        transferDefaultCentrosUsuario: transferCentrosUsuario,
                        targetKeysTransferCatalogos: transferCatalogosUsuario,
                        targetKeysTransferCentros: transferCentrosUsuario
                    })
                }

                return
            }

            Modal.error({
                title: 'Error',
                content: `Ha ocurrido el siguiente error: ${message}`,
            });

        } catch (error) {
            const message = error.message
            this.handleError(message)
        }
    }

    getRolUsuario = async () => {

        try {
            const { response } = await database.obtenerRoles()
            const { status, message } = response

            if (status === 'success' && message.length > 0) {
                const roles = message
                const usuario = this.state.selectedRowKeysUsuario[0]
                const result = await database.obtenerUsuarioRoles(usuario)

                if (result.response.status === 'success' && result.response.message.length > 0) {

                    const checkboxRolesDefault = result.response.message.map(({ ID_ROL }) => ID_ROL)
                    const checkboxRoles = roles.map(({ DESCRIPCION, ID }) => ({ label: DESCRIPCION, value: ID }))

                    this.setState({
                        plainOptionsRol: checkboxRoles,
                        plainOptionsRolDefault: checkboxRolesDefault
                    })

                    return
                }

                Modal.error({
                    title: 'Error',
                    content: result.response.message
                })
            }

            Modal.error({
                title: 'Error',
                content: message
            })

        } catch (error) {
            const message = error.message
            this.handleError(message)
        }

    }

    insertarUsuarioRolesBD = async (values) => {

        const arrayCentros = []
        const arrayCentrosCatalogo = []

        this.state.targetKeysTransferCentros.forEach(center => {
            const catalogo = center.substring(0, center.indexOf("."))
            const restoStr = center.substring(center.indexOf(".") + 1, center.length)
            const centro = restoStr.substring(0, restoStr.indexOf("."))

            arrayCentrosCatalogo.push(`'${catalogo}-${centro}'`)
            arrayCentros.push(centro)
        })

        const nombre = values.nombre
        const email = values.email
        const username = values.username
        const usuarioAdmin = JSON.parse(localStorage.getItem('user')).sAMAccountName
        const arrayRoles = values.rolesUser.toString()

        try {
            const { response } = await database.insertarUsuarioRoles(nombre,
                username,
                email,
                usuarioAdmin,
                usuarioAdmin,
                arrayRoles,
                this.state.targetKeysTransferCatalogos,
                arrayCentros,
                arrayCentrosCatalogo
            )

            const { status, message } = response

            if (status === 'success') {

                this.setState({
                    visibleModalAsignarRolUsuario: false,
                    targetKeysTransferCatalogos: [],
                    targetKeysTransferCentros: []
                });

                Modal.success({
                    title: 'Éxito',
                    content: message,
                })

                return
            }

            this.setState({
                targetKeysTransferCatalogos: [],
                targetKeysTransferCentros: []
            })
            Modal.error({
                title: 'Error',
                content: message,
            })
        } catch (error) {
            const message = error.message
            this.handleError(message)
        }
    };

    filterOptionTransfer = (inputValue, option) => option.description.indexOf(inputValue) > -1;

    filterOptionTransferCentros = (inputValue, option) => option.description.indexOf(inputValue) > -1;

    handleChangeTransfer = targetKeys => {
        this.setState({
            targetKeysTransferCatalogos: targetKeys,
            transferDefaultCatalogosUsuario: targetKeys
        });
    };

    handleChangeTransferCentros = targetKeys => {
        this.setState({
            targetKeysTransferCentros: targetKeys,
            transferDefaultCentrosUsuario: targetKeys
        });
    };

    getlistaOptionsCatalogosUsuario = async () => {
        try {
            const accountName = JSON.parse(localStorage.getItem('user')).sAMAccountName
            const { response } = await database.getCatalogosUsuario(accountName)

            this.setState({
                listaOptionsCatalogosUsuario: response,
                statusListaCatalogosUsuario: 'success'
            });

            const resultadoCatalogoUsuario = [];
            const catalogosId = [];
            this.state.listaOptionsCatalogosUsuario.forEach(({ CATALOGO, NOMBRE }) => {
                resultadoCatalogoUsuario.push({ value: `${CATALOGO}`, label: `${NOMBRE}` });
                catalogosId.push(CATALOGO);
            })

            this.setState({
                optionsCatalogosUsuario: resultadoCatalogoUsuario,
                listaCatalogoId: catalogosId,
            })

        } catch (error) {
            console.log(error)
        }
    }


    onChangeCheckMultiporcion = e => {
        this.setState({
            checkedMultiporcion: e.target.checked,
            disabledPesoNeto: e.target.checked === true ? true : false,
        });
    };

    getListaIdiomas = async () => {
        try {
            const { response } = await database.getIdiomas()

            this.setState({
                listaIdiomas: response,
                statusListaIdiomas: 'success',
            });
        } catch (error) {
            console.log(error)
        }
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    handleChangeLineasProduccion = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    obtenerDatosUsuarios = (usuarioSeleccionado) => {

        this.setState({
            loadingSpingImprimirEtiquetas: true,
            textoLoadingSpingImprimirEtiquetas: 'Cargando datos del usuario...',
            checkedBoxGuarnicion: false,
        });

        const dataTabla = [];

        this.state.listaAllUsersAD.forEach(({ fullname, username, email }) => {
            username === usuarioSeleccionado && dataTabla.push({ fullname, username, email })
        })



        const rowSelectionUsuario = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowsUsuario: selectedRows, selectedRowKeysUsuario: selectedRowKeys });
            }
        };

        const columns = [
            {
                title: 'Nombre',
                dataIndex: 'fullname',
                key: 'fullname',
                ellipsis: true,
                width: 400,
            },
            {
                title: 'Usuario',
                dataIndex: 'username',
                key: 'username',
                ellipsis: true,
                width: 150,
            },
            {
                title: 'Correo Electrónico',
                dataIndex: 'email',
                key: 'email',
                ellipsis: true,
                width: 490,
            },
        ];

        ReactDOM.render(
            <div>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>

                    <Tooltip placement="topLeft" title={textoIconDetalleUsuario}>
                        <Button size="default" style={{ boxShadow: '0 0 0 0 rgba(0,123,255,.25)', borderColor: '#fff', border: 0, backgroundColor: '#fff' }} onClick={this.showModalDetalleRolUsuario} type="dashed"><UserOutlined /></Button>
                    </Tooltip>

                    <Table rowSelection={{ type: 'radio', ...rowSelectionUsuario }} size={"small"} rowKey={record => record.username} columns={columns} dataSource={dataTabla} scroll={{ x: 650, y: 600 }} onChange={this.handleChange} />

                </ConfigProvider>
            </div>

            , document.getElementById('infoUsers'));

        this.setState({
            loadingSpingImprimirEtiquetas: false,
            textoloadingSpingImprimirEtiquetas: '',
        });
    }

    enviarDatosEmpresas = async (nameEmpresa, nameSocial, direccion, cif, registroSanitario, idEmpresa, selectCatalogo) => {

        this.setState({ loading: true })

        try {
            const { response } = await database.insertarDatosEmpresas(nameEmpresa,
                nameSocial,
                direccion,
                cif,
                registroSanitario,
                JSON.parse(localStorage.getItem('user')).sAMAccountName,
                idEmpresa,
                selectCatalogo
            )

            const { status, message } = response
            this.setState({ loading: false })

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                })
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                })
            }
        } catch (error) {
            this.handleError(error.message)
        }

        this.showDrawerDatosEmpresa()
        this.onChildrenDrawerCloseDatosEmpresa()

    };

    enviarDatosClientes = async (nameCliente, catalogo) => {

        this.setState({ loading: true })

        try {
            const response = await database.insertarClientes(nameCliente, JSON.parse(localStorage.getItem('user')).sAMAccountName, catalogo)
            const { status, message } = response

            this.setState({ loading: false })

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                });
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                });
            }
        } catch (error) {
            this.setState({ error: error.message, loading: false })
        }

        this.showDrawerClientes();
        this.onChildrenDrawerCloseClientes();
    };

    enviarObsEspeciales = async (ObsEspeciales, catalogo) => {

        this.setState({ loading: true })

        try {
            const result = await database.insertarObsEspeciales(ObsEspeciales, JSON.parse(localStorage.getItem('user')).sAMAccountName, catalogo)
            const { status, message } = result

            this.setState({ loading: false });

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                });
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                });
            }

        } catch (error) {
            this.setState({ error: error.message, loading: false })
        }

        this.showDrawerObsEspeciales();
        this.onChildrenDrawerCloseObsEspeciales();
    };

    enviarLineasProduccion = async (nameLineaProduccion, tipo, descripcionTipo, diasCaducidad, textoFCaducidad, condicionesConservacion, modoEmpleo, catalogo) => {

        this.setState({ loading: true });

        try {
            const result = await database.insertarLineasProduccion(
                nameLineaProduccion,
                tipo,
                descripcionTipo,
                diasCaducidad,
                textoFCaducidad,
                condicionesConservacion,
                modoEmpleo,
                JSON.parse(localStorage.getItem('user')).sAMAccountName,
                catalogo
            )
            const { status, message } = result

            this.setState({ loading: false });

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                });
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                });
            }

        } catch (error) {
            this.setState({ error: error.message, loading: false })
        }

        this.showDrawerLineasProduccion();
        this.onChildrenDrawerCloseLineasProduccion();
    };

    enviarModificarDatosEmpresas = async (codigo, nameEmpresa, nameSocial, direccion, cif, registroSanitario, idEmpresa, catalogo) => {

        this.setState({ loading: true })

        try {
            const result = await database.actualizarDatosEmpresas(
                codigo,
                nameEmpresa,
                nameSocial,
                direccion,
                cif,
                registroSanitario,
                JSON.parse(localStorage.getItem('user')).sAMAccountName,
                idEmpresa,
                catalogo
            )
            const { status, message } = result

            this.setState({ loading: false });

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                })
                this.showDrawerDatosEmpresa()
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                });
            }

        } catch (error) {
            this.setState({ error: error.message, loading: false })
        }

        this.onChildrenDrawerCloseModificarDatosEmpresa();
    }

    enviarModificarDatosClientes = async (codigo, nameCliente, catalogo) => {

        this.setState({ loading: true })

        try {
            const result = await database.actualizarDatosEmpresas(
                codigo,
                nameCliente,
                JSON.parse(localStorage.getItem('user')).sAMAccountName,
                catalogo
            )
            const { status, message } = result

            this.setState({ loading: false });

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                })
                this.showDrawerClientes()
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                });
            }

        } catch (error) {
            this.setState({ error: error.message, loading: false })
        }

        this.onChildrenDrawerCloseModificarDatosClientes();
    }

    enviarModificarObsEspeciales = async (codigoOE, nameOE, catalogo) => {

        this.setState({ loading: true })

        try {
            const result = await database.actualizarObsEspecial(
                codigoOE,
                nameOE,
                JSON.parse(localStorage.getItem('user')).sAMAccountName,
                catalogo
            )
            const { status, message } = result

            this.setState({ loading: false });

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                })
                this.showDrawerObsEspeciales()
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                });
            }

        } catch (error) {
            this.setState({ error: error.message, loading: false })
        }

        this.onChildrenDrawerCloseModificarObsEspeciales();
    };

    enviarModificarLineasProduccion = async (codigoLineasProduccion, nameLineaProduccion, tipo, descripcionTipo, diasCaducidad, textoFCaducidad, condicionesConservacion, modoEmpleo, catalogo) => {

        this.setState({ loading: true })

        try {
            const result = await database.actualizarLineasProduccion(
                codigoLineasProduccion,
                nameLineaProduccion,
                tipo,
                descripcionTipo,
                diasCaducidad,
                textoFCaducidad,
                condicionesConservacion,
                modoEmpleo,
                JSON.parse(localStorage.getItem('user')).sAMAccountName,
                catalogo
            )
            const { status, message } = result

            this.setState({ loading: false });

            if (status === 'success') {
                Modal.success({
                    title: 'Éxito',
                    content: message,
                })
                this.showDrawerLineasProduccion();
            }
            else {
                Modal.error({
                    title: 'Error',
                    content: message,
                });
            }

        } catch (error) {
            this.setState({ error: error.message, loading: false })
        }

        this.onChildrenDrawerCloseModificarLineasProduccion();
    }

    modalConfigurar = () => {

        ReactDOM.render(
            <div>

                <Divider />

                <Checkbox checked={false} onChange={this.onChangeCheckboxGuarnicion} style={{ marginBottom: 20 }}>GUARNICIÓN</Checkbox>

            </div>

            , document.getElementById('infoRecetasNomComercialesGuarnicion'));
    }

    onChangeCheckboxGuarnicion = e => {

        const checkedGU = e.target.checked;

        if (checkedGU) {

            const dataTablaGuarnicion = [];

            this.state.listaRecetasIdiomas.forEach(({ TIPO_PLATO, COD_RECETA, NOM_RECETA, NOM_PLATO }) => {
                if (TIPO_PLATO === 'GUARNICION') {
                    dataTablaGuarnicion.push({ COD_RECETA, NOM_RECETA, NOM_PLATO });
                }
            })

            const rowSelectionGuarnicion = {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.setState({ selectedRowsGuarnicion: selectedRows, selectedRowKeysGuarnicion: selectedRowKeys });
                }
            }

            const columnsGuarnicion = [
                {
                    title: 'Código Receta',
                    dataIndex: 'COD_RECETA',
                    key: 'COD_RECETA',
                    defaultSortOrder: 'descend',
                    ellipsis: true,
                    width: 130,
                    fixed: 'left',
                    ...this.getColumnSearchPropsCodReceta('COD_RECETA'),
                },
                {
                    title: 'Nombre Receta',
                    dataIndex: 'NOM_RECETA',
                    key: 'NOM_RECETA',
                    ellipsis: true,
                    width: 490,
                    ...this.getColumnSearchPropsNombreReceta('NOM_RECETA'),
                },
                {
                    title: 'Nombre Plato',
                    dataIndex: 'NOM_PLATO',
                    key: 'NOM_PLATO',
                    ellipsis: true,
                    width: 490,
                },
            ];

            ReactDOM.render(
                <div>
                    <ConfigProvider renderEmpty={customizeRenderEmpty}>

                        <Divider />

                        <Checkbox checked={true} onChange={this.onChangeCheckboxGuarnicion} style={{ marginBottom: 20 }}>GUARNICIÓN</Checkbox>

                        <Table title={() => 'Guarnición'} rowSelection={{ type: 'radio', ...rowSelectionGuarnicion, }} size={"small"} rowKey={record => record.COD_RECETA} columns={columnsGuarnicion} dataSource={dataTablaGuarnicion} scroll={{ x: 650, y: 600 }} onChange={this.handleChange} locale={{ filterConfirm: 'Aceptar', filterReset: 'Cancelar', emptyText: 'No Datos' }} />

                    </ConfigProvider>
                </div>

                , document.getElementById('infoRecetasNomComercialesGuarnicion'))

            return
        }

        this.modalConfigurar();
        this.setState({ selectedRowsGuarnicion: [], selectedRowKeysGuarnicion: [] });
    }

    getDatosPlatoPrincipalGuarnicionSeleccionada = async () => {

        this.setState({
            valueTextAreaIngredientesAlergenosTotal: '',
            nombrePrincipal: '',
            nombreGuarnicion: '',
            valueTextAreaAlergenosPrincipal: '',
            valueTextAreaAlergenosGuarnicion: '',
            ingredientesGuarnicionEtiqueta: '',
            ingredientesPrincipalEtiqueta: '',
            valueTextAreaIngredientesTotal: '',
            valueTextAreaGuarnicion: '',
            valueTextAreaAdvertenciaPrincipal: '',
            valueTextAreaAdvertenciaGuarnicion: '',
            colorAdvertenciaPrincipalTA: '',
            colorAdvertenciaGuarnicionTA: '',
            colorTabPanelAdvertencia: '',
            disabledTabPanelAdevrtencia: '',
        })

        const codRecetaPlatoPrincipal = this.state.selectedRowKeysUsuario.length === 1 ? this.state.selectedRowKeysUsuario[0] : 'null'
        const codrecetaGuarnicion = this.state.selectedRowKeysGuarnicion.length === 1 ? this.state.selectedRowKeysGuarnicion[0] : 'null'

        try {
            const response = await database.obtenerDatosPlatoPrincipalGuarnicion(codRecetaPlatoPrincipal, codrecetaGuarnicion)
            const { status, message } = response

            if (status === 'success') {
                const datosPlatoPrincipal = [];
                const datosGuarnicion = [];
                const platoPrincipalGuarnicion = message

                platoPrincipalGuarnicion.forEach(element => {
                    const objAux = {
                        CATALOGO: element.CATALOGO,
                        CODIGO: element.CODIGO,
                        NOMBRE: element.NOMBRE,
                        PESOT: element.PESOT,
                        PESON: element.PESON,
                        CALORIASR: element.CALORIASR,
                        KJULIOSR: element.KJULIOSR,
                        PROTEINASR: element.PROTEINASR,
                        GRASAS: element.GRASAS,
                        SATURADAS: element.SATURADAS,
                        MONOINSATURADAS: element.MONOINSATURADAS,
                        POLIINSATURADAS: element.POLIINSATURADAS,
                        HIDRATOSR: element.HIDRATOSR,
                        FIBRA_R: element.FIBRA_R,
                        AZUCARES_R: element.AZUCARES_R,
                        SAL_R: element.SAL_R,
                        PLATO: element.PLATO,
                        ALERGENOS: element.ALERGENOS,
                        SUBINGREDIENTES: element.SUBINGREDIENTES,
                        ELABORACION_FRIO: element.ELABORACION_FRIO,
                        ALMIDON: element.ALMIDON,
                        POLIALCOHOLES: element.POLIALCOHOLES,
                        ALERTA_NOMBRE: element.ALERTA_NOMBRE,
                        ALERTA_INGRED: element.ALERTA_INGRED,
                        TEXTO_ALERTA: element.TEXTO_ALERTA,
                        PUEDE_CONTENER: element.PUEDE_CONTENER,
                        CONTIENE_STRAZAS: element.CONTIENE_STRAZAS,
                    }

                    element.PLATO !== 'GUARNICION' ? datosPlatoPrincipal.push(objAux) : datosGuarnicion.push(objAux)
                })

                let colorTabPanel

                if (datosPlatoPrincipal.length !== 0 && datosGuarnicion.length !== 0) {

                    const alergenosPrincipal = datosPlatoPrincipal[0].CONTIENE_STRAZAS || ''
                    const alergenosGuarnicion = datosGuarnicion[0].CONTIENE_STRAZAS || ''

                    const cadenaConRepetidosAlergenos = alergenosGuarnicion.concat(',').concat(alergenosPrincipal);

                    const arrayConRepetidosAlergenos = cadenaConRepetidosAlergenos.split(',');

                    const cadenaConRepetidos = datosGuarnicion[0].PUEDE_CONTENER.concat(',').concat(datosPlatoPrincipal[0].PUEDE_CONTENER);

                    const arrayConRepetidos = cadenaConRepetidos.split(',');

                    const distintos = [...new Set(arrayConRepetidos)];

                    distintos = distintos.filter(item => !arrayConRepetidosAlergenos.includes(item.trim()))

                    this.setState({ puedeContenerState: distintos });
                } else {
                    this.setState({
                        puedeContenerState: datosPlatoPrincipal.length !== 0
                            ? datosPlatoPrincipal[0].PUEDE_CONTENER
                            : datosGuarnicion[0].PUEDE_CONTENER
                    })

                }

                const advertenciaGuarnicon = (datosGuarnicion.length !== 0 && datosGuarnicion[0].ALERTA_INGRED === 1)
                    ? datosGuarnicion[0].TEXTO_ALERTA
                    : ''

                const advertenciaPrincipal = (datosPlatoPrincipal.length !== 0 && datosPlatoPrincipal[0].ALERTA_INGRED === 1)
                    ? datosPlatoPrincipal[0].TEXTO_ALERTA
                    : ''

                if (advertenciaPrincipal.length !== 0 || advertenciaGuarnicon.length !== 0) {
                    habilitarTabPanelAdvertencia = false;
                    colorTabPanel = colorTabPanelAdvertenciaRed;
                } else {
                    habilitarTabPanelAdvertencia = true;
                    colorTabPanel = colorTabPanelAdvertenciaGris;
                }

                const alertaGuarnicion = datosGuarnicion.length === 0 ? '' : datosGuarnicion[0].ALERTA_INGRED

                const alergenosPrincipal = (datosPlatoPrincipal.length !== 0 && datosPlatoPrincipal[0].ALERGENOS !== null)
                    ? datosPlatoPrincipal[0].ALERGENOS
                    : ''

                const aleGuarnicion = datosGuarnicion.length === 0 ? '' : datosGuarnicion[0].ALERGENOS
                const alergenosGuarnicion = aleGuarnicion === null ? '' : aleGuarnicion

                const nomeGuarnicion = datosGuarnicion.length === 0 ? '' : datosGuarnicion[0].NOMBRE

                const ingGuarnicion = datosGuarnicion.length === 0 ? '' : datosGuarnicion[0].SUBINGREDIENTES

                const ingPrincipalTotal = datosPlatoPrincipal.length === 0
                    ? ''
                    : `INGREDIENTES PRINCIPAL:${datosPlatoPrincipal[0].SUBINGREDIENTES}`

                let alerTotal = ''
                let contiene = ''

                if (alergenosPrincipal.length != 0 && alergenosGuarnicion.length != 0) {
                    alerTotal = alergenosPrincipal.length === 0 ? '' : alergenosPrincipal + alergenosGuarnicion.length === 0 ? '' : ', ' + alergenosGuarnicion;
                    contiene = `CONTIENE: ${alerTotal}`
                } else {
                    alerTotal = alergenosPrincipal.length !== 0 ? alergenosPrincipal : alergenosGuarnicion
                    contiene = `CONTIENE: ${alerTotal}`
                }

                const pesoNetoPrincipal = datosPlatoPrincipal.length == 0 ? 0 : datosPlatoPrincipal[0].PESON
                const pesoNetoGuarnicion = datosGuarnicion.length == 0 ? 0 : datosGuarnicion[0].PESON

                this.setState({
                    valueTextAreaIngredientesAlergenosTotal: ingPrincipalTotal + '\n' + ingGuarnicion + '\n' + contiene,
                    ingredientesPrincipalEtiqueta: datosPlatoPrincipal.length === 0 ? '' : datosPlatoPrincipal[0].SUBINGREDIENTES,
                    ingredientesGuarnicionEtiqueta: ingGuarnicion ?? '',
                    nombrePrincipal: datosPlatoPrincipal.length === 0 ? '' : datosPlatoPrincipal[0].NOMBRE,
                    nombreGuarnicion: nomeGuarnicion,
                    valueTextAreaAlergenosPrincipal: alergenosPrincipal,
                    valueTextAreaGuarnicion: ingGuarnicion ?? '',
                    deshabilitarBotonEditarIngGuarnicion: ingGuarnicion?.length > 0 ? false : true,
                    deshabilitarBotonEditarAlergenosGuarnicion: alergenosGuarnicion.length > 0 ? false : true,
                    valueTextAreaAlergenosGuarnicion: alergenosGuarnicion,
                    valuePesoNetoTotal: pesoNetoPrincipal + pesoNetoGuarnicion,
                    valueTextAreaIngredientesTotal: datosPlatoPrincipal.length === 0 ? '' : datosPlatoPrincipal[0].SUBINGREDIENTES,
                    valueTextAreaAdvertenciaPrincipal: advertenciaPrincipal,
                    valueTextAreaAdvertenciaGuarnicion: advertenciaGuarnicon,
                    colorAdvertenciaPrincipalTA: datosPlatoPrincipal.length === 0 ? '' : datosPlatoPrincipal[0].ALERTA_INGRED === 1 ? colorAdvertenciaTextArea : '',
                    colorAdvertenciaGuarnicionTA: alertaGuarnicion === 1 ? colorAdvertenciaTextArea : '',
                    colorTabPanelAdvertencia: colorTabPanel,
                    disabledTabPanelAdevrtencia: habilitarTabPanelAdvertencia,
                })

                const calculosNutricionalesReceta = ARRAY_OF_NUTRITIONAL_CALCULATIONS.map(({ key, descripcionNutrientes, prop }) => {
                    let total

                    if (datosPlatoPrincipal.length !== 0) {
                        total = (prop === 'PESOT' || prop === 'PESON')
                            ? datosGuarnicion[0][prop].toFixed(2)
                            : datosGuarnicion[0] == null ? '' : datosGuarnicion[0][prop].toFixed(2)
                    } else {
                        total = datosGuarnicion[0] == null
                            ? Number(datosPlatoPrincipal[0][prop].toFixed(2))
                            : Number((datosPlatoPrincipal[0][prop] + datosGuarnicion[0][prop]).toFixed(2))
                    }

                    return {
                        key,
                        descripcionNutrientes,
                        principal: datosPlatoPrincipal.length === 0 ? '' : Number(datosPlatoPrincipal[0][prop].toFixed(2)),
                        guarnicion: datosGuarnicion[0] == null ? '' : Number(datosGuarnicion[0][prop].toFixed(2)),
                        total
                    }
                })

                const calculosNutricionalesReceta100 = ARRAY_OF_NUTRITIONAL_CALCULATIONS.slice(2).map(({ key, descripcionNutrientes, prop }) => {
                    let total

                    if (datosPlatoPrincipal.length !== 0) {
                        total = datosGuarnicion[0] == null
                            ? Number(((datosPlatoPrincipal[0][prop] / datosPlatoPrincipal[0].PESOT) * 100).toFixed(2))
                            : Number((datosGuarnicion[0][prop] / datosGuarnicion[0].PESOT) * 100).toFixed(2)
                    } else {
                        total = datosGuarnicion[0] == null
                            ? Number(((datosPlatoPrincipal[0][prop] / datosPlatoPrincipal[0].PESOT) * 100).toFixed(2))
                            : Number((((datosPlatoPrincipal[0][prop] / datosPlatoPrincipal[0].PESOT) * 100) + ((datosGuarnicion[0][prop] / datosGuarnicion[0].PESOT) * 100)).toFixed(2))
                    }

                    return {
                        key,
                        descripcionNutrientes,
                        principal: datosPlatoPrincipal.length === 0 ? '' : Number(((datosPlatoPrincipal[0][prop] / datosPlatoPrincipal[0].PESOT) * 100).toFixed(2)),
                        guarnicion: datosGuarnicion[0] == null ? '' : Number(((datosGuarnicion[0][prop] / datosGuarnicion[0].PESOT) * 100).toFixed(2)),
                        total
                    }
                })

                this.setState({
                    infoNutricionalReceta: calculosNutricionalesReceta,
                    infoNutricionalReceta100: calculosNutricionalesReceta100,
                });

                return
            }

            Modal.error({
                title: 'Error',
                content: 'No se pudo recuperar datos de la receta para mostrar.',
            })

            this.setState({
                visible: false,
            })

        } catch (error) {
            Modal.error({
                title: 'Error',
                content: `Ha ocurrido el siguiente error: ${error.message}`,
            })

            this.setState({
                visible: false,
            })
        }

    }

    onClose = () => {
        this.setState({
            visible: false,
            tabPaneActiva: '1',
            txtAreaAlergenosTotal: true,
            txtAreaIngredientesTotal: true,
            txtAreaAlergenosGuarnicion: true,
            txtAreaIngredientesGuarnicion: true,
            botonEditarIngredientesTotal: false,
            botonEditarAlergenosTotal: false,
            deshabilitarBotonEditarIngGuarnicion: false,
            deshabilitarBotonEditarAlergenosGuarnicion: false,
        });
    };

    getColumnSearchPropsCodReceta = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder='Buscar Código Receta'
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Cancelar
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    getColumnSearchPropsNombreReceta = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder='Buscar Nombre Receta'
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Cancelar
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    getColumnSearchPropsNombre = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder='Buscar Nombre Datos Empresas'
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Cancelar
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    showDrawerDatosEmpresa = async () => {

        this.setState({
            visibleDrawerDatosEmpresa: true,
        });

        const listaCatalogoId = this.state.listaCatalogoId
        try {
            const response = await database.obtenerDatosEmpresas(codEmpresa, listaCatalogoId)

            this.setState({
                listaDatosEmpresas: response,
            })

            const dataTablaDatosEmpresas = this.state.listaDatosEmpresas.map(data => ({
                CODIGO_UCP: data.CODIGO_UCP,
                ID_UCP: data.ID_UCP,
                NOMBRE_UCP: data.NOMBRE_UCP,
                NOMBRE_SOCIAL_UCP: data.NOMBRE_SOCIAL_UCP,
                DIRECCION_UCP: data.DIRECCION_UCP,
                CIF_UCP: data.CIF_UCP,
                REGISTRO_SANITARIO_UCP: data.REGISTRO_SANITARIO_UCP,
                CATALOGO: data.CATALOGO,
            }))

            const constRowSelectionDatosEmpresas = {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.setState({ selectedRowsDatosEmpresas: selectedRows, selectedRowKeysDatosEmpresas: selectedRowKeys });
                }
            }

            this.setState({
                datosEmpresas: dataTablaDatosEmpresas,
                rowSelectionDatosEmpresas: constRowSelectionDatosEmpresas,
            })

        } catch (error) {
            console.log(error)
        }

    }

    showDrawerClientes = async () => {
        this.setState({
            visibleDrawerClientes: true,
        });

        const listaCatalogoId = this.state.listaCatalogoId

        const response = await database.obtenerDatosClientes(listaCatalogoId)

        this.setState({
            listaDatosClientes: response,
        })

        const dataTablaDatosClientes = this.state.listaDatosClientes.map(({ CODIGO_CLIENTES, NOMBRE_CLIENTES, CATALOGO }) => ({
            CODIGO_CLIENTES,
            NOMBRE_CLIENTES,
            CATALOGO
        }))

        const constRowSelectionDatosClientes = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowsDatosClientes: selectedRows, selectedRowKeysDatosClientes: selectedRowKeys });
            }

        }

        this.setState({
            datosClientes: dataTablaDatosClientes,
            rowSelectionDatosClientes: constRowSelectionDatosClientes,
        })

    }

    showDrawerObsEspeciales = async () => {
        this.setState({
            visibleDrawerObsEspeciales: true,
        })

        const listaCatalogoId = this.state.listaCatalogoId

        const response = await database.obtenerObsEspeciales(listaCatalogoId)

        this.setState({
            listaObsEspeciales: response,
        })

        const dataTablaObsEspeciales = this.state.listaObsEspeciales.map(({ CODIGO_OBS_ESPECIALES, NOMBRE_OBS_ESPECIALES, CATALOGO }) => ({
            CODIGO_OBS_ESPECIALES,
            NOMBRE_OBS_ESPECIALES,
            CATALOGO
        }))

        const constRowSelectionObsEspeciales = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowsObsEspeciales: selectedRows, selectedRowKeysObsEspeciales: selectedRowKeys });
            }
        }

        this.setState({
            datosObsEspeciales: dataTablaObsEspeciales,
            rowSelectionObsEspeciales: constRowSelectionObsEspeciales,
        })
    }

    showDrawerLineasProduccion = async () => {
        this.setState({
            visibleDrawerLineasProduccion: true,
        });

        const listaCatalogoId = this.state.listaCatalogoId

        const response = await database.obtenerLineasProduccion(listaCatalogoId)

        this.setState({
            listaLineasProduccion: response,
        })

        const dataTablaLineasProduccion = this.state.listaLineasProduccion.map(data => ({
            CODIGO_LINEA_PRODUCCION: data.CODIGO_LINEA_PRODUCCION,
            NOMBRE_LINEA_PRODUCCION: data.NOMBRE_LINEA_PRODUCCION,
            TIPO_FECHA_CADUCIDAD: data.TIPO_FECHA_CADUCIDAD,
            DESC_TIPO_FECHA_CADUCIDAD: data.DESC_TIPO_FECHA_CADUCIDAD,
            TEXTO_FECHA_CADUCIDAD: data.TEXTO_FECHA_CADUCIDAD,
            DIAS_CADUCIDAD: data.DIAS_CADUCIDAD,
            CONDICIONES_CONSERVACION: data.CONDICIONES_CONSERVACION,
            MODO_EMPLEO: data.MODO_EMPLEO,
            CATALOGO: data.CATALOGO,
        }))

        const constRowSelectionLineasProduccion = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys });
            }
        }

        const columnsLineasProduccion = [
            {
                title: 'Código',
                dataIndex: 'CODIGO_LINEA_PRODUCCION',
                key: 'CODIGO_LINEA_PRODUCCION',
                ellipsis: true,
                width: 80,
            },
            {
                title: 'Nombre',
                dataIndex: 'NOMBRE_LINEA_PRODUCCION',
                key: 'NOMBRE_LINEA_PRODUCCION',
                ellipsis: true,
                width: 350,
                ...this.getColumnSearchPropsNombre('NOMBRE_LINEA_PRODUCCION'),
                sorter: (a, b) => a.NOMBRE_LINEA_PRODUCCION.length - b.NOMBRE_LINEA_PRODUCCION.length,
                sortDirections: ['descend', 'ascend'],
                showSorterTooltip: false,
            },
            {
                title: 'Tipo Fecha Caducidad',
                dataIndex: 'TIPO_FECHA_CADUCIDAD',
                key: 'TIPO_FECHA_CADUCIDAD',
                ellipsis: true,
                width: 150,
            },
            {
                title: 'Descripción Tipo Fecha Caducidad',
                dataIndex: 'DESC_TIPO_FECHA_CADUCIDAD',
                key: 'DESC_TIPO_FECHA_CADUCIDAD',
                ellipsis: true,
                width: 350,
            },
            {
                title: 'Texto Fecha Caducidad',
                dataIndex: 'TEXTO_FECHA_CADUCIDAD',
                key: 'TEXTO_FECHA_CADUCIDAD',
                ellipsis: true,
                width: 400,
            },
            {
                title: 'Dias Caducidad',
                dataIndex: 'DIAS_CADUCIDAD',
                key: 'DIAS_CADUCIDAD',
                ellipsis: true,
                width: 120,
            },
            {
                title: 'Condiciones Conservación',
                dataIndex: 'CONDICIONES_CONSERVACION',
                key: 'CONDICIONES_CONSERVACION',
                ellipsis: true,
                width: 350,
            },
            {
                title: 'Modo Empleo',
                dataIndex: 'MODO_EMPLEO',
                key: 'MODO_EMPLEO',
                ellipsis: true,
                width: 350,
            },
        ]

        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                selectedRow: [],
                loading: false,
            });
        }, 1000);

        ReactDOM.render(
            <div>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>

                    <Divider orientation="center">Listado de Líneas de Producción</Divider>

                    <Table rowSelection={{ type: "radio", ...constRowSelectionLineasProduccion }}
                        rowKey={record => record.CODIGO_LINEA_PRODUCCION}
                        size="small"
                        columns={columnsLineasProduccion}
                        dataSource={dataTablaLineasProduccion}
                        onChange={this.handleChangeLineasProduccion}
                        bordered
                        pagination={{ defaultPageSize: 20 }}
                    />
                </ConfigProvider>
            </div>

            , document.getElementById('tablaLP'));

        this.setState({
            datosLineasProduccion: dataTablaLineasProduccion,
            rowSelectionLineasProduccion: constRowSelectionLineasProduccion,
        })
    }

    onChildrenDrawerCloseDatosEmpresa = () => {
        this.setState({
            childrenDrawerDatosEmpresa: false,
        });
    }

    onChildrenDrawerCloseClientes = () => {
        this.setState({
            childrenDrawerClientes: false,
        });
    }

    onChildrenDrawerCloseObsEspeciales = () => {
        this.setState({
            childrenDrawerObsEspeciales: false,
        });
    };

    onChildrenDrawerCloseLineasProduccion = () => {
        this.setState({
            childrenDrawerLineasProduccion: false,
        });
    }

    onChildrenDrawerCloseModificarDatosEmpresa = () => {
        this.setState({
            childrenDrawerModificarDatosEmpresa: false,
        });
    }

    onChildrenDrawerCloseModificarDatosClientes = () => {
        this.setState({
            childrenDrawerModificarDatosClientes: false,
        });
    }

    onChildrenDrawerCloseModificarObsEspeciales = () => {
        this.setState({
            childrenDrawerModificarObsEspeciales: false,
        });
    }

    onChildrenDrawerCloseModificarLineasProduccion = () => {
        this.setState({
            childrenDrawerModificarLineasProduccion: false,
        });
    }


    onChangeDatePickerFechaElaboracion = (date, dateString) => {

        const camposFormularioOpciones = this.formRef.current.getFieldsValue();

        if (camposFormularioOpciones.nameLineaProduccion !== undefined && camposFormularioOpciones.nameCocina !== undefined) {
            const diaCaducidad = Object.values(camposFormularioOpciones.nameLineaProduccion.split(".", 2));
            const lote = moment(camposFormularioOpciones.fechaElaboracion).format('YYYY') + moment(camposFormularioOpciones.fechaElaboracion).format('MM') + moment(camposFormularioOpciones.fechaElaboracion).format('DD');
            const codigoPlatoLote = this.state.selectedRowKeysUsuario.length != 0 ? this.state.selectedRowKeysUsuario : this.state.selectedRowKeysGuarnicion;
            const datoCocinaSeleccionada = camposFormularioOpciones.nameCocina;
            const cocina = datoCocinaSeleccionada === undefined ? '' : Object.values(camposFormularioOpciones.nameCocina.split(".", 2));

            this.formRef.current.setFieldsValue({
                fechaCaducidad: moment(date).add(diaCaducidad[1], 'd'),
                codigoLote: cocina === '' ? lote + '-' + codigoPlatoLote : lote + '-' + cocina[1] + '-' + codigoPlatoLote,
            })

            return
        }

        Modal.warning({
            title: 'Advertencia',
            content: 'Debes seleccionar una Línea de Producción primero.',
        });
    }

    llamarActualizarEstadosSpingImprimirEtiquetas() {
        setTimeout(() => {
            this.setState({
                loadingSpingImprimirEtiquetas: false,
                loadingButtonLanzarImpresion: false,
                tabPaneActiva: '1',
            });
        }, 2000);
    }

    llamarImprimirEtiquetasGeneral = (
        codLineaProduccion,
        codCliente,
        fechaElaboracion,
        totalDiasCaducidad,
        fechaCaducidad,
        codLote,
        codCocina,
        codObservacionesEspeciales,
        rutaLogoIzquierdo,
        rutaLogoDerecho,
        observaciones,
        cantidadEtiqImprimir,
        alergenosGuarnicion,
        alergenosPrincipal,
        ingredientesGuarnicion,
        ingredientesPrincipal,
        texto01,
        texto02,
        texto03,
        texto04,
        plantilla,
        pesoNetoTotal,
        unidadMedida,
        mostrarPuedeContener,
        puedeContener,
        multiporcion
    ) => {

        try {
            this.setState({
                loadingSpingImprimirEtiquetas: true,
                textoLoadingSpingImprimirEtiquetas: 'Imprimiendo etiquetas ...',
                loadingButtonLanzarImpresion: true
            });

            const nombrePrincipal = this.state.selectedRowsUsuario.length > 0 ? this.state.selectedRowsUsuario : '';
            const nameGuarnicion = this.state.selectedRowsGuarnicion.length > 0 ? this.state.selectedRowsGuarnicion : '';
            const listaLineasProduccion = this.state.listaLineasProduccion;
            const listaCatalogoId = this.state.listaCatalogoId;
            const datosCocinaImprimir = this.state.datosCocinaImprimir;
            const ingredientesPrincipalEtiqueta = this.state.ingredientesPrincipalEtiqueta;
            const ingredientesGuarnicionEtiqueta = this.state.ingredientesGuarnicionEtiqueta;
            const valueTextAreaAlergenosPrincipal = this.state.valueTextAreaAlergenosPrincipal;
            const valueTextAreaAlergenosGuarnicion = this.state.valueTextAreaAlergenosGuarnicion;
            const infoNutricionalReceta = this.state.infoNutricionalReceta;
            const infoNutricionalReceta100 = this.state.infoNutricionalReceta100;

            const codLote1 = codLote.substring(codLote.indexOf("-") + 1, codLote.length);
            const receta = codLote1.substring(codLote1.indexOf("-") + 1, codLote1.length);

            let nombreGurnicion = ''

            if (nameGuarnicion.length != 0) {
                nombreGurnicion = this.state.selectedRowsUsuario.length === 0
                    ? nameGuarnicion[0].NOM_PLATO
                    : nameGuarnicion[0].NOM_PLATO.substr(0, 4) === 'CON ' ? ' ' + nameGuarnicion[0].NOM_PLATO : ' CON ' + nameGuarnicion[0].NOM_PLATO
            }

            const arrayPlantillas = {
                "plantilla-1": plantillas.imprimirEtiquetasGeneral(codLineaProduccion, codCliente, fechaElaboracion, totalDiasCaducidad, fechaCaducidad, codLote, codCocina, codObservacionesEspeciales, rutaLogoIzquierdo, rutaLogoDerecho, observaciones, cantidadEtiqImprimir, alergenosGuarnicion, alergenosPrincipal, ingredientesGuarnicion, ingredientesPrincipal, texto01, texto02, texto03, texto04, plantilla, infoNutricionalReceta, valueTextAreaAlergenosGuarnicion, valueTextAreaAlergenosPrincipal, ingredientesGuarnicionEtiqueta, ingredientesPrincipalEtiqueta, datosCocinaImprimir, nombrePrincipal, nameGuarnicion, nombreGurnicion, listaLineasProduccion, listaCatalogoId, infoNutricionalReceta100, pesoNetoTotal, unidadMedida, receta, mostrarPuedeContener, puedeContener, multiporcion),
                "plantilla-2": plantillas.imprimirEtiquetasGeneral(codLineaProduccion, codCliente, fechaElaboracion, totalDiasCaducidad, fechaCaducidad, codLote, codCocina, codObservacionesEspeciales, rutaLogoIzquierdo, rutaLogoDerecho, observaciones, cantidadEtiqImprimir, alergenosGuarnicion, alergenosPrincipal, ingredientesGuarnicion, ingredientesPrincipal, texto01, texto02, texto03, texto04, plantilla, infoNutricionalReceta, valueTextAreaAlergenosGuarnicion, valueTextAreaAlergenosPrincipal, ingredientesGuarnicionEtiqueta, ingredientesPrincipalEtiqueta, datosCocinaImprimir, nombrePrincipal, nameGuarnicion, nombreGurnicion, listaLineasProduccion, listaCatalogoId, infoNutricionalReceta100, pesoNetoTotal, unidadMedida, receta, mostrarPuedeContener, puedeContener, multiporcion),
                "plantilla-3": plantillas.imprimirEtiquetasGeneralPlantilla3(codLineaProduccion, codCliente, fechaElaboracion, totalDiasCaducidad, fechaCaducidad, codLote, codCocina, codObservacionesEspeciales, rutaLogoIzquierdo, rutaLogoDerecho, observaciones, cantidadEtiqImprimir, alergenosGuarnicion, alergenosPrincipal, ingredientesGuarnicion, ingredientesPrincipal, texto01, texto02, texto03, texto04, plantilla, infoNutricionalReceta, valueTextAreaAlergenosGuarnicion, valueTextAreaAlergenosPrincipal, ingredientesGuarnicionEtiqueta, ingredientesPrincipalEtiqueta, datosCocinaImprimir, nombrePrincipal, nameGuarnicion, nombreGurnicion, listaLineasProduccion, listaCatalogoId, infoNutricionalReceta100, pesoNetoTotal, unidadMedida, mostrarPuedeContener, puedeContener, multiporcion),
                "plantilla-4": plantillas.imprimirEtiquetasGeneralPlantilla4(codLineaProduccion, codCliente, fechaElaboracion, totalDiasCaducidad, fechaCaducidad, codLote, codCocina, codObservacionesEspeciales, rutaLogoIzquierdo, rutaLogoDerecho, observaciones, cantidadEtiqImprimir, alergenosGuarnicion, alergenosPrincipal, ingredientesGuarnicion, ingredientesPrincipal, texto01, texto02, texto03, texto04, plantilla, infoNutricionalReceta, valueTextAreaAlergenosGuarnicion, valueTextAreaAlergenosPrincipal, ingredientesGuarnicionEtiqueta, ingredientesPrincipalEtiqueta, datosCocinaImprimir, nombrePrincipal, nameGuarnicion, nombreGurnicion, listaLineasProduccion, listaCatalogoId, infoNutricionalReceta100, pesoNetoTotal, unidadMedida, mostrarPuedeContener, puedeContener, multiporcion),
                "plantilla-5": plantillas.imprimirEtiquetasGeneralPlantilla5(codLineaProduccion, codCliente, fechaElaboracion, totalDiasCaducidad, fechaCaducidad, codLote, codCocina, codObservacionesEspeciales, rutaLogoIzquierdo, rutaLogoDerecho, observaciones, cantidadEtiqImprimir, alergenosGuarnicion, alergenosPrincipal, ingredientesGuarnicion, ingredientesPrincipal, texto01, texto02, texto03, texto04, plantilla, infoNutricionalReceta, valueTextAreaAlergenosGuarnicion, valueTextAreaAlergenosPrincipal, ingredientesGuarnicionEtiqueta, ingredientesPrincipalEtiqueta, datosCocinaImprimir, nombrePrincipal, nameGuarnicion, nombreGurnicion, listaLineasProduccion, listaCatalogoId, infoNutricionalReceta100, pesoNetoTotal, unidadMedida, mostrarPuedeContener, puedeContener, multiporcion),
                "plantilla-6": plantillas.imprimirEtiquetasGeneralPlantilla6(codLineaProduccion, codCliente, fechaElaboracion, totalDiasCaducidad, fechaCaducidad, codLote, codCocina, codObservacionesEspeciales, rutaLogoIzquierdo, rutaLogoDerecho, observaciones, cantidadEtiqImprimir, alergenosGuarnicion, alergenosPrincipal, ingredientesGuarnicion, ingredientesPrincipal, texto01, texto02, texto03, texto04, plantilla, infoNutricionalReceta, valueTextAreaAlergenosGuarnicion, valueTextAreaAlergenosPrincipal, ingredientesGuarnicionEtiqueta, ingredientesPrincipalEtiqueta, datosCocinaImprimir, nombrePrincipal, nameGuarnicion, nombreGurnicion, listaLineasProduccion, listaCatalogoId, infoNutricionalReceta100, pesoNetoTotal, unidadMedida, receta, mostrarPuedeContener, puedeContener, multiporcion),
            }

            arrayPlantillas[plantilla]

            this.llamarActualizarEstadosSpingImprimirEtiquetas();
        } catch (e) {

            this.setState({
                loadingSpingImprimirEtiquetas: false,
                textoLoadingSpingImprimirEtiquetas: '',
                loadingButtonLanzarImpresion: false
            })

            Modal.error({
                title: 'Error',
                content: `Ha ocurrido el siguiente error al Imprimir las Etiquetas: ${e}. Contactar con el administrador.`
            })
        }

    }

    onChangeLineaProduccion = (value, option) => {

        this.setState({
            tabPaneActiva: '1',
            errorFieldNameLineaProduccion: false,
        })

        const camposFormularioOpciones = this.formRef.current.getFieldsValue();
        const diaCaducidad = Object.values(value.split(".", 2));
        const diaCaducidadGlobal = Object.values(camposFormularioOpciones.nameLineaProduccion.split(".", 2));
        const lote = moment(camposFormularioOpciones.fechaElaboracion).format('YYYY') + moment(camposFormularioOpciones.fechaElaboracion).format('MM') + moment(camposFormularioOpciones.fechaElaboracion).format('DD');
        const datoCocinaSeleccionada = camposFormularioOpciones.nameCocina;
        const cocina = datoCocinaSeleccionada === undefined ? '' : Object.values(camposFormularioOpciones.nameCocina.split(".", 2));
        const codigoPlatoLote = this.state.selectedRowKeysUsuario.length != 0 ? this.state.selectedRowKeysUsuario : this.state.selectedRowKeysGuarnicion;

        this.formRef.current.setFieldsValue({
            fechaCaducidad: moment(camposFormularioOpciones.fechaElaboracion).add(diaCaducidadGlobal[1], 'd'),
            codigoLote: cocina === '' ? `${lote}-${codigoPlatoLote}` : `${lote}-${cocina[1]}-${codigoPlatoLote}`,
            dias: diaCaducidad[1],
            nameLineaProduccion: value,
        })

        if (this.state.cargarFomrularioPrimeraVez === 0 || this.state.cargarFomrularioPrimeraVez === 2) {
            this.setState({
                cargarFomrularioPrimeraVez: 1,
            })
        }
    }

    onChangeSelectCocina = (value, option) => {

        const camposFormularioOpciones = this.formRef.current.getFieldsValue();

        if (camposFormularioOpciones.nameLineaProduccion != null) {

            const diaCaducidad = Object.values(camposFormularioOpciones.nameLineaProduccion.split(".", 2));
            const cocina = Object.values(camposFormularioOpciones.nameCocina.split(".", 2));
            const lote = moment(camposFormularioOpciones.fechaElaboracion).format('YYYY') + moment(camposFormularioOpciones.fechaElaboracion).format('MM') + moment(camposFormularioOpciones.fechaElaboracion).format('DD');
            const codigoPlatoLote = this.state.selectedRowKeysUsuario.length != 0 ? this.state.selectedRowKeysUsuario : this.state.selectedRowKeysGuarnicion;

            this.formRef.current.setFieldsValue({
                fechaCaducidad: moment(camposFormularioOpciones.fechaElaboracion).add(diaCaducidad[1], 'd'),
                codigoLote: `${lote}-${cocina[1]}-${codigoPlatoLote}`
            })

            return
        }

        Modal.warning({
            title: 'Advertencia',
            content: 'Debes seleccionar una Línea de Producción.',
        });
    }

    editarIngredientesTotal = () => {

        const valoresFormulario = this.formRef.current.getFieldsValue();

        this.setState({
            txtAreaIngredientesTotal: valoresFormulario.ingredientesPrincipal.length === 0 ? true : false,
            txtAreaAlergenosTotal: valoresFormulario.ingredientesPrincipal.length === 0 ? true : false,
            txtAreaIngredientesGuarnicion: valoresFormulario.ingredientesGuarnicion.length === 0 ? true : false,
            tabPaneActiva: '4',
            botonEditarIngredientesTotal: true,
            cargarFomrularioPrimeraVez: 1,
            txtAreaAlergenosGuarnicion: valoresFormulario.ingredientesGuarnicion.length === 0 ? true : false,
        })
    }

    render() {

        const { Option } = Select;

        /* De esta forma obtengo el estado actual que tiene cada elemento. */
        const { loadingSpingImprimirEtiquetas, textoLoadingSpingImprimirEtiquetas } = this.state;

        const AdvancedSearchForm = () => {

            const [form] = Form.useForm()

            const onFinish = values => {

                const usuarioSeleccionado = values.selectUsuario;
                const n = usuarioSeleccionado.indexOf('.');
                const usuario = usuarioSeleccionado.substring(n + 1, usuarioSeleccionado.length)

                this.obtenerDatosUsuarios(usuario);
            }

            return (
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >

                    <Row gutter={8}>
                        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 8, offset: 1 }} style={{ float: "left" }}>

                            <Form.Item
                                hasFeedback
                                name='selectUsuario'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obligatorio'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Seleccionar Usuario"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.listaSelectAllUsersAD}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }} >

                            <Button key="back" onClick={() => { form.resetFields(); }} style={{ float: "left" }}>
                                Cancelar
                            </Button>

                            <Button loading={this.state.loading} type="primary" htmlType="submit" style={{ float: "left", marginLeft: 20 }}>
                                Aceptar
                            </Button>

                        </Col>
                    </Row >

                </Form>
            )
        }

        const AsignarRolUsuario = () => {

            const [form] = Form.useForm();

            const inicializarValoresFormAsignarRol = {
                rolesUser: this.state.plainOptionsRolDefault,
                nombre: this.state.selectedRowsUsuario[0].fullname,
                username: this.state.selectedRowsUsuario[0].username,
                email: this.state.selectedRowsUsuario[0].email,
            };

            const onFinish = values => {
                this.insertarUsuarioRolesBD(values);
            }

            return (
                <Form
                    onFinish={onFinish}
                    initialValues={inicializarValoresFormAsignarRol}
                    form={form}
                >
                    <Row gutter={24}>

                        <Col key={1} span={24}>
                            <Title level={5}>Datos del usuario.</Title>
                            <p></p>
                        </Col>
                        <Col key={2} span={16}>
                            <Form.Item
                                name='nombre'
                                rules={[
                                    {
                                        required: false,
                                        message: 'Input something!'
                                    },
                                ]}
                            >
                                <Input suffix={
                                    <Tooltip title="Nombre del usuario.">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                    prefix={<UserOutlined className="site-form-item-icon" />} disabled />

                            </Form.Item>
                        </Col>

                        <Col key={3} span={8}>
                            <Form.Item
                                name='username'
                                rules={[
                                    {
                                        required: false,
                                        message: 'Input something!'
                                    },
                                ]}
                            >
                                <Input suffix={
                                    <Tooltip title="Usuario.">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                    prefix={<UserSwitchOutlined className="site-form-item-icon" />} disabled />

                            </Form.Item>
                        </Col>

                        <Col key={4} span={24}>
                            <Form.Item
                                name='email'
                                rules={[
                                    {
                                        required: false,
                                        message: 'Input something!'
                                    },
                                ]}
                            >
                                <Input suffix={
                                    <Tooltip title="Correo electrónico del usuario.">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                    prefix={<MailOutlined className="site-form-item-icon" />} disabled />
                            </Form.Item>
                        </Col>

                        <Col key={5} span={24}>
                            <Title level={5}>1. Asignar o eliminar el/los roles al usuario seleccionado.</Title>
                            <p></p>
                        </Col>

                        <Col key={6} span={24}>
                            <Form.Item
                                name='rolesUser'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!'
                                    },
                                ]}
                            >

                                <Checkbox.Group options={this.state.plainOptionsRol} />

                            </Form.Item>
                        </Col>

                        <Col key={7} span={24}>
                            <Title level={5}>2. Asignar o eliminar centros para el rol de Consultor.</Title>
                            <p></p>
                        </Col>

                        <Col key={8} span={24}>
                            <Form.Item
                                hasFeedback
                                name='centrosAsignados'
                                rules={[
                                    {
                                        required: false,
                                        message: 'Input something!'
                                    },
                                ]}
                            >
                                <Transfer
                                    dataSource={this.state.mockDataTransferCentros}
                                    showSearch
                                    filterOption={this.filterOptionTransferCentros}
                                    targetKeys={this.state.transferDefaultCentrosUsuario.length > 0 ? this.state.transferDefaultCentrosUsuario : this.state.targetKeysTransferCentros}
                                    onChange={this.handleChangeTransferCentros}
                                    render={item => item.title}
                                    listStyle={{
                                        width: 345,
                                        height: 250,
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col key={9} span={24}>
                            <Title level={5}>3. Asignar o eliminar catálogos para el rol de Operaciones y Etiquetador.</Title>
                            <p></p>
                        </Col>

                        <Col key={10} span={24}>
                            <Form.Item
                                hasFeedback
                                name='catalogosAsignados'
                                rules={[
                                    {
                                        required: false,
                                        message: 'Input something!'
                                    },
                                ]}
                            >
                                <Transfer
                                    dataSource={this.state.mockDataTransferCatalogos}
                                    showSearch
                                    filterOption={this.filterOptionTransfer}
                                    targetKeys={this.state.transferDefaultCatalogosUsuario.length > 0 ? this.state.transferDefaultCatalogosUsuario : this.state.targetKeysTransferCatalogos}
                                    onChange={this.handleChangeTransfer}
                                    render={item => item.title}
                                    listStyle={{
                                        width: 345,
                                        height: 250,
                                    }}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col
                            span={24}
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Aceptar
                            </Button>
                            <Button
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => {
                                    this.handleCancelModalasignarRolUsuario();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            );
        };


        return (
            <div>
                <Spin spinning={loadingSpingImprimirEtiquetas} delay={500} tip={textoLoadingSpingImprimirEtiquetas}>
                    <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <div>
                            <AdvancedSearchForm />

                            <div id="infoUsers">
                            </div>

                            <Modal
                                visible={this.state.visibleModalAsignarRolUsuario}
                                title="Asignar rol"
                                maskClosable={false}
                                width={775}
                                footer={null}
                                closable={true}
                                onCancel={this.handleCancelModalasignarRolUsuario}
                            >
                                <Space direction="vertical">
                                    <AsignarRolUsuario />
                                </Space>
                            </Modal>
                        </div>
                    </ConfigProvider>
                </Spin>
                <BackTop />
            </div>
        );
    }
}

export default ImprimirEtiquetas;
