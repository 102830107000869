import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Form, Input, Button, Cascader, Row, Col, AutoComplete, Calendar, DatePicker, Divider,
  Select, ConfigProvider, Spin, Modal, Table, Tooltip, Card, List, Avatar, Drawer, Typography,
} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import { database } from '../services/database.service';
import {
  DatabaseOutlined, FileExcelOutlined, PrinterOutlined, ProfileOutlined, ContactsOutlined,
  ScheduleOutlined, LineOutlined, ExclamationCircleOutlined, SearchOutlined, ClearOutlined, FormOutlined, EditOutlined, DeleteOutlined,
  FundViewOutlined, EyeOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { userService } from '../services';
const { TextArea } = Input;

let textoMostrarMenuDiario = 'Visualizar Menú del Día';
let textoVisualizarFT = 'Visualizar Ficha Técnica';
let textoDesayuno = <p style={{ fontWeight: 'bold' }}>DESAYUNO</p>;
let textoMediaMannana = <p>MEDIA MAÑANA</p>;
let textoComida = <p>COMIDA</p>;
let textoMerienda = <p>MERIENDA</p>;
let textoCena = <p>CENA</p>;
let textoSinOpcion = <p>SIN OPCIÓN</p>;
let varEnergia = <p style={{ fontWeight: 'bold' }}>Energía(Kcal): </p>;

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
moment.locale('es');
const { Title } = Typography;
const { Meta } = Card;

const gridStyle = {
  width: '14%',
  padding: 2,
  textAlign: 'center',
};

const conAlergeno = {
  width: 65,
  height: 70,
};

const styleDescNoTrazas = {
  opacity: 0,
};

const styleDescTrazas = {
  opacity: 1,
};

const sinAlergeno = {
  width: 65,
  height: 70,
  opacity: 0.1,
};

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <DatabaseOutlined style={{ fontSize: 20 }} />
    <p>Datos No Encontrados</p>
  </div>
);

const columnsRecetasMenuFechaServicio = [
  {
    title: 'Platos',
    //dataIndex: 'ID_MENU',
    //key: 'ID_MENU',
    //defaultSortOrder: 'descend',
    ellipsis: true,
    width: 50,
    fixed: 'left',
    render: (text, record) => (
      <Avatar src={record.imagen} alt={record.description} />
    ),
  },
  {
    title: 'Nombre Receta',
    //dataIndex: 'title',
    key: 'codigoReceta',
    ellipsis: true,
    fixed: 'left',
    width: 400,
    render: (text, record) => (
      <span>
        {record.title}
        <br />
        {record.description}
      </span>
    ),
  },
];

const data = [
  {
    title: 'Desayuno',
    imagen: 'src/assets/images/opcionesPlato/desayunoPlato.jpg',
    alt: 'Desayuno',
  },
  {
    title: 'Media Mañana',
    imagen: 'src/assets/images/opcionesPlato/mediaMannana.jpg',
    alt: 'Media Mañana',
  },
  {
    title: 'Merienda',
    imagen: 'src/assets/images/opcionesPlato/merienda.jpg',
    alt: 'Merienda',
  },
  {
    title: 'Aperitivo',
    imagen: 'src/assets/images/opcionesPlato/aperitivoPlato.jpg',
    alt: 'Aperitivo',
  },
  {
    title: 'Primer Plato',
    imagen: 'src/assets/images/opcionesPlato/primerPlato.jpg',
    alt: 'Primer Plato',
  },
  {
    title: 'Segundo Plato',
    imagen: 'src/assets/images/opcionesPlato/segundoPlato.jpg',
    alt: 'Segundo Plato',
  },
  {
    title: 'Guarnición',
    imagen: 'src/assets/images/opcionesPlato/guarnicionPlato.jpg',
    alt: 'Guarnición',
  },
  {
    title: 'Postre',
    imagen: 'src/assets/images/opcionesPlato/postrePlato.jpg',
    alt: 'Postre',
  },
  {
    title: 'Bebida',
    imagen: 'src/assets/images/opcionesPlato/bebidaPlato.jpg',
    alt: 'Bebida',
  },
  {
    title: 'Pan',
    imagen: 'src/assets/images/opcionesPlato/panPlato.jpg',
    alt: 'Pan',
  },
  {
    title: 'Plato Único',
    imagen: 'src/assets/images/opcionesPlato/unicoPlato.jpg',
    alt: 'Plato Único',
  },
  {
    title: 'Sin Opción',
    imagen: 'src/assets/images/opcionesPlato/sinOpcionPlato.jpg',
    alt: 'Sin Opción',
  },
];

const columnsTablaMenus = [
  {
    title: 'Código Menú',
    dataIndex: 'MENU_ID',
    key: 'KEY',
    defaultSortOrder: 'descend',
    ellipsis: true,
    width: 60,
    fixed: 'left',
  },
  {
    title: 'Nombre Menú',
    dataIndex: 'NOMBRE_MENU',
    ellipsis: true,
    width: 280,
  },
  {
    title: 'Tipo Dieta',
    dataIndex: 'TIPO_DIETA',
    ellipsis: true,
    width: 60,
  },
  {
    title: 'Nombre Centro/Dieta',//<span>Kj / Kcal <Button size="default" type="dashed" style={{ boxShadow: '0 0 0 0 rgba(0,123,255,.25)', borderColor: '#fff', border: 0, backgroundColor: '#fff' }} onClick={() => openNotificationWithIcon('info')}></Button></span>,
    dataIndex: 'NOMBRE',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Tipo Menú',//<span>Kj / Kcal <Button size="default" type="dashed" style={{ boxShadow: '0 0 0 0 rgba(0,123,255,.25)', borderColor: '#fff', border: 0, backgroundColor: '#fff' }} onClick={() => openNotificationWithIcon('info')}></Button></span>,
    dataIndex: 'TIPO_MENU',
    ellipsis: true,
    width: 60,
  }
];

class BuscarPorMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      users: [],
      employee: {},
      employees: [],
      dataSource: [],
      lCentros: [],
      redirecting: false,
      statusCentrosUsuarios: null,
      listaCentrosUsuarios: [],
      valueFecha: null,
      loadingSpingMenu: false,
      selectedRowKeys: [],
      selectedRows: [],
      loadingSpingBuscarMenu: false,
      textoLoadingSpingBuscarMenu: '',
      visibleDrawerlVisualizarMenuDiario: false,
      diaServicioModal: null,
      nombreCentro: null,
      dataListaRecetasMenuSelected: [],
      infoNutricionalReceta: [],
      childrenDrawerDetalleReceta: false,
      nomRecetaDetalle: '',
      racionesRecetaDetalle: '',
      familiaRecetaDetalle: '',
      comensalRecetaDetalle: '',
      dietaRecetaDetalle: '',
      caloriasRecetaDetalle: '',
      pesoBrutoRecetaDetalle: '',
      aguaRecetaDetalle: '',
      codRecetasDetalle: '',
      ingredientesRecetasDetalle: [],
      glutenRecetaDetalle: '',
      crustaceosRecetaDetalle: '',
      huevoRecetaDetalle: '',
      pescadoRecetaDetalle: '',
      cacahuetesRecetaDetalle: '',
      sojaRecetaDetalle: '',
      lecheRecetaDetalle: '',
      frutos_cascarasRecetaDetalle: '',
      apioRecetaDetalle: '',
      mostazaRecetaDetalle: '',
      sesamoRecetaDetalle: '',
      dxo_azufreRecetaDetalle: '',
      altramucesRecetaDetalle: '',
      moluscosRecetaDetalle: '',
      alergenosIncompletoDetalle: '',
      elaboracionRecetaDetalle: '',
      infoNutricionalReceta: [],
      widthDrawerMenuDiario: 630,
      recetasAdicionalIngDetalle: [],
      styleDescGlutenRecetaDetalle: '',
      styleDescSesamoRecetaDetalle: '',
      styleDescSojaRecetaDetalle: '',
      styleDescCrustaceosRecetaDetalle: '',
      styleDescHuevoRecetaDetalle: '',
      styleDescPescadoRecetaDetalle: '',
      styleDescCacahuetesRecetaDetalle: '',
      styleDescLecheRecetaDetalle: '',
      styleDescFrutos_cascarasRecetaDetalle: '',
      styleDescApioRecetaDetalle: '',
      styleDescMostazaRecetaDetalle: '',
      styleDescDxo_azufreRecetaDetalle: '',
      styleDescAltramucesRecetaDetalle: '',
      styleDescMoluscosRecetaDetalle: '',
      //datosTablaMenusEstado: [],
    };
  }

  //Sacame todo lo que tengas que sacar de la Api antes de imprimir la VISTA.
  componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem('user')),
      users: { loading: true },
      employee: JSON.parse(localStorage.getItem('employee')),
      employees: { loading: true },
      isLoaded: true,
      valueFecha: null,
      loadingSpingMenu: true,
      textoLoadingSpingMenu: 'Cargando pantalla para Buscar por Menú...',
    });

    this.getListaCentro();
  };

  getListaCentro = () => {

    // console.log(JSON.parse(localStorage.getItem('user')).sAMAccountName);

    // database.getCentrosUsuarios(JSON.parse(localStorage.getItem('user')).sAMAccountName)
    //   .then(res => {

    //     this.setState({
    //       listaCentrosUsuarios: res.response,
    //       statusCentrosUsuarios: 'success',
    //       loadingSpingMenu: false,
    //       textoLoadingSpingMenu: '',
    //     });

    //     console.log(this.state);

    //   })
    database.obtenerCentrosUsuarioMongoDB(JSON.parse(localStorage.getItem('user')).sAMAccountName.toUpperCase())
      .then(res => {

        var listadoCentrosInsertados = res.response;

        console.log(listadoCentrosInsertados);
        if (listadoCentrosInsertados.status === 'success' && listadoCentrosInsertados.message.length > 0) {
          this.setState({
            listaCentrosUsuarios: listadoCentrosInsertados.message,
            statusCentrosUsuarios: 'success',
            loadingSpingMenu: false,
            textoLoadingSpingMenu: '',
          });

          console.log(this.state);

        } else if (listadoCentrosInsertados.status === 'success' && listadoCentrosInsertados.message.length === 0) {
          this.setState({
            loadingSpingBuscarPlato: false,
            textoLoadingSpingBuscarPlato: '',
          });
        } else {
          Modal.error({
            title: 'Error',
            content: 'Ha ocurrido el siguiente error: ' + listadoCentrosInsertados.message,
          });
        }
      });
  };

  enterLoading = () => {
    this.setState({ loading: true });
  };

  enterIconLoading = () => {
    this.setState({ iconLoading: true });
  };

  /*select-Centro*/

  onChange = (value) => {
    console.log(`Centro seleccionado: ${value}`);
  }

  //Llamado cuando el desenfoque Select
  onBlur = () => {
    console.log('blur');
  }

  onFocus = () => {
    console.log('focus');
  }

  onSearch = (val) => {
    console.log('search:', val);
  }

  /*date-picker*/

  onChangeDatePicker = (value) => {
    console.log('DatePicker change: ', value && value.format(dateFormat));
  }

  /**
 * Manejar la paginación al mostrar el resultado de la busqueda de las recetas. 
 * */
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  mostarMenuDiarioRecetas = () => {

    //var menuSeleccionado = this.state.selectedRowKeys;

    var arrayMenuSeleccionado = this.state.selectedRows;
    var fecha;


    if (arrayMenuSeleccionado.length === 1) {

      var listadoRecetasMenu = 'null';
      var menuSelected = arrayMenuSeleccionado[0].MENU_ID;
      var tipoMenu = arrayMenuSeleccionado[0].TIPO_MENU;
      console.log('menuSelected');
      console.log(menuSelected);

      if (tipoMenu === 'MENSUAL') {
        // var fechaServicio = this.state.diaServicioModal.toString().replace("/", "");

        // console.log('fechaServiciooooooooooooooooooooooo');
        // console.log(fechaServicio);
        fecha = this.state.diaServicioModal;

        console.log('fechaaaaaaaaaaaaaaaaaaa');
        console.log(fecha);

      } else if (tipoMenu === 'CICLO') {
        fecha = arrayMenuSeleccionado[0].FECHA_SERVICIO;
      }

      console.log('fecha');
      console.log(fecha);

      // var diasSinServicioFinde = new Array(6, 7, 13, 14, 20, 21, 27, 28, 34, 35);

      // for(let i = 0; i < diasSinServicioFinde.length;  i++){

      //   console.log('i: ' + diasSinServicioFinde[i]);
      //   if(fecha == diasSinServicioFinde[i]){
      //     noMostrarDia = 1;
      //   }
      // }



      // console.log('noMostrarDia');
      // console.log(noMostrarDia);

      /**
     * Obtengo el listado de recetas con sus datos dado el menú...
     */

      if (arrayMenuSeleccionado[0].SERVICIO_FINDE === 'N' && (arrayMenuSeleccionado[0].DIA_SEMANA === 6 || arrayMenuSeleccionado[0].DIA_SEMANA === 7)) {

        Modal.warning({
          title: 'Advertencia',
          content: 'El menú ' + arrayMenuSeleccionado[0].MENU_ID + '.' + arrayMenuSeleccionado[0].NOMBRE_MENU + ' no tiene servico para ese día.',
        });

      } else {
        try {

            database.getRecetasMenu(menuSelected.toString(), listadoRecetasMenu, fecha, tipoMenu).then(valor => {

              var listaRecetas = valor.response;

              if (listaRecetas.status === 'success' && listaRecetas.message.length > 0) {

                var listaRecetasMenuSelected = listaRecetas.message;

                var recetasMenuFechaServicio = [];
                var recetasMenuFechaServicioDesayuno = [];
                var recetasMenuFechaServicioMediaMannana = [];
                var recetasMenuFechaServicioComida = [];
                var recetasMenuFechaServicioMerienda = [];
                var recetasMenuFechaServicioCena = [];
                var recetasMenuFechaServicioSinOpcion = [];
                var valoracionDesayunoCalorias = 0;
                var valoracionDesayunoProteinas = 0;
                var valoracionDesayunoHCarbonos = 0;
                var valoracionDesayunoLipidos = 0;
                var cantidadRecetasDesayuno = 0;
                var valoracionMediaMCalorias = 0;
                var valoracionMediaMProteinas = 0;
                var valoracionMediaMHCarbonos = 0;
                var valoracionMediaMLipidos = 0;
                var cantidadRecetasMediaM = 0;
                var valoracionComidaCalorias = 0;
                var valoracionComidaProteinas = 0;
                var valoracionComidaHCarbonos = 0;
                var valoracionComidaLipidos = 0;
                var cantidadRecetasComida = 0;
                var valoracionMeriendaCalorias = 0;
                var valoracionMeriendaProteinas = 0;
                var valoracionMeriendaHCarbonos = 0;
                var valoracionMeriendaLipidos = 0;
                var cantidadRecetasMerienda = 0;
                var valoracionCenaCalorias = 0;
                var valoracionCenaProteinas = 0;
                var valoracionCenaHCarbonos = 0;
                var valoracionCenaLipidos = 0;
                var cantidadRecetasCena = 0;
                var valoracionSinOpcionCalorias = 0;
                var valoracionSinOpcionProteinas = 0;
                var valoracionSinOpcionHCarbonos = 0;
                var valoracionSinOpcionLipidos = 0;
                var cantidadRecetasSinOpcion = 0;

                const images = {
                  'Aperitivo': 'src/assets/images/opcionesPlato/aperitivoPlato.jpg',
                  'Primero': 'src/assets/images/opcionesPlato/primerPlato.jpg',
                  'Segundo': 'src/assets/images/opcionesPlato/segundoPlato.jpg',
                  'Guarnición': 'src/assets/images/opcionesPlato/guarnicionPlato.jpg',
                  'Postre': 'src/assets/images/opcionesPlato/postrePlato.jpg',
                  'Bebida': 'src/assets/images/opcionesPlato/bebidaPlato.jpg',
                  'Pan': 'src/assets/images/opcionesPlato/panPlato.jpg',
                  'Plato Único': 'src/assets/images/opcionesPlato/unicoPlato.jpg',
                }

                const valoracionNutricional = (kcal, prot, carb, lipi) => {
                  return 'Energía(Kcal): ' + kcal + '  Proteínas(g): ' + prot + '  H.Carbono(g): ' + carb + '  Lípidos(g): ' + lipi;
                }
                for (let i = 0; i < listaRecetasMenuSelected.length; i++) {

                  if (listaRecetasMenuSelected[i].INGESTA === 'Desayuno') {
                    valoracionDesayunoCalorias = Number(Number(valoracionDesayunoCalorias) + Number(listaRecetasMenuSelected[i].CALORIAS)).toFixed(2);
                    valoracionDesayunoProteinas = Number(Number(valoracionDesayunoProteinas) + Number(listaRecetasMenuSelected[i].PROTEINAS)).toFixed(2);
                    valoracionDesayunoHCarbonos = Number(Number(valoracionDesayunoHCarbonos) + Number(listaRecetasMenuSelected[i].HIDRATOS_C)).toFixed(2);
                    valoracionDesayunoLipidos = Number(Number(valoracionDesayunoLipidos) + Number(listaRecetasMenuSelected[i].LIPIDOS)).toFixed(2);
                    cantidadRecetasDesayuno++;

                    const kcal = (valoracionDesayunoCalorias / cantidadRecetasDesayuno).toFixed(2);
                    const prot = (valoracionDesayunoProteinas / cantidadRecetasDesayuno).toFixed(2);
                    const carb = (valoracionDesayunoHCarbonos / cantidadRecetasDesayuno).toFixed(2);
                    const lipi = (valoracionDesayunoLipidos / cantidadRecetasDesayuno).toFixed(2);
                    if (recetasMenuFechaServicioDesayuno.length === 0) {
                      recetasMenuFechaServicioDesayuno.push({
                        "catalogo": 1,
                        "codigoReceta": -1,
                        "title": 'DESAYUNO',
                        "imagen": 'src/assets/images/opcionesPlato/imagenBlanco.jpg',
                        "alt": valoracionNutricional(kcal, prot, carb, lipi),
                        "description": valoracionNutricional(kcal, prot, carb, lipi),
                      });
                    }

                    recetasMenuFechaServicioDesayuno.push({
                      "catalogo": listaRecetasMenuSelected[i].CATALOGO,
                      "codigoReceta": listaRecetasMenuSelected[i].CODIGO_RECETA,
                      "title": listaRecetasMenuSelected[i].NCOMERCIAL_RECETA,
                      "imagen": 'src/assets/images/opcionesPlato/desayunoPlato.jpg',
                      "alt": listaRecetasMenuSelected[i].OPCION,
                      "description": listaRecetasMenuSelected[i].OPCION,
                    });

                    recetasMenuFechaServicioDesayuno[0].alt = valoracionNutricional(kcal, prot, carb, lipi);
                    recetasMenuFechaServicioDesayuno[0].description = valoracionNutricional(kcal, prot, carb, lipi);

                  } else if (listaRecetasMenuSelected[i].INGESTA === 'Media Mañana') {
                    valoracionMediaMCalorias = Number(Number(valoracionMediaMCalorias) + Number(listaRecetasMenuSelected[i].CALORIAS)).toFixed(2);
                    valoracionMediaMProteinas = Number(Number(valoracionMediaMProteinas) + Number(listaRecetasMenuSelected[i].PROTEINAS)).toFixed(2);
                    valoracionMediaMHCarbonos = Number(Number(valoracionMediaMHCarbonos) + Number(listaRecetasMenuSelected[i].HIDRATOS_C)).toFixed(2);
                    valoracionMediaMLipidos = Number(Number(valoracionMediaMLipidos) + Number(listaRecetasMenuSelected[i].LIPIDOS)).toFixed(2);
                    cantidadRecetasMediaM++;

                    const kcal = (valoracionMediaMCalorias / cantidadRecetasMediaM).toFixed(2);
                    const prot = (valoracionMediaMProteinas / cantidadRecetasMediaM).toFixed(2);
                    const carb = (valoracionMediaMHCarbonos / cantidadRecetasMediaM).toFixed(2);
                    const lipi = (valoracionMediaMLipidos / cantidadRecetasMediaM).toFixed(2);
                    if (recetasMenuFechaServicioMediaMannana.length === 0) {
                      recetasMenuFechaServicioMediaMannana.push({
                        "catalogo": 1,
                        "codigoReceta": -2,
                        "title": 'MEDIA MAÑANA',
                        "imagen": 'src/assets/images/opcionesPlato/imagenBlanco.jpg',
                        "alt": valoracionNutricional(kcal, prot, carb, lipi),
                        "description": valoracionNutricional(kcal, prot, carb, lipi),
                      });
                    }

                    recetasMenuFechaServicioMediaMannana.push({
                      "catalogo": listaRecetasMenuSelected[i].CATALOGO,
                      "codigoReceta": listaRecetasMenuSelected[i].CODIGO_RECETA,
                      "title": listaRecetasMenuSelected[i].NCOMERCIAL_RECETA,
                      "imagen": 'src/assets/images/opcionesPlato/mediaMannana.jpg',
                      "alt": listaRecetasMenuSelected[i].OPCION,
                      "description": listaRecetasMenuSelected[i].OPCION,
                    });

                    recetasMenuFechaServicioMediaMannana[0].alt = valoracionNutricional(kcal, prot, carb, lipi);
                    recetasMenuFechaServicioMediaMannana[0].description = valoracionNutricional(kcal, prot, carb, lipi);

                  } else if (listaRecetasMenuSelected[i].INGESTA === 'Comida') {
                    valoracionComidaCalorias = Number(Number(valoracionComidaCalorias) + Number(listaRecetasMenuSelected[i].CALORIAS)).toFixed(2);
                    valoracionComidaProteinas = Number(Number(valoracionComidaProteinas) + Number(listaRecetasMenuSelected[i].PROTEINAS)).toFixed(2);
                    valoracionComidaHCarbonos = Number(Number(valoracionComidaHCarbonos) + Number(listaRecetasMenuSelected[i].HIDRATOS_C)).toFixed(2);
                    valoracionComidaLipidos = Number(Number(valoracionComidaLipidos) + Number(listaRecetasMenuSelected[i].LIPIDOS)).toFixed(2);
                    cantidadRecetasComida++;

                    if (recetasMenuFechaServicioComida.length === 0) {
                      recetasMenuFechaServicioComida.push({
                        "catalogo": 1,
                        "codigoReceta": -3,
                        "title": 'COMIDA',
                        "imagen": 'src/assets/images/opcionesPlato/imagenBlanco.jpg',
                        "alt": valoracionNutricional(valoracionComidaCalorias, valoracionComidaProteinas, valoracionComidaHCarbonos, valoracionComidaLipidos),
                        "description": valoracionNutricional(valoracionComidaCalorias, valoracionComidaProteinas, valoracionComidaHCarbonos, valoracionComidaLipidos),
                      });
                    }

                    let image = 'src/assets/images/opcionesPlato/sinOpcionPlato.jpg';
                    if (listaRecetasMenuSelected[i].OPCION in images) {
                      image = images[listaRecetasMenuSelected[i].OPCION]
                    }

                    recetasMenuFechaServicioComida.push({
                      "catalogo": listaRecetasMenuSelected[i].CATALOGO,
                      "codigoReceta": listaRecetasMenuSelected[i].CODIGO_RECETA,
                      "title": listaRecetasMenuSelected[i].NCOMERCIAL_RECETA,
                      "imagen": image,
                      "alt": listaRecetasMenuSelected[i].OPCION,
                      "description": listaRecetasMenuSelected[i].OPCION,
                    });

                    recetasMenuFechaServicioComida[0].alt = valoracionNutricional(valoracionComidaCalorias, valoracionComidaProteinas, valoracionComidaHCarbonos, valoracionComidaLipidos);
                    recetasMenuFechaServicioComida[0].description = valoracionNutricional(valoracionComidaCalorias, valoracionComidaProteinas, valoracionComidaHCarbonos, valoracionComidaLipidos);

                  } else if (listaRecetasMenuSelected[i].INGESTA === 'Merienda') {
                    valoracionMeriendaCalorias = Number(Number(valoracionMeriendaCalorias) + Number(listaRecetasMenuSelected[i].CALORIAS)).toFixed(2);
                    valoracionMeriendaProteinas = Number(Number(valoracionMeriendaProteinas) + Number(listaRecetasMenuSelected[i].PROTEINAS)).toFixed(2);
                    valoracionMeriendaHCarbonos = Number(Number(valoracionMeriendaHCarbonos) + Number(listaRecetasMenuSelected[i].HIDRATOS_C)).toFixed(2);
                    valoracionMeriendaLipidos = Number(Number(valoracionMeriendaLipidos) + Number(listaRecetasMenuSelected[i].LIPIDOS)).toFixed(2);
                    cantidadRecetasMerienda++;

                    const kcal = (valoracionMeriendaCalorias / cantidadRecetasMerienda).toFixed(2);
                    const prot = (valoracionMeriendaProteinas / cantidadRecetasMerienda).toFixed(2);
                    const carb = (valoracionMeriendaHCarbonos / cantidadRecetasMerienda).toFixed(2);
                    const lipi = (valoracionMeriendaLipidos / cantidadRecetasMerienda).toFixed(2);
                    if (recetasMenuFechaServicioMerienda.length === 0) {
                      recetasMenuFechaServicioMerienda.push({
                        "catalogo": 1,
                        "codigoReceta": -4,
                        "title": 'MERIENDA',
                        "imagen": 'src/assets/images/opcionesPlato/imagenBlanco.jpg',
                        "alt": valoracionNutricional(kcal, prot, carb, lipi),
                        "description": valoracionNutricional(kcal, prot, carb, lipi),
                      });
                    }

                    recetasMenuFechaServicioMerienda.push({
                      "catalogo": listaRecetasMenuSelected[i].CATALOGO,
                      "codigoReceta": listaRecetasMenuSelected[i].CODIGO_RECETA,
                      "title": listaRecetasMenuSelected[i].NCOMERCIAL_RECETA,
                      "imagen": 'src/assets/images/opcionesPlato/mediaMannana.jpg',
                      "alt": listaRecetasMenuSelected[i].OPCION,
                      "description": listaRecetasMenuSelected[i].OPCION,
                    });

                    recetasMenuFechaServicioMerienda[0].alt = valoracionNutricional(kcal, prot, carb, lipi);
                    recetasMenuFechaServicioMerienda[0].description = valoracionNutricional(kcal, prot, carb, lipi);

                  } else if (listaRecetasMenuSelected[i].INGESTA === 'Cena') {
                    valoracionCenaCalorias = Number(Number(valoracionCenaCalorias) + Number(listaRecetasMenuSelected[i].CALORIAS)).toFixed(2);
                    valoracionCenaProteinas = Number(Number(valoracionCenaProteinas) + Number(listaRecetasMenuSelected[i].PROTEINAS)).toFixed(2);
                    valoracionCenaHCarbonos = Number(Number(valoracionCenaHCarbonos) + Number(listaRecetasMenuSelected[i].HIDRATOS_C)).toFixed(2);
                    valoracionCenaLipidos = Number(Number(valoracionCenaLipidos) + Number(listaRecetasMenuSelected[i].LIPIDOS)).toFixed(2);
                    cantidadRecetasCena++;

                    const kcal = (valoracionCenaCalorias / cantidadRecetasCena).toFixed(2);
                    const prot = (valoracionCenaProteinas / cantidadRecetasCena).toFixed(2);
                    const carb = (valoracionCenaHCarbonos / cantidadRecetasCena).toFixed(2);
                    const lipi = (valoracionCenaLipidos / cantidadRecetasCena).toFixed(2);
                    if (recetasMenuFechaServicioCena.length === 0) {
                      recetasMenuFechaServicioCena.push({
                        "catalogo": 1,
                        "codigoReceta": -5,
                        "title": 'CENA',
                        "imagen": 'src/assets/images/opcionesPlato/imagenBlanco.jpg',
                        "alt": valoracionNutricional(kcal, prot, carb, lipi),
                        "description": valoracionNutricional(kcal, prot, carb, lipi),
                      });
                    }

                    let image = 'src/assets/images/opcionesPlato/sinOpcionPlato.jpg';
                    if (listaRecetasMenuSelected[i].OPCION in images) {
                      image = images[listaRecetasMenuSelected[i].OPCION]
                    }

                    recetasMenuFechaServicioCena.push({
                      "catalogo": listaRecetasMenuSelected[i].CATALOGO,
                      "codigoReceta": listaRecetasMenuSelected[i].CODIGO_RECETA,
                      "title": listaRecetasMenuSelected[i].NCOMERCIAL_RECETA,
                      "imagen": image,
                      "alt": listaRecetasMenuSelected[i].OPCION,
                      "description": listaRecetasMenuSelected[i].OPCION,
                    });

                    recetasMenuFechaServicioCena[0].alt = valoracionNutricional(kcal, prot, carb, lipi);
                    recetasMenuFechaServicioCena[0].description = valoracionNutricional(kcal, prot, carb, lipi);

                  } else {
                    valoracionSinOpcionCalorias = Number(Number(valoracionSinOpcionCalorias) + Number(listaRecetasMenuSelected[i].CALORIAS)).toFixed(2);
                    valoracionSinOpcionProteinas = Number(Number(valoracionSinOpcionProteinas) + Number(listaRecetasMenuSelected[i].PROTEINAS)).toFixed(2);
                    valoracionSinOpcionHCarbonos = Number(Number(valoracionSinOpcionHCarbonos) + Number(listaRecetasMenuSelected[i].HIDRATOS_C)).toFixed(2);
                    valoracionSinOpcionLipidos = Number(Number(valoracionSinOpcionLipidos) + Number(listaRecetasMenuSelected[i].LIPIDOS)).toFixed(2);
                    cantidadRecetasSinOpcion++;

                    const kcal = (valoracionSinOpcionCalorias / cantidadRecetasSinOpcion).toFixed(2);
                    const prot = (valoracionSinOpcionProteinas / cantidadRecetasSinOpcion).toFixed(2);
                    const carb = (valoracionSinOpcionHCarbonos / cantidadRecetasSinOpcion).toFixed(2);
                    const lipi = (valoracionSinOpcionLipidos / cantidadRecetasSinOpcion).toFixed(2);
                    if (recetasMenuFechaServicioSinOpcion.length === 0) {
                      recetasMenuFechaServicioComida.push({
                        "catalogo": 1,
                        "codigoReceta": -6,
                        "title": 'SIN OPCIÓN',
                        "imagen": 'src/assets/images/opcionesPlato/imagenBlanco.jpg',
                        "alt": valoracionNutricional(kcal, prot, carb, lipi),
                        "description": valoracionNutricional(kcal, prot, carb, lipi),
                      });
                    }

                    recetasMenuFechaServicioSinOpcion.push({
                      "catalogo": listaRecetasMenuSelected[i].CATALOGO,
                      "codigoReceta": listaRecetasMenuSelected[i].CODIGO_RECETA,
                      "title": listaRecetasMenuSelected[i].NCOMERCIAL_RECETA,
                      "imagen": 'src/assets/images/opcionesPlato/sinOpcionPlato.jpg',
                      "alt": listaRecetasMenuSelected[i].INGESTA,
                      "description": listaRecetasMenuSelected[i].INGESTA,
                    });

                    recetasMenuFechaServicioSinOpcion[0].alt = valoracionNutricional(kcal, prot, carb, lipi);
                    recetasMenuFechaServicioSinOpcion[0].description = valoracionNutricional(kcal, prot, carb, lipi);
                  }
                }

                recetasMenuFechaServicio = recetasMenuFechaServicioDesayuno.concat(recetasMenuFechaServicioMediaMannana).concat(recetasMenuFechaServicioComida).concat(recetasMenuFechaServicioMerienda).concat(recetasMenuFechaServicioCena).concat(recetasMenuFechaServicioSinOpcion);

                recetasMenuFechaServicio.forEach(element => {
                  if (element.title === "COMIDA") {
                    element.alt = valoracionNutricional(
                      Number(valoracionComidaCalorias).toFixed(2),
                      Number(valoracionComidaProteinas / cantidadRecetasComida).toFixed(2),
                      Number(valoracionComidaHCarbonos / cantidadRecetasComida).toFixed(2),
                      Number(valoracionComidaLipidos / cantidadRecetasComida).toFixed(2)
                    );
                    element.description = valoracionNutricional(
                      Number(valoracionComidaCalorias).toFixed(2),
                      Number(valoracionComidaProteinas / cantidadRecetasComida).toFixed(2),
                      Number(valoracionComidaHCarbonos / cantidadRecetasComida).toFixed(2),
                      Number(valoracionComidaLipidos / cantidadRecetasComida).toFixed(2)
                    );
                  }
                });

                this.setState({
                  visibleDrawerlVisualizarMenuDiario: true,
                  nombreCentro: this.state.selectedRows[0].NOMBRE,
                  dataListaRecetasMenuSelected: recetasMenuFechaServicio,
                });

              } else if (listaRecetas.status === 'success' && listaRecetas.message.length === 0) {

                this.setState({
                  visibleDrawerlVisualizarMenuDiario: true,
                  nombreCentro: this.state.selectedRows[0].NOMBRE,
                  dataListaRecetasMenuSelected: recetasMenuFechaServicio,
                });

                Modal.error({
                  title: 'Error',
                  content: 'El menú seleccionado no tiene recetas asociadas.',
                });
              }
              else {
                this.setState({
                  visibleDrawerlVisualizarMenuDiario: true,
                  nombreCentro: this.state.selectedRows[0].NOMBRE,
                  dataListaRecetasMenuSelected: recetasMenuFechaServicio,
                });

                Modal.error({
                  title: 'Error',
                  content: 'Ha ocurrido el siguiente error: ' + listaRecetas.message,
                  onOk() {

                  }
                });

              }
            },
              error => this.setState({ error, loading: true })
            )
   

        } catch (e) {
          console.log(e);

          Modal.error({
            title: 'Error',
            content: 'Ha ocurrido el siguiente error al Recuperar las recetas del Menú seleccionado: ' + e + '. Contactar con el administrador.',
          });

        }
      }
    }
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    //this.setState({ selectedRows: [], selectedRowKeys: [] });
    console.log('selectedRows changed: ', selectedRows);
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRows, selectedRowKeys });
  }

  recuperarMenusCentroMensual = (catalogo, centro, diaServicio) => {

    this.setState({
      loadingSpingMenu: true,
      textoLoadingSpingMenu: 'Cargando datos...',
      diaServicioModal: diaServicio.format(dateFormat),
    });

    try {

        database.obtenerMenusMensualesCentros(catalogo, centro, diaServicio).then(valor => {

          let datos = valor.response;
          console.log(datos);

          const datosTablaMenus = [];

          if (datos.status === 'success' && datos.message.length != 0) {

            for (let i = 0; i < datos.message.length; i++) {
              datosTablaMenus.push({
                MENU_ID: datos.message[i].MENU_ID,
                KEY: datos.message[i].MENU_ID.toString() + i,
                NOMBRE_MENU: datos.message[i].NOMBRE_MENU,
                TIPO_MENU: datos.message[i].TIPO_MENU,
                TIPO_DIETA: datos.message[i].TIPO_DIETA,
                NOMBRE: datos.message[i].CODIGO_CENTRO + '.' + datos.message[i].NOMBRE,
                FECHA_SERVICIO: datos.message[i].FECHA_SERVICIO,
                DIA_SEMANA: datos.message[i].DIA_SEMANA,
                SERVICIO_FINDE: datos.message[i].SERVICIO_FINDE
              });
            }

            const rowSelection = {
              onChange: this.onSelectChange,
            };

            ReactDOM.render(
              <ConfigProvider renderEmpty={customizeRenderEmpty}>

                <Tooltip placement="topLeft" title={textoMostrarMenuDiario}>
                  <Button size="default" style={{ boxShadow: '0 0 0 0 rgba(0,123,255,.25)', borderColor: '#fff', border: 0, backgroundColor: '#fff' }} onClick={this.mostarMenuDiarioRecetas} type="dashed"><FundViewOutlined /></Button>
                </Tooltip>

                <Table rowSelection={{ type: 'radio', ...rowSelection }} rowKey={record => record.KEY} size={"small"} columns={columnsTablaMenus} dataSource={datosTablaMenus} scroll={{ x: 1000, y: 600 }} bordered />
              </ConfigProvider>

              , document.getElementById('infoBusquedaPorMenu'));

            this.setState({
              loading: false,
              loadingSpingMenu: false,
              textoLoadingSpingBuscarMenu: ''
            });

          } else if (datos.status === 'success' && datos.message.length === 0) {

            this.setState({
              loading: false,
              loadingSpingMenu: false,
              textoLoadingSpingBuscarMenu: ''
            });

            Modal.info({
              title: 'Notificación',
              content: 'El Centro seleccionado no tiene menús para el día de servicio: ' + diaServicio.format(dateFormat) + '.',
              onOk() {

              }
            });

          } else {

            this.setState({
              loading: false,
              loadingSpingMenu: false,
              textoLoadingSpingBuscarMenu: ''
            });

            Modal.error({
              title: 'Error',
              content: 'Ha ocurrido el siguiente error: ' + datos.message,
              onOk() {

              }
            });

          }
        });
      

    } catch (e) {
      console.log(e);

      this.setState({
        loading: false,
        loadingSpingMenu: false,
        textoLoadingSpingBuscarMenu: ''
      });

      Modal.error({
        title: 'Error',
        content: 'Ha ocurrido el siguiente error al Recuperar las recetas del Menú seleccionado: ' + e + '. Contactar con el administrador.',
      });

    }


  }

  /**
   * Cancelar Drawer Visualizar Menú Diario
   */
  handleCancelDrawerVisualizarMenuDiario = () => {
    this.setState({
      visibleDrawerlVisualizarMenuDiario: false,
      widthDrawerMenuDiario: 630,
    });
  }

  /**
  * Cancelar Drawer Visualizar Detalle Receta
  */


  handleonChildrenDrawerCloseDetalleReceta = () => {
    this.setState({
      childrenDrawerDetalleReceta: false,
      widthDrawerMenuDiario: 630,
    });
  }

  /**
   * Visualizar detalle de la receta seleccionada del día del menú.
   */
  // visualizarRecetaSeleccionada = (codReceta) => {
  //   console.log('Detalle receta: ' + codReceta);
  // }

  visualizarRecetaSeleccionada = (codReceta) => {

    console.log('codRecetaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    console.log(codReceta);

    if (codReceta.length === 0) {

      Modal.error({
        title: 'Error',
        content: 'Solo es posible mostrar el detalle de una sola recta y no de varias.',
      });

    } else {
      database.getRecetasAdicionalesIngredientesDetalle(codReceta)
        .then(res => {

          const dataRecetasAdicional = [];
          const resultadoRecetasAdicIngre = res.response;

          console.log('resultadoRecetasAdicIngre: ' + resultadoRecetasAdicIngre);

          if (resultadoRecetasAdicIngre.length >= 1) {

            for (let x = 0; x < resultadoRecetasAdicIngre.length; x++) {
              dataRecetasAdicional.push({
                codigoReceta: resultadoRecetasAdicIngre[x].CODIGO,
                nombreReceta: resultadoRecetasAdicIngre[x].NOMBRE,
                racion: (resultadoRecetasAdicIngre[x].RACIONES / resultadoRecetasAdicIngre.length),
              });
            }
            console.log('Dentro for: ' + dataRecetasAdicional);

            this.setState({
              recetasAdicionalIngDetalle: dataRecetasAdicional,
              recetaNoAdicionales: '',
            });

          } else {
            this.setState({
              recetasAdicionalIngDetalle: [],
              recetaNoAdicionales: 'SIN RECETAS ADICIONALES',
            });
          }
        });

      database.obtenerRecetasIngredientesFichasTecnicas(codReceta.toString())
        .then(res => {

          const resultadoRecetaIngredientesDetalle = res.response;

          console.log('resultadoRecetaIngredientesDetalle');
          console.log(resultadoRecetaIngredientesDetalle);

          if (resultadoRecetaIngredientesDetalle.status === 'success') {

            var resultadoRecetaIngredientesDetalleFT = resultadoRecetaIngredientesDetalle.message;

            console.log('resultadoRecetaIngredientesDetalleFT-------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
            console.log(resultadoRecetaIngredientesDetalleFT);

            if (resultadoRecetaIngredientesDetalleFT.length != 0) {

              const ingredientesReceta = [];

              for (let i = 0; i < resultadoRecetaIngredientesDetalleFT.length; i++) {
                ingredientesReceta.push({
                  desc_producto: resultadoRecetaIngredientesDetalleFT[i].DESC_PRODUCTO,
                  cantidad: resultadoRecetaIngredientesDetalleFT[i].CANTIDAD + ' (g)',
                  cod_producto: resultadoRecetaIngredientesDetalleFT[i].COD_PRODUCTO,
                  proveedor: resultadoRecetaIngredientesDetalleFT[i].NOM_PROVEEVOR,
                  ruta_ft: resultadoRecetaIngredientesDetalleFT[i].RUTA,
                });
              }

              var kcalPorRacion = Number(resultadoRecetaIngredientesDetalleFT[0].CALORIAS.toFixed(2));
              var kjPorRacion = Number(((1.000001104 * kcalPorRacion) / 0.239006).toFixed(2));
              var kcalPor100 = Number(((kcalPorRacion / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2));
              var kjPor100 = Number(((kjPorRacion / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2));

              const valoresNutricionalesReceta = [
                {
                  key: '1',
                  descripcionNutrientes: 'Kj / Kcal',
                  por100G: kjPor100 + ' / ' + kcalPor100,
                  porRacion: kjPorRacion + ' / ' + kcalPorRacion,
                },
                {
                  key: '2',
                  descripcionNutrientes: 'Grasas(g)',
                  por100G: Number(((resultadoRecetaIngredientesDetalleFT[0].LIPIDOS / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2)),
                  porRacion: Number(resultadoRecetaIngredientesDetalleFT[0].LIPIDOS.toFixed(2)),
                },
                {
                  key: '3',
                  descripcionNutrientes: 'Ácidos grasos saturados(g)',
                  por100G: Number(((resultadoRecetaIngredientesDetalleFT[0].AGS / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2)),
                  porRacion: Number(resultadoRecetaIngredientesDetalleFT[0].AGS.toFixed(2)),
                },
                {
                  key: '4',
                  descripcionNutrientes: 'Hidratos de Carbono (g)',
                  por100G: Number(((resultadoRecetaIngredientesDetalleFT[0].HIDRATOS_C / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2)),
                  porRacion: Number(resultadoRecetaIngredientesDetalleFT[0].HIDRATOS_C.toFixed(2)),
                },
                {
                  key: '5',
                  descripcionNutrientes: 'Azúcares (g)',
                  por100G: Number(((resultadoRecetaIngredientesDetalleFT[0].AZUCARES / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2)),
                  porRacion: Number(resultadoRecetaIngredientesDetalleFT[0].AZUCARES.toFixed(2)),
                },
                {
                  key: '6',
                  descripcionNutrientes: 'Proteínas(g)',
                  por100G: Number(((resultadoRecetaIngredientesDetalleFT[0].PROTEINAS / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2)),
                  porRacion: Number(resultadoRecetaIngredientesDetalleFT[0].PROTEINAS.toFixed(2)),
                },
                {
                  key: '7',
                  descripcionNutrientes: 'Sal(g)',
                  por100G: Number(((resultadoRecetaIngredientesDetalleFT[0].SAL / resultadoRecetaIngredientesDetalleFT[0].PESOT) * 100).toFixed(2)),
                  porRacion: Number(resultadoRecetaIngredientesDetalleFT[0].SAL.toFixed(2)),
                },
              ];

              this.setState({
                ingredientesRecetasDetalle: ingredientesReceta,
                infoNutricionalReceta: valoresNutricionalesReceta,
                nomRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].NOM_RECETA,
                racionesRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].RACIONES,
                familiaRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].DESC_FAMILIA,
                comensalRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].DESC_COMENSAL,
                dietaRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].DIETA,
                caloriasRecetaDetalle: Number((resultadoRecetaIngredientesDetalleFT[0].CALORIAS).toFixed(2)),
                pesoBrutoRecetaDetalle: Number((resultadoRecetaIngredientesDetalleFT[0].PESOT).toFixed(2)),
                aguaRecetaDetalle: Number((resultadoRecetaIngredientesDetalleFT[0].AGUA).toFixed(2)),
                codRecetasDetalle: codReceta,
                glutenRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].GLUTEN === 'NO' ? sinAlergeno : conAlergeno,
                styleDescGlutenRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].GLUTEN != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                crustaceosRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].CRUSTACEOS === 'NO' ? sinAlergeno : conAlergeno,
                styleDescCrustaceosRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].CRUSTACEOS != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                huevoRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].HUEVO === 'NO' ? sinAlergeno : conAlergeno,
                styleDescHuevoRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].HUEVO != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                pescadoRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].PESCADO === 'NO' ? sinAlergeno : conAlergeno,
                styleDescPescadoRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].PESCADO != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                cacahuetesRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].CACAHUETES === 'NO' ? sinAlergeno : conAlergeno,
                styleDescCacahuetesRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].CACAHUETES != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                sojaRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].SOJA === 'NO' ? sinAlergeno : conAlergeno,
                styleDescSojaRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].SOJA != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                lecheRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].LECHE === 'NO' ? sinAlergeno : conAlergeno,
                styleDescLecheRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].LECHE != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                frutos_cascarasRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].FRUTOS_CASCARAS === 'NO' ? sinAlergeno : conAlergeno,
                styleDescFrutos_cascarasRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].FRUTOS_CASCARAS != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                apioRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].APIO === 'NO' ? sinAlergeno : conAlergeno,
                styleDescApioRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].APIO != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                mostazaRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].MOSTAZA === 'NO' ? sinAlergeno : conAlergeno,
                styleDescMostazaRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].MOSTAZA != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                sesamoRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].SESAMO === 'NO' ? sinAlergeno : conAlergeno,
                styleDescSesamoRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].SESAMO != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                dxo_azufreRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].DXO_AZUFRE === 'NO' ? sinAlergeno : conAlergeno,
                styleDescDxo_azufreRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].DXO_AZUFRE != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                altramucesRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].ALTRAMUCES === 'NO' ? sinAlergeno : conAlergeno,
                styleDescAltramucesRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].ALTRAMUCES != 'TRAZAS' ? styleDescNoTrazas : styleDescTrazas,
                moluscosRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].MOLUSCOS === 'NO' ? sinAlergeno : conAlergeno,
                styleDescMoluscosRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].MOLUSCOS === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
                alergenosIncompletoDetalle: resultadoRecetaIngredientesDetalleFT[0].DECLARACION_ALERGENOS,
                elaboracionRecetaDetalle: resultadoRecetaIngredientesDetalleFT[0].ELABORACION,
                childrenDrawerDetalleReceta: true,
                widthDrawerMenuDiario: 830,
              });

              //   console.log('Estado' + this.staterecetasAdicionalIngDetalle);

            } else {
              Modal.error({
                title: 'Error',
                content: 'La receta no tiene ingredientes asociados.',
              });
            }

          } else {

            Modal.error({
              title: 'Error',
              content: resultadoRecetaIngredientesDetalleFT.message,
            });

          }
        });
    }
  }

  render() {

    const { selectedRows, selectedRowKeys } = this.state;

    const pStyle = {
      fontSize: 16,
      lineHeight: '24px',
      display: 'block',
      marginBottom: 16,
    };

    const columnsRecetasAdicional = [
      {
        title: 'Código Receta',
        dataIndex: 'codigoReceta',
        key: 'codigoReceta',
        ellipsis: true,
        width: 125,
      },
      {
        title: 'Nombre Receta',
        dataIndex: 'nombreReceta',
        key: 'nombreReceta',
        ellipsis: true,
      },
      {
        title: 'Raciones',
        dataIndex: 'racion',
        key: 'racion',
        align: 'center',
        ellipsis: true,
      },
    ];

    const columnsInfoNutricional = [
      {
        title: 'Descripción Nutrientes',
        dataIndex: 'descripcionNutrientes',
        key: 'descripcionNutrientes',
        ellipsis: true,
      },
      {
        title: 'Por 100g',
        dataIndex: 'por100G',
        key: 'por100G',
        align: 'center',
        ellipsis: true,
      },
      {
        title: 'Por Ración',
        dataIndex: 'porRacion',
        key: 'porRacion',
        align: 'center',
        ellipsis: true,
      },
    ];

    const DescriptionItem = ({ title, content }) => (
      <div
        className="site-description-item-profile-wrapper"
        style={{
          fontSize: 14,
          lineHeight: '22px',
          marginBottom: 7,
        }}
      >
        <p
          className="site-description-item-profile-p"
          style={{
            marginRight: 8,
            display: 'inline-block',
          }}
        >
          {title}:
        </p>
        {content}
      </div>
    );

    //  const { startValue, endValue, endOpen, startOpen, listado } = this.state;

    const { valueFecha, cargandoCentros, loadingSpingMenu, textoLoadingSpingMenu, textoLoadingSpingBuscarMenu,
      visibleDrawerlVisualizarMenuDiario, diaServicioModal, nombreCentro, dataListaRecetasMenuSelected } = this.state;

    const dateFormat = "DD/MM/YYYY";

    // const { getFieldDecorator } = this.props.form;

    const { Option } = Select;

    let resultadoCentros = [];
    for (let i = 0; i < this.state.listaCentrosUsuarios.length; i++) {
      //resultadoCentros.push({ value: `${this.state.listaCentrosUsuarios[i].CATALOGO}` + `.` + `${this.state.listaCentrosUsuarios[i].COD_CENTRO}`, label: `${this.state.listaCentrosUsuarios[i].COD_CENTRO}` + `.` + `${this.state.listaCentrosUsuarios[i].DESC_CENTRO}` });
      resultadoCentros.push(<Select.Option key={this.state.listaCentrosUsuarios[i].CATALOGO + '.' + this.state.listaCentrosUsuarios[i].COD_CENTRO} label={this.state.listaCentrosUsuarios[i].COD_CENTRO + '.' + this.state.listaCentrosUsuarios[i].DESC_CENTRO} title={this.state.listaCentrosUsuarios[i].COD_CENTRO + '.' + this.state.listaCentrosUsuarios[i].DESC_CENTRO}>
        {this.state.listaCentrosUsuarios[i].COD_CENTRO + '.' + this.state.listaCentrosUsuarios[i].DESC_CENTRO}
      </Select.Option>);
    };

    const BuscarPorMenuForm = () => {

      const [form] = Form.useForm();

      var inicializarValoresFormBuscarMenu = {
        diaServicio: moment(Date.now()),
        //diaServicio: moment("18/09/2019", dateFormat),
      };

      const onFinishBuscarPorMenu = (values) => {


        console.log('Received values of form: ', values);
        //var diaServicio = values.diaServicio.format(dateFormat);
        var diaServicio = values.diaServicio;
        var catalogoCentro = values.selectCentro.split(".");
        var catalogo = catalogoCentro[0];
        var centro = catalogoCentro[1];//catalogoCentro[1].substr(0, 4);

        console.log('diaServicio: ', diaServicio);
        console.log('catalogoCentro: ', catalogoCentro);
        console.log('catalogo: ', catalogo);
        console.log('centro: ', centro);

        setTimeout(() => {
          this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            // datosTablaMenusEstado: []
          });
          this.recuperarMenusCentroMensual(catalogo, centro, diaServicio);
        }, 1000);


      };

      return (
        <Form name="advanced_search" className="ant-advanced-search-form" onFinish={onFinishBuscarPorMenu} form={form} initialValues={inicializarValoresFormBuscarMenu}>

          <Row gutter={24}>
            <Col span={6} key={1}>
              <Form.Item
                hasFeedback
                name="selectCentro"
                rules={[{ required: true, message: 'Campo obligatorio.' },]}>
                <Select
                  showSearch
                  placeholder="Seleccionar Centro"
                  optionFilterProp="children"
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {resultadoCentros}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6} key={2}>
              <Form.Item
                hasFeedback
                name="diaServicio"
                rules={[{ required: true, message: 'Campo requerido.' }]}
              >
                <DatePicker locale={locale} format={dateFormat} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={24} style={{ textAlign: 'right' }} key={3}>
              <Form.Item>

                <Button style={{ margin: '0 8px', }} key="back" onClick={() => { form.resetFields(); }}>
                  Cancelar
                </Button>

                <Button type="primary" htmlType="submit" style={{ margin: '0 8px', }} >
                  Aceptar
                </Button>
              </Form.Item>
            </Col>

          </Row>

        </Form>
      );
    };

    return (
      <div>
        <Spin spinning={loadingSpingMenu} delay={500} tip={textoLoadingSpingMenu}>

          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div>
              <BuscarPorMenuForm />
              <div id="infoBusquedaPorMenu">


                {/*
                    Se muestra los resultados de la búsqueda de los Menús.
                   */}
              </div>

              {/* <Modal
            visible={visibleDrawerlVisualizarMenuDiario}
            //title="Cualquier"
           title={'Centro: ' + nombreCentro} 
            width={550}
            footer={null}
            closable={true}
            style={{ top: 20 }}
            onCancel={this.handleCancelDrawerVisualizarMenuDiario}
          >
             
                <List
                  size="small"
                  header={<div>Menú del día {diaServicioModal}</div>}
                  bordered={false}
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item.imagen} alt={item.alt}/>}
                        title={<a href="https://ant.design">{item.title}</a>}
                        description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                      />
                    </List.Item>
                  )}
                />
          </Modal> */}

              <Drawer
                title={<Title level={4} >Centro: {nombreCentro}</Title>}
                width={this.state.widthDrawerMenuDiario}
                closable={false}
                onClose={this.handleCancelDrawerVisualizarMenuDiario}
                visible={this.state.visibleDrawerlVisualizarMenuDiario}
              >
                {/*<List
              size="small"
              header={<div>Menú del día {diaServicioModal}</div>}
              bordered={false}
              itemLayout="horizontal"
              dataSource={dataListaRecetasMenuSelected}
              rowKey={record => record.KEY}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.imagen} alt={item.alt} />}
                    title={<a href="#" onClick={this.onClickReceta(item.codigoReceta, item.catalogo)}>{item.title}</a>}
                    //title={<Button type="link" onClick={this.onClickReceta(item.codigoReceta, item.catalogo)}>{item.title}</Button>}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
              <Table onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: event => { this.visualizarRecetaSeleccionada(record.CODIGO_RECETA) }, // click row      
                };
              }} rowKey={record => record.CODIGO_RECETA} size={"small"} columns={columns} dataSource={dataListaRecetasMenuSelected} 
              />  */}
                <Table onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: event => { this.visualizarRecetaSeleccionada(record.codigoReceta) }, // click row      
                  };
                }} pagination={{ defaultPageSize: 10 }} title={() => 'Menú del día ' + diaServicioModal} showHeader={false} rowKey={record => record.codigoReceta} size={"small"} columns={columnsRecetasMenuFechaServicio} dataSource={dataListaRecetasMenuSelected}
                />

                <Drawer
                  title={<Title level={4} >Detalle Receta</Title>}
                  width={630}
                  closable={false}
                  onClose={this.handleonChildrenDrawerCloseDetalleReceta}
                  visible={this.state.childrenDrawerDetalleReceta}
                >

                  <div>

                    <Row id="ant-row1">

                      <Col span={24}>
                        <DescriptionItem title="Nombre" content={this.state.nomRecetaDetalle} />
                      </Col>

                    </Row>
                    <Row>

                      <Col span={8}>
                        <DescriptionItem title="Código" content={this.state.codRecetasDetalle} />
                      </Col>

                      <Col span={16}>
                        <DescriptionItem title="Familia" content={this.state.familiaRecetaDetalle} />
                      </Col>

                    </Row>
                    <Row>

                      <Col span={8}>
                        <DescriptionItem title="Raciones" content={this.state.racionesRecetaDetalle} />
                      </Col>

                      <Col span={8}>
                        <DescriptionItem title="Dieta" content={this.state.dietaRecetaDetalle} />
                      </Col>

                      <Col span={8}>
                        <DescriptionItem title="Comensal" content={this.state.comensalRecetaDetalle} />
                      </Col>

                    </Row>

                    <Row>

                      <Col span={8}>
                        <DescriptionItem title="Calorías" content={this.state.caloriasRecetaDetalle} />
                      </Col>

                      <Col span={8}>
                        <DescriptionItem title="Agua [g]" content={this.state.aguaRecetaDetalle} />
                      </Col>

                      <Col span={8}>
                        <DescriptionItem title="Peso Bruto [g]" content={this.state.pesoBrutoRecetaDetalle} />
                      </Col>

                    </Row>

                    <Divider orientation="left">Ingredientes</Divider>

                    <List
                      size="small"
                      dataSource={this.state.ingredientesRecetasDetalle}
                      renderItem={item => (
                        <List.Item
                          key={item.id}
                        >
                          <List.Item.Meta
                            title={`${item.desc_producto} (${item.cod_producto}) - ${item.cantidad}`}
                            description={item.proveedor}
                          />
                          <Tooltip placement="topLeft" title={textoVisualizarFT}>

                            {
                              item.ruta_ft === null ? '' : <Button href={item.ruta_ft} target="blank" type="link"><EyeOutlined /></Button>
                            }

                          </Tooltip>
                        </List.Item>
                      )}
                    />

                    <Divider orientation="left">Elaboración</Divider>

                    <TextArea value={this.state.elaboracionRecetaDetalle} autoSize disabled />

                    <Divider orientation="left">Declaración de Alérgenos</Divider>

                    <Row>

                      <Col span={24} style={{ color: '#f3000c', marginBottom: 10 }}>
                        <span> {this.state.alergenosIncompletoDetalle === 'INCOMPLETA' ? 'Declaración de alérgenos INCOMPLETA.' : ''}</span>
                      </Col>

                    </Row>

                    <Card bordered={false} style={{ marginBottom: 30 }}>
                      <Card.Grid style={gridStyle}><img style={this.state.glutenRecetaDetalle} alt="example" src="src/assets/images/alergenoGluten.png" /><Meta style={this.state.styleDescGlutenRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.crustaceosRecetaDetalle} alt="example" src="src/assets/images/alergenoCrustaceos.png" /><Meta style={this.state.styleDescCrustaceosRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.huevoRecetaDetalle} alt="example" src="src/assets/images/alergenoHuevos.png" /><Meta style={this.state.styleDescHuevoRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.pescadoRecetaDetalle} alt="example" src="src/assets/images/alergenoPescado.png" /><Meta style={this.state.styleDescPescadoRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.cacahuetesRecetaDetalle} alt="example" src="src/assets/images/alergenoCacahuetes.png" /><Meta style={this.state.styleDescCacahuetesRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.sojaRecetaDetalle} alt="example" src="src/assets/images/alergenoSoja.png" /><Meta style={this.state.styleDescSojaRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.lecheRecetaDetalle} alt="example" src="src/assets/images/alergenoLacteos.png" /><Meta style={this.state.styleDescLecheRecetaDetalle} title="Trazas" /></Card.Grid>

                    </Card>
                    <Card bordered={false} style={{ marginBottom: 30 }}>
                      <Card.Grid style={gridStyle}><img style={this.state.frutos_cascarasRecetaDetalle} alt="example" src="src/assets/images/alergenoFrutaCascara.png" /><Meta style={this.state.styleDescFrutos_cascarasRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.apioRecetaDetalle} alt="example" src="src/assets/images/alergenoApio.png" /><Meta style={this.state.styleDescApioRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.mostazaRecetaDetalle} alt="example" src="src/assets/images/alergenoMostaza.png" /><Meta style={this.state.styleDescMostazaRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.sesamoRecetaDetalle} alt="example" src="src/assets/images/alergenoGranosSesamo.png" /><Meta style={this.state.styleDescSesamoRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.dxo_azufreRecetaDetalle} alt="example" src="src/assets/images/alergenoDxoAzufreSulfitos.png" /><Meta style={this.state.styleDescDxo_azufreRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.altramucesRecetaDetalle} alt="example" src="src/assets/images/alergenoAltramuces.png" /><Meta style={this.state.styleDescAltramucesRecetaDetalle} title="Trazas" /></Card.Grid>
                      <Card.Grid style={gridStyle}><img style={this.state.moluscosRecetaDetalle} alt="example" src="src/assets/images/alergenoMoluscos.png" /><Meta style={this.state.styleDescMoluscosRecetaDetalle} title="Trazas" /></Card.Grid>
                    </Card>

                    <Divider orientation="left">Información Nutricional</Divider>

                    <Table columns={columnsInfoNutricional} dataSource={this.state.infoNutricionalReceta} size={"small"} pagination={false} bordered />

                    <Divider orientation="left">Platos Adicionales</Divider>

                    <Col span={24} style={{ color: '#f3000c', marginBottom: 10 }}>
                      <span> {this.state.recetaNoAdicionales === 'SIN RECETAS ADICIONALES' ? 'Esta receta no tiene platos adicionales.' : ''}</span>
                    </Col>

                    <Table columns={columnsRecetasAdicional} size={"small"} dataSource={this.state.recetasAdicionalIngDetalle} pagination={false} bordered />

                  </div>
                </Drawer>
              </Drawer>
            </div>
          </ConfigProvider>
        </Spin>
      </div>);
  }
}

export default BuscarPorMenu;