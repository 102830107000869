"use strict";
import React from "react"
import { Form, Row, Col, Input, Button, Modal, Select } from "antd"

export const DrawerInsertarDatosEmpresasForm = (props) => {
    const { enviarDatosEmpresas, state, onChildrenDrawerCloseDatosEmpresa, selectCatalogosUsuario } = props

    const [form] = Form.useForm();

    const onFinishInsertarDatosEmpresas = (values) => {
        const { idEmpresa, nameEmpresa, nameSocial, direccion, cif, registroSanitario, selectCatalogo } = values

        if (
            values.nameEmpresa.indexOf("'") != -1 ||
            values.nameSocial.indexOf("'") !== -1 ||
            values.direccion.indexOf("'") !== -1
        ) {
            Modal.error({
                title: "Error",
                content:
                    "Existe al menos un valor con el caracter ' . Por favor cambialo por ´ u otro similar.",
            })

            return
        }

        enviarDatosEmpresas(nameEmpresa, nameSocial, direccion, cif, registroSanitario, idEmpresa, selectCatalogo)
    }

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinishInsertarDatosEmpresas}
            form={form}
        >
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        hasFeedback
                        name="idEmpresa"
                        label="Código Empresa:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el código" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameEmpresa"
                        label="Nombre Empresa:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir nombre empresa" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameSocial"
                        label="Nombre Social:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir nombre social" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="direccion"
                        label="Dirección:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir la dirección" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        hasFeedback
                        name="cif"
                        label="CIF:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el CIF" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        hasFeedback
                        name="registroSanitario"
                        label="Registro Sanitario:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el Reg. San" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="selectCatalogo"
                        label="Catálogo:"
                        rules={[
                            {
                                required: true,
                                message: "Campo obligatorio.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Por favor, seleccionar catálogo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {selectCatalogosUsuario}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={onChildrenDrawerCloseDatosEmpresa}
                            style={{ marginRight: 8 }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state.loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};