import React, { useState, useEffect, useRef } from "react";
import { Table, Input, InputNumber, ConfigProvider, Form } from "antd";
import { DatabaseOutlined } from "@ant-design/icons";
import EditableCell from "../EditableCell";

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <DatabaseOutlined style={{ fontSize: 20 }} />
    <p>Datos No Encontrados</p>
  </div>
);

function DietsTable({ listRecipes, listarDatos, handler, loading, ...props }) {
  const [form] = Form.useForm();
  const [data, setData] = useState(listRecipes);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const cancel = () => {
    setEditingKey("");
  };

  const columns = [
    {
      title: "Detalle Recetas",
      dataIndex: "detalle",
      width: 660,
      children: [
        {
          title: "Código Receta",
          dataIndex: "codigo_receta",
          width: 150,
        },
        {
          title: "Receta",
          dataIndex: "receta",
          width: 400,
        },
        {
          title: "Alérgenos",
          dataIndex: "alergenos",
          width: 500,
        },
        {
          title: "Opción",
          dataIndex: "opcion",
          width: 110,
          editable: true,
        },
        {
          title: "Ingredientes",
          dataIndex: "ingredientes",
          width: 830,
          children: [
            {
              title: "Ingrediente",
              dataIndex: "ingrediente",
              width: 500,
            },
            {
              title: "Proveedor",
              dataIndex: "proveedor",
              width: 200,
            },
            {
              title: "Cantidad (g)",
              dataIndex: "cantidad",
              width: 130,
            },
          ],
        },
      ],
    },
    {
      title: "Dietas",
      dataIndex: "dietas",
      editable: true,
      width: 530,
      children: [
        {
          title: "No. Cubiertos",
          dataIndex: "cubiertos",
          width: 130,
          editable: true,
        },
        {
          title: "Cantidad Cubiertos (g)",
          dataIndex: "cantidad_racion",
          width: 130,
        },
        {
          title: "Menús  (Basal/Dietas)",
          dataIndex: "dieta",
          width: 400,
        },
      ],
    },
  ];

  const mergedColumns = (col) => {
    if (!col.editable) {
      return col;
    }

    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable.toString(),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };

    if (col.children) {
      newCol.children = col.children.map(mergedColumns);
    }

    return newCol;
  };

  return listRecipes.length != 0 ? (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        size="small"
        columns={columns.map(mergedColumns)}
        rowClassName="editable-row"
        loading={loading}
        pagination={{
          onChange: cancel,
        }}
        scroll={{ x: "calc(750px + 50%)", y: 300 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {},
          };
        }}
        {...props}
      />
    </Form>
  ) : (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table columns={columns} />
    </ConfigProvider>
  );
}

export default DietsTable;
