"use strict";
import React, { useEffect } from "react"
import { Form, Row, Col, Input, Button, Select } from "antd"
import { enviarModificarObsEspeciales } from "../functions";

export const DrawerModificarObsEspecialesForm = (props) => {
    const { state, updateStates, onChildrenDrawerCloseModificarObsEspeciales, selectCatalogosUsuario } = props

    const rowsModificarObsEspeciales = state.selectedRowsObsEspeciales
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue({
            nameObsEspeciales: rowsModificarObsEspeciales[0].NOMBRE_OBS_ESPECIALES,
            catalogo: catalogoActualCentro,
        })
    }, [state.selectedRowsObsEspeciales])

    let catalogoActualCentro
    state.listaOptionsCatalogosUsuario.forEach(({ CATALOGO, NOMBRE }) => {

        if (rowsModificarObsEspeciales[0].CATALOGO === CATALOGO) {
            catalogoActualCentro = `${CATALOGO}.${NOMBRE}`
        }
    })

    const mostrarObsEspeciales = {
        nameObsEspeciales: rowsModificarObsEspeciales[0].NOMBRE_OBS_ESPECIALES,
        catalogo: catalogoActualCentro,
    };

    const onFinishActualizarObsEspeciales = (values) => {
        const { nameObsEspeciales, catalogo } = values

        const codigoObsEspeciales =
            rowsModificarObsEspeciales[0].CODIGO_OBS_ESPECIALES.toString();

        const catalogoConPunto = catalogo.indexOf(".");

        const catalog = catalogoConPunto === -1 ? catalogo : catalogo.substring(0, catalogo.indexOf("."))

        enviarModificarObsEspeciales(codigoObsEspeciales, nameObsEspeciales, catalog, updateStates, onChildrenDrawerCloseModificarObsEspeciales)

    }

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinishActualizarObsEspeciales}
            form={form}
            initialValues={mostrarObsEspeciales}
        >
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameObsEspeciales"
                        label="Descripción Observaciones:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir la descripción de las observaciones" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="catalogo"
                        label="Catálogo:"
                        rules={[
                            {
                                required: true,
                                message: "Campo obligatorio.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Por favor, seleccionar catálogo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {selectCatalogosUsuario}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={onChildrenDrawerCloseModificarObsEspeciales}
                            style={{ marginRight: 8 }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state.loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};