"use strict";
import React, { useEffect } from "react"
import { Form, Row, Col, Input, Button, Modal, Select } from "antd"
import { enviarModificarDatosEmpresas, handleResponse } from "../functions";

export const DrawerModificarDatosEmpresasForm = (props) => {
    const { onChildrenDrawerCloseModificarDatosEmpresa, state, selectCatalogosUsuario, updateStates } = props

    let rowsModificarDatosEmpresa = state.selectedRowsDatosEmpresas;
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            nameEmpresa: state.selectedRowsDatosEmpresas[0].NOMBRE_UCP,
            nameSocial: state.selectedRowsDatosEmpresas[0].NOMBRE_SOCIAL_UCP,
            direccion: state.selectedRowsDatosEmpresas[0].DIRECCION_UCP,
            cif: state.selectedRowsDatosEmpresas[0].CIF_UCP,
            registroSanitario: state.selectedRowsDatosEmpresas[0].REGISTRO_SANITARIO_UCP,
            idEmpresa: state.selectedRowsDatosEmpresas[0].ID_UCP,
            catalogo: catalogoActualCentro,
        })
    }, [state.selectedRowsDatosEmpresas])

    let catalogoActualCentro

    state.listaOptionsCatalogosUsuario.forEach(({ CATALOGO, NOMBRE }) => {

        if (rowsModificarDatosEmpresa[0].CATALOGO === CATALOGO) {
            catalogoActualCentro = `${CATALOGO}.${NOMBRE}`
        }

    })

    const mostrarDatosEmpresas = {
        nameEmpresa: rowsModificarDatosEmpresa[0].NOMBRE_UCP,
        nameSocial: rowsModificarDatosEmpresa[0].NOMBRE_SOCIAL_UCP,
        direccion: rowsModificarDatosEmpresa[0].DIRECCION_UCP,
        cif: rowsModificarDatosEmpresa[0].CIF_UCP,
        registroSanitario: rowsModificarDatosEmpresa[0].REGISTRO_SANITARIO_UCP,
        idEmpresa: rowsModificarDatosEmpresa[0].ID_UCP,
        catalogo: catalogoActualCentro,
    }

    const onFinishActualizarDatosEmpresas = (values) => {

        const { nameEmpresa, nameSocial, direccion, cif, registroSanitario } = values

        const idEmpresa = values.idEmpresa.toString()
        const catalogo = values.catalogo.toString()
        const codigoDatosEmpresas = rowsModificarDatosEmpresa[0].CODIGO_UCP.toString()

        if (
            values.nameEmpresa.indexOf("'") != -1 ||
            values.nameSocial.indexOf("'") != -1 ||
            values.direccion.indexOf("'") != -1
        ) {
            Modal.error({
                title: "Error",
                content:
                    "Existe al menos un valor con el caracter ' . Por favor cambialo por ´ u otro similar.",
            })

            return
        }

        const catalogoConPunto = catalogo.indexOf(".")
        const catalog = catalogoConPunto === -1 ? catalogo : catalogo.substring(0, catalogo.indexOf("."))

        enviarModificarDatosEmpresas(
            codigoDatosEmpresas,
            nameEmpresa,
            nameSocial,
            direccion,
            cif,
            registroSanitario,
            idEmpresa,
            catalog,
            updateStates,
            onChildrenDrawerCloseModificarDatosEmpresa
        )

    }

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinishActualizarDatosEmpresas}
            form={form}
            initialValues={mostrarDatosEmpresas}
        >
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        hasFeedback
                        name="idEmpresa"
                        label="Código Empresa:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el código" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameEmpresa"
                        label="Nombre Empresa:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir nombre empresa" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="nameSocial"
                        label="Nombre Social:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir nombre social" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="direccion"
                        label="Dirección:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir la dirección" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        hasFeedback
                        name="cif"
                        label="CIF:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el CIF" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        hasFeedback
                        name="registroSanitario"
                        label="Registro Sanitario:"
                        rules={[{ required: true, message: "Campo obligatorio." }]}
                    >
                        <Input placeholder="Por favor, escribir el Reg. San" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        hasFeedback
                        name="catalogo"
                        label="Catálogo:"
                        rules={[
                            {
                                required: true,
                                message: "Campo obligatorio.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Por favor, seleccionar catálogo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {selectCatalogosUsuario}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={24}>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={onChildrenDrawerCloseModificarDatosEmpresa}
                            style={{ marginRight: 8 }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state.loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}