//'use strict';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Form,
  Drawer,
  List,
  Card,
  Row,
  Col,
  Typography,
  Input,
  Button,
  Spin,
  Divider,
  Select,
  Table,
  Tooltip,
  Modal,
  ConfigProvider,
  BackTop,
  Checkbox
} from "antd";
import { database } from '../services/database.service';
import { userService } from '../services';
import PDFDocument from 'pdfkit';
import blobStream from 'blob-stream';
import './register-files';
import { DatabaseOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FuncionesAuxiliares } from '../utility/funcionesAuxiliares';
import {
  buildAsciiRecipeImage,
  impressionsBeforeTheIngredients,
  printMarksAndBorders,
  pieGraph,
  printRecipeAllergens,
  printRecipeImage,
  printsIngredientsParagraph,
  printSVG,
  printDescriptive,
  printHeaderSecondPage,
  printFooterSecondPage,
  printTablesPriorNutritionalValue,
  printTablesNutritionalValue
} from '../utility/pdfBuildFunctions'

const textoIconExportarPDF = <span>Imprimir Recetas PDF</span>;
const textoIconVisualizarDetalleRec = <span>Visualizar Detalle Receta</span>;
const { TextArea } = Input;

const f = new Date();
const fechaActual = f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear();
const informacionAlergenos = '* La información sobre alérgenos ha sido obtenida de los datos facilitados por los proveedores y puede estar sujeta a cambios por lo que siempre debe cotejarse con la lectura de etiqueta u otro soporte facilitado por el proveedor.';
const { Title } = Typography;
const { Meta } = Card;

const styleDescNoTrazas = {
  opacity: 0,
};

const styleDescTrazas = {
  opacity: 1,
};

const gridStyle = {
  width: '14%',
  padding: 2,
  textAlign: 'center',
};

const conAlergeno = {
  width: 65,
  height: 70,
};

const sinAlergeno = {
  width: 65,
  height: 70,
  opacity: 0.1,
};

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <DatabaseOutlined style={{ fontSize: 20 }} />
    <p>Datos No Encontrados</p>
  </div>
);

class BuscarPorPlato extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      users: [],
      redirecting: false,
      loading: false,
      iconLoading: false,
      employee: {},
      employees: [],
      options: [],
      listaOptionsFamilia: [],
      optionsFamilia: [],
      statusListaFamilia: null,
      listaCentrosUsuarios: [],
      optionsCentrosUsuarios: [],
      statusCentrosUsuarios: null,
      usuario: null,
      resultadoBusquedaPorPlato: [],
      statusResultadoBusquedaPorPlato: null,
      varallowClearDieta: true,
      statusValueFamilia: null,
      statusValueDieta: null,
      statusValueNomPlato: null,
      filteredInfo: null,
      sortedInfo: null,
      exportarPlatosExcel: [],
      familiaSeleccionada: null,
      dietaSeleccionada: null,
      nombrePlato: null,
      selectedRows: [], // Check here to configure the default column
      selectedRowKeys: [],
      listaFotosRecetas: [],
      loadingSpingBuscarPlato: false,
      textoLoadingSpingBuscarPlato: '',
      visibleModalLogo: false,
      valorSelectLogo: '',
      visibleDrawerDetalleReceta: false,
      recetaIngrdienteDetalle: [],
      codRecetasDetalle: '',
      nomRecetaDetalle: '',
      racionesRecetaDetalle: '',
      familiaRecetaDetalle: '',
      comensalRecetaDetalle: '',
      dietaRecetaDetalle: '',
      caloriasRecetaDetalle: '',
      pesoBrutoRecetaDetalle: '',
      aguaRecetaDetalle: '',
      ingredientesRecetasDetalle: [],
      infoNutricionalReceta: [],
      glutenRecetaDetalle: '',
      crustaceosRecetaDetalle: '',
      huevoRecetaDetalle: '',
      pescadoRecetaDetalle: '',
      cacahuetesRecetaDetalle: '',
      sojaRecetaDetalle: '',
      lecheRecetaDetalle: '',
      frutos_cascarasRecetaDetalle: '',
      apioRecetaDetalle: '',
      mostazaRecetaDetalle: '',
      sesamoRecetaDetalle: '',
      dxo_azufreRecetaDetalle: '',
      altramucesRecetaDetalle: '',
      moluscosRecetaDetalle: '',
      alergenosIncompletoDetalle: '',
      recetasAdicionalIngDetalle: [],
      recetaNoAdicionales: '',
      elaboracionRecetaDetalle: '',
      checkboxImprimirFamiliaPlato: false,
      styleDescGlutenRecetaDetalle: '',
      styleDescSesamoRecetaDetalle: '',
      styleDescSojaRecetaDetalle: '',
      styleDescCrustaceosRecetaDetalle: '',
      styleDescHuevoRecetaDetalle: '',
      styleDescPescadoRecetaDetalle: '',
      styleDescCacahuetesRecetaDetalle: '',
      styleDescLecheRecetaDetalle: '',
      styleDescFrutos_cascarasRecetaDetalle: '',
      styleDescApioRecetaDetalle: '',
      styleDescMostazaRecetaDetalle: '',
      styleDescDxo_azufreRecetaDetalle: '',
      styleDescAltramucesRecetaDetalle: '',
      styleDescMoluscosRecetaDetalle: '',
    };
  }

  /* Sacame todo lo que tengas que sacar de la API antes de imprimir Pantalla Buscar Por Plato */
  componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem('user')),
      users: { loading: true },
      employee: JSON.parse(localStorage.getItem('employee')),
      employees: { loading: true },
      isLoaded: true,
    })
    this.updateLoadingSpingBuscarPorPlato(true, 'Cargando pantalla para buscar platos...')

    this.getListaOptionsFamilia();
    this.getListaCentro();
  }

  /* Obtener el lisatdo de centros del usuario y actualizo estados */
  getListaCentro = () => {

    database.obtenerCentrosUsuarioMongoDB(JSON.parse(localStorage.getItem('user')).sAMAccountName.toUpperCase())
      .then(res => {

        const listadoCentrosInsertados = res.response;

        if (listadoCentrosInsertados.status === 'success' && listadoCentrosInsertados.message.length > 0) {
          this.setState({
            listaCentrosUsuarios: listadoCentrosInsertados.message,
            statusCentrosUsuarios: 'success',
          })
          this.updateLoadingSpingBuscarPorPlato(false, '')

        }

        if (listadoCentrosInsertados.status === 'success' && listadoCentrosInsertados.message.length === 0) {
          this.updateLoadingSpingBuscarPorPlato(false, '')
        }

        if (listadoCentrosInsertados.status !== 'success' || !listadoCentrosInsertados.message?.length) {
          Modal.error({
            title: 'Error',
            content: `Ha ocurrido el siguiente error: ${listadoCentrosInsertados.message}`
          });
        }
      });
  }

  /* Obtengo el listado de familia de los platos y actualizo estado. */
  getListaOptionsFamilia = () => {

    database.getFamiliaAlimentos()
      .then(res => {
        this.setState({
          listaOptionsFamilia: res.response,
          statusListaFamilia: 'success'
        });

        let resultadoFamilia = this.state.listaOptionsFamilia.map(
          opcion => ({
            value: String(opcion.ID_FAMILIA),
            label: opcion.NOMBRE_FAMILIA
          })
        )

        this.setState({ optionsFamilia: resultadoFamilia });
      })
  }

  /* Manejar la paginación al mostrar el resultado de la busqueda de las recetas. */
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  /** 
   * Verificar que el usurio tenga seleccionado el menú y mostrar el Modal dialogs 
   * para que el usuario selecciones el logo que se debe imrimir en la plantilla de la etiqueta.
  */
  confirmacionImprimirRecetasPDF = () => {

    if (this.state.selectedRowKeys.length === 0) {

      Modal.error({
        title: 'Error',
        content: 'Debes seleccionar al menos una receta para imprimir.',
      })

      return null
    }

    this.setState({
      visibleModalLogo: true,
    })

  }

  /**
   * Imprime las recetas seleccionadas por el usuario almacenadas en this.state.selectedRowKeys
   */
  imprimirRecetasPDF = () => {

    this.setState({ listaIngredientesRecetas: [], });

    if (this.state.selectedRowKeys.length > 20) {

      Modal.error({
        title: 'Error',
        content: 'Debes seleccionar como mucho 20 recetas a imprimir.',
      })

    } else {

      this.updateLoadingSpingBuscarPorPlato(true, 'Imprimiendo recetas seleccionadas...')

      /* Recupero todos los ingredientes de las recetas seleccionadas por el usuario. */
        database.getIngredientesRecetas(this.state.selectedRowKeys).then(valor => {

          const listaIngredientesRecetas = valor.response;

            database.getTotalIngredientesRecetas(this.state.selectedRowKeys).then(valor => {

              const listaTotalIngredientesRecetas = valor.response;

              this.setState({ listaFotosRecetas: [] });

              //Obtener lista de recetas con fotos a imprimir
                database.getFotosRecetas(this.state.selectedRowKeys).then(valor => {

                  const fotosRecetas = valor.response;

                  this.setState({ listaFotosRecetas: fotosRecetas });

                  //Crear el documento
                  const doc = new PDFDocument(
                    {
                      layout: "landscape",
                      margin: 5,
                      info: {
                        Title: 'Recetas',
                        Author: 'Alejandro Vázquez Chávez',
                      }
                    }
                  );

                  //Canalizar el documento a un blob
                  const stream = doc.pipe(blobStream());

                  this.state.selectedRows.forEach((row, index) => {
                    if (row != null) {

                      /* Creo el gráfico de pastel de cada receta. */
                      const { arcoGrafico, leyendaGrafico, etiquetaGrafico } = pieGraph(row)

                      printSVG(arcoGrafico, leyendaGrafico, etiquetaGrafico, doc)

                      impressionsBeforeTheIngredients(
                        row,
                        doc,
                        this.state.valorSelectLogo,
                        fechaActual,
                        informacionAlergenos
                      )

                      printsIngredientsParagraph(
                        row,
                        doc,
                        listaIngredientesRecetas,
                        listaTotalIngredientesRecetas
                      )

                      let ascii = buildAsciiRecipeImage(this.state.listaFotosRecetas, row)

                      const { b64, error } = printRecipeImage(doc, ascii)

                      if (error) {
                        Modal.error({
                          title: 'Error',
                          content: 'El formato de la imagen de la recéta ' + row.CODIGO_RECETA + ' es incorrecto. Debes eliminar o actualizar la imagen en Menuplanner.',
                        });

                        this.setState({ loadingSpingBuscarPlato: false })
                      }

                      //Código receta
                      doc
                        .fontSize(18)
                        .text(`${row.CODIGO_RECETA}`, 25, 100, { align: 'left', width: 90 })
                        .moveDown();

                      //Alergenos
                      printRecipeAllergens(doc, row)

                      //Marcas y Bordes
                      printMarksAndBorders(doc, row)

                      //Descriptivo de: INGREDIENTES, ELABORACIÓN DEL PLATO, PORCENTAJE DE NUTRIENTES y ALÉRGENOS
                      printDescriptive(doc, row)

                      //Agrego la primera página
                      doc.addPage();

                      printHeaderSecondPage(doc, b64, ascii, this.state.valorSelectLogo, row)

                      printFooterSecondPage(doc, row, fechaActual)

                      printTablesPriorNutritionalValue(doc, row, this.state.checkboxImprimirFamiliaPlato)

                      printTablesNutritionalValue(doc, row)

                      //Agrego la segunda página
                      if (this.state.selectedRows.length != 1 && index != this.state.selectedRows.length - 1) {
                        doc.addPage()
                      }
                    }
                  })

                  // Obtengo el blob cuando está hecho todo.
                  doc.end();

                  this.setState({ loadingSpingBuscarPlato: false, checkboxImprimirFamiliaPlato: false })

                  stream.on('finish', function () {
                    const url = stream.toBlobURL('application/pdf')
                    window.open(url, '_blank')
                  })
                })
              
            })
          
        })
      
    }
  }

  start = () => {
    this.setState({ loading: true })
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
      })
    }, 1000)
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows })
  }

  visualizarRecetaSeleccionada = (codReceta) => {
    this.setState({
      codRecetasDetalle: codReceta,
    });

    this.obtenerDatosRecetasDetalle(codReceta);
  }

  visualizarDetalleRecetaSeleccionada = () => {

    if (this.state.selectedRows.length === 1) {
      this.obtenerDatosRecetasDetalle(this.state.selectedRows[0].CODIGO_RECETA)
      return
    }

    Modal.error({
      title: 'Error',
      content: this.state.selectedRows.length === 0 ?
        'Debes seleccionar una receta para visualizar su detalle.' :
        'Solo es posible mostrar el detalle de una sola recta y no de varias.',
    })
  }

  obtenerDatosRecetasDetalle = (codigoRecetaDetalle) => {

    database.getRecetasAdicionalesIngredientesDetalle(codigoRecetaDetalle)
      .then(res => {

        const resultadoRecetasAdicIngre = res.response;

        if (resultadoRecetasAdicIngre.length >= 1) {

          const dataRecetasAdicional = resultadoRecetasAdicIngre.map(result => {
            return ({
              codigoReceta: result.CODIGO,
              nombreReceta: result.NOMBRE,
              racion: (result.RACIONES / resultadoRecetasAdicIngre.length),
            })
          })

          this.setState({
            recetasAdicionalIngDetalle: dataRecetasAdicional,
            recetaNoAdicionales: '',
          });

        } else {
          this.setState({
            recetasAdicionalIngDetalle: [],
            recetaNoAdicionales: 'SIN RECETAS ADICIONALES',
          });
        }

      })

    database.getRecetaIngredientesDetalle(codigoRecetaDetalle)
      .then(res => {

        const resultadoRecetaIngredientesDetalle = res.response;

        if (resultadoRecetaIngredientesDetalle.length >= 1) {

          const ingredientesReceta = resultadoRecetaIngredientesDetalle.map(result => {
            return ({
              desc_producto: result.DESC_PRODUCTO,
              cantidad: result.CANTIDAD + ' (g)',
              proveedor: result.NOM_PROVEEVOR,
            })
          })

          let kcalPorRacion = Number(resultadoRecetaIngredientesDetalle[0].CALORIAS.toFixed(2));
          let kjPorRacion = Number(((1.000001104 * kcalPorRacion) / 0.239006).toFixed(2));
          let kcalPor100 = Number(((kcalPorRacion / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2));
          let kjPor100 = Number(((kjPorRacion / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2));

          const valoresNutricionalesReceta = [
            {
              key: '1',
              descripcionNutrientes: 'Kj / Kcal',
              por100G: kjPor100 + ' / ' + kcalPor100,
              porRacion: kjPorRacion + ' / ' + kcalPorRacion,
            },
            {
              key: '2',
              descripcionNutrientes: 'Grasas(g)',
              por100G: Number(((resultadoRecetaIngredientesDetalle[0].LIPIDOS / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2)),
              porRacion: Number(resultadoRecetaIngredientesDetalle[0].LIPIDOS.toFixed(2)),
            },
            {
              key: '3',
              descripcionNutrientes: 'Ácidos grasos saturados(g)',
              por100G: Number(((resultadoRecetaIngredientesDetalle[0].AGS / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2)),
              porRacion: Number(resultadoRecetaIngredientesDetalle[0].AGS.toFixed(2)),
            },
            {
              key: '4',
              descripcionNutrientes: 'Hidratos de Carbono (g)',
              por100G: Number(((resultadoRecetaIngredientesDetalle[0].HIDRATOS_C / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2)),
              porRacion: Number(resultadoRecetaIngredientesDetalle[0].HIDRATOS_C.toFixed(2)),
            },
            {
              key: '5',
              descripcionNutrientes: 'Azúcares (g)',
              por100G: Number(((resultadoRecetaIngredientesDetalle[0].AZUCARES / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2)),
              porRacion: Number(resultadoRecetaIngredientesDetalle[0].AZUCARES.toFixed(2)),
            },
            {
              key: '6',
              descripcionNutrientes: 'Proteínas(g)',
              por100G: Number(((resultadoRecetaIngredientesDetalle[0].PROTEINAS / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2)),
              porRacion: Number(resultadoRecetaIngredientesDetalle[0].PROTEINAS.toFixed(2)),
            },
            {
              key: '7',
              descripcionNutrientes: 'Sal(g)',
              por100G: Number(((resultadoRecetaIngredientesDetalle[0].SAL / resultadoRecetaIngredientesDetalle[0].PESOT) * 100).toFixed(2)),
              porRacion: Number(resultadoRecetaIngredientesDetalle[0].SAL.toFixed(2)),
            },
          ];


          this.setState({
            ingredientesRecetasDetalle: ingredientesReceta,
            infoNutricionalReceta: valoresNutricionalesReceta,
            nomRecetaDetalle: resultadoRecetaIngredientesDetalle[0].NOM_RECETA,
            racionesRecetaDetalle: resultadoRecetaIngredientesDetalle[0].RACIONES,
            familiaRecetaDetalle: resultadoRecetaIngredientesDetalle[0].DESC_FAMILIA,
            comensalRecetaDetalle: resultadoRecetaIngredientesDetalle[0].DESC_COMENSAL,
            dietaRecetaDetalle: resultadoRecetaIngredientesDetalle[0].DIETA,
            caloriasRecetaDetalle: Number((resultadoRecetaIngredientesDetalle[0].CALORIAS).toFixed(2)),
            pesoBrutoRecetaDetalle: Number((resultadoRecetaIngredientesDetalle[0].PESOT).toFixed(2)),
            aguaRecetaDetalle: Number((resultadoRecetaIngredientesDetalle[0].AGUA).toFixed(2)),
            codRecetasDetalle: codigoRecetaDetalle,
            glutenRecetaDetalle: resultadoRecetaIngredientesDetalle[0].GLUTEN === 'NO' ? sinAlergeno : conAlergeno,
            styleDescGlutenRecetaDetalle: resultadoRecetaIngredientesDetalle[0].GLUTEN === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            crustaceosRecetaDetalle: resultadoRecetaIngredientesDetalle[0].CRUSTACEOS === 'NO' ? sinAlergeno : conAlergeno,
            styleDescCrustaceosRecetaDetalle: resultadoRecetaIngredientesDetalle[0].CRUSTACEOS === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            huevoRecetaDetalle: resultadoRecetaIngredientesDetalle[0].HUEVO === 'NO' ? sinAlergeno : conAlergeno,
            styleDescHuevoRecetaDetalle: resultadoRecetaIngredientesDetalle[0].HUEVO === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            pescadoRecetaDetalle: resultadoRecetaIngredientesDetalle[0].PESCADO === 'NO' ? sinAlergeno : conAlergeno,
            styleDescPescadoRecetaDetalle: resultadoRecetaIngredientesDetalle[0].PESCADO === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            cacahuetesRecetaDetalle: resultadoRecetaIngredientesDetalle[0].CACAHUETES === 'NO' ? sinAlergeno : conAlergeno,
            styleDescCacahuetesRecetaDetalle: resultadoRecetaIngredientesDetalle[0].CACAHUETES === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            sojaRecetaDetalle: resultadoRecetaIngredientesDetalle[0].SOJA === 'NO' ? sinAlergeno : conAlergeno,
            styleDescSojaRecetaDetalle: resultadoRecetaIngredientesDetalle[0].SOJA === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            lecheRecetaDetalle: resultadoRecetaIngredientesDetalle[0].LECHE === 'NO' ? sinAlergeno : conAlergeno,
            styleDescLecheRecetaDetalle: resultadoRecetaIngredientesDetalle[0].LECHE === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            frutos_cascarasRecetaDetalle: resultadoRecetaIngredientesDetalle[0].FRUTOS_CASCARAS === 'NO' ? sinAlergeno : conAlergeno,
            styleDescFrutos_cascarasRecetaDetalle: resultadoRecetaIngredientesDetalle[0].FRUTOS_CASCARAS === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            apioRecetaDetalle: resultadoRecetaIngredientesDetalle[0].APIO === 'NO' ? sinAlergeno : conAlergeno,
            styleDescApioRecetaDetalle: resultadoRecetaIngredientesDetalle[0].APIO === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            mostazaRecetaDetalle: resultadoRecetaIngredientesDetalle[0].MOSTAZA === 'NO' ? sinAlergeno : conAlergeno,
            styleDescMostazaRecetaDetalle: resultadoRecetaIngredientesDetalle[0].MOSTAZA === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            sesamoRecetaDetalle: resultadoRecetaIngredientesDetalle[0].SESAMO === 'NO' ? sinAlergeno : conAlergeno,
            styleDescSesamoRecetaDetalle: resultadoRecetaIngredientesDetalle[0].SESAMO === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            dxo_azufreRecetaDetalle: resultadoRecetaIngredientesDetalle[0].DXO_AZUFRE === 'NO' ? sinAlergeno : conAlergeno,
            styleDescDxo_azufreRecetaDetalle: resultadoRecetaIngredientesDetalle[0].DXO_AZUFRE === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            altramucesRecetaDetalle: resultadoRecetaIngredientesDetalle[0].ALTRAMUCES === 'NO' ? sinAlergeno : conAlergeno,
            styleDescAltramucesRecetaDetalle: resultadoRecetaIngredientesDetalle[0].ALTRAMUCES === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            moluscosRecetaDetalle: resultadoRecetaIngredientesDetalle[0].MOLUSCOS === 'NO' ? sinAlergeno : conAlergeno,
            styleDescMoluscosRecetaDetalle: resultadoRecetaIngredientesDetalle[0].MOLUSCOS === 'TRAZAS' ? styleDescTrazas : styleDescNoTrazas,
            alergenosIncompletoDetalle: resultadoRecetaIngredientesDetalle[0].DECLARACION_ALERGENOS,
            elaboracionRecetaDetalle: resultadoRecetaIngredientesDetalle[0].ELABORACION,
            visibleDrawerDetalleReceta: true,
          });

        } else {

          Modal.error({
            title: 'Error',
            content: 'La receta no tiene ingredientes asociados.',
          })

        }
      })
  }

  onFinish = (pCentro, pFamilia, pDieta, pPlato) => {

    this.setState({ loadingSpingBuscarPlato: true })

    this.updateLoadingSpingBuscarPorPlato(true, 'Cargando datos...')

    this.setState({ selectedRows: [], selectedRowKeys: [] })

    const centroSeleccionado = pCentro
    let familiaSeleccionada = pFamilia
    let dietaSeleccionada = pDieta == undefined ? "null" : pDieta
    let nombrePlato = pPlato == undefined ? "null" : pPlato

    this.setState({ loading: true, });

      database.getBusquedaPorPlato(centroSeleccionado, familiaSeleccionada, dietaSeleccionada, nombrePlato).then(valor => {

        const dataTabla = valor.response.map(value => {
          return ({
            CODIGO_RECETA: value.CODIGO_RECETA,
            FT_DIETA: value.FT_DIETA,
            CATALOGO: value.CATALOGO,
            NOMBRE_RECETA: value.NOMBRE_RECETA,
            CALORIAS: Number((value.CALORIAS).toFixed(2)),
            KCALORIAS: Number((value.KCALORIAS).toFixed(2)),
            LIPIDOS: Number((value.LIPIDOS).toFixed(2)),
            FAMILIA: value.FAMILIA,
            DESC_FAMILIA: value.DESC_FAMILIA,
            GLUTEN: value.GLUTEN,
            CRUSTACEOS: value.CRUSTACEOS,
            DESC_CRUSTACEOS: value.DESC_CRUSTACEOS,
            HUEVO: value.HUEVO,
            PESCADO: value.PESCADO,
            DESC_PESCADO: value.DESC_PESCADO,
            CACAHUTES: value.CACAHUTES,
            SOJA: value.SOJA,
            LECHE_Y_DERIVADOS: value.LECHE_Y_DERIVADOS,
            FRUTOS_CASCARAS: value.FRUTOS_CASCARAS,
            DESC_FRUTOS_CASCARAS: value.DESC_FRUTOS_CASCARAS,
            APIO_Y_DERIVADOS: value.APIO_Y_DERIVADOS,
            MOSTAZA_Y_DERIVADOS: value.MOSTAZA_Y_DERIVADOS,
            SESAMO: value.SESAMO,
            DIXIDO_AZUFRE_Y_SULFITOS: value.DIXIDO_AZUFRE_Y_SULFITOS,
            ALTRAMUCES: value.ALTRAMUCES,
            MOLUSCOS: value.MOLUSCOS,
            DESC_MOLUSCOS: value.DESC_MOLUSCOS,
            AGS: FuncionesAuxiliares.nullOrValueAsNumber(value.AGS),
            HIDRATOS_C: FuncionesAuxiliares.nullOrValueAsNumber(value.HIDRATOS_C),
            AZUCARES: FuncionesAuxiliares.nullOrValueAsNumber(value.AZUCARES),
            PROTEINAS: FuncionesAuxiliares.nullOrValueAsNumber(value.PROTEINAS),
            SAL: FuncionesAuxiliares.nullOrValueAsNumber(value.SAL),
            PESOT: FuncionesAuxiliares.nullOrValueAsNumber(value.PESOT),
            DECLARACION_ALERGENOS: value.DECLARACION_ALERGENOS,
            PESON: FuncionesAuxiliares.nullOrValueAsNumber(value.PESON),
            FIBRA: FuncionesAuxiliares.nullOrValueAsNumber(value.FIBRA),
            CALCIO: FuncionesAuxiliares.nullOrValueAsNumber(value.CALCIO),
            HIERRO: FuncionesAuxiliares.nullOrValueAsNumber(value.HIERRO),
            IODO: FuncionesAuxiliares.nullOrValueAsNumber(value.IODO),
            MAGNESIO: FuncionesAuxiliares.nullOrValueAsNumber(value.MAGNESIO),
            ZINC: FuncionesAuxiliares.nullOrValueAsNumber(value.ZINC),
            SODIO: FuncionesAuxiliares.nullOrValueAsNumber(value.SODIO),
            POTASIO: FuncionesAuxiliares.nullOrValueAsNumber(value.POTASIO),
            VITA_B1: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_B1),
            VITA_B2: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_B2),
            NIACINA: FuncionesAuxiliares.nullOrValueAsNumber(value.NIACINA),
            VITA_B6: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_B6),
            A_FOLICO: FuncionesAuxiliares.nullOrValueAsNumber(value.A_FOLICO),
            VITA_B12: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_B12),
            VITA_C: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_C),
            VITA_A: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_A),
            RETINOL: FuncionesAuxiliares.nullOrValueAsNumber(value.RETINOL),
            CAROTENO: FuncionesAuxiliares.nullOrValueAsNumber(value.CAROTENO),
            VITA_D: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_D),
            VITA_E: FuncionesAuxiliares.nullOrValueAsNumber(value.VITA_E),
            COLESTEROL: FuncionesAuxiliares.nullOrValueAsNumber(value.COLESTEROL),
            FOSFORO: FuncionesAuxiliares.nullOrValueAsNumber(value.FOSFORO),
            SELENIO: FuncionesAuxiliares.nullOrValueAsNumber(value.SELENIO),
            ELABORACION: value.ELABORACION,
            DESAYUNO: value.DESAYUNO,
            MEDIAMA: value.MEDIAMA,
            PRIMERO: value.PRIMERO,
            SEGUNDO: value.SEGUNDO,
            POSTRE: value.POSTRE,
            MERIENDA: value.MERIENDA,
            NCOMERCIAL_RECETA: value.NCOMERCIAL_RECETA,
          })
        })

        const rowSelection = {
          onChange: this.onSelectChange,
        };

        const columns = [
          {
            title: 'Código Receta',
            dataIndex: 'CODIGO_RECETA',
            key: 'CODIGO_RECETA',
            defaultSortOrder: 'descend',
            ellipsis: true,
            width: 110,
            fixed: 'left',
          },
          {
            title: 'Nombre Receta',
            dataIndex: 'NCOMERCIAL_RECETA',
            ellipsis: true,
            width: 300,
          },
          {
            title: 'Kj / Kcal',
            dataIndex: 'CALORIAS',
            ellipsis: true,
            width: 100,
          },
          {
            title: 'Grasas(g)',
            dataIndex: 'LIPIDOS',
            ellipsis: true,
            width: 110,
          },
          {
            title: 'AGS(g)',
            dataIndex: 'AGS',
            ellipsis: true,
            width: 100,
          },
          {
            title: 'Hidratos C. (g)',
            dataIndex: 'HIDRATOS_C',
            ellipsis: true,
            width: 140,
          },
          {
            title: 'Azúcares (g)',
            dataIndex: 'AZUCARES',
            ellipsis: true,
            width: 130,
          },
          {
            title: 'Proteínas (g)',
            dataIndex: 'PROTEINAS',
            ellipsis: true,
            width: 130,
          },
          {
            title: 'Sal (g)',
            dataIndex: 'SAL',
            ellipsis: true,
            width: 90,
          },
          {
            title: 'Declaración de Alérgenos',
            dataIndex: 'DECLARACION_ALERGENOS',
            ellipsis: true,
            width: 400,
          }
        ];

        ReactDOM.render(
          <div>
            <ConfigProvider renderEmpty={customizeRenderEmpty}>

              <Tooltip placement="topLeft" title={textoIconExportarPDF}>
                <Button size="default" style={{ boxShadow: '0 0 0 0 rgba(0,123,255,.25)', borderColor: '#fff', border: 0, backgroundColor: '#fff' }} onClick={this.confirmacionImprimirRecetasPDF} type="dashed"><FilePdfOutlined /></Button>
              </Tooltip>

              <Divider type="vertical" />

              <Tooltip placement="topLeft" title={textoIconVisualizarDetalleRec}>
                <Button size="default" style={{ boxShadow: '0 0 0 0 rgba(0,123,255,.25)', borderColor: '#fff', border: 0, backgroundColor: '#fff' }} onClick={this.visualizarDetalleRecetaSeleccionada} type="dashed"><EyeOutlined /></Button>
              </Tooltip>

              <Table bordered onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: event => { this.visualizarRecetaSeleccionada(record.CODIGO_RECETA) },
                };
              }} rowSelection={{ type: 'radio', ...rowSelection }} rowKey={record => record.CODIGO_RECETA} size={"small"} columns={columns} dataSource={dataTabla} scroll={{ x: 1600, y: 600 }} onChange={this.handleChange}
              />
            </ConfigProvider>
          </div>

          , document.getElementById('infoBusquedaPorPlato'));

        this.setState({ loading: false, loadingSpingBuscarPlato: false });

      },
        error => this.setState({ error, loading: true })
      )
    
  }

  loadingSpingBuscarPorPlato = () => {
    this.setState({
      loadingSpingBuscarPlato: true,
      textoLoadingSpingBuscarPlato: 'Cargando datos...'
    });
  }

  updateLoadingSpingBuscarPorPlato = (loadingSpingBuscarPlato, textoLoadingSpingBuscarPlato) => {
    this.setState({
      loadingSpingBuscarPlato,
      textoLoadingSpingBuscarPlato
    })
  }

  onChangeFamilia = (value) => {
    this.setState({
      statusValueFamilia: 'success'
    });
  }

  onChangeDieta = (value) => {
    this.setState({
      statusValueDieta: 'success'
    });
  }

  onChangeNomPlato = (e) => {
    if (e.target.value === '') {
      this.setState({
        statusValueNomPlato: 'null'
      })
      return
    }

    this.setState({
      statusValueNomPlato: 'success'
    })
  }

  /**
  * Comprueba que se haya seleccionado un logo y lanzo la impresión de las recetas llamando a 
  * la función imprimirRecetasPDF y actualizo el estado de mantener vislible el Modal dialogs de los logos. 
  */
  handleOkModalLogo = () => {
    if (this.state.valorSelectLogo.length === 0) {

      Modal.error({
        title: 'Error',
        content: 'Debes seleccionar un logo para imprimir las recetas.',
      });

    } else {
      setTimeout(() => {
        this.setState({ visibleModalLogo: false });
      }, 1000);
      this.imprimirRecetasPDF();
    }
  };

  // Actualizo el estado de mantener vislible el Modal dialogs de la info de las recetas. 
  handleOkModalInfoRecetas = () => {
    setTimeout(() => {
      this.setState({ visibleModalInfoRecetas: false });
    }, 1000);
  };

  // Actualizo el estado de mantener vislible el Modal dialogs de los logos al usuario cancelar la acción.
  handleCancelModalLogo = () => {
    this.setState({ visibleModalLogo: false });
  };

  // Actualizo el estado de mantener vislible el Modal dialogs de la info de las recetas pinchada por el usuari al cancelar la acción.
  handleCancelModalInfoRecetas = () => {
    this.setState({ visibleModalInfoRecetas: false });
  };

  // Actualizo el estado con el valor del logo seleccionado por el usuario.
  onChangeSelectLogo = (value) => {
    this.setState({
      statusValueFamilia: 'success',
      valorSelectLogo: value,
    });
  }

  // Actualizo el estado de visibleDrawerDetalleReceta para cerrarlo
  onCloseDrawer = () => {
    this.setState({
      visibleDrawerDetalleReceta: false,
    });
  };

  onChangeCheckboxImprimirFamiliaPlato = (e) => {

    this.setState({
      checkboxImprimirFamiliaPlato: e.target.checked,
    });

  };

  render() {

    const pStyle = {
      fontSize: 16,
      lineHeight: '24px',
      display: 'block',
      marginBottom: 16,
    };

    const DescriptionItem = ({ title, content }) => (
      <div
        className="site-description-item-profile-wrapper"
        style={{
          fontSize: 14,
          lineHeight: '22px',
          marginBottom: 7,
        }}
      >
        <p
          className="site-description-item-profile-p"
          style={{
            marginRight: 8,
            display: 'inline-block',
          }}
        >
          {title}:
        </p>
        {content}
      </div>
    )

    const columnsRecetasAdicional = [
      {
        title: 'Código Receta',
        dataIndex: 'codigoReceta',
        key: 'codigoReceta',
        ellipsis: true,
        width: 125,
      },
      {
        title: 'Nombre Receta',
        dataIndex: 'nombreReceta',
        key: 'nombreReceta',
        ellipsis: true,
      },
      {
        title: 'Raciones',
        dataIndex: 'racion',
        key: 'racion',
        align: 'center',
        ellipsis: true,
      },
    ];

    const columnsInfoNutricional = [
      {
        title: 'Descripción Nutrientes',
        dataIndex: 'descripcionNutrientes',
        key: 'descripcionNutrientes',
        ellipsis: true,
      },
      {
        title: 'Por 100g',
        dataIndex: 'por100G',
        key: 'por100G',
        align: 'center',
        ellipsis: true,
      },
      {
        title: 'Por Ración',
        dataIndex: 'porRacion',
        key: 'porRacion',
        align: 'center',
        ellipsis: true,
      },
    ];

    const { Option } = Select;

    let resultadoFamilia = this.state.listaOptionsFamilia.map(({ ID_FAMILIA, NOMBRE_FAMILIA }) => {
      return (
        <Select.Option key={ID_FAMILIA} label={NOMBRE_FAMILIA} title={NOMBRE_FAMILIA}>
          {NOMBRE_FAMILIA}
        </Select.Option>
      )
    })

    let resultadoCentros = this.state.listaCentrosUsuarios.map(({ CATALOGO, ID_CENTRO, COD_CENTRO, DESC_CENTRO }) => {
      return (
        <Select.Option key={`${CATALOGO}.${ID_CENTRO}`} label={`${COD_CENTRO}.${DESC_CENTRO}`} title={`${COD_CENTRO}.${DESC_CENTRO}`}>
          {`${COD_CENTRO}.${DESC_CENTRO}`}
        </Select.Option>
      )
    })

    const {
      loadingSpingBuscarPlato,
      textoLoadingSpingBuscarPlato,
      selectedRows,
      visibleModalLogo,
      visibleDrawerDetalleReceta,
      codRecetasDetalle,
    } = this.state;

    const hasSelected = selectedRows.length > 0;

    const AdvancedSearchForm = () => {

      const [form] = Form.useForm();

      const onFinish = (values) => {

        event.preventDefault()

        let valoresBuscarPlato = Object.values(values);

        let selectCentro = valoresBuscarPlato[0];
        let selectDieta = valoresBuscarPlato[1];
        let selectFamilia = valoresBuscarPlato[2];
        let inputPlato = valoresBuscarPlato[3];

        this.onFinish(selectCentro, selectDieta, selectFamilia, inputPlato);
      }

      return (
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >

          <Row gutter={8}>
            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }} style={{ float: "left" }}>

              <Form.Item
                hasFeedback
                name='select-Centro'
                rules={[
                  {
                    required: true,
                    message: 'Campo obligatorio'
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seleccionar Centro"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {resultadoCentros}
                </Select>

              </Form.Item>

            </Col>

            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }} style={{ float: "left" }}>

              <Form.Item
                hasFeedback
                name='select-Familia'
                rules={[
                  {
                    required: true,
                    message: 'Campo obligatorio'
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seleccionar Familia"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {resultadoFamilia}
                </Select>

              </Form.Item>

            </Col>

            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }} style={{ float: "left" }}>

              <Form.Item name='select-Dieta'

                hasFeedback
                rules={[
                  {
                    required: false,
                    message: 'Campo obligatorio'
                  },
                ]}>
                <Select placeholder="Seleccionar Dieta" >
                  <Option key="1" value="S">SI</Option>
                  <Option key="0" value="N">NO</Option>
                </Select>

              </Form.Item>

            </Col>

            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }} style={{ float: "left" }}>

              <Form.Item
                name='input-Plato'
                hasFeedback
                rules={[
                  {
                    required: false,
                    message: 'Campo obligatorio'
                  },
                ]}>
                <Input placeholder="Nombre Receta" />

              </Form.Item>

            </Col>
          </Row >

          <Row gutter={8} >

            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 14, offset: 1 }} style={{ float: "left", marginTop: 0 }}>
              <span> {hasSelected ? `Receta seleccionada: ${selectedRows[0].NOMBRE_RECETA}` : ''}</span>
            </Col>

            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 8, offset: 1 }} >

              <Button type="primary" htmlType="submit" style={{ float: "right", marginLeft: 20, marginRight: 5, marginTop: 0 }}>
                Aceptar
              </Button>

              <Button key="back" onClick={() => { form.resetFields(); }} style={{ float: "right", marginTop: 0 }}>
                Cancelar
              </Button>

            </Col>

          </Row>

        </Form>
      )
    }

    return (
      <div>
        <Spin spinning={loadingSpingBuscarPlato} delay={500} tip={textoLoadingSpingBuscarPlato}>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div>
              <AdvancedSearchForm />

              <div id="infoBusquedaPorPlato"> </div>

              <Modal
                visible={visibleModalLogo}
                title="Imprimir Receta"
                maskClosable={false}
                onOk={this.handleOkModalLogo}
                onCancel={this.handleCancelModalLogo}
                footer={[
                  <Button key="back" onClick={this.handleCancelModalLogo}>
                    Cancelar
                  </Button>,
                  <Button key="submit" type="primary" onClick={this.handleOkModalLogo}>
                    Aceptar
                  </Button>,
                ]}>
                <p className="select-modal">
                  Debes seleccionar el logo que desea imprimir en las recetas y si deseas imprimir la familia de la misma.
                </p>

                <Row span={24}>

                  <Col span={12}>
                    <Checkbox
                      checked={this.state.checkboxImprimirFamiliaPlato}
                      onChange={this.onChangeCheckboxImprimirFamiliaPlato}
                    >
                      Imprimir familia del plato.
                    </Checkbox>
                  </Col>

                  <Col span={12}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      id="selectBuscador"
                      placeholder="Seleccionar Logo"
                      optionFilterProp="children"
                      onChange={this.onChangeSelectLogo}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value="images/logoCompassGroup.png">Compass Group</Option>
                      <Option value="images/logoEurestColectividades.png">Eurest Colectividades</Option>
                      <Option value="images/logoEurestCatalunya.png">Eurest Catalunya</Option>
                      <Option value="images/logoEurestEuskadi.png">Eurest Euskadi</Option>
                      <Option value="images/logoEurestServices.png">Eurest Services</Option>
                      <Option value="images/logoMedirest.png">Medirest</Option>
                      <Option value="images/logoScolarest.png">Scolarest</Option>
                      <Option value="images/logoVilaplana.png">Vilaplana</Option>
                      <Option value="images/logoVitarest.png">Vitarest</Option>
                    </Select>

                  </Col>
                </Row>
              </Modal>

              <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={this.onCloseDrawer}
                visible={visibleDrawerDetalleReceta}
              >
                <Title level={4} style={{ ...pStyle, marginBottom: 24 }}>Detalle Receta</Title>

                <Row id="ant-row1">

                  <Col span={24}>
                    <DescriptionItem title="Nombre" content={this.state.nomRecetaDetalle} />
                  </Col>

                </Row>
                <Row>

                  <Col span={8}>
                    <DescriptionItem title="Código" content={codRecetasDetalle} />
                  </Col>

                  <Col span={16}>
                    <DescriptionItem title="Familia" content={this.state.familiaRecetaDetalle} />
                  </Col>

                </Row>
                <Row>

                  <Col span={8}>
                    <DescriptionItem title="Raciones" content={this.state.racionesRecetaDetalle} />
                  </Col>

                  <Col span={8}>
                    <DescriptionItem title="Dieta" content={this.state.dietaRecetaDetalle} />
                  </Col>

                  <Col span={8}>
                    <DescriptionItem title="Comensal" content={this.state.comensalRecetaDetalle} />
                  </Col>

                </Row>

                <Row>

                  <Col span={8}>
                    <DescriptionItem title="Calorías" content={this.state.caloriasRecetaDetalle} />
                  </Col>

                  <Col span={8}>
                    <DescriptionItem title="Agua [g]" content={this.state.aguaRecetaDetalle} />
                  </Col>

                  <Col span={8}>
                    <DescriptionItem title="Peso Bruto [g]" content={this.state.pesoBrutoRecetaDetalle} />
                  </Col>

                </Row>

                <Divider orientation="left">Ingredientes</Divider>

                <List
                  size="small"
                  dataSource={this.state.ingredientesRecetasDetalle}
                  renderItem={item => (
                    <List.Item
                      key={item.id}
                    >
                      <List.Item.Meta
                        title={item.desc_producto + ' - ' + item.cantidad}
                        description={item.proveedor}
                      />
                    </List.Item>
                  )}
                />

                <Divider orientation="left">Elaboración</Divider>

                <TextArea value={this.state.elaboracionRecetaDetalle} autoSize disabled />

                <Divider orientation="left">Declaración de Alérgenos</Divider>

                <Row>

                  <Col span={24} style={{ color: '#f3000c', marginBottom: 10 }}>
                    <span> {this.state.alergenosIncompletoDetalle === 'INCOMPLETA' ? 'Declaración de alérgenos INCOMPLETA.' : ''}</span>
                  </Col>

                </Row>

                <Card bordered={false} style={{ marginBottom: 30 }}>
                  <Card.Grid style={gridStyle}><img style={this.state.glutenRecetaDetalle} alt="example" src="src/assets/images/alergenoGluten.png" /><Meta style={this.state.styleDescGlutenRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.crustaceosRecetaDetalle} alt="example" src="src/assets/images/alergenoCrustaceos.png" /><Meta style={this.state.styleDescCrustaceosRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.huevoRecetaDetalle} alt="example" src="src/assets/images/alergenoHuevos.png" /><Meta style={this.state.styleDescHuevoRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.pescadoRecetaDetalle} alt="example" src="src/assets/images/alergenoPescado.png" /><Meta style={this.state.styleDescPescadoRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.cacahuetesRecetaDetalle} alt="example" src="src/assets/images/alergenoCacahuetes.png" /><Meta style={this.state.styleDescCacahuetesRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.sojaRecetaDetalle} alt="example" src="src/assets/images/alergenoSoja.png" /><Meta style={this.state.styleDescSojaRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.lecheRecetaDetalle} alt="example" src="src/assets/images/alergenoLacteos.png" /><Meta style={this.state.styleDescLecheRecetaDetalle} title="Trazas" /></Card.Grid>

                </Card>
                <Card bordered={false} style={{ marginBottom: 30 }}>
                  <Card.Grid style={gridStyle}><img style={this.state.frutos_cascarasRecetaDetalle} alt="example" src="src/assets/images/alergenoFrutaCascara.png" /><Meta style={this.state.styleDescFrutos_cascarasRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.apioRecetaDetalle} alt="example" src="src/assets/images/alergenoApio.png" /><Meta style={this.state.styleDescApioRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.mostazaRecetaDetalle} alt="example" src="src/assets/images/alergenoMostaza.png" /><Meta style={this.state.styleDescMostazaRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.sesamoRecetaDetalle} alt="example" src="src/assets/images/alergenoGranosSesamo.png" /><Meta style={this.state.styleDescSesamoRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.dxo_azufreRecetaDetalle} alt="example" src="src/assets/images/alergenoDxoAzufreSulfitos.png" /><Meta style={this.state.styleDescDxo_azufreRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.altramucesRecetaDetalle} alt="example" src="src/assets/images/alergenoAltramuces.png" /><Meta style={this.state.styleDescAltramucesRecetaDetalle} title="Trazas" /></Card.Grid>
                  <Card.Grid style={gridStyle}><img style={this.state.moluscosRecetaDetalle} alt="example" src="src/assets/images/alergenoMoluscos.png" /><Meta style={this.state.styleDescMoluscosRecetaDetalle} title="Trazas" /></Card.Grid>
                </Card>

                <Divider orientation="left">Información Nutricional</Divider>

                <Table columns={columnsInfoNutricional} dataSource={this.state.infoNutricionalReceta} size={"small"} pagination={false} bordered={true} />

                <Divider orientation="left">Platos Adicionales</Divider>

                <Col span={24} style={{ color: '#f3000c', marginBottom: 10 }}>
                  <span> {this.state.recetaNoAdicionales === 'SIN RECETAS ADICIONALES' ? 'Esta receta no tiene platos adicionales.' : ''}</span>
                </Col>

                <Table columns={columnsRecetasAdicional} size={"small"} dataSource={this.state.recetasAdicionalIngDetalle} pagination={false} bordered={true} />

              </Drawer>

            </div>
          </ConfigProvider>
        </Spin>
        <BackTop />
      </div>
    );
  }
}

export default BuscarPorPlato;