"use strict";
import React from "react"
import { Button, Modal, Tooltip } from "antd"
import { database } from "../../../services/database.service"
import { userService } from "../../../services"
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons"


export const EliminarDatosClientesTooltip = (props) => {
    const { state, showDrawerClientes } = props

    const textoEliminarClientes = <span>Eliminar Datos de Clientes</span>

    return (
        <Tooltip placement="topLeft" title={textoEliminarClientes}>
            <Button
                size="default"
                style={{
                    boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                    borderColor: "#fff",
                    border: 0,
                    backgroundColor: "#fff",
                }}
                type="dashed"
                onClick={() => showConfirmModalEliminarDatosClientes(state, showDrawerClientes)}
            >
                <DeleteOutlined />
            </Button>
        </Tooltip>
    )
}

const showConfirmModalEliminarDatosClientes = (state, showDrawerClientes) => {
    if (!state.selectedRowKeysDatosClientes.length === 1 || !state.selectedRowsDatosClientes.length === 1) {

        Modal.error({
            title: "Error",
            content: "Debes seleccionar el cliente que deseas eliminar.",
        })

        return
    }

    const codigo = state.selectedRowsDatosClientes[0].CODIGO_CLIENTES.toString();

    Modal.confirm({
        title:
            `¿Deseas eliminar los Datos del Cliente seleccionado: ${state.selectedRowsDatosClientes[0].NOMBRE_CLIENTES}?`,
        icon: <ExclamationCircleOutlined />,
        content: "Esta acción eliminará los Datos del Cliente seleccionado.",
        okText: "Aceptar",
        cancelText: "Cancelar",
        onOk: () => {
            userService.secure().then;
            {
                database.eliminarDatosClientes(
                    codigo,
                    JSON.parse(localStorage.getItem("user")).sAMAccountName
                ).then((res) => {
                    const resEliminarDatosClientes = Object.values(res.response);

                    if (resEliminarDatosClientes[0] === "success") {

                        Modal.success({
                            title: "Éxito",
                            content: resEliminarDatosClientes[1],
                        })

                        return showDrawerClientes()

                    } else {

                        Modal.error({
                            title: "Error",
                            content: resEliminarDatosClientes[1],
                        })

                    }
                },
                    (error) => console.log(error)
                );
            }
        },
        onCancel() {
            console.log(
                "Se ha cancelado la acción de eliminar Datos del CLiente."
            );
        },
    });
}