import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { PrivateRoute } from '../components'
import HomePage from '../home_page/HomePage'
import LoginPage from '../login_page/LoginPage'
import MaintenanceMode from '../components/maintenanceMode'
import { checkMaintenanceStatus } from '../services/maintenance'
import { MAINTENANCE_TIME_TO_RELOAD_CHECK } from '../utility/constants'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maintenanceStatus: false,
      maintenanceStatusMessage: '',
    }
  }

  componentDidMount() {
    this.checkMaintenance()
    this.interval = setInterval(() => this.checkMaintenance(), MAINTENANCE_TIME_TO_RELOAD_CHECK)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  async checkMaintenance() {
    try {
      const { status, message } = await checkMaintenanceStatus()
      this.setState({
        maintenanceStatus: status,
        maintenanceStatusMessage: message,
      })
    } catch (error) {
      console.error('Error fetching maintenance status:', error)
    }
  }

  render() {
    return (
      <div>
        {this.state.maintenanceStatus ? (
          <MaintenanceMode message={this.state.maintenanceStatusMessage} />
        ) : (
          <Router>
            <PrivateRoute exact path='/' component={HomePage} />
            <Route path='/login' component={LoginPage} />
          </Router>
        )}
      </div>
    )
  }
}

export default App
