"use strict";
import React from "react"
import { Button, Modal, Tooltip } from "antd"
import { database } from "../../../services/database.service"
import { userService } from "../../../services"
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons"

export const EliminarDatosLineasDeProduccion = (props) => {
    const {
        showDrawerLineasProduccion,
        state
    } = props

    const textoEliminarLineasProduccion = (<span>Eliminar Líneas de Producción</span>)

    return (
        <Tooltip
            placement="topLeft"
            title={textoEliminarLineasProduccion}
        >
            <Button
                size="default"
                style={{
                    boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                    borderColor: "#fff",
                    border: 0,
                    backgroundColor: "#fff",
                }}
                type="dashed"
                onClick={() => showConfirmModalEliminarLineasProduccion(state, showDrawerLineasProduccion)}
            >
                <DeleteOutlined />
            </Button>
        </Tooltip>
    )
}

const showConfirmModalEliminarLineasProduccion = (state, showDrawerLineasProduccion) => {
    if (!state.selectedRowKeys.length === 1 || !state.selectedRows.length === 1) {

        Modal.error({
            title: "Error",
            content:
                "Debes seleccionar la Línea de Producción que deseas eliminar.",
        })

    }

    const codigo = state.selectedRows[0].CODIGO_LINEA_PRODUCCION.toString();

    Modal.confirm({
        title:
            `¿Deseas eliminar la Línea de Producción seleccionada: ${state.selectedRows[0].NOMBRE_LINEA_PRODUCCION}?`,
        icon: <ExclamationCircleOutlined />,
        content:
            "Esta acción eliminará los Datos de la Línea de Producción seleccionada.",
        okText: "Aceptar",
        cancelText: "Cancelar",
        onOk: () => {
            userService.secure().then;
            {
                database
                    .eliminarLineasProduccion(
                        codigo,
                        JSON.parse(localStorage.getItem("user")).sAMAccountName
                    ).then((res) => {
                        const resEliminarLineasProduccion = Object.values(res.response);

                        if (resEliminarLineasProduccion[0] === "success") {

                            Modal.success({
                                title: "Éxito",
                                content: resEliminarLineasProduccion[1],
                            })

                            return showDrawerLineasProduccion()

                        } else {

                            Modal.error({
                                title: "Error",
                                content: resEliminarLineasProduccion[1],
                            })

                        }
                    },
                        (error) => console.log(error)
                    );
            }
        },
        onCancel() {
            console.log(
                "Se ha cancelado la acción de eliminar Líneas de Producción."
            );
        },
    })
}