import styled from 'styled-components'

export const StyledMaintenanceMode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  font-size: 2rem;
  line-height: 1.5;
  white-space: pre-line;
  text-align: center;
`
