import { authHeader } from "../helpers";

export const database = {
  getFamiliaAlimentos,
  getCentrosUsuarios,
  getBusquedaPorPlato,
  getFotosRecetas,
  getIngredientesRecetas,
  getEstadosMenus,
  getMenus,
  getRecetasMenu,
  getRecetaIngredientesDetalle,
  getRecetasAdicionalesIngredientesDetalle,
  getCatalogosUsuario,
  getIdiomas,
  getListadoRecetasNCIdiomas,
  insertarDatosEmpresas,
  obtenerDatosEmpresas,
  actualizarDatosEmpresas,
  eliminarDatosEmpresas,
  insertarClientes,
  obtenerDatosClientes,
  actualizarDatosClientes,
  eliminarDatosClientes,
  insertarLineasProduccion,
  obtenerLineasProduccion,
  actualizarLineasProduccion,
  eliminarLineasProduccion,
  insertarObsEspeciales,
  obtenerObsEspeciales,
  actualizarObsEspecial,
  eliminarObsEspeciales,
  obtenerDatosPlatoPrincipalGuarnicion,
  getTotalIngredientesRecetas,
  obtenerMenusMensualesCentros,
  obtenerRecetasIngredientesFichasTecnicas,
  insertarCentrosusuarioMongoDB,
  obtenerCentrosUsuarioMongoDB,
  eliminarCentrosUsuarioMongoDB,
  getRecetasBloques,
  obtenerBarcode,
  getImprimirRecetasMenu,
  obtenerRoles,
  obtenerUsuarioRoles,
  insertarUsuarioRoles,
  obtenerCentrosActivos,
  obtenerCatalogosActivos,
  obtenerCatalogosUsuario,
  obtenerCentrosUsuario,
  getListadoRecetasNCIdiomasConTipoPlato,
  getFotosRecetasPlantillas,
  obtenerDatosInformeHojaProduccion,
  obtenerMenusHojaProduccion,
  obtenerDietasClientesComensales,
  actualizarDietasClientes,
  getEtiquetas,
};

const BASE_URL = process.env.DATA_BASE_API_URL;
const MONGO_URL = process.env.DATA_BASE_MONGO_API_URL;

async function getEtiquetas() {
  return await callAPI(BASE_URL + "/api/logos-etiquetas/");
}

async function getFamiliaAlimentos() {
  return await callAPI(BASE_URL + "/api/familia/");
}

async function getCentrosUsuarios(login) {
  return await callAPI(BASE_URL + "/api/centrosUsuario/" + login);
}

async function getBusquedaPorPlato(a, b, c, d) {
  return await callAPI(`${BASE_URL}/api/buscarPorPlato/${a}/${b}/${c}/${d}`);
}

async function getFotosRecetas(id) {
  return await callAPI(BASE_URL + `/api/fotoRecetas/${id}`);
}
async function getFotosRecetasPlantillas(id) {
  return await callAPI(BASE_URL + `/api/foto-recetas/${id}`);
}

async function getIngredientesRecetas(a) {
  return await callAPI(BASE_URL + "/api/buscarIngRecetas/" + a);
}

async function getTotalIngredientesRecetas(a) {
  return await callAPI(BASE_URL + "/api/recuperarTotalIngredientes/" + a);
}

async function getEstadosMenus() {
  return await callAPI(BASE_URL + "/api/estadosMenus/");
}

async function getMenus(a, b, c, d) {
  return await callAPI(
    BASE_URL + "/api/menus/" + a + "/" + b + "/" + c + "/" + d
  );
}

async function getRecetasMenu(a, b, c, d) {
  const url = BASE_URL + "/api/buscar-recetas-menu/";
  const data = {
    parametroCodigoMenu: a,
    parametroListadoRecetasMenu: b,
    parametroFechaServicio: c,
    parametroTipoMenu: d,
  };

  return await callAPI(url, "POST", data);
}

async function getImprimirRecetasMenu(a, b, c, d) {
  const url = BASE_URL + "/api/buscar-imprimir-recetas-menu/";
  const data = {
    parametroCodigoMenu: a,
    parametroListadoRecetasMenu: b,
    parametroFechaServicio: c,
    parametroTipoMenu: d,
  };
  return await callAPI(url, "POST", data);
}

async function getRecetasBloques(a, b, c) {
  const url = BASE_URL + "/api/buscar-recetas-bloques/";
  const data = {
    parametroCatalogo: a,
    parametroListadoRecetas: b,
    parametroIdioma: c,
  };
  return await callAPI(url, "POST", data);
}

async function getRecetaIngredientesDetalle(a) {
  return await callAPI(BASE_URL + "/api/waRecetaIngrediente/" + a);
}

async function getRecetasAdicionalesIngredientesDetalle(a) {
  return await callAPI(BASE_URL + "/api/waRecetasAdicIngredientes/" + a);
}

async function getCatalogosUsuario(login) {
  return await callAPI(BASE_URL + "/api/catalogosUsuario/" + login);
}

async function getIdiomas() {
  return await callAPI(BASE_URL + "/api/idiomas/");
}

async function getListadoRecetasNCIdiomas(a, b) {
  return await callAPI(BASE_URL + "/api/buscarRecetasIdioma/" + a + "/" + b);
}

async function getListadoRecetasNCIdiomasConTipoPlato(a, b) {
  return await callAPI(
    BASE_URL + "/api/recetas-idioma-tipo-plato/" + a + "/" + b
  );
}

async function insertarDatosEmpresas(a, b, c, d, e, f, g, h) {
  const url = BASE_URL + "/api/insertar-datos-empresas/";
  const data = {
    parametroNombreUCP: a,
    parametroNombreSocialUCP: b,
    parametroDireccionUCP: c,
    parametroCifUCP: d,
    parametroRegSanUCP: e,
    parametroUsuario: f,
    parametroIdUCP: g,
    parametroSelectCatalogo: h,
  };
  return await callAPI(url, "POST", data);
}

async function obtenerDatosEmpresas(a, b) {
  const url = BASE_URL + "/api/obtener-datos-empresas/";
  const data = { parametroCodigo: a, parametroListaCatalogoId: b };
  return await callAPI(url, "POST", data);
}

async function actualizarDatosEmpresas(a, b, c, d, e, f, g, h, i) {
  const url = BASE_URL + "/api/actualizar-datos-empresas/";
  const data = {
    parametroCodigo: a,
    parametroNombre: b,
    parametroNombreSocial: c,
    parametroDireccion: d,
    parametroCif: e,
    parametroRegSan: f,
    parametroUsuario: g,
    parametroIdUCP: h,
    parametroSelectCatalogo: i,
  };
  return await callAPI(url, "POST", data);
}

async function eliminarDatosEmpresas(a, b) {
  const url = BASE_URL + "/api/eliminar-datos-empresa/";
  const data = { parametroCodigo: a, parametroUsuario: b };
  return await callAPI(url, "POST", data);
}

async function insertarClientes(a, b, c) {
  const url = BASE_URL + "/api/insertar-datos-clientes/";
  const data = {
    parametroNombreC: a,
    parametroUsuario: b,
    parametroCatalogo: c,
  };
  return await callAPI(url, "POST", data);
}

async function obtenerDatosClientes(a) {
  const url = BASE_URL + "/api/obtener-datos-clientes/";
  const data = { parametroListaCatalogoId: a };
  return await callAPI(url, "POST", data);
}

async function actualizarDatosClientes(a, b, c, d) {
  const url = BASE_URL + "/api/actualizar-datos-clientes/";
  const data = {
    parametroCodigoC: a,
    parametroNombreC: b,
    parametroUsuario: c,
    parametroCatalogo: d,
  };
  return await callAPI(url, "POST", data);
}

async function eliminarDatosClientes(a, b) {
  const url = BASE_URL + "/api/eliminar-datos-clientes/";
  const data = { parametroCodigoC: a, parametroUsuario: b };
  return await callAPI(url, "POST", data);
}

async function insertarLineasProduccion(a, b, c, d, e, f, g, h, i) {
  const url = BASE_URL + "/api/insertar-lineas-produccion/";
  const data = {
    parametroNombreLineaProduccion: a,
    parametroTipoFechaCaducidad: b,
    parametroDescTipoFechaCaducidad: c,
    parametroDiasCaducidad: d,
    parametroTextoFechaCducidad: e,
    parametroCondicionesConservacion: f,
    parametroModoEmpleo: g,
    parametroUsuario: h,
    parametroCatalogo: i,
  };
  return await callAPI(url, "POST", data);
}

async function obtenerLineasProduccion(catalogoIds) {
  const url = BASE_URL + "/api/obtener-lineas-produccion/";
  const data = { parametroListaCatalogoId: catalogoIds };
  return await callAPI(url, "POST", data);
}

async function actualizarLineasProduccion(a, b, c, d, e, f, g, h, i, j) {
  const url = BASE_URL + "/api/actualizar-lineas-produccion/";
  const data = {
    parametroCodigoLP: a,
    parametroNombreLP: b,
    parametroTipoFechaCaducidadLP: c,
    descTipoFechaCaducidad: d,
    parametroDiasCaducidadLP: e,
    parametroTextoFechaCaducidadLP: f,
    parametroCondicionesConservacionLP: g,
    parametroModoEmpleoLP: h,
    parametroUsuarioLP: i,
    parametroCatalogo: j,
  };
  return await callAPI(url, "POST", data);
}

async function eliminarLineasProduccion(a, b) {
  const url = BASE_URL + "/api/eliminar-lineas-produccion/";
  const data = { parametroCodigoLP: a, parametroUsuarioLP: b };
  return await callAPI(url, "POST", data);
}

async function insertarObsEspeciales(a, b, c) {
  const url = BASE_URL + "/api/insertar-observaciones-especiales/";
  const data = {
    parametroNombreOE: a,
    parametroUsuarioOE: b,
    parametroCatalogo: c,
  };
  return await callAPI(url, "POST", data);
}

async function obtenerObsEspeciales(a) {
  const url = BASE_URL + "/api/obtener-observaciones-especiales/";
  const data = { parametroListaCatalogoId: a };
  return await callAPI(url, "POST", data);
}

//Actualizar en la BD los datos de las observaciones epeciales
async function actualizarObsEspecial(a, b, c, d) {
  const url = BASE_URL + "/api/actualizar-observaciones-especiales/";
  const data = {
    parametroCodigoOE: a,
    parametroNombreOE: b,
    parametroUsuarioOE: c,
    parametroCatalogo: d,
  };
  return await callAPI(url, "POST", data);
}

async function eliminarObsEspeciales(a, b) {
  const url = BASE_URL + "/api/eliminar-observaciones-especiales/";
  const data = { parametroCodigoOE: a, parametroUsuarioOE: b };
  return await callAPI(url, "POST", data);
}

async function obtenerDatosPlatoPrincipalGuarnicion(a, b) {
  const url = BASE_URL + "/api/waPlatoPrincipalGuarnicion/";
  const data = { codigoPlatoPrincipal: a, codigoPlatoGuarnicion: b };
  return await callAPI(url, "POST", data);
}

async function obtenerMenusMensualesCentros(a, b, c) {
  const url = BASE_URL + "/api/buscar-menu-mensual/";
  const data = {
    parametroCatalogo: a,
    parametroCentro: b,
    parametroFechaServicio: c,
  };
  return await callAPI(url, "POST", data);
}

async function obtenerRecetasIngredientesFichasTecnicas(a) {
  const url = BASE_URL + "/api/wa-receta-ingrediente-ft/";
  const data = { codigoRecetaFT: a };
  return await callAPI(url, "POST", data);
}

async function obtenerBarcode(a) {
  const url = MONGO_URL + "/api/obtener-barcode/";
  const data = { parametroLote: a };
  return await callAPI(url, "POST", data);
}

async function obtenerRoles() {
  const url = BASE_URL + "/api/obtener-roles/";
  return await callAPI(url, "POST");
}

async function obtenerUsuarioRoles(username) {
  const url = BASE_URL + "/api/obtener-usuario-roles/";
  const data = { parametroUsername: username };
  return await callAPI(url, "POST", data);
}

async function obtenerCatalogosActivos() {
  const url = BASE_URL + "/api/obtener-catalogos/";
  return await callAPI(url, "POST");
}

async function obtenerCentrosActivos() {
  const url = BASE_URL + "/api/obtener-todos-centros/";
  return await callAPI(url, "POST");
}

async function insertarUsuarioRoles(a, b, c, d, e, f, g, h, i) {
  const url = BASE_URL + "/api/insertar-usuario/";
  const data = {
    pNombre: a,
    pUsername: b,
    pEmail: c,
    pUsuariocreacion: d,
    pUsuariom: e,
    pRoles: f,
    pCatalogos: g,
    pCentros: h,
    pCentrosCatalogos: i,
  };
  return await callAPI(url, "POST", data);
}

async function obtenerCatalogosUsuario(a) {
  const url = BASE_URL + "/api/obtener-usuario-catalogos/";
  const data = { parametroUsername: a };
  return await callAPI(url, "POST", data);
}

async function obtenerCentrosUsuario(a) {
  const url = BASE_URL + "/api/obtener-usuario-centros/";
  const data = { parametroUsername: a };
  return await callAPI(url, "POST", data);
}

async function insertarCentrosusuarioMongoDB(a) {
  const url = MONGO_URL + "/api/save-usuario-centros/";
  const data = { listadoCentros: a };
  return await callAPI(url, "POST", data);
}

async function obtenerCentrosUsuarioMongoDB(a) {
  const url = MONGO_URL + "/api/get-usuario-centros";
  const data = { usuario: a };
  return await callAPI(url, "POST", data);
}

async function eliminarCentrosUsuarioMongoDB(a) {
  const url = MONGO_URL + "/api/delete-usuario-centros";
  const data = { usuario: a };
  return await callAPI(url, "POST", data);
}

async function obtenerDatosInformeHojaProduccion(data) {
  const url = BASE_URL + "/api/informe-hoja-produccion/";
  return await callAPI(url, "POST", data);
}

async function obtenerMenusHojaProduccion(data) {
  const url = BASE_URL + "/api/obtener-menu-hoja-gestion";
  return await callAPI(url, "POST", data);
}

async function obtenerDietasClientesComensales(data) {
  const url = BASE_URL + "/api/dietas/";
  return await callAPI(url, "POST", data);
}

async function actualizarDietasClientes(data) {
  const url = BASE_URL + "/api/actualizar-dietas/";
  return await callAPI(url, "POST", data);
}

async function callAPI(url, method = "GET", data = null) {
  try {
    const requestOptions = {
      method: method,
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (data) {
      requestOptions.body = JSON.stringify(data);
    }

    const response = await fetch(url, requestOptions);
    if (response.status === 403) {
      handleError(response);
    }
    const responseData = await response.json();
    return { response: responseData };
  } catch (error) {
    console.error("Error:" + error);
  }
}

function handleError(response) {
  if (response == "Unauthorized" || response == "Accesso Denegado.") {
    localStorage.removeItem("user");
    alert("Sesión caducada");
    location.reload(true);
  } else {
    console.error("Error:" + error);
  }
}
